<template>
  <PageHeader
    title="Repeating Invoice"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.entities.finance.label') },
      { label: 'Repeating Invoices', to: '/settings/finance/repeating-invoices' },
      { label: state?.billingAccount?.name || 'New' }
    ]"
  >
  </PageHeader>
  <div v-if="invoiceRequest.isLoading.value || !state">
    <div class="grid sm:grid-cols-1 md:grid-cols-4 gap-x-8">
      <Skeleton width="100%" height="80vh" />
      <div class="md:col-span-3">
        <Skeleton width="100%" height="80vh" />
      </div>
    </div>
  </div>
  <div v-else class="flex gap-6">
    <div class="min-w-72">
      <Card>
        <CardBody>
          <ToggleSwitch v-model="state.isActive" label="Enabled" />

          <div class="mt-6">
            <FormGroup label="Type">
              <RadioTabbed
                v-model="state.isFixedFee"
                selection-mode="single"
                :options="[
                  { value: true, label: 'Fixed' },
                  { value: false, label: 'PAYG' }
                ]"
                :has-error="v$.isFixedFee.$error"
                full-width
                @option-change="toggleIsFixedFee"
              />
              <InputError :has-error="v$.isFixedFee.$error">{{ v$.isFixedFee.$errors[0].$message }}</InputError>
            </FormGroup>

            <FormGroup label="Billing account">
              <Select
                v-model="state.billingAccountId"
                :options="billingAccountsList"
                value-key="id"
                label-key="name"
                searchable
                :has-error="v$.billingAccountId.$error"
                @select="onBillingAccountChange"
              />
              <InputError :has-error="v$.billingAccountId.$error">{{ v$.billingAccountId.$errors[0].$message }}</InputError>
            </FormGroup>

            <FormGroup label="Generation date">
              <DatePicker v-model="state.startDate" :has-error="v$.startDate.$error" />
              <InputError :has-error="v$.startDate.$error">{{ v$.startDate.$errors[0].$message }}</InputError>
            </FormGroup>

            <FormGroup label="Invoice dated">
              <Select
                v-model="state.invoiceDated"
                :options="[
                  { value: 'generation_date', label: 'Generation date' },
                  { value: 'service_period_last_day', label: 'Last day of service period' },
                  { value: 'last_period_last_day', label: 'Last day of prev period' }
                ]"
                :has-error="v$.invoiceDated.$error"
              />
              <InputError :has-error="v$.invoiceDated.$error">{{ v$.invoiceDated.$errors[0].$message }}</InputError>
            </FormGroup>

            <FormGroup label="Due net" class="max-w-32">
              <InputGroup>
                <Input v-model="state.dueNet" :has-error="v$.dueNet.$error" />
                <InputGroupAddon :readonly="readonly" class="whitespace-nowrap">days</InputGroupAddon>
              </InputGroup>
              <InputError :has-error="v$.dueNet.$error">{{ v$.dueNet.$errors[0].$message }}</InputError>
            </FormGroup>

            <FormGroup label="Repetition">
              <RadioTabbed
                v-model="state.repetitionPeriod"
                selection-mode="single"
                :options="[
                  { value: 'month', label: 'Monthly' },
                  { value: 'quarter', label: 'Quarterly' }
                ]"
                :has-error="v$.repetitionPeriod.$error"
                full-width
              />
              <InputError :has-error="v$.repetitionPeriod.$error">{{ v$.repetitionPeriod.$errors[0].$message }}</InputError>
            </FormGroup>

            <FormGroup label="Service period" info="The period which this invoice is charging for">
              <RadioTabbed
                v-model="state.periodToBill"
                selection-mode="single"
                :options="[
                  { value: 'last', label: 'Last' },
                  { value: 'next', label: 'Next' }
                ]"
                :has-error="v$.periodToBill.$error"
                full-width
              />
              <InputError :has-error="v$.periodToBill.$error">{{ v$.periodToBill.$errors[0].$message }}</InputError>
            </FormGroup>
            <div class="flex gap-2 pt-4">
              <!-- <Button label="Preview" severity="secondary" outlined block @click="previewInvoice" /> -->
              <Button label="Save" icon="save" block @click="submitInvoice" />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div class="font-semibold flex items-center gap-2">
            <Icon type="circle-info" class="text-primary" />
            <span>Next Invoice</span>
          </div>

          <div class="grid grid-cols-2 mt-2">
            <div>Generated</div>
            <div class="text-right">{{ nextInvoiceMessage.generated }}</div>
            <div>Dated</div>
            <div class="text-right">{{ nextInvoiceMessage.dated }}</div>
            <div>Due</div>
            <div class="text-right">{{ nextInvoiceMessage.due }}</div>
          </div>
        </CardBody>
      </Card>
    </div>

    <div class="w-full">
      <Card class="w-full">
        <CardBody>
          <div class="text-xl">{{ state.billingAccount?.name }}</div>
          <div v-if="state.billingAccount" class="flex flex-col text-sm mr-12">
            <div>{{ state.billingAccount.address.line1 }}</div>
            <div>{{ state.billingAccount.address.line2 }}</div>
            <div>{{ state.billingAccount.address.line3 }}</div>
            <div>
              {{ state.billingAccount.address.town }}, {{ state.billingAccount.address.county }},
              {{ state.billingAccount.address.postcode }}
            </div>
            <div>{{ state.billingAccount.address.country }}</div>
          </div>
        </CardBody>
      </Card>

      <Card class="w-full">
        <CardBody class="w-full">
          <Accordion>
            <div v-for="dealership in state.dealerships">
              <AccordionItem v-model:is-open="dealership.accordionItemIsOpen" class="mb-4 hover:cursor-pointer">
                <template #header>
                  <div class="flex justify-between pb-4 items-center">
                    <div class="font-medium text-lg flex items-center gap-4 just-center">
                      <Icon :type="dealership.accordionItemIsOpen ? 'chevron-up' : 'chevron-down'" />
                      <div>
                        {{ dealerships?.find(d => d.id == dealership.id)?.displayName || '?' }}
                      </div>
                      <div>
                        <Chip
                          v-if="!dealerships?.find(d => d.id == dealership.id).isActive"
                          icon="triangle-exclamation"
                          severity="warning"
                          size="sm"
                          >Dealership is deactivated</Chip
                        >
                      </div>
                    </div>
                    <div class="flex gap-2 items-center">
                      <Button severity="danger" label="Remove" size="sm" plain @click.stop="removeDealership(dealership.id)" />
                    </div>
                  </div>
                </template>

                <InvoiceItems
                  v-model="dealership.items"
                  :dealership-id="dealership.id"
                  :read-only="!$can('manage_dealership_billing')"
                  @item-validated="invoiceItemsValidated"
                  @item-removed="removeItem"
                  @item-added="addItem"
                />
              </AccordionItem>
            </div>
            <div class="max-w-64">
              <Select
                v-model="dealershipSelected"
                :options="dealershipsSelectable"
                value-key="id"
                label-key="displayName"
                searchable
                placeholder="Add a dealership"
                @select="onDealershipSelected"
              >
                <template #option="{ option }">
                  <div class="flex gap-2">
                    <div>{{ option.displayName }}</div>
                  </div>
                </template>
              </Select>
            </div>
          </Accordion>
        </CardBody>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import PageHeader from '@/components/page/PageHeader.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputGroup from '@/components/forms/InputGroup.vue'
import InputGroupAddon from '@/components/forms/InputGroupAddon.vue'
import Select from '@/components/forms/Select.vue'
import InputError from '@/components/forms/InputError.vue'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import Button from '@/components/button/Button.vue'
import DatePicker from '@/components/forms/DatePicker.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import InvoiceItems from '@/components/invoice/InvoiceItems.vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import Accordion from '@/components/accordion/Accordion.vue'
import AccordionItem from '@/components/accordion/AccordionItem.vue'
import { alertToast } from '@/utilities/notification'

import useApiRequest from '@/composables/useApiRequest'
import dayjs from 'dayjs'

import Skeleton from 'primevue/skeleton'
import Swal from 'sweetalert2'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const emit = defineEmits(['formUpdated', 'isSubmitting', 'completed'])
const route = useRoute()
const invoiceId = ref(route.params.val)

const billingAccountsRequest = useApiRequest()
const billingAccountsList = ref(null)
const getDealershipsRequest = useApiRequest()
const dealerships = ref(null)
const invoiceRequest = useApiRequest()
const newInvoiceRequest = useApiRequest()
const updateInvoiceRequest = useApiRequest()
const dealershipsSelectable = ref(null)
const dealershipSelected = ref(null)

const defaultState = {
  id: null,
  startDate: null,
  dueNet: null,
  endDate: null,
  repetitionPeriod: 'month',
  invoiceDated: 'generation_date',
  isFixedFee: null,
  periodToBill: null,
  isPaused: false,
  isActive: true,
  billingAccountId: null,
  billingAccount: null,
  dealerships: []
}

const state = ref({ ...defaultState })
const isNewTemplate = route.params.val == 'new' ? true : false

if (!isNewTemplate) {
  try {
    invoiceRequest.send({ method: 'GET', endpoint: '/v1/finance/invoice-templates/' + invoiceId.value }).then(response => {
      console.log('response', response)

      if (response.success) {
        state.value = response.data
        state.value = {
          id: response.data.id,
          startDate: response.data.startDate,
          dueNet: response.data.dueNet,
          endDate: response.data.endDate,
          repetitionPeriod: response.data.repetitionPeriod,
          invoiceDated: response.data.invoiceDated,
          isFixedFee: response.data.isFixedFee,
          periodToBill: response.data.periodToBill,
          isPaused: response.data.isPaused,
          isActive: !response.data.isPaused,
          billingAccountId: response.data.billingAccount.id,
          billingAccount: response.data.billingAccount,
          dealerships: [],
          items: response.data.items.map(item => ({ ...item, randId: Math.random(), isValid: false }))
        }
        const groupedItems = state.value.items.reduce((acc, item) => {
          const dealershipId = item.invoiceTemplateDealershipId
          if (!acc[dealershipId]) {
            acc[dealershipId] = { id: dealershipId, accordionItemIsOpen: false, isValid: false, items: [] }
          }
          acc[dealershipId].items.push(item)
          return acc
        }, {})

        // Convert grouped items to an array of dealer objects
        state.value.dealerships = Object.values(groupedItems)
        if (state.value.dealerships.length == 1) {
          state.value.dealerships[0].accordionItemIsOpen = true
        }
      } else {
        alertToast('Failed to fetch invoice', response.message, 'error')
      }
    })
  } catch (err) {
    alertToast('Failed to fetch invoice', err.message, 'error')
  }
}

const inputRules = {
  isActive: { required },
  billingAccountId: { required },
  startDate: { required },
  invoiceDated: { required },
  dueNet: { required },
  repetitionPeriod: { required },
  periodToBill: { required },
  isFixedFee: { required }
}

const v$ = useVuelidate(inputRules, state)

const datePickerConfig = {
  enableTime: false,
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'd/m/Y'
}

try {
  billingAccountsRequest.send({ method: 'GET', endpoint: '/v1/finance/billing-accounts' }).then(response => {
    if (response.success) {
      billingAccountsList.value = response.data
    } else {
      alertToast('Failed to fetch billing accounts', response.message, 'error')
    }
  })
} catch (err) {
  alertToast('Failed to fetch billing accounts', err.message, 'error')
}

try {
  getDealershipsRequest
    .send({ method: 'GET', endpoint: '/v1/dealerships', params: { showAll: true, showDisabled: true } })
    .then(response => {
      if (response.data && response.success) {
        dealerships.value = response.data

        // Add only isActive dealerships to: dealershipsSelectable
        dealershipsSelectable.value = response.data.filter(dealership => dealership.isActive)
      } else {
        alertToast('Failed to fetch dealerships', response.message, 'error')
      }
    })
} catch (error) {
  alertToast('Failed to fetch dealerships', err.message, 'error')
}

async function submitInvoice() {
  const isFormCorrect = await v$.value.$validate()
  const invalidItems = state.value.dealerships.some(dealership => !dealership.isValid)

  if (!isFormCorrect || invalidItems) {
    return
  }

  let dataToPost = {
    id: state.value.id,
    startDate: state.value.startDate,
    dueNet: state.value.dueNet,
    endDate: state.value.endDate,
    repetitionPeriod: state.value.repetitionPeriod,
    invoiceDated: state.value.invoiceDated,
    isFixedFee: state.value.isFixedFee,
    periodToBill: state.value.periodToBill,
    isPaused: !state.value.isActive,
    billingAccount: { ...state.value.billingAccount, id: state.value.billingAccountId },
    items: []
  }

  state.value.dealerships.forEach(dealership => {
    dealership.items.forEach(item => {
      // Set override rate to null if ""
      if (item.overrideRate == '') {
        item.overrideRate = null
      }
      dataToPost.items.push(item)
    })
  })

  console.log(dataToPost)
  if (isNewTemplate) {
    try {
      newInvoiceRequest.send({ method: 'POST', endpoint: '/v1/finance/invoice-templates', data: dataToPost }).then(response => {
        if (response.success) {
          alertToast('Invoice created', response.message, 'success')
        } else {
          alertToast('Failed to create invoice', response.message, 'error')
        }
      })
    } catch (err) {
      alertToast('Failed to create invoice', err.message, 'error')
    }
  } else {
    try {
      updateInvoiceRequest
        .send({ method: 'PATCH', endpoint: '/v1/finance/invoice-templates/' + state.value.id, data: dataToPost })
        .then(response => {
          if (response.success) {
            alertToast('Invoice updated', response.message, 'success')
          } else {
            alertToast('Failed to update invoice', response.message, 'error')
          }
        })
    } catch (err) {
      alertToast('Failed to update invoice', err.message, 'error')
    }
  }
}

function toggleIsFixedFee(isFixed) {
  if (isFixed) {
    state.value.periodToBill = 'last'
  }
}

function invoiceItemsValidated(valid, dealershipId) {
  state.value.dealerships.find(dealership => dealership.id == dealershipId).isValid = valid
  if (!valid) {
    state.value.dealerships.find(dealership => dealership.id == dealershipId).accordionItemIsOpen = true
  }
}

function removeDealership(id) {
  Swal.fire({
    title: 'Are you sure?',
    text: "The dealership will be removed from the invoice, but will keep it's items",
    icon: 'warning',
    reverseButtons: true,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: 'Yes, remove this dealership',
    confirmButtonColor: 'var(--bg-error-solid)',
    cancelButtonText: 'Cancel'
  }).then(function (response) {
    if (response.isConfirmed) {
      let dealership = state.value.dealerships.find(dealership => dealership.id == id)
      let index = state.value.dealerships.indexOf(dealership)

      if (index !== -1) {
        state.value.dealerships.splice(index, 1)
      }
    }
  })
}

function removeItem(randId, dealershipId) {
  console.log('randId', randId)
  console.log('dealershipId', dealershipId)

  let dealership = state.value.dealerships.find(dealership => dealership.id == dealershipId)
  let dealershipIndex = state.value.dealerships.indexOf(dealership)

  let item = state.value.dealerships[dealershipIndex].items.find(item => item.randId == randId)
  let itemIndex = state.value.dealerships[dealershipIndex].items.indexOf(item)

  if (dealershipIndex != -1 && itemIndex != -1) {
    state.value.dealerships[dealershipIndex].items.splice(itemIndex, 1)
  }
}

function addItem(dealershipId, item) {
  state.value.dealerships.find(dealership => dealership.id == dealershipId).items.push(item)
}

function onBillingAccountChange(id) {
  let billingAccount = billingAccountsList.value.find(account => account.id == id)
  state.value.billingAccount = billingAccount
}

function onDealershipSelected() {
  state.value.dealerships.push({
    id: dealershipSelected.value,
    accordionItemIsOpen: true,
    isValid: false,
    items: []
  })
  dealershipSelected.value = null
}

function toggleDealershipAccordion(id) {
  let dealership = state.value.dealerships.find(d => d.id == id)
  if (dealership) {
    dealership.accordionItemIsOpen = !dealership.accordionItemIsOpen
  }
}

const nextInvoiceMessage = computed(() => {
  // get the latest start date
  let currentYear = dayjs().format('YYYY')
  let startDate = dayjs(state.value.startDate).year(currentYear)
  if (dayjs(startDate) > dayjs()) {
    startDate = dayjs(startDate).subtract(1, 'year')
  }
  let generationDateDay = dayjs(state.value.startDate).format('D')

  let invoiceSentDate = null
  // const startDateCurrentYear = dayjs(state.value.startDate).year(currentYear).format('DD-MM-YYYY')
  let invoiceYearQuarters = []
  // console.log('start date this year', startDateCurrentYear)

  if (state.value.repetitionPeriod == 'quarter') {
    invoiceYearQuarters.push(
      dayjs(startDate),
      dayjs(startDate).add(3, 'month').date(generationDateDay),
      dayjs(startDate).add(6, 'month').date(generationDateDay),
      dayjs(startDate).add(9, 'month').date(generationDateDay),
      dayjs(startDate).add(1, 'year').date(generationDateDay)
    )
    for (let i = 0; i <= 3; i++) {
      if (dayjs() > invoiceYearQuarters[i] && dayjs() < invoiceYearQuarters[i + 1]) {
        console.log('between', invoiceYearQuarters[i])
        console.log('and', invoiceYearQuarters[i + 1])
        if (state.value.invoiceDated == 'generation_date') {
          invoiceSentDate = invoiceYearQuarters[i + 1]
        } else if (state.value.invoiceDated == 'service_period_last_day' || state.value.invoiceDated == 'last_period_last_day') {
          invoiceSentDate = dayjs(invoiceYearQuarters[i + 1])
            .date(generationDateDay)
            .subtract(1, 'day')
        }
      }
    }
  } else if (state.value.repetitionPeriod == 'month') {
    let currentMonth = dayjs().month()
    if (state.value.invoiceDated == 'generation_date') {
      invoiceSentDate = dayjs().date(generationDateDay)
      if (dayjs(invoiceSentDate) < dayjs()) {
        invoiceSentDate = dayjs(invoiceSentDate).add(1, 'month')
      }
    } else if (state.value.invoiceDated == 'service_period_last_day' || state.value.invoiceDated == 'last_period_last_day') {
      invoiceSentDate = dayjs().endOf('month')
    }
  }

  // If all data is set, return the formatted dates, else return N/A for each
  if (!state.value.startDate || !state.value.dueNet) {
    return {
      generated: '-',
      dated: '-',
      due: '-'
    }
  }

  return {
    generated: dayjs(state.value.startDate).format('Do MMM YYYY'),
    dated: invoiceSentDate?.format('Do MMM YYYY'),
    due: dayjs(invoiceSentDate).add(state.value.dueNet, 'day').format('Do MMM YYYY')
  }
})
</script>

<style></style>
