// notification.js

import { useToast } from 'vue-toastification'
import CustomToast from '@/components/notifications/NotificationToast.vue'
import NotificationToastCloseButton from '@/components/notifications/NotificationToastCloseButton.vue'

import dayjs from 'dayjs'

/**
 * Displays a notification toast with the given parameters.
 *
 * @param {Object} params - The parameters for the notification toast.
 * @param {Object} notificationData - Additional data to be passed to the toast. (e.g., name, titles, images, etc)
 * @param {Function} onActionClick - Callback function to handle action button clicks.
 */
export function notificationToast(params, notificationData, onActionClick) {
  const defaultParams = {
    design: 'info', // alert, info
    title: 'Notification',
    text: 'This is a notification',
    severity: null,
    icon: 'info',
    avatarUrl: null,
    avatarText: null,
    position: 'top-right',
    timeout: 5000,
    showDismissButton: true,
    showCloseButton: true,
    showActionButton: false,
    actionButtonText: 'View',
    actionButtonLink: null,
    hideProgressBar: true
  }

  const props = { ...defaultParams, ...params }

  if (notificationData?.notificationId) {
    console.log('notificationData', notificationData.data)

    switch (notificationData.notificationId) {
      case 1:
        Object.assign(props, {
          design: 'info',
          title: 'New Appointment',
          text: `Booked for ${notificationData.data.customerName} at ${dayjs(notificationData.data.dateTime).format('ll')}`,
          severity: 'info',
          icon: 'calendar-plus',
          showActionButton: true,
          actionButtonLink: `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
        })
        break
      case 2:
        Object.assign(props, {
          design: 'info',
          title: 'Appointment Updated',
          text: `Appointment for ${notificationData.data.customerName} has changed`,
          severity: 'info',
          icon: 'calendar-pen',
          showActionButton: true,
          actionButtonLink: `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
        })
        break
      case 3:
        Object.assign(props, {
          design: 'info',
          title: 'Appointment Cancelled',
          text: `${notificationData.data.customerName} cancelled their appointment`,
          severity: 'danger',
          icon: 'calendar-times',
          showActionButton: true,
          actionButtonLink: `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
        })
        break
      case 4:
        Object.assign(props, {
          design: 'info',
          title: 'New Appointment Assigned',
          text: `You will be seeing ${notificationData.data.customerName} at ${dayjs(notificationData.data.dateTime).format('LT')} on ${dayjs(notificationData.data.dateTime).format('ll')}`,
          severity: 'info',
          icon: 'calendar-circle-user',
          showActionButton: true,
          actionButtonLink: `/agreement/${notificationData.data.agreementId}/appointments/${notificationData.data.appointmentId}`
        })
        break
      case 14:
        // Assuming the second case should have a different ID, e.g., 15
        Object.assign(props, {
          design: 'info',
          title: 'New Valuation',
          text: `${notificationData.data.customerName} requires their vehicle to be valued`,
          severity: 'info',
          icon: 'car',
          showActionButton: true,
          actionButtonLink: `/vehicles/valuations/${notificationData.data.valuationId}`
        })
        break
      case 15: // Changed from 14 to 15 to avoid duplication
        Object.assign(props, {
          design: 'info',
          title: 'New ReAuction',
          text: `A new vehicle is up for auction`,
          severity: 'secondary',
          icon: 'car',
          showActionButton: true,
          actionButtonLink: `/vehicles/reauction/${notificationData.data.valuationId}`
        })
        break
      default:
        // Handle other notification IDs if necessary
        break
    }
  }

  // Removed the redirectTo function

  const toast = useToast()

  const config = {
    position: props.position,
    timeout: props.timeout,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: props.hideProgressBar,
    closeButton: props.showCloseButton ? NotificationToastCloseButton : false,
    icon: false,
    rtl: false,
    transition: 'Vue-Toastification__fade'
  }

  const toastId = toast(
    {
      component: CustomToast,
      props: props,
      listeners: {
        click: () => console.log('Clicked!'),
        dismissBtnClick: () => toast.dismiss(toastId),
        viewBtnClick: () => {
          if (onActionClick && typeof onActionClick === 'function') {
            onActionClick(props.actionButtonLink)
          } else {
            console.warn('onActionClick callback is not provided or not a function.')
          }
        }
      }
    },
    config
  )
}

/**
 * Displays a simple alert toast.
 *
 * @param {String} title - The title of the alert.
 * @param {String} text - The text content of the alert.
 * @param {String} severity - The severity level (e.g., 'success', 'error').
 * @param {String} icon - The icon to display.
 */
export function alertToast(title, text, severity, icon) {
  if (severity) {
    switch (severity) {
      case 'success':
        icon = 'check'
        break
      case 'info':
        icon = 'info'
        break
      case 'error':
        icon = 'exclamation'
        break
      case 'warning':
        icon = 'triangle-exclamation'
        break
      default:
        icon = 'bell'
        break
    }
  }

  notificationToast({
    design: 'alert',
    title: title,
    text: text,
    icon: icon,
    severity: severity,
    showDismissButton: false,
    showActionButton: false,
    showCloseButton: false,
    position: 'top-center',
    hideProgressBar: false,
    timeout: 4000
  })
}
