<template>
  <PageHeader
    title="Dealerships Data"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.dealership', 2) },
      { label: $t('ui.common.data') }
    ]"
  >
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="dealershipData"
      removable-sort
      sort-field="id"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-dealership-data"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'displayName', 'missingInfo']"
      :loading="dealershipsApiRequest.isLoading.value"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <!-- <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div> -->
      </template>

      <Column field="displayName" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <router-link :to="'/settings/dealerships/' + data.id">
            <AvatarLabelGroup :label="data.displayName" :avatar-url="data.manufacturerLogo" avatar-is-logo class="link-hover" />
          </router-link>
        </template>
        <!-- <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template> -->
      </Column>

      <Column field="missingInfo" header="Missing Data" :sortable="true">
        <template #body="{ data }">
          <div class="flex flex-col gap-1">
            <div v-for="(item, index) in data.missingInfo" :key="index">
              <Chip size="sm">{{ getInfoLabel(item) }}</Chip>
            </div>
          </div>
        </template>
      </Column>

      <Column field="lastCustomerImportDate" header="Last Import Date" :sortable="true">
        <template #body="{ data }">
          <div
            v-if="data.lastCustomerImportDate"
            v-tippy="dayjs(data.lastCustomerImportDate).format('ll')"
            :class="dayjs().diff(dayjs(data.lastCustomerImportDate), 'month') >= 3 ? 'text-error' : ''"
            class="whitespace-nowrap"
          >
            {{ dayjs().to(dayjs(data.lastCustomerImportDate)) }}
          </div>
          <div v-else class="text-quaternary">never</div>
        </template>
      </Column>

      <Column field="activeCustomerCount" header="Active Customers" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.activeCustomerCount }}</div>
        </template>
      </Column>

      <Column field="lastOutboundCallTimestamp" header="Last Outbound Call" :sortable="true">
        <template #body="{ data }">
          <div v-if="data.lastOutboundCallTimestamp" v-tippy="dayjs(data.lastOutboundCallTimestamp).format('ll')" class="whitespace-nowrap">
            {{ dayjs().to(dayjs(data.lastOutboundCallTimestamp)) }}
          </div>
          <div v-else class="text-quaternary">never</div>
        </template>
      </Column>

      <Column field="unassignedAppointmentsCount" header="Unassigned Appointments" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.unassignedAppointmentsCount }}</div>
        </template>
      </Column>

      <Column field="noOutcomeAppointmentsCount" header="No Outcome Appointments" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.noOutcomeAppointmentsCount }}</div>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { computed, ref } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'

import { useUserStore } from '@/stores/UserStore'
import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import dayjs from 'dayjs'

const dt = ref()
const filters = ref(null)

const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

const dealershipData = ref(null)
const dealershipsApiRequest = useApiRequest()

try {
  dealershipsApiRequest.send({ endpoint: '/v1/reports/dealerships/data', method: 'GET' }).then(response => {
    if (response.success) {
      dealershipData.value = response.data
    }
  })
} catch (err) {
  alertToast('Failed to fetch data', err.message, 'error')
}

const missingInfoOptions = [
  {
    value: 'business_hours',
    label: 'Business Hours'
  },
  {
    value: 'import_source',
    label: 'Import Source'
  },
  {
    value: 'import_source_id',
    label: 'Import Source ID'
  },
  {
    value: 'address',
    label: 'Address'
  }
]

function getInfoLabel(value) {
  return missingInfoOptions.find(item => item.value == value)?.label || value
}

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.IN },
    missingInfo: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

const clearFilters = () => {
  initFilters()
}

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style lang="scss" scoped></style>
