<template>
  <div class="alert-container relative">
    <div class="flex">
      <Icon :type="icon" class="text-xl absolute top-1 left-2" :severity="severity" featured />
      <div class="ml-10">
        <div class="font-semibold text-secondary">{{ title }}</div>
        <div class="text-quaternary">{{ message }}</div>
        <div v-if="showDismissButton || actionButton" class="mt-3 space-x-4">
          <Button v-if="showDismissButton" severity="secondary" text label="Dismiss" @click="dismissAlert" />
          <Button v-if="actionButton" severity="primary" text :label="actionButtonText" @click="actionButtonClicked" />
        </div>
      </div>
    </div>
    <div class="absolute top-3 right-3">
      <Button v-if="showCloseButton" icon="times" plain severity="secondary" @click="dismissAlert" />
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'

defineProps({
  severity: {
    type: String,
    default: 'info'
  },
  icon: {
    type: String,
    default: 'info-circle'
  },
  title: {
    type: String,
    default: null
  },
  message: {
    type: String,
    default: null
  },
  showCloseButton: {
    type: Boolean,
    default: true
  },
  showDismissButton: {
    type: Boolean,
    default: true
  },
  actionButton: {
    type: Boolean,
    default: false
  },
  actionButtonText: {
    type: String,
    default: 'Dismiss'
  }
})

const emit = defineEmits(['dismiss', 'action'])

const dismissAlert = () => {
  emit('dismiss')
}

const actionButtonClicked = () => {
  emit('action')
}
</script>

<style>
.alert-container {
  padding: var(--s-4);
  border: 1px solid var(--border-primary);
  border-radius: var(--rounded-xl);
}
</style>
