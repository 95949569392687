<template>
  <PageHeader
    title="Insights"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: 'Engagement' },
      { label: 'Insights', to: '/reports/engagement/insights' }
    ]"
  >
    <!-- <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          max-range="31"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template> -->
  </PageHeader>
  <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3">
    <Card>
      <CardBody>
        <CardTitle title="Successful Call Times" :has-margin="false">
          <template #right>
            <div class="text-sm text-quaternary">01/08/2024 - 26/11/2024</div>
          </template>
        </CardTitle>
      </CardBody>
      <div>
        <apexchart type="heatmap" :options="options" :series="series"></apexchart>
      </div>
    </Card>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'
import { alertToast } from '@/utilities/notification'

import dayjs from 'dayjs'

const options = {
  chart: {
    height: 300,
    parentHeightOffset: 0,
    type: 'heatmap',
    toolbar: {
      show: false
    },
    sparkline: {
      enabled: false
    }
  },
  grid: {
    padding: {
      top: -30
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#5bc0de']
}

const series = [
  {
    name: 'Mon',
    data: [
      {
        x: '9am',
        total_calls: 849,
        successful_calls: 117,
        y: 13.78
      },
      {
        x: '10am',
        total_calls: 981,
        successful_calls: 151,
        y: 15.39
      },
      {
        x: '11am',
        total_calls: 800,
        successful_calls: 120,
        y: 15
      },
      {
        x: '12am',
        total_calls: 810,
        successful_calls: 130,
        y: 16.05
      },
      {
        x: '1pm',
        total_calls: 761,
        successful_calls: 111,
        y: 14.59
      },
      {
        x: '2pm',
        total_calls: 511,
        successful_calls: 91,
        y: 17.81
      },
      {
        x: '3pm',
        total_calls: 527,
        successful_calls: 100,
        y: 18.98
      },
      {
        x: '4pm',
        total_calls: 369,
        successful_calls: 59,
        y: 15.99
      },
      {
        x: '5pm',
        total_calls: 202,
        successful_calls: 29,
        y: 14.36
      },
      {
        x: '6pm',
        total_calls: 168,
        successful_calls: 34,
        y: 20.24
      },
      {
        x: '7pm',
        total_calls: 46,
        successful_calls: 11,
        y: 23.91
      }
    ]
  },
  {
    name: 'Tue',
    data: [
      {
        x: '9am',
        total_calls: 937,
        successful_calls: 141,
        y: 15.05
      },
      {
        x: '10am',
        total_calls: 1146,
        successful_calls: 167,
        y: 14.57
      },
      {
        x: '11am',
        total_calls: 1030,
        successful_calls: 137,
        y: 13.3
      },
      {
        x: '12am',
        total_calls: 1002,
        successful_calls: 164,
        y: 16.37
      },
      {
        x: '1pm',
        total_calls: 969,
        successful_calls: 144,
        y: 14.86
      },
      {
        x: '2pm',
        total_calls: 622,
        successful_calls: 113,
        y: 18.17
      },
      {
        x: '3pm',
        total_calls: 435,
        successful_calls: 72,
        y: 16.55
      },
      {
        x: '4pm',
        total_calls: 317,
        successful_calls: 70,
        y: 22.08
      },
      {
        x: '5pm',
        total_calls: 129,
        successful_calls: 28,
        y: 21.71
      },
      {
        x: '6pm',
        total_calls: 134,
        successful_calls: 37,
        y: 27.61
      },
      {
        x: '7pm',
        total_calls: 41,
        successful_calls: 12,
        y: 29.27
      }
    ]
  },
  {
    name: 'Wed',
    data: [
      {
        x: '9am',
        total_calls: 817,
        successful_calls: 120,
        y: 14.69
      },
      {
        x: '10am',
        total_calls: 909,
        successful_calls: 138,
        y: 15.18
      },
      {
        x: '11am',
        total_calls: 822,
        successful_calls: 107,
        y: 13.02
      },
      {
        x: '12am',
        total_calls: 807,
        successful_calls: 106,
        y: 13.14
      },
      {
        x: '1pm',
        total_calls: 796,
        successful_calls: 115,
        y: 14.45
      },
      {
        x: '2pm',
        total_calls: 521,
        successful_calls: 80,
        y: 15.36
      },
      {
        x: '3pm',
        total_calls: 434,
        successful_calls: 77,
        y: 17.74
      },
      {
        x: '4pm',
        total_calls: 256,
        successful_calls: 58,
        y: 22.66
      },
      {
        x: '5pm',
        total_calls: 139,
        successful_calls: 37,
        y: 26.62
      },
      {
        x: '6pm',
        total_calls: 163,
        successful_calls: 42,
        y: 25.77
      },
      {
        x: '7pm',
        total_calls: 61,
        successful_calls: 5,
        y: 8.2
      }
    ]
  },
  {
    name: 'Thu',
    data: [
      {
        x: '9am',
        total_calls: 1042,
        successful_calls: 142,
        y: 13.63
      },
      {
        x: '10am',
        total_calls: 1070,
        successful_calls: 174,
        y: 16.26
      },
      {
        x: '11am',
        total_calls: 1015,
        successful_calls: 155,
        y: 15.27
      },
      {
        x: '12am',
        total_calls: 855,
        successful_calls: 141,
        y: 16.49
      },
      {
        x: '1pm',
        total_calls: 830,
        successful_calls: 118,
        y: 14.22
      },
      {
        x: '2pm',
        total_calls: 537,
        successful_calls: 89,
        y: 16.57
      },
      {
        x: '3pm',
        total_calls: 505,
        successful_calls: 85,
        y: 16.83
      },
      {
        x: '4pm',
        total_calls: 235,
        successful_calls: 50,
        y: 21.28
      },
      {
        x: '5pm',
        total_calls: 102,
        successful_calls: 23,
        y: 22.55
      },
      {
        x: '6pm',
        total_calls: 111,
        successful_calls: 30,
        y: 27.03
      },
      {
        x: '7pm',
        total_calls: 28,
        successful_calls: 7,
        y: 25
      }
    ]
  },
  {
    name: 'Fri',
    data: [
      {
        x: '9am',
        total_calls: 648,
        successful_calls: 107,
        y: 16.51
      },
      {
        x: '10am',
        total_calls: 684,
        successful_calls: 125,
        y: 18.27
      },
      {
        x: '11am',
        total_calls: 591,
        successful_calls: 90,
        y: 15.23
      },
      {
        x: '12am',
        total_calls: 533,
        successful_calls: 80,
        y: 15.01
      },
      {
        x: '1pm',
        total_calls: 599,
        successful_calls: 94,
        y: 15.69
      },
      {
        x: '2pm',
        total_calls: 375,
        successful_calls: 64,
        y: 17.07
      },
      {
        x: '3pm',
        total_calls: 313,
        successful_calls: 46,
        y: 14.7
      },
      {
        x: '4pm',
        total_calls: 160,
        successful_calls: 30,
        y: 18.75
      },
      {
        x: '5pm',
        total_calls: 127,
        successful_calls: 30,
        y: 23.62
      },
      {
        x: '6pm',
        total_calls: 115,
        successful_calls: 26,
        y: 22.61
      },
      {
        x: '7pm',
        total_calls: 39,
        successful_calls: 7,
        y: 17.95
      }
    ]
  },
  {
    name: 'Sat',
    data: [
      {
        x: '9am',
        total_calls: 46,
        successful_calls: 8,
        y: 17.39
      },
      {
        x: '10am',
        total_calls: 45,
        successful_calls: 3,
        y: 6.67
      },
      {
        x: '11am',
        total_calls: 58,
        successful_calls: 9,
        y: 15.52
      },
      {
        x: '12am',
        total_calls: 60,
        successful_calls: 10,
        y: 16.67
      },
      {
        x: '1pm',
        total_calls: 81,
        successful_calls: 22,
        y: 27.16
      },
      {
        x: '2pm',
        total_calls: 65,
        successful_calls: 14,
        y: 21.54
      },
      {
        x: '3pm',
        total_calls: 96,
        successful_calls: 23,
        y: 23.96
      },
      {
        x: '4pm',
        total_calls: 18,
        successful_calls: 2,
        y: 11.11
      },
      {
        x: '5pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '6pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '7pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      }
    ]
  },
  {
    name: 'Sun',
    data: [
      {
        x: '9am',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '10am',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '11am',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '12am',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '1pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '2pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '3pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '4pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '5pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '6pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      },
      {
        x: '7pm',
        total_calls: 0,
        successful_calls: 0,
        y: null
      }
    ]
  }
]
</script>

<style></style>
