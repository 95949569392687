import { reactive, toRefs } from 'vue'
import axios from 'axios'
import { alertToast } from '@/utilities/notification'
import * as Sentry from '@sentry/vue'

/**
 * Represents the structure of the API request configuration.
 *
 * @typedef {Object} ApiRequestConfig
 * @property {string} [baseURL=import.meta.env.VITE_API_BASE_URL] - The base URL for the API request.
 * @property {string} endpoint - The API endpoint to send the request to.
 * @property {string} [method='GET'] - The HTTP method for the request (e.g., 'GET', 'POST').
 * @property {Object} [params={}] - The URL parameters to include in the request.
 * @property {Object|null} [data=null] - The payload to send with the request (for methods like POST).
 * @property {Object} [headers={}] - Additional headers to include in the request.
 */

/**
 * Represents the structure of a successful API response.
 *
 * @typedef {Object} ApiResponse
 * @property {any} data - The simplified data payload from the API response.
 * @property {boolean} success - Indicates whether the request was successful.
 * @property {Array<string>} [errors] - An array of error messages, if any.
 * @property {string} [message] - A message accompanying the response.
 * @property {number} [status] - The HTTP status code of the response.
 * @property {Object} http - The full Axios response object.
 */

/**
 * Represents the structure of an error object returned by a failed API request.
 *
 * @typedef {Object} ApiError
 * @property {boolean} hasError - Indicates that an error occurred.
 * @property {string} message - The error message.
 * @property {Object|null} http - The Axios error response, if available.
 */

/**
 * Provides a reactive API request handler using Axios.
 *
 * @returns {Object} An object containing reactive state properties and methods for making API requests.
 * @property {Object|null} response - The full Axios response object.
 * @property {any|null} data - The simplified data payload from the response.
 * @property {boolean} isLoading - Indicates whether the request is in progress.
 * @property {string|null} error - The error message, if any.
 * @property {function(ApiRequestConfig): Promise<ApiResponse>} send - Sends an API request with the specified configuration.
 * @property {function(): boolean} hasErrors - Checks if there are any errors in the current state.
 */
export default function useApiRequest() {
  const state = reactive({
    /** @type {Object|null} The full Axios response */
    response: null,
    /** @type {any|null} The simplified data payload */
    data: null,
    /** @type {boolean} Indicates if the request is loading */
    isLoading: false,
    /** @type {string|null} The error message, if any */
    error: null
  })

  /**
   * Sends an API request using Axios with the provided configuration.
   *
   * @async
   * @param {ApiRequestConfig} config - The configuration object for the API request.
   * @param {string} config.endpoint - The API endpoint to send the request to.
   * @param {string} [config.baseURL] - The base URL for the API request.
   * @param {string} [config.method='GET'] - The HTTP method for the request.
   * @param {Object} [config.params={}] - The URL parameters to include in the request.
   * @param {Object|null} [config.data=null] - The payload to send with the request.
   * @param {Object} [config.headers={}] - Additional headers to include in the request.
   * @returns {Promise<ApiResponse>} A promise that resolves with the API response or rejects with an error.
   */
  const send = async ({
    baseURL = import.meta.env.VITE_API_BASE_URL,
    endpoint,
    method = 'GET',
    params = {},
    data = null,
    headers = {}
  }) => {
    if (!baseURL) {
      console.error(`Error: baseURL is undefined. Endpoint: ${endpoint}`)
      alertToast('Error', `baseURL is undefined for endpoint: ${endpoint}`, 'error')
      state.error = `baseURL is undefined for endpoint: ${endpoint}`
      return Promise.reject({ error: true, message: state.error })
    }

    state.isLoading = true

    try {
      const response = await axios({
        baseURL,
        url: endpoint,
        method,
        params,
        data,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // TODO: remove this temp fix for safari browsers not liking httpOnly cookies
          ...headers
        }
      })

      if (!response || !response.status) {
        state.error = 'Endpoint failed'
        throw new Error(`No response from API, endpoint failed: ${endpoint}`)
      }

      // If response code is in the 400s or 500s, throw an error
      if (response.status >= 400) {
        state.error = response.statusText
        throw new Error(response.statusText)
      }

      if (response.data?.response?.status === 'error') {
        state.error = response.data.response.status
        alertToast('Error', response.data.response.message || response.data.message, 'error')
        throw new Error(state.error)
      }

      if (response.data.errors && response.data.errors.length > 0) {
        state.error = response.data.errors.join(', ')
        // alertToast('Error', state.error, 'error')
        // throw new Error(state.error)
      }

      // Store the entire response object
      state.response = response

      // Simplify the data payload
      state.data = response.data.data || response.data

      state.error = null

      // Handle old api response format
      let success = false
      if (response.data?.response?.status === 'success') {
        success = true
      } else {
        success = state.response.success
      }

      // Return both data and the full response
      return {
        data: state.data, // Actual payload's data key
        success: success,
        errors: state.response.errors,
        message: state.response.message,
        status: state.response.status,
        http: state.response
      }
    } catch (err) {
      alertToast('Error (API Request)', err.message, 'error')
      Sentry.captureException(err)
      state.error = err.message

      return Promise.reject({
        hasError: true,
        message: err.message,
        http: err.response || null
      })
    } finally {
      state.isLoading = false
    }
  }

  /**
   * Checks if there are any errors in the current state.
   *
   * @returns {boolean} `true` if there is an error, otherwise `false`.
   */
  const hasErrors = () => !!state.error

  return {
    ...toRefs(state),
    send,
    hasErrors
  }
}
