<template>
  <div class="mb-5">
    <router-link :to="'/agreement/' + route.params.agid + '/appointments'" class="inline-flex items-center hover:text-primary">
      <Icon type="chevron-left" class="mr-1" />
      <span>{{ $t('ui.entities.appointment.label', 2) }}</span>
    </router-link>
  </div>

  <div v-if="appointment.data.value">
    <Card>
      <div class="grid md:grid-cols-4">
        <CardBody class="border-b md:border-b-0 md:border-r border-secondary flex flex-col gap-4 items-center">
          <Chip
            v-if="appointment.data.value.customerIsAttending != null"
            v-tippy="'Customer confirmed via SMS'"
            size="md"
            icon="user-check"
            :severity="appointment.data.value.customerIsAttending ? 'success' : 'danger'"
          >
            <span>{{ appointment.data.value.customerIsAttending ? 'Customer Attending' : 'Customer Not Attending' }}</span>
          </Chip>
          <!-- TODO: refactor below into own component which accepts a datetime object or string -->
          <div class="w-28 h-32 flex flex-col" :class="{ 'text-secondary': appointment.data.value?.isCancelled }">
            <div
              class="p-1 flex justify-center rounded-t-md text-white text-lg font-medium"
              :style="[`background-color: var(--${getProperties('appointmentStatus', appointment.data.value?.status)?.color});`]"
            >
              {{ dayjs(appointment.data.value.time).format('MMM') }}
            </div>
            <div class="text-3xl font-semibold border-x border-primary flex flex-1 justify-center items-center">
              {{ dayjs(appointment.data.value.time).format('D') }}
            </div>
            <div class="flex border border-primary rounded-b-md">
              <div class="text-center p-1 border-r border-primary w-full">{{ dayjs(appointment.data.value.time).format('ddd') }}</div>
              <div class="w-full p-1 text-center">{{ dayjs(appointment.data.value.time).format('H:mm') }}</div>
            </div>
          </div>
          <div v-if="appointment.data.value.timeZone != appointment.data.value.customerTimeZone" class="text-secondary text-center">
            {{ appointment.data.value.timeZone }} time
          </div>
          <Chip
            size="lg"
            :icon="appointment.data.value.method ? getProperties('appointmentMethod', appointment.data.value.method)?.icon : 'question'"
          >
            {{
              $t(
                appointment.data.value.method
                  ? getProperties('appointmentMethod', appointment.data.value.method)?.localeKey
                  : 'Unknown Method'
              )
            }}
          </Chip>
        </CardBody>

        <div class="md:col-span-3">
          <CardBody class="border-b border-secondary">
            <div class="flex flex-col w-full items-start md:justify-between md:flex-row mb-4 md:mb-2">
              <div>
                <div class="flex items-center gap-2">
                  <h3
                    class="mb-4 md:mb-0"
                    :style="[`color: var(--${getProperties('appointmentStatus', appointment.data.value?.status)?.color});`]"
                  >
                    {{ $t(getProperties('appointmentStatus', appointment.data.value?.status)?.localeKey) || 'Unknown Status' }}
                  </h3>

                  <div v-if="appointment.data.value?.isCancelled">
                    <div>
                      <span class="text-secondary mr-1 lowercase">{{ $t('ui.common.by') }}</span>
                      <span
                        v-if="appointment.data.value.cancelledBySource != 'customer' && appointment.data.value.cancelledByName"
                        v-tippy="{
                          content: `<span class='capitalize'>${appointment.data.value.cancelledBySource}</span>`,
                          allowHTML: true
                        }"
                        class="text-secondary"
                      >
                        {{ appointment.data.value.cancelledByName }}
                      </span>
                      <span v-else class="text-secondary">{{ appointment.data.value.cancelledBySource }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="appointment.data.value?.cancelledReason" class="my-2">
                  <q>{{ appointment.data.value.cancelledReason }}</q>
                </div>
              </div>

              <div class="flex flex-col w-full md:w-auto sm:flex-row gap-2">
                <div class="relative">
                  <Multiselect
                    ref="assignmentMultiselect"
                    v-model="appointment.data.value.assignedTo"
                    :disabled="!$can('assign_appointments') || appointment.data.value?.isCancelled || appointmentIsLocked"
                    mode="single"
                    label="fullName"
                    value-prop="id"
                    track-by="fullName"
                    class="min-w-[160px]"
                    :placeholder="$t('ui.actions.select')"
                    floating="Framework"
                    :close-on-select="true"
                    :searchable="true"
                    :options="assignableUsers.data.value"
                    :loading="appointmentAssigning"
                    @change="updateAssignedUser"
                  />
                  <div
                    v-if="appointment.data.value.assignedTo"
                    class="absolute -top-2 left-2 text-xs rounded-full bg-white dark:bg-darkmode-1 text-secondary px-1"
                  >
                    {{ $t('ui.entities.appointment.assigned-to') }}
                  </div>
                </div>

                <div class="md:ml-4 flex gap-2 flex-grow">
                  <Button
                    v-tippy="{ content: $t('ui.actions.edit') }"
                    severity="primary"
                    outlined
                    icon="pencil"
                    :disabled="appointmentIsLocked"
                    class="flex-grow"
                    @click="rescheduleClick"
                  />
                  <Button
                    v-tippy="{ content: $t('ui.entities.appointment.reschedule') }"
                    severity="secondary"
                    outlined
                    icon="calendar-clock"
                    :disabled="appointment.data.value?.isCancelled"
                    class="flex-grow"
                    @click="rescheduleClick"
                  />
                  <Button
                    v-tippy="{ content: $t('ui.actions.cancel') }"
                    severity="danger"
                    outlined
                    icon="ban"
                    :disabled="appointmentIsLocked"
                    class="flex-grow"
                    @click="cancelClick"
                  />
                  <Button
                    v-if="$can('delete_appointments')"
                    v-tippy="{ content: $t('ui.actions.delete') }"
                    severity="danger"
                    outlined
                    icon="trash"
                    class="flex-grow"
                    @click="deleteClick"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="appointmentDetailsCollapsable"
              class="inline-flex text-secondary items-center gap-1 cursor-pointer link-hover"
              :class="{ 'mb-2': appointmentDetailsVisible }"
              @click="appointmentDetailsVisible = !appointmentDetailsVisible"
            >
              <div>{{ appointmentDetailsVisible ? 'Hide' : 'Show' }} Details</div>
              <Icon :type="appointmentDetailsVisible ? 'chevron-up' : 'chevron-down'" />
            </div>

            <div v-if="appointmentDetailsVisible">
              <div class="flex items-center mb-2">
                <Icon type="location-dot" class="mr-3" />
                <span>{{ appointment.data.value.dealershipName }}</span>
              </div>
              <div v-if="appointment.data.value.intent" v-tippy="{ content: 'Intent' }" class="inline-block mb-2">
                <Icon type="thought-bubble" class="mr-3" />
                <span>{{ appointment.data.value.intent }}</span>
              </div>
              <div class="flex items-center mb-2">
                <Icon type="globe-pointer" class="mr-3" />
                <CopyText>
                  {{ appointment.data.value.selfServeUrl }}
                </CopyText>
              </div>
              <div>
                <div class="flex items-baseline">
                  <Icon type="note" class="mr-3" />
                  <p>{{ appointment.data.value.note ? appointment.data.value.note : 'No note left' }}</p>
                </div>
              </div>
            </div>
          </CardBody>

          <CardBody>
            <div v-if="appointment.data.value?.outcome.decision">
              <div class="flex justify-between items-center">
                <h3>{{ $t(getProperties('appointmentDecision', appointment.data.value.outcome.decision)?.label) }}</h3>
                <div>
                  <Button
                    v-tippy="{ content: $t('ui.crud.update', { item: $t('ui.entities.appointment.outcome') }) }"
                    severity="secondary"
                    outlined
                    icon="pencil"
                    size="sm"
                    :label="$t('ui.crud.update', { item: $t('ui.entities.appointment.outcome.outcome') })"
                    :disabled="appointmentIsLocked"
                    @click="outcomeModalIsOpen = true"
                  />
                </div>
              </div>

              <div v-if="appointment.data.value.outcome.reason" class="mb-2">
                <!-- <Label no-margin>{{ $t('ui.common.note') }}</Label> -->
                <div>
                  <q>{{ appointment.data.value.outcome.reason }}</q>
                </div>
              </div>
              <div v-if="appointment.data.value.outcome.decision == 'refinanced'" class="mb-2">
                <div class="flex gap-2 max-w-[400px] mt-2">
                  <div class="details-grid-box flex-1">
                    <div class="details-grid-box-title">{{ $t('ui.common.amount') }}</div>
                    <div class="details-grid-box-data capitalize">
                      {{
                        numberFormatter(
                          appointment.data.value.outcome.refinance.amountRefinanced,
                          'currency',
                          0,
                          appointment.data.value.outcome.currency
                        )
                      }}
                    </div>
                  </div>
                  <div class="details-grid-box flex-1">
                    <div class="details-grid-box-title">{{ $t('ui.common.until') }}</div>
                    <div class="details-grid-box-data">
                      {{
                        appointment.data.value.outcome.refinance.refinancedUntil
                          ? dayjs(appointment.data.value.outcome.refinance.refinancedUntil).format('LL')
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="appointment.data.value.outcome.decision == 'part_exchanged' && appointment.data.value.outcome.newVehicle.type">
                <Label no-margin>{{ $t('ui.entities.vehicle.new-vehicle') }}</Label>
                <div class="flex gap-2 max-w-[400px]">
                  <div class="details-grid-box flex-1">
                    <div class="details-grid-box-title">{{ $t('ui.common.type') }}</div>
                    <div class="details-grid-box-data capitalize">
                      {{ appointment.data.value.outcome.newVehicle.type }}
                    </div>
                  </div>
                  <div class="details-grid-box flex-1">
                    <div class="details-grid-box-title">{{ $t('ui.common.value') }}</div>
                    <div class="details-grid-box-data">
                      {{
                        numberFormatter(
                          appointment.data.value.outcome.newVehicle.value,
                          'currency',
                          0,
                          appointment.data.value.outcome.currency
                        )
                      }}
                    </div>
                  </div>
                  <div class="details-grid-box flex-1">
                    <div class="details-grid-box-title">Est. Delivery</div>
                    <div class="details-grid-box-data">
                      {{ dayjs(appointment.data.value.outcome.newVehicle.estimatedDeliveryDate).format("D MMM 'YY") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="$can('outcome_appointments') && !appointment.data.value.outcome.decision"
              v-tippy="{ content: !isActionable ? 'Available closer to the appointment time' : null }"
              class="inline"
            >
              <Button
                :label="$t('ui.entities.appointment.outcome.set-outcome')"
                :disabled="!isActionable || appointment.data.value?.isCancelled"
                icon="check"
                @click="outcomeModalIsOpen = true"
              />
            </div>
          </CardBody>
        </div>
      </div>
    </Card>

    <div class="grid md:grid-cols-2 md:gap-4">
      <!-- TODO: Add functionality to add/display files once returned by API -->
      <div>
        <Card>
          <CardBody>
            <CardTitle :title="$t('ui.entities.appointment.checklist.label')" />
            <ListGroup>
              <ListGroupItem>
                <div>
                  <div class="flex items-center justify-between">
                    <div>{{ $t('ui.entities.appointment.checklist.settlement-figure-given') }}</div>
                    <Checkbox v-model="appChecklist.settlementFigureGiven" size="md" no-margin :readonly="!!appointmentIsLocked" />
                  </div>
                  <div v-if="appChecklist.settlementFigureGiven">
                    <Separator class="my-4" />
                    <div>
                      <div class="mt-4">
                        <div class="flex justify-between">
                          <div>
                            <Input
                              v-model="appChecklist.settlementAmount"
                              :readonly="!!appointmentIsLocked"
                              :icon="appointment.data.value.outcome.currency.toLowerCase()"
                              class="w-[120px]"
                              :has-error="vChecklist$.settlementAmount.$error"
                            />
                            <InputError :has-error="vChecklist$.settlementAmount.$error">{{
                              vChecklist$.settlementAmount.$errors[0]?.$message
                            }}</InputError>
                          </div>
                          <div>
                            <Button icon="cloud-upload" outlined severity="secondary" @click="uploadChecklistFileClick('settlement')" />
                          </div>
                        </div>
                        <div
                          v-for="file in appointment.data.value.checklist.settlementFiles"
                          :key="file"
                          class="flex justify-end mt-1 hover:text-primary cursor-pointer"
                          @click="openFileInNewTab(file)"
                        >
                          <Icon type="file" class="mr-1" />
                          <span>{{ file.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
            <ListGroup class="mt-2">
              <ListGroupItem>
                <div class="flex justify-between">
                  <div>{{ $t('ui.entities.appointment.checklist.vehicle-valued') }}</div>
                  <Checkbox v-model="appChecklist.vehicleValued" no-margin :readonly="!!appointmentIsLocked" />
                </div>
                <div v-if="appChecklist.vehicleValued">
                  <Separator class="my-4" />
                  <div>
                    <Input
                      v-model="appChecklist.valuationAmount"
                      :readonly="!!appointmentIsLocked"
                      :icon="appointment.data.value.outcome.currency.toLowerCase()"
                      class="w-[120px]"
                      :has-error="vChecklist$.valuationAmount.$error"
                    />
                    <InputError :has-error="vChecklist$.valuationAmount.$error">{{
                      vChecklist$.valuationAmount.$errors[0]?.$message
                    }}</InputError>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
            <ListGroup class="mt-2">
              <ListGroupItem>
                <div class="flex justify-between">
                  <div>{{ $t('ui.entities.appointment.checklist.test-drive-completed') }}</div>
                  <Checkbox v-model="appChecklist.testDriveCompleted" no-margin :readonly="!!appointmentIsLocked" />
                </div>
              </ListGroupItem>
            </ListGroup>
            <ListGroup class="mt-2">
              <ListGroupItem>
                <div class="flex justify-between">
                  <div>{{ $t('ui.entities.appointment.checklist.new-vehicle-offers-provided') }}</div>

                  <Checkbox v-model="appChecklist.newCarOffersProvided" no-margin :readonly="!!appointmentIsLocked" />
                </div>
                <div v-if="appChecklist.newCarOffersProvided">
                  <Separator class="my-4" />
                  <div class="flex justify-end">
                    <Button icon="cloud-upload" outlined severity="secondary" @click="uploadChecklistFileClick('newCarOffers')" />
                  </div>
                  <div
                    v-for="file in appointment.data.value.checklist.newCarOfferFiles"
                    :key="file"
                    class="flex justify-end mt-1 hover:text-primary cursor-pointer"
                    @click="openFileInNewTab(file)"
                  >
                    <Icon type="file" class="mr-1" />
                    <span>{{ file.name }}</span>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
            <ListGroup class="mt-2">
              <ListGroupItem>
                <div class="flex justify-between">
                  <div>{{ $t('ui.entities.appointment.checklist.refinance-quote-given') }}</div>
                  <Checkbox v-model="appChecklist.refinanceQuoteGiven" no-margin :readonly="!!appointmentIsLocked" />
                </div>

                <div v-if="appChecklist.refinanceQuoteGiven">
                  <Separator class="my-4" />
                  <div class="flex justify-between">
                    <div>
                      <Input
                        v-model="appChecklist.refinanceQuoteAmount"
                        :readonly="!!appointmentIsLocked"
                        :icon="appointment.data.value.outcome.currency.toLowerCase()"
                        class="w-[120px]"
                        :has-error="vChecklist$.refinanceQuoteAmount.$error"
                      />
                      <InputError :has-error="vChecklist$.refinanceQuoteAmount.$error">{{
                        vChecklist$.refinanceQuoteAmount.$errors[0]?.$message
                      }}</InputError>
                    </div>
                    <div>
                      <Button icon="cloud-upload" outlined severity="secondary" @click="uploadChecklistFileClick('refinanceQuote')" />
                    </div>
                  </div>
                  <div
                    v-for="file in appointment.data.value.checklist.refinanceQuoteFiles"
                    :key="file"
                    class="flex justify-end mt-1 hover:text-primary cursor-pointer"
                    @click="openFileInNewTab(file)"
                  >
                    <Icon type="file" class="mr-1" />
                    <span>{{ file.name }}</span>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>

            <Button
              v-if="$can('outcome_appointments')"
              :label="$t('ui.actions.save')"
              icon="save"
              outlined
              severity="primary"
              class="mt-4"
              @click="updateChecklistSubmit"
            />
          </CardBody>
        </Card>
      </div>

      <div>
        <Card>
          <CardBody>
            <div v-if="appointment.data.value.reloop">
              <div class="flex justify-between">
                <div class="flex flex-col gap-1">
                  <CardTitle title="ReLoop" :has-margin="false" />
                  <div class="text-secondary text-sm">
                    {{ $t('ui.entities.reloop.submitted-by') }} {{ appointment.data.value.reloop.details.completedBy }}
                    <span
                      class="underline decoration-dotted"
                      v-tippy="{ content: dayjs(appointment.data.value.reloop.details.createdAt).format('ll HH:MM') }"
                      >{{ dayjs().to(dayjs(appointment.data.value.reloop.details.createdAt)) }}</span
                    >
                  </div>
                  <div class="flex items-center gap-2">
                    <div v-if="appointment.data.value.reloop.questions.find(q => q.id == 10)">
                      <Chip
                        :severity="appointment.data.value.reloop.questions.find(q => q.id == 10)?.answer.value >= 5 ? 'success' : 'danger'"
                      >
                        {{ $t('ui.entities.reloop.satisfaction') }}:
                        {{ appointment.data.value.reloop.questions.find(q => q.id == 10)?.answer.value }}
                      </Chip>
                    </div>
                    <span v-if="appointment.data.value.reloop.questions.find(q => q.id == 13)?.answer.value == 1">
                      <Chip icon="warning" severity="warning">{{ $t('ui.entities.reloop.alert-management') }}</Chip>
                    </span>
                  </div>
                </div>
                <div>
                  <Chip
                    size="lg"
                    :severity="
                      getScoreColor((appointment.data.value.reloop.score.result / appointment.data.value.reloop.score.possible) * 100)
                    "
                  >
                    <div class="text-sm mr-2">{{ $t('ui.entities.reloop.score') }}</div>
                    <div v-if="appointment.data.value.reloop.score.possible > 0" class="text-xl font-medium">
                      {{ appointment.data.value.reloop.score.result }}<Icon type="slash-forward" class="mx-0" />{{
                        appointment.data.value.reloop.score.possible
                      }}
                    </div>
                    <div v-else>N/A</div>
                  </Chip>
                </div>
              </div>

              <ListGroup class="mt-4">
                <ListGroupItem v-if="(reloopAttended = appointment.data.value.reloop.questions.find(r => r.id == 1))" class="flex gap-2">
                  <Icon
                    :type="reloopAttended.answer.score == 1 ? 'check-circle' : 'circle-xmark'"
                    :class="reloopAttended.answer.score == 1 ? 'text-success' : 'text-danger'"
                  />
                  <div>{{ reloopAttended.shortTitle }}</div>
                </ListGroupItem>
                <ListGroupItem
                  v-if="(reloopSettlementGiven = appointment.data.value.reloop.questions.find(r => r.id == 2))"
                  class="flex gap-2"
                >
                  <Icon
                    :type="reloopSettlementGiven.answer.score == 1 ? 'check-circle' : 'circle-xmark'"
                    :class="reloopSettlementGiven.answer.score == 1 ? 'text-success' : 'text-danger'"
                  />
                  <div class="flex-grow">{{ reloopSettlementGiven.shortTitle }}</div>
                  <div v-if="reloopSettlementGiven.answer.value != 'Yes' && reloopSettlementGiven.answer.value != 'No'">
                    {{ reloopSettlementGiven.answer.value }}
                  </div>
                </ListGroupItem>
                <ListGroupItem v-if="(reloopValued = appointment.data.value.reloop.questions.find(r => r.id == 4))" class="flex gap-2">
                  <Icon
                    :type="reloopValued.answer.score == 1 ? 'check-circle' : 'circle-xmark'"
                    :class="reloopValued.answer.score == 1 ? 'text-success' : 'text-danger'"
                  />
                  <div class="flex-grow">{{ reloopValued.shortTitle }}</div>
                  <div v-if="reloopValued.answer.value != 'Yes' && reloopValued.answer.value != 'No'">{{ reloopValued.answer.value }}</div>
                </ListGroupItem>
                <ListGroupItem v-if="(reloopTestDrive = appointment.data.value.reloop.questions.find(r => r.id == 6))" class="flex gap-2">
                  <Icon
                    :type="reloopTestDrive.answer.score == 1 ? 'check-circle' : 'circle-xmark'"
                    :class="reloopTestDrive.answer.score == 1 ? 'text-success' : 'text-danger'"
                  />
                  <div class="flex-grow">{{ reloopTestDrive.shortTitle }}</div>
                  <div v-if="reloopTestDrive.answer.value != 'Yes' && reloopTestDrive.answer.value != 'No'">
                    {{ reloopTestDrive.answer.value }}
                  </div>
                </ListGroupItem>
                <ListGroupItem
                  v-if="(reloopMetManagement = appointment.data.value.reloop.questions.find(r => r.id == 7))"
                  class="flex gap-2"
                >
                  <Icon
                    :type="reloopMetManagement.answer.score == 1 ? 'check-circle' : 'circle-xmark'"
                    :class="reloopMetManagement.answer.score == 1 ? 'text-success' : 'text-danger'"
                  />
                  <div class="flex-grow">{{ reloopMetManagement.shortTitle }}</div>
                  <div v-if="reloopMetManagement.answer.value != 'Yes' && reloopMetManagement.answer.value != 'No'">
                    {{ reloopMetManagement.answer.value }}
                  </div>
                </ListGroupItem>
                <ListGroupItem
                  v-if="(reloopFinalOptions = appointment.data.value.reloop.questions.find(r => r.id == 8))"
                  class="flex gap-2"
                >
                  <Icon
                    :type="reloopFinalOptions.answer.score == 1 ? 'check-circle' : 'circle-xmark'"
                    :class="reloopFinalOptions.answer.score == 1 ? 'text-success' : 'text-danger'"
                  />
                  <div class="flex-grow">{{ reloopFinalOptions.shortTitle }}</div>
                  <div v-if="reloopFinalOptions.answer.value != 'Yes' && reloopFinalOptions.answer.value != 'No'">
                    {{ reloopFinalOptions.answer.value }}
                  </div>
                </ListGroupItem>
              </ListGroup>

              <ListGroup class="mt-4">
                <ListGroupItem v-if="(reloopDecision = appointment.data.value.reloop.questions.find(r => r.id == 9))">
                  <div class="flex gap-2">
                    <Icon type="thought-bubble" />
                    <div>{{ reloopDecision.shortTitle }}</div>
                  </div>
                  <div class="ml-7">{{ reloopDecision.answer.value }}</div>
                </ListGroupItem>
                <ListGroupItem v-if="(reloopSettlementAmount = appointment.data.value.reloop.questions.find(r => r.id == 3))">
                  <div class="flex gap-2">
                    <Icon type="money-check" />
                    <div>{{ reloopSettlementAmount.shortTitle }}</div>
                  </div>
                  <div class="ml-7">
                    {{ numberFormatter(reloopSettlementAmount.answer.value, 'currency', 2, appointment.data.value.outcome.currency) }}
                  </div>
                </ListGroupItem>
                <ListGroupItem v-if="(reloopValuation = appointment.data.value.reloop.questions.find(r => r.id == 5))">
                  <div class="flex gap-2">
                    <Icon type="car-side" />
                    <div>{{ reloopValuation.shortTitle }}</div>
                  </div>
                  <div class="ml-7">
                    {{ numberFormatter(reloopValuation.answer.value, 'currency', 2, appointment.data.value.outcome.currency) }}
                  </div>
                </ListGroupItem>
                <ListGroupItem v-if="(reloopFeedback = appointment.data.value.reloop.questions.find(r => r.id == 11))">
                  <div class="flex gap-2">
                    <Icon type="message-lines" />
                    <div>{{ reloopFeedback.shortTitle }}</div>
                  </div>
                  <div class="ml-7">{{ reloopFeedback.answer.value }}</div>
                </ListGroupItem>
                <ListGroupItem v-if="(reloopBadExperience = appointment.data.value.reloop.questions.find(r => r.id == 12))">
                  <div class="flex gap-2">
                    <Icon type="thumbs-down" />
                    <div>{{ reloopBadExperience.shortTitle }}</div>
                  </div>
                  <div class="ml-7">{{ reloopBadExperience.answer.value }}</div>
                </ListGroupItem>
                <ListGroupItem v-if="(reloopNoShowReason = appointment.data.value.reloop.questions.find(r => r.id == 14))">
                  <div class="flex gap-2">
                    <Icon type="user-slash" />
                    <div>{{ reloopNoShowReason.shortTitle }}</div>
                  </div>
                  <div class="ml-7">{{ reloopNoShowReason.answer.value }}</div>
                </ListGroupItem>
              </ListGroup>
            </div>

            <div v-else>
              <div class="flex justify-between items-center mb-4">
                <CardTitle title="ReLoop" :has-margin="false" />
                <div
                  v-if="dayjs().isBefore(dayjs(appointment.data.value.time).add(1, 'day').hour(10).minute(0).second(0))"
                  class="text-quaternary"
                >
                  Sending {{ dayjs().to(dayjs(appointment.data.value.time).add(1, 'day')) }}
                </div>
                <div v-else-if="!props.agreementData.customer.contact.email" class="text-quaternary">
                  <Icon type="circle-xmark" class="text-error" />
                  Not sent - no email address
                </div>
                <div v-else-if="appointment.data.value.reloopEmailSentAt" class="text-quaternary">
                  <Icon type="circle-check" class="text-success" />
                  Sent on {{ dayjs(appointment.data.value.reloopEmailSentAt).format('Do MMM YYYY') }}
                </div>
                <div v-else class="text-quaternary">
                  <Icon type="circle-xmark" class="text-error" />
                  Failed to send
                </div>
              </div>
              <div class="flex gap-2">
                <a
                  v-if="
                    $can('submit_reloop') && !appointment.data.value.isCancelled && dayjs(appointment.data.value.time).isBefore(dayjs())
                  "
                  :href="appointment.data.value?.reloopFormUrl"
                  target="_blank"
                >
                  <Button :label="$t('ui.actions.new')" icon="plus" outlined severity="secondary" />
                </a>
                <!-- <Button
                    label="Re-send"
                    icon="share"
                    outlined
                    severity="secondary"
                    :is-loading="sendReloopIsLoading"
                    @click="sendReloopForm"
                  /> -->
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  </div>
  <div v-else>
    <Skeleton width="100%" height="20rem" class="mb-4" />
    <div class="flex justify-between gap-4">
      <Skeleton width="50%" height="25rem" class="mb-4" />
      <Skeleton width="50%" height="25rem" class="mb-4" />
    </div>
  </div>

  <teleport to="#modals-container">
    <AppointmentHandler
      v-if="appointment.data.value"
      :key="Math.random()"
      :appointment="appointment.data.value"
      :is-open="appointmentHandlerIsOpen"
      :agreement-contact-details="props.agreementData.customer.contact"
      @close-appointment-handler="appointmentHandlerIsOpen = false"
      @appointment-updated="rescheduleAppointmentSubmit"
    />

    <Modal
      :is-open="editNoteModalIsOpen"
      :title="$t('ui.crud.update', { item: $t('ui.common.note') })"
      :buttons="['close', 'ok']"
      ok-text="Save"
      close-text="Cancel"
      :show-loader-on-confirm="true"
      :is-confirming="appointmentNoteIsLoading"
      :disable-close="appointmentNoteIsLoading"
      min-width="400"
      @close-modal="editNoteModalIsOpen = false"
      @ok-modal="editNoteSubmit"
    >
      <FormGroup :label="$t('ui.common.note')">
        <TextArea v-model="editNoteData.note" />
        <InputError :has-error="vEditNote$.note.$error">{{ vEditNote$.note.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup>
        <Checkbox v-model="editNoteData.notifyDealership" :label="$t('ui.actions.notify-dealership')" />
      </FormGroup>
    </Modal>

    <Modal
      :is-open="cancelModalIsOpen"
      min-width="350"
      icon="calendar-xmark"
      :title="$t('ui.entities.appointment.cancel-appointment')"
      description="Only cancel when the appointment is no longer required."
      :buttons="['close', 'ok']"
      :button-colors="{ close: 'secondary', ok: 'danger' }"
      ok-text="Cancel"
      close-text="Close"
      :show-loader-on-confirm="true"
      :is-confirming="cancelIsLoading"
      :disable-close="cancelIsLoading"
      @close-modal="cancelModalIsOpen = false"
      @ok-modal="cancelAppointmentSubmit"
    >
      <FormGroup :label="$t('ui.entities.appointment.cancelled-by')">
        <Chip :avatar="userStore.details.profilePicUrl" :avatar-text="userStore.details.initials" size="sm">
          {{ userStore.details.name }}
        </Chip>
      </FormGroup>
      <FormGroup :label="$t('ui.common.reason')">
        <TextArea
          v-model="cancelAppointmentData.updateNote"
          :value="cancelAppointmentData.updateNote"
          spell-check
          :rows="3"
          :has-error="vCancel$.updateNote.$error"
        />
        <InputError :has-error="vCancel$.updateNote.$error">{{ vCancel$.updateNote.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup label-for="notifyCustomer">
        <Checkbox v-model="cancelAppointmentData.notifyCustomer" :label="$t('ui.actions.notify-customer')"></Checkbox>
      </FormGroup>
      <FormGroup label-for="notifyDealership">
        <Checkbox v-model="cancelAppointmentData.notifyDealership" :label="$t('ui.actions.notify-dealership')"></Checkbox>
      </FormGroup>

      <InputError :has-error="vCancel$.updatedByUserID.$error">{{ vCancel$.updatedByUserID.$errors[0]?.$message }}</InputError>
    </Modal>

    <Modal
      :is-open="deleteModalIsOpen"
      min-width="350"
      icon="trash"
      :title="$t('ui.entities.appointment.delete-appointment')"
      description="Only delete when the appointment is no longer required."
      :buttons="['close', 'ok']"
      :button-colors="{ close: 'secondary', ok: 'danger' }"
      ok-text="Delete"
      close-text="Close"
      :show-loader-on-confirm="true"
      :is-confirming="deleteIsLoading"
      :disable-close="deleteIsLoading"
      @close-modal="deleteModalIsOpen = false"
      @ok-modal="deleteAppointmentSubmit"
    >
      <Alert severity="warning" title="This cannot be undone!" :show-close-button="false" :show-dismiss-button="false" />
      <FormGroup :label="$t('ui.entities.appointment.deleted-by')">
        <Chip :avatar="userStore.details.profilePicUrl" :avatar-text="userStore.details.initials" size="sm">
          {{ userStore.details.name }}
        </Chip>
      </FormGroup>
      <FormGroup :label="$t('ui.common.reason')">
        <TextArea
          v-model="deleteAppointmentData.updateNote"
          :value="deleteAppointmentData.updateNote"
          spell-check
          :rows="3"
          :has-error="vDelete$.updateNote.$error"
        />
        <InputError :has-error="vDelete$.updateNote.$error">{{ vDelete$.updateNote.$errors[0]?.$message }}</InputError>
      </FormGroup>
    </Modal>

    <Modal
      :is-open="outcomeModalIsOpen"
      min-width="550"
      icon="calendar-check"
      :title="$t('ui.entities.appointment.outcome.set-outcome')"
      description="What was the customer's decision?"
      :buttons="['close', 'ok']"
      ok-text="Submit"
      close-text="Cancel"
      :show-loader-on-confirm="true"
      :is-confirming="outcomeIsLoading"
      :disable-close="outcomeIsLoading"
      pin-to-top
      @close-modal="outcomeModalIsOpen = false"
      @ok-modal="submitOutcome"
    >
      <FormGroup>
        <RadioBoxed
          v-model="appOutcome.outcome"
          :options="appOutcomeSettings.outcomeOptions"
          :has-error="vOutcome$.outcome.$error"
          @option-change="outcomeSelectionChange"
        />
        <InputError :has-error="vOutcome$.outcome.$error">{{ vOutcome$.outcome.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup v-if="appOutcome.outcome && appOutcome.outcome != 'no-show'">
        <RadioTabbed
          v-model="appOutcome.decision"
          :options="applicableOutcomeDecisions"
          full-width
          class="my-2"
          :has-error="vOutcome$.decision.$error"
          @option-change="decisionSelectionChange"
        />
        <InputError :has-error="vOutcome$.decision.$error">{{ vOutcome$.decision.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup
        v-if="appOutcome.decision == 'part_exchanged'"
        :label="$t('ui.entities.appointment.outcome.new-vehicle-type')"
        label-for="new-vehicle-type"
      >
        <Multiselect
          v-model="appOutcome.newVehicleType"
          mode="single"
          label="label"
          value-prop="value"
          track-by="label"
          :close-on-select="true"
          :searchable="false"
          :options="appOutcomeSettings.newVehicleTypes"
        />
        <InputError :has-error="vOutcome$.newVehicleType.$error">{{ vOutcome$.newVehicleType.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup
        v-if="appOutcome.decision == 'part_exchanged'"
        :label="$t('ui.entities.appointment.outcome.new-vehicle-value')"
        label-for="new-vehicle-value"
      >
        <Input v-model="appOutcome.newVehicleValue" :icon="appointment.data.value.outcome.currency.toLowerCase()" />
        <InputError :has-error="vOutcome$.newVehicleValue.$error">{{ vOutcome$.newVehicleValue.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup
        v-if="appOutcome.decision == 'part_exchanged'"
        :label="$t('ui.entities.agreement.estimated-delivery-date')"
        label-for="new-vehicle-delivery-date"
      >
        <DatePicker v-model="appOutcome.newVehicleDeliveryDate" :config="datePickerConfigs.outcomeDeliveryDate" />
        <InputError :has-error="vOutcome$.newVehicleDeliveryDate.$error">{{
          vOutcome$.newVehicleDeliveryDate.$errors[0]?.$message
        }}</InputError>
      </FormGroup>

      <FormGroup
        v-if="appOutcome.decision == 'refinanced'"
        :label="$t('ui.entities.finance-agreement.amount-refinanced')"
        label-for="refinanced-amount"
      >
        <Input v-model="appOutcome.refinancedAmount" :icon="appointment.data.value.outcome.currency.toLowerCase()" />
        <InputError :has-error="vOutcome$.refinancedAmount.$error">{{ vOutcome$.refinancedAmount.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup
        v-if="appOutcome.decision == 'refinanced'"
        :label="$t('ui.entities.finance-agreement.refinanced-until')"
        label-for="refinanced-until"
      >
        <DatePicker v-model="appOutcome.refinancedEndDate" :config="datePickerConfigs.outcomeRefinance" />
        <InputError :has-error="vOutcome$.refinancedEndDate.$error">{{ vOutcome$.refinancedEndDate.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup
        v-if="appOutcome.outcome && (appOutcome.decision || appOutcome.outcome == 'no-show')"
        :label="$t('ui.common.note')"
        label-for="note"
      >
        <TextArea v-model="appOutcome.reason" spell-check :rows="3" />
        <InputError :has-error="vOutcome$.reason.$error">{{ vOutcome$.reason.$errors[0]?.$message }}</InputError>
      </FormGroup>
    </Modal>

    <Modal
      title="Upload Files"
      :is-open="uploadFilesModalIsOpen"
      ok-text="Submit"
      close-text="Cancel"
      show-loader-on-confirm
      :buttons="[]"
      @close-modal="uploadFilesModalIsOpen = false"
    >
      <FileUpload
        :header-title="null"
        :api-endpoint="uploadFileApiEndpoint"
        :api-headers="{ Authorization: `Bearer ${sessionToken}` }"
        button-position="full-width"
        @cancel-all="uploadFilesModalIsOpen = false"
        @uploaded="$nextTick(() => fetchAppointment())"
      />
    </Modal>
  </teleport>
</template>

<script>
import { ref, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, numeric, maxLength, requiredIf, helpers } from '@vuelidate/validators'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import DatePicker from '@/components/forms/DatePicker.vue'

import { useUserStore } from '@/stores/UserStore'
import { useAbility } from '@casl/vue'

import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Label from '@/components/forms/Label.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import Input from '@/components/forms/Input.vue'
import TextArea from '@/components/forms/TextArea.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'
import RadioBoxed from '@/components/forms/RadioBoxed.vue'
import InputError from '@/components/forms/InputError.vue'
import Card from '@/components/card/Card.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import CardBody from '@/components/card/CardBody.vue'
import Chip from '@/components/chip/Chip.vue'

import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'

import Skeleton from 'primevue/skeleton'
import Multiselect from '@vueform/multiselect'
import Modal from '@/components/modal/Modal.vue'
import { alertToast } from '@/utilities/notification'
import { getProperties } from '@/utilities/dataMapper'
import { useBreakPoints } from '@/composables/useWindowSize'

import AppointmentHandler from '@/components/unique/appointments/AppointmentHandler.vue'

import useApiRequest from '@/composables/useApiRequest'

import CopyText from '@/components/CopyText.vue'
import Separator from '@/components/separator/Separator.vue'
import FileUpload from '@/components/file-upload/FileUpload.vue'
import Alert from '@/components/alert/Alert.vue'

export default {
  components: {
    Icon,
    Button,
    Label,
    Input,
    TextArea,
    InputError,
    Checkbox,
    RadioTabbed,
    RadioBoxed,
    Skeleton,
    FormGroup,
    Multiselect,
    Modal,
    DatePicker,
    CopyText,
    ListGroup,
    ListGroupItem,
    AppointmentHandler,
    Card,
    CardBody,
    CardTitle,
    Chip,
    Separator,
    FileUpload,
    Alert
  },
  props: {
    agreementData: {
      type: Object,
      default: null
    }
  },
  emits: ['appointmentUpdated'],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const userStore = useUserStore()
    const { can } = useAbility()

    const appointmentDetailsVisible = ref(false)
    const appointmentDetailsCollapsable = ref(false)

    const updateSource = userStore.details.type == 1 ? 'retain' : 'dealer'

    const assignmentMultiselect = ref()

    const appointment = useApiRequest()
    const appointmentCancel = useApiRequest()
    const appointmentDelete = useApiRequest()
    const appointmentAssign = useApiRequest()
    const appointmentUpdateNote = useApiRequest()
    const appointmentUpdateChecklist = useApiRequest()
    const appointmentSubmitOutcome = useApiRequest()

    const assignableUsers = useApiRequest()

    const appointmentHandlerIsOpen = ref(false)

    const appChecklist = ref({
      settlementFigureGiven: false,
      settlementAmount: null,
      vehicleValued: false,
      valuationAmount: null,
      testDriveCompleted: false,
      newCarOffersProvided: false,
      refinanceQuoteGiven: false,
      refinanceQuoteAmount: null
    })

    const datePickerConfigs = {
      reschedule: {
        enableTime: true,
        minDate: Date.now(),
        dateFormat: 'Y-m-d H:i:S',
        altInput: true,
        altFormat: 'h:iK \\- D J M Y'
      },
      outcomeDeliveryDate: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'D J M Y'
      },
      outcomeRefinance: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'D J M Y'
      }
    }

    const rescheduleData = ref({
      time: null,
      notifyCustomer: true
    })

    const rescheduleVuelidateRules = {
      time: { required },
      notifyCustomer: {}
    }
    const vReschedule$ = useVuelidate(rescheduleVuelidateRules, rescheduleData, { $stopPropagation: true })

    function fetchAppointment() {
      appointment.send({ endpoint: '/v1/appointments/' + route.params.id, method: 'GET' }).then(response => {
        if (!response.success) {
          throw Error('Endpoint failed, missing appointment: /v1/appointments/' + route.params.id)
        }

        //Get all dealers for this dealership
        assignableUsers
          .send({
            endpoint:
              '/v1/users?showDisabled=true&forAppointmentAssignment=true&userType=externalUsers&dealerships=' +
              appointment.data.value.dealershipID,
            method: 'GET'
          })
          .then(() => {
            if (assignableUsers.data.value && assignableUsers.data.value.length) {
              assignableUsers.data.value = assignableUsers.data.value
                .filter(user => {
                  // Update 'disabled' property
                  user.disabled = !user.active

                  // Filter out disabled users, unless it's the one assigned to
                  return !user.disabled || user.id === appointment.data.value.assignedTo
                })
                .sort((a, b) => a.forename.localeCompare(b.forename))
            }

            if (appointment.data.value.assignedTo && appointment.data.value.assignedTo > 0) {
              let isWithinAssignableUsers = assignableUsers.data.value.find(user => user.id === appointment.data.value.assignedTo)

              if (!isWithinAssignableUsers) {
                assignableUsers.data.value.push({
                  id: appointment.data.value.assignedTo,
                  fullName: appointment.data.value.assignedToName,
                  disabled: true
                })
              }

              let assignedUser = assignableUsers.data.value.find(obj => obj.id == appointment.data.value.assignedTo)
              assignmentMultiselect.value?.select(assignedUser)
            }

            // Set appChecklist values from appointment.checklist object as a copy, not a reference
            appChecklist.value = JSON.parse(JSON.stringify(appointment.data.value.checklist))

            // Set individual outcome values from appointment.outcome object as a copy, not a reference
            let outcome = JSON.parse(JSON.stringify(appointment.data.value.outcome))
            appOutcome.value.outcome = outcome.outcome ?? null
            appOutcome.value.decision = outcome.decision ?? null
            appOutcome.value.newVehicleType = outcome.newVehicle?.type ?? null
            appOutcome.value.newVehicleValue = outcome.newVehicle?.value ?? null
            appOutcome.value.newVehicleDeliveryDate = outcome.newVehicle?.estimatedDeliveryDate ?? null
            appOutcome.value.refinancedEndDate = outcome.refinancedUntil ?? null
            appOutcome.value.reason = outcome.reason ?? null

            // If appointment is in the past, set appointmentDetailsCollapsable to true
            if (dayjs().isAfter(dayjs(appointment.data.value.time))) {
              appointmentDetailsCollapsable.value = true
            } else {
              appointmentDetailsCollapsable.value = false
              appointmentDetailsVisible.value = true
            }
            console.log(appointment.data.value)
          })
      })
    }
    fetchAppointment()

    const isActionable = computed(() => {
      // get the appointment time
      const appointmentTime = dayjs(appointment.data.value.time)

      // check if the current time is within an hour of the appointment time
      return dayjs().isAfter(appointmentTime.subtract(1, 'hour'))
    })

    const cancelModalIsOpen = ref(false)
    const cancelIsLoading = ref(false)

    const cancelAppointmentData = ref({
      notifyCustomer: true,
      notifyDealership: true,
      updatedByUserID: userStore.details.id,
      updateSource: updateSource,
      updateNote: null
    })

    const appointmentCancelRules = {
      updateNote: {
        required: requiredIf(() => cancelAppointmentData.value.updateSource == 'retain')
      },
      updatedByUserID: { required }
    }

    const vCancel$ = useVuelidate(appointmentCancelRules, cancelAppointmentData)

    async function cancelAppointmentSubmit() {
      const isFormCorrect = await vCancel$.value.$validate()
      if (!isFormCorrect) {
        console.log(vCancel$.value.$errors)
        return
      }

      cancelIsLoading.value = true
      // Make this part of the function wait for the response from the API
      let response = await appointmentCancel.send({
        endpoint: '/v1/appointments/' + route.params.id + '/cancel',
        method: 'PATCH',
        data: cancelAppointmentData.value
      })

      if (!response.success) {
        alertToast('Error!', response.message, 'error')
      }
      if (response.success) {
        cancelModalIsOpen.value = false

        //Make sure the Appointment ID is received in the response
        if (!response.data) {
          alertToast('No Data returned. Contact support', null, 'error')
        } else {
          //Fetch the appointment data again //TODO change this to be more efficient and not fetching the entire appointment again.
          fetchAppointment()

          //If response has a message, means there was an issue.
          if (response.message) {
            alertToast('Cancelled', response.message, 'warning')
          } else {
            alertToast('Cancelled', null, 'success')
          }
        }
      }
      cancelIsLoading.value = false
    }

    function cancelClick() {
      cancelModalIsOpen.value = true
    }

    const deleteModalIsOpen = ref(false)
    const deleteIsLoading = ref(false)

    const deleteAppointmentData = ref({
      notifyCreator: true,
      notifyAssignedUser: true,
      updateSource: updateSource,
      updateNote: null
    })

    const appointmentDeleteRules = {
      updateNote: {
        required: requiredIf(() => cancelAppointmentData.value.updateSource == 'retain')
      }
    }

    const vDelete$ = useVuelidate(appointmentDeleteRules, deleteAppointmentData)

    function deleteClick() {
      deleteModalIsOpen.value = true
    }

    async function deleteAppointmentSubmit() {
      const isFormCorrect = await vDelete$.value.$validate()
      if (!isFormCorrect) {
        console.log(vDelete$.value.$errors)
        return
      }

      deleteIsLoading.value = true
      // Make this part of the function wait for the response from the API
      let response = await appointmentDelete.send({
        endpoint: '/v1/appointments/' + route.params.id,
        method: 'DELETE',
        data: deleteAppointmentData.value
      })

      if (response.success) {
        deleteModalIsOpen.value = false
        emit('appointmentUpdated', appointment.data.value)
        router.push('/agreement/' + route.params.agid + '/appointments')
        alertToast('Deleted', null, 'success')
      } else {
        alertToast('Failed to delete appointment', response.data?.message, 'error')
      }
    }

    function rescheduleAppointmentSubmit() {
      console.log('Rescheduling Appointment: ' + appointment.data.value.id)
      appointmentHandlerIsOpen.value = false
      fetchAppointment()
    }

    function rescheduleClick() {
      appointmentHandlerIsOpen.value = true
    }

    function getScoreColor(score) {
      if (score <= 70) {
        return 'danger'
      } else if (score <= 80) {
        return 'warning'
      } else {
        return 'success'
      }
    }

    const appointmentAssigning = appointmentAssign.isLoading
    function updateAssignedUser(assignTo) {
      // console.log(appointment.data.value.assignedTo, assignTo)
      if (appointment.data.value.assignedTo == assignTo) {
        return
      }

      if (assignableUsers.data.value.find(user => user.disabled == true)) {
        assignableUsers.data.value = assignableUsers.data.value.filter(user => user.disabled == false)
      }

      assignTo = assignTo ?? null
      let dataToPost = {
        userToAssign: assignTo,
        notifyUser: false,
        updatedBy: userStore.details.id,
        updateSource: updateSource
      }

      // Make this part of the function wait for the response from the API
      appointmentAssign
        .send({
          endpoint: '/v1/appointments/' + route.params.id + '/assign',
          method: 'PATCH',
          data: dataToPost
        })
        .then(response => {
          if (response.success) {
            appointment.data.value.status = response.data.status
            alertToast('Assigned', null, 'success')
          } else {
            alertToast('Failed to assign appointment', response.message, 'error')
          }
        })
    }

    const editNoteModalIsOpen = ref(false)

    const editNoteData = ref({
      note: null,
      notifyDealership: false
    })

    const editNoteVuelidateRules = {
      note: { required },
      notifyDealership: {}
    }
    const vEditNote$ = useVuelidate(editNoteVuelidateRules, editNoteData, { $stopPropagation: true })

    function editNoteClick() {
      //Update the textarea with the current appointment note
      editNoteData.value.note = appointment.data.value?.note
      editNoteModalIsOpen.value = true
    }

    const appointmentNoteIsLoading = ref(false)

    async function editNoteSubmit() {
      let currentNote = appointment.data.value.note
      if (currentNote == editNoteData.value.note) {
        editNoteModalIsOpen.value = false
        return
      }

      const isFormCorrect = await vEditNote$.value.$validate()

      if (!isFormCorrect) {
        return
      }
      let dataToPost = {
        note: editNoteData.value.note,
        notifyDealership: editNoteData.value.notifyDealership,
        updatedByUserId: userStore.details.id,
        updateSource: updateSource
      }

      appointmentNoteIsLoading.value = true
      // Make this part of the function wait for the response from the API
      appointmentUpdateNote
        .send({
          endpoint: '/v1/appointments/' + route.params.id + '/update-note',
          method: 'PATCH',
          data: dataToPost
        })
        .then(response => {
          if (response.success) {
            //Make sure the Appointment ID is received in the response
            if (!response.data) {
              alertToast('No Data returned', 'Contact support', 'error')
            } else {
              //If response has a message, means there was an issue.
              if (response.message) {
                alertToast('Appointment Note Updated', response.message, 'warning')
              } else {
                alertToast('Appointment Note Updated', null, 'success')
              }
              editNoteModalIsOpen.value = false
              appointment.data.value.note = response.data.note
            }
          } else {
            alertToast('failed to update', response.message, 'error')
          }
          appointmentNoteIsLoading.value = false
        })
    }

    const checklistModalIsOpen = ref(false)

    const appointmentChecklistRules = {
      settlementAmount: {
        numeric: helpers.withMessage('Invalid number', numeric)
      },
      valuationAmount: {
        numeric: helpers.withMessage('Invalid number', numeric)
      },
      refinanceQuoteAmount: {
        numeric: helpers.withMessage('Invalid number', numeric)
      }
    }

    const checklistDataToPost = ref(null)
    const vChecklist$ = useVuelidate(appointmentChecklistRules, checklistDataToPost, { $stopPropagation: true })

    async function updateChecklistSubmit() {
      // remove commas before vuelidate check
      checklistDataToPost.value = {
        ...appChecklist.value,
        refinanceQuoteAmount:
          appChecklist.value.refinanceQuoteAmount != null ? appChecklist.value.refinanceQuoteAmount.replace(/[,]/g, '') : null,
        settlementAmount: appChecklist.value.settlementAmount != null ? appChecklist.value.settlementAmount.replace(/[,]/g, '') : null,
        valuationAmount: appChecklist.value.valuationAmount != null ? appChecklist.value.valuationAmount.replace(/[,]/g, '') : null
      }

      const isFormCorrect = await vChecklist$.value.$validate()

      if (!isFormCorrect) {
        return
      }

      // convert empty strings into null
      for (let key in checklistDataToPost.value) {
        if (checklistDataToPost.value[key] === '') {
          checklistDataToPost.value[key] = null
        }
      }

      appointmentUpdateChecklist
        .send({
          endpoint: '/v1/appointments/' + route.params.id + '/checklist',
          method: 'PATCH',
          data: checklistDataToPost.value
        })
        .then(response => {
          if (response.success) {
            //If response has a message, means there was an issue.
            if (response.message) {
              alertToast('Checklist Updated', response.message, 'warning')
            } else {
              alertToast('Checklist Updated', null, 'success')
            }
          } else {
            alertToast(response.message, 'error')
          }
        })
    }

    const appOutcomeSettings = ref({
      outcomeOptions: [
        {
          label: 'No-Show',
          value: 'no-show',
          icon: 'ban',
          description: "Customer didn't attend",
          classes: 'col-span-2'
        },
        {
          label: 'Completed',
          value: 'completed',
          icon: 'check',
          description: 'Decision made',
          classes: 'col-span-2'
        },
        {
          label: 'Ongoing',
          value: 'ongoing',
          icon: 'arrows-rotate',
          description: 'No decision was made',
          classes: 'col-span-2'
        }
      ],
      decisionOptions: [
        {
          label: 'Part-Exchanged',
          value: 'part_exchanged',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['PCP', 'HP']
        },
        {
          label: 'Refinanced',
          value: 'refinanced',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['PCP']
        },
        {
          label: 'Settled',
          value: 'settled',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['PCP']
        },
        {
          label: 'Handed Back',
          value: 'handed_back',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
        },
        {
          label: 'Buy Back',
          value: 'buy_back',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['PCP']
        },
        {
          label: 'New Lease',
          value: 'new_lease',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['CH', 'PCH', 'BCH']
        },
        {
          label: 'Extended',
          value: 'extended',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['CH', 'PCH', 'BCH']
        },
        {
          label: 'Keeping',
          value: 'keeping',
          applicableOutcomeTypes: ['completed'],
          applicableContractTypes: ['HP']
        },
        {
          label: 'Undecided',
          value: 'undecided',
          applicableOutcomeTypes: ['ongoing'],
          applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
        },
        {
          label: 'Not Ready',
          value: 'not_ready',
          applicableOutcomeTypes: ['ongoing'],
          applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
        },
        {
          label: 'Poor Offer',
          value: 'poor_offer',
          applicableOutcomeTypes: ['ongoing'],
          applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
        }
      ],
      newVehicleTypes: [
        { value: 'hatchback', label: 'Hatchback' },
        { value: 'estate', label: 'Estate' },
        { value: 'crossover', label: 'Crossover' },
        { value: 'suv', label: 'SUV' },
        { value: 'motorcycle', label: 'Motorcycle' }
      ]
    })

    const appOutcome = ref({
      outcome: appointment.data.value?.outcome.outcome ?? null,
      decision: appointment.data.value?.outcome.decision ?? null,
      newVehicleType: appointment.data.value?.outcome.newVehicle?.type ?? null,
      newVehicleValue: appointment.data.value?.outcome.newVehicle?.value ?? null,
      newVehicleDeliveryDate: appointment.data.value?.outcome.newVehicle?.deliveryDate ?? null,
      refinancedAmount: appointment.data.value?.outcome.refinanced?.amount ?? null,
      refinancedEndDate: appointment.data.value?.outcome.refinanced?.until ?? null,
      reason: appointment.data.value?.outcome.reason ?? null
    })

    const appointmentOutcomeRules = {
      outcome: { required },
      decision: { required: requiredIf(() => appOutcome.value.outcome != 'no-show') },
      newVehicleType: {},
      newVehicleValue: { numeric },
      newVehicleDeliveryDate: {},
      refinancedAmount: { numeric },
      refinancedEndDate: {},
      reason: { maxLength: maxLength(200) }
    }

    function outcomeSelectionChange(option) {
      appOutcome.value.outcome = option
      appOutcome.value.decision = null
    }

    // Computed value which sets the outcome decisions based on the outcome selection, and the appointment.data.value.financeProductType
    const applicableOutcomeDecisions = computed(() => {
      let applicableOutcomeDecisions = appOutcomeSettings.value.decisionOptions.filter(option => {
        return (
          option.applicableOutcomeTypes.includes(appOutcome.value.outcome) &&
          option.applicableContractTypes.includes(appointment.data.value.financeProductType)
        )
      })
      if (applicableOutcomeDecisions.length === 0) {
        return appOutcomeSettings.value.decisionOptions.filter(option => {
          return option.applicableOutcomeTypes.includes(appOutcome.value.outcome)
        })
      }
      return applicableOutcomeDecisions
    })

    const outcomeModalIsOpen = ref(false)
    const submitOutcomeIsLoading = ref(false)
    const vOutcome$ = useVuelidate(appointmentOutcomeRules, appOutcome, { $stopPropagation: true })

    async function submitOutcome() {
      const isFormCorrect = await vOutcome$.value.$validate()

      console.log(isFormCorrect)

      if (!isFormCorrect) {
        return
      }
      let dataToPost = {
        outcome: appOutcome.value.outcome,
        decision: appOutcome.value.decision,
        partExchanged: {
          type: appOutcome.value.newVehicleType,
          value: appOutcome.value.newVehicleValue,
          estimatedDeliveryDate: appOutcome.value.newVehicleDeliveryDate
        },
        refinanced: {
          amount: appOutcome.value.refinancedAmount,
          until: appOutcome.value.refinancedEndDate
        },
        note: appOutcome.value.reason,
        updatedBy: userStore.details.id,
        updatedByType: updateSource
      }
      console.log(dataToPost)

      submitOutcomeIsLoading.value = true
      appointmentSubmitOutcome
        .send({
          endpoint: '/v1/appointments/' + route.params.id + '/outcome',
          method: 'PUT',
          data: dataToPost
        })
        .then(response => {
          if (response.success) {
            //If response has a message, means there was an issue.
            if (response.message) {
              alertToast('Appointment Outcome Updated', response.message, 'warning')
            } else {
              alertToast('Appointment Outcome Updated', null, 'success')
            }
            outcomeModalIsOpen.value = false
            // OPTIMIZE: Update the appointment data without fetching the entire appointment again
            fetchAppointment()
          } else {
            alertToast('Failed to Update', response.message, 'error')
          }
          submitOutcomeIsLoading.value = false
        })
    }

    const appointmentIsLocked = computed(() => {
      let reason = null

      if (can('edit_locked_appointments')) return reason

      if (dayjs(appointment.data.value?.time).isBefore(dayjs().subtract(7, 'days')) && appointment.data.value?.outcome.outcome) {
        reason = 'Appointment is locked as it is older than 7 days and an outcome has been set.'
      } else if (appointment.data.value?.isCancelled) {
        reason = 'Appointment is locked as it has been cancelled.'
      }

      return reason
    })

    const uploadFileApiEndpoint = ref(null)
    const uploadFilesModalIsOpen = ref(false)

    function uploadChecklistFileClick(category) {
      uploadFileApiEndpoint.value = `${import.meta.env.VITE_API_BASE_URL}/v1/appointments/${route.params.id}/file-upload`
      switch (category) {
        case 'settlement':
          uploadFileApiEndpoint.value = uploadFileApiEndpoint.value + '?cat=settlement'
          break
        case 'newCarOffers':
          uploadFileApiEndpoint.value = uploadFileApiEndpoint.value + '?cat=new-car-offers'
          break
        case 'refinanceQuote':
          uploadFileApiEndpoint.value = uploadFileApiEndpoint.value + '?cat=refinance-quote'
          break

        default:
          break
      }

      uploadFilesModalIsOpen.value = true
    }

    function openFileInNewTab(file) {
      window.open(file.url, '_blank')
    }

    const sendReloopIsLoading = ref(false)

    function sendReloopForm() {
      sendReloopIsLoading.value = true
      let customerEmail = props.agreementData.customer.contact.email

      // TODO: Send Reloop to customer here

      sendReloopIsLoading.value = false
    }

    return {
      props,
      userStore,
      route,
      appointmentDetailsCollapsable,
      appointmentDetailsVisible,
      isActionable,
      appointment,
      assignableUsers,
      assignmentMultiselect,
      cancelAppointmentData,
      cancelModalIsOpen,
      cancelAppointmentSubmit,
      cancelIsLoading,
      cancelClick,
      deleteAppointmentData,
      deleteModalIsOpen,
      deleteAppointmentSubmit,
      deleteIsLoading,
      deleteClick,
      rescheduleClick,
      appointmentHandlerIsOpen,
      datePickerConfigs,
      rescheduleData,
      vReschedule$,
      rescheduleAppointmentSubmit,
      updateAssignedUser,
      appointmentAssigning,
      editNoteClick,
      editNoteModalIsOpen,
      editNoteData,
      editNoteSubmit,
      appointmentNoteIsLoading,
      vEditNote$,
      checklistModalIsOpen,
      appChecklist,
      updateChecklistSubmit,
      appOutcomeSettings,
      appOutcome,
      vOutcome$,
      vChecklist$,
      vCancel$,
      vDelete$,
      outcomeSelectionChange,
      outcomeModalIsOpen,
      applicableOutcomeDecisions,
      submitOutcome,
      submitOutcomeIsLoading,
      getProperties,
      useBreakPoints,
      appointmentIsLocked,
      getScoreColor,
      uploadFileApiEndpoint,
      uploadFilesModalIsOpen,
      uploadChecklistFileClick,
      fetchAppointment,
      openFileInNewTab,
      sendReloopForm,
      sendReloopIsLoading
    }
  }
}
</script>
