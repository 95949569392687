<template>
  <teleport to="#modals-container">
    <ShiftHandler
      :key="sidebarKey"
      :is-open="shiftHandlerIsOpen"
      :shift="shiftHandlerData"
      :users="users"
      :dealerships="dealerships"
      @close-shift-handler="shiftHandlerToggle()"
      @add-new-shift-submit="newShiftSubmit"
      @update-shift-submit="updateShiftSubmit"
      @delete-shift="deleteShift"
    />
  </teleport>

  <PageHeader
    :title="$t('ui.entities.schedule.label')"
    breadcrumb
    :items="[{ label: $t('ui.entities.setting', 2), to: '/settings' }, { label: $t('ui.entities.schedule.label') }]"
  ></PageHeader>

  <div>
    <Card>
      <div class="schedule-header grid" :style="'grid-template-columns: repeat(' + (columns.length + 2) + ', minmax(0, 1fr));'">
        <div class="schedule-header-cell col-span-2">
          <DatePicker v-model="periodSelected" :config="periodSelectionConfig" />
        </div>
        <div v-for="column in columns" :key="column" class="schedule-header-cell text-center">
          <div>{{ column.dateString }}</div>
          <div v-tippy="{ content: $t('ui.entities.schedule.productive-hours') }" class="text-sm">
            {{ getDayProductiveHours(column.date) }} <span class="text-secondary">{{ $t('ui.common.date-time.hr', 2) }}</span>
          </div>
        </div>
      </div>
      <div
        v-for="user in users"
        :key="user.id"
        class="schedule-row grid"
        :style="'grid-template-columns: repeat(' + (columns.length + 2) + ', minmax(0, 1fr));'"
      >
        <div class="schedule-row-side col-span-2 flex justify-between">
          <div class="flex justify-between items-center">
            <Avatar size="md" :url="user.profilePicURL" :text="user.initials" :user-presence-user-id="user.id" />
            <span class="ml-3">{{ user.fullName }}</span>
          </div>
          <div v-tippy="{ content: $t('ui.entities.schedule.productive-hours') }" class="text-xs">
            {{ getWeekProductiveHours(user.id) }} <span class="text-secondary">{{ $t('ui.common.date-time.hr', 2) }}</span>
          </div>
        </div>
        <div
          v-for="column in columns"
          :key="column.date"
          class="drop-block"
          @drop="onDrop($event, user.id, column.date)"
          @dragenter.prevent
          @dragover.prevent
          @dragenter="highlightDrop($event)"
          @dragleave="unhighlightDrop($event)"
          @click.self="addShift($event, user.id, column.date)"
        >
          <div v-if="!getShiftsRequest.isLoading.value && getShift(user.id, column.date)?.length > 0">
            <div
              v-for="shift in getShift(user.id, column.date)"
              :key="shift.id"
              class="shift-block border-l-4"
              :style="{ 'border-color': shift.typeColor }"
              draggable="true"
              @dragstart="startDrag($event, shift)"
              @click="shiftHandlerToggle(shift)"
            >
              <div class="shift-block-delete" @click.stop="deleteShift(shift)">
                <Icon type="trash" />
              </div>
              <div class="font-medium text-[14px] flex justify-center items-center gap-1">
                {{ formatShiftTime(shift.start) }}
                <Icon type="arrow-right" class="text-secondary text-xs" />
                <!-- <span class="text-secondary">-</span> -->
                {{ formatShiftTime(shift.end) }}
              </div>
              <div class="text-[13px] text-slate-400 flex justify-center gap-4">
                <span class="flex items-center">
                  <Icon type="business-time" class="text-[10px] mr-[2px]" />
                  <span>{{ shift.productiveHours }}</span>
                </span>
                <span class="flex items-center">
                  <Icon type="phone" class="text-[10px] mr-[2px]" />
                  <span>{{ shift.callTarget }}</span>
                </span>
                <span class="flex items-center">
                  <Icon type="calendar" class="text-[10px] mr-[2px]" />
                  <span>{{ shift.appointmentTarget }}</span>
                </span>
              </div>
              <div>
                <ProgressBar
                  :value="shift.allocatedPercentage"
                  :show-value="false"
                  style="height: 0.1rem"
                  class="w-full mt-1"
                  :class="[shift.allocatedPercentage != 100 ? 'jobcard-progress-danger' : 'jobcard-progress-success']"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid" :style="'grid-template-columns: repeat(' + (columns.length + 2) + ', minmax(0, 1fr));'">
        <div class="p-5 col-span-2 flex justify-between">
          <span>{{ $t('ui.common.total') }}</span
          ><span>{{ totalProductiveHours + $t('ui.common.date-time.hr', 2) }}</span>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { computed } from 'vue'

import ShiftHandler from '@/components/unique/ShiftHandler.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Icon from '../../../components/icon/Icon.vue'
import Avatar from '../../../components/avatar/Avatar.vue'
import DatePicker from '@/components/forms/DatePicker.vue'
import ProgressBar from 'primevue/progressbar'

import { alertToast } from '@/utilities/notification'
import useApiRequest from '@/composables/useApiRequest'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

export default {
  components: {
    PageHeader,
    Card,
    Icon,
    Avatar,
    ShiftHandler,
    DatePicker,
    ProgressBar
  },
  setup() {
    const periodSelected = ref(dayjs().startOf('week').format('YYYY-MM-DD') + ' to ' + dayjs().endOf('week').format('YYYY-MM-DD'))
    const period = ref([dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().endOf('week').format('YYYY-MM-DD')])

    //TODO - Use isLoading on all data to show skeleton and avoid errors

    const shifts = ref(null)
    const users = ref(null)
    const shiftTypes = ref(null)
    const dealerships = ref(null)

    const getShiftsRequest = useApiRequest()
    const moveShiftRequest = useApiRequest()
    const copyShiftRequest = useApiRequest()
    const deleteShiftRequest = useApiRequest()

    const getUsersRequest = useApiRequest()
    const getShiftTypesRequest = useApiRequest()
    const getDealershipsRequest = useApiRequest()

    function getShifts() {
      try {
        getShiftsRequest
          .send({ method: 'GET', endpoint: '/v1/users/schedule/current', params: { start: period.value[0], end: period.value[1] } })
          .then(response => {
            if (response.success) {
              shifts.value = response.data
            } else {
              alertToast('Failed to fetch shifts', response.message, 'error')
            }
          })
      } catch (err) {
        alertToast('Failed to fetch shifts', err.message, 'error')
      }
    }

    function getUsers() {
      try {
        getUsersRequest
          .send({ method: 'GET', endpoint: '/v1/users', params: { userType: 'retainUsers', forSchedule: true } })
          .then(response => {
            if (response.data && response.success) {
              users.value = response.data
              // Order users by name
              users.value?.sort((a, b) => {
                return a.fullName.localeCompare(b.fullName)
              })
            } else {
              alertToast('Failed to fetch users', response.message, 'error')
            }
          })
      } catch (error) {
        alertToast('Failed to fetch users', err.message, 'error')
      }
    }
    getUsers()

    function getShiftTypes() {
      try {
        getShiftTypesRequest.send({ method: 'GET', endpoint: '/v1/users/schedule/shift-types' }).then(response => {
          if (response.data && response.success) {
            shiftTypes.value = response.data
          } else {
            alertToast('Failed to fetch shift types', response.message, 'error')
          }
        })
      } catch (error) {
        alertToast('Failed to fetch shift types', err.message, 'error')
      }
    }
    getShiftTypes()

    function getDealerships() {
      try {
        getDealershipsRequest.send({ method: 'GET', endpoint: '/v1/dealerships', params: { showAll: true } }).then(response => {
          if (response.data && response.success) {
            dealerships.value = response.data
          } else {
            alertToast('Failed to fetch dealerships', response.message, 'error')
          }
        })
      } catch (error) {
        alertToast('Failed to fetch dealerships', err.message, 'error')
      }
    }
    getDealerships()

    const periodSelectionConfig = {
      enableTime: false,
      altInput: true,
      altFormat: 'j M',
      weekNumbers: true,
      mode: 'range',
      locale: { firstDayOfWeek: 1 },
      allowInput: true,
      onReady: function (dateObj, dateStr, instance) {
        instance.altInput.value = dayjs(dateStr, 'YYYY-MM-DD').week() + ' | ' + instance.altInput.value
      },
      onChange: function (selectedDates, dateStr, instance) {
        let from = dayjs(dateStr, 'YYYY-MM-DD').startOf('week').format('YYYY-MM-DD')
        let to = dayjs(dateStr, 'YYYY-MM-DD').startOf('week').add(6, 'day').format('YYYY-MM-DD')

        instance.setDate([from, to], false)

        //Update period selected
        period.value = [from, to]
        instance.altInput.value = dayjs(dateStr, 'YYYY-MM-DD').week() + ' | ' + instance.altInput.value
        instance.close()
      }
    }

    const sidebarKey = ref(Math.random())

    // Watch for change in period selected
    watch(
      () => [...period.value],
      () => {
        setColumns()
      }
    )

    const columns = ref([])

    //Function to set columns based on the period selected
    const setColumns = () => {
      columns.value = [] //Reset columns before pushing new ones
      for (let index = 0; index < 7; index++) {
        let periodStart = period.value[0]
        let date = dayjs(periodStart).add(index, 'day').format('YYYY-MM-DD')
        let dateString = dayjs(periodStart).add(index, 'day').format('ddd D MMM')
        columns.value.push({ date: date, dateString: dateString })
      }
      //Run loadShifts once the new dates are set
      getShifts()
    }
    setColumns() //Run once on init

    const getWeekProductiveHours = userId => {
      let userShifts = shifts.value?.filter(shift => shift.userId == userId)
      let weekHours = userShifts?.reduce((n, { productiveHours }) => n + productiveHours, 0)

      if (weekHours > 0) {
        return weekHours
      } else {
        return 0
      }
    }

    const getDayProductiveHours = column => {
      let dayShifts = shifts.value?.filter(shift => shift.period == column)
      let dayHours = dayShifts?.reduce((n, { productiveHours }) => n + productiveHours, 0)

      if (dayHours > 0) {
        return dayHours
      } else {
        return 0
      }
    }

    const startDrag = (event, shift) => {
      if (event.ctrlKey) {
        //Copy
        event.dataTransfer.dropEffect = 'copy'
        event.dataTransfer.effectAllowed = 'copy'
      } else {
        //Move
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.effectAllowed = 'move'
      }
      event.dataTransfer.setData('shiftID', shift.id)
    }

    function copyShift(event, shiftData) {
      //Show spinner
      event.target.classList.add('drop-block-spinner')
      try {
        copyShiftRequest.send({ method: 'POST', endpoint: '/v1/users/schedule/current', data: shiftData }).then(response => {
          if (response.data != '' && response.success) {
            //Add shift to shifts array
            shifts.value?.push(response.data)
            alertToast('Copied', null, 'success')

            //Hide spinner
            event.target.classList.remove('drop-block-spinner')
          } else {
            alertToast('Failed to copy the shift', response.message, 'error')
            throw Error('Error posting data')
          }
        })
      } catch (err) {
        alertToast('Failed to copy the shift', err.message, 'error')
      }
    }

    function moveShift(data, userId, column) {
      let updatedData = { ...data }
      updatedData.userId = userId
      updatedData.period = column

      try {
        moveShiftRequest
          .send({ method: 'PATCH', endpoint: '/v1/users/schedule/current/' + updatedData.id, data: updatedData })
          .then(response => {
            if (response.success) {
              data.period = column
              data.userId = userId

              alertToast('Updated', response.message, 'success')
            } else {
              alertToast('Update Failed', response.message, 'error')
              throw Error('Error patching data')
            }
          })
      } catch (err) {
        alertToast('Update Failed', err.message, 'error')
      }
    }

    const highlightDrop = event => {
      // console.log(event.target)
      event.target.classList.add('dragover')
    }
    const unhighlightDrop = event => {
      event.target.classList.remove('dragover')
    }
    const onDrop = (event, userId, column) => {
      const shiftID = event.dataTransfer.getData('shiftID')
      const shift = shifts.value?.find(shift => shift.id == shiftID)
      if (event.shiftKey) {
        //Copy shift
        let newShift = { ...shift }
        newShift.userId = userId
        newShift.period = column

        //Create new shift in DB
        copyShift(event, newShift)
      } else {
        //Move shift
        moveShift(shift, userId, column)
      }
      event.target.classList.remove('dragover')
    }
    const formatShiftTime = time => {
      return dayjs(time, 'hh:mm:ss').format('HH:mm')
    }

    const shiftHandlerIsOpen = ref(false)
    const shiftHandlerData = ref()

    const addShift = (event, userId, period) => {
      // console.log(event.target)
      shiftHandlerData.value = { userId: userId, period: period }

      shiftHandlerToggle()
    }

    function shiftHandlerToggle(shift) {
      sidebarKey.value = Math.random() // Change key so sidebar will re-mount
      shiftHandlerIsOpen.value = !shiftHandlerIsOpen.value

      if (shift) {
        //If update shift
        shiftHandlerData.value = shift
      }
    }

    function newShiftSubmit(data) {
      shifts.value?.push(data)
      shiftHandlerToggle()
    }

    function updateShiftSubmit(data) {
      // Update shift with new data
      const shiftIndex = shifts.value?.findIndex(x => x.id === data.id)
      shifts.value[shiftIndex] = data

      shiftHandlerToggle()
    }

    async function deleteShift(shift) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this shift!',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fa fa-trash"></i> Delete',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'var(--bg-tertiary)'
      }).then(function (response) {
        if (response.isConfirmed) {
          try {
            deleteShiftRequest.send({ method: 'DELETE', endpoint: '/v1/users/schedule/current/' + shift.id }).then(response => {
              if (response.success) {
                alertToast('Deleted', response.message, 'success')

                const shiftIndex = shifts.value?.findIndex(x => x.id === shift.id)
                shifts.value?.splice(shiftIndex, 1)
                shiftHandlerIsOpen.value = false
              } else {
                alertToast('Failed to delete the shift', response.message, 'error')
                throw Error('Error deleting data')
              }
            })
          } catch (err) {
            alertToast('Failed to delete the shift', err.message, 'error')
          }
        } else {
          shiftHandlerToggle()
        }
      })
    }

    const computedShifts = computed(() => {
      shifts.value?.forEach(shift => {
        let productiveHours = shift.productiveHours
        let allocatedHours = shift.jobCards.reduce((total, item) => total + item.hours, 0)

        if (allocatedHours == productiveHours) {
          shift.allocatedPercentage = 100
        } else {
          shift.allocatedPercentage = (allocatedHours / productiveHours) * 100
        }

        //Get shift color and type
        let typeObj = shiftTypes.value?.find(type => type.id === shift.type)

        if (typeObj && Object.keys(typeObj)?.length > 0) {
          shift.typeName = typeObj.name
          shift.typeColor = typeObj.color
        } else {
          shift.typeName = 'Normal'
          shift.typeColor = 'secondary'
        }
      })

      return shifts.value
    })

    const getShift = (userId, column) => {
      let filteredShifts = computedShifts.value?.filter(shift => shift.userId == userId && shift.period == column)

      // Sort the filtered shifts by start time
      filteredShifts?.sort((a, b) => {
        return a.start.localeCompare(b.start)
      })

      return filteredShifts
    }

    const totalProductiveHours = computed(() => {
      let computedHours = shifts.value?.reduce((n, { productiveHours }) => n + productiveHours, 0)

      return computedHours
    })

    return {
      periodSelected,
      period,
      periodSelectionConfig,
      users,
      dealerships,
      columns,
      computedShifts,
      getShift,
      totalProductiveHours,
      getWeekProductiveHours,
      getDayProductiveHours,
      addShift,
      formatShiftTime,
      startDrag,
      highlightDrop,
      unhighlightDrop,
      onDrop,
      shiftHandlerIsOpen,
      sidebarKey,
      shiftHandlerToggle,
      shiftHandlerData,
      newShiftSubmit,
      updateShiftSubmit,
      deleteShift,
      getShiftsRequest
    }
  }
}
</script>

<style>
.schedule-header {
  border-bottom: 1px solid var(--border-secondary);
  position: sticky;
  top: 0px;
  z-index: 1;
  border-radius: var(--rounded-md) var(--rounded-md) 0 0;
}

.schedule-header-cell {
  padding: 0.6rem 1rem;
}

.schedule-row {
  border-bottom: 1px solid var(--border-secondary);
}

.schedule-row-side {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--border-secondary);
  min-height: 62px;
}

.drop-block {
  min-height: 56px;
  padding: 3px 2px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.drop-block:empty:hover {
  cursor: pointer;
}

.drop-block:empty:hover::after {
  font-family: 'Font Awesome 6 Pro';
  content: '\2b';
  font-size: 16px;
  color: var(--text-secondary);
  background-color: var(--bg-tertiary);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--rounded-md);
}

.drop-block-spinner::after {
  font-family: 'Font Awesome 6 Pro';
  content: '\f110';
  font-size: 18px;
  position: absolute;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  left: calc(50% - 8px);
  top: calc(50% - 14px);
}

.dragover {
  background-color: var(--bg-secondary);
}

.shift-block {
  background-color: var(--bg-tertiary);
  padding: 0.5rem 0.8rem;
  border-radius: var(--rounded-md);
  min-height: 50px;
  margin-bottom: 3px;
  position: relative;
}

.drop-block > div > .shift-block:last-of-type {
  margin-bottom: 0;
}

.shift-block:hover {
  cursor: pointer;
}

.shift-block:hover > .shift-block-delete {
  display: flex;
}

.shift-block-delete {
  display: none;
  color: var(--fg-error);
  background-color: var(--bg-primary);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  font-size: 0.7rem;
  z-index: 1;
}

.dark .shift-block-delete {
  background-color: var(--bg-error);
  color: white;
}

.shift-block-delete:hover {
  background-color: var(--bg-error);
  color: white;
}
</style>
