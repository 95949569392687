<template>
  <div class="invoice-item flex justify-between gap-4">
    <div class="w-full">
      <div v-if="1 == 2">
        <div><Input v-model="state.catalogItem.name" /></div>
        <div class="mt-2"><TextArea v-model="state.catalogItem.description" /></div>
      </div>
      <div v-else>
        <div class="font-medium">{{ state.catalogItem.name }}</div>
        <div class="mt-1 text-sm text-secondary leading-tight">{{ state.catalogItem.description }}</div>
      </div>
    </div>
    <div class="flex gap-4">
      <div class="w-20">
        <Input
          v-model="state.overrideRate"
          :readonly="readOnly"
          :placeholder="state.catalogItem.saleRate"
          :has-error="v$.overrideRate.$error"
        />
        <div v-if="state.overrideRate && state.catalogItem.saleRate" v-tippy="'Default item rate'" class="line-through ml-2 mt-2">
          {{ state.catalogItem.saleRate }}
        </div>
        <InputError :has-error="v$.overrideRate.$error">{{ v$.overrideRate.$errors[0].$message }}</InputError>
      </div>
      <div class="w-20">
        <Input v-model="state.quantity" type="number" :min="1" :readonly="readOnly" :has-error="v$.quantity.$error" />
        <InputError :has-error="v$.quantity.$error">{{ v$.quantity.$errors[0].$message }}</InputError>
      </div>
      <div class="w-40">
        <InputGroup>
          <Input
            v-model.number="state.discountValue"
            type="number"
            :max="100"
            class="w-[100px]"
            :readonly="readOnly"
            :has-error="v$.discountValue.$error"
          />
          <Multiselect
            v-model="state.discountUnit"
            mode="single"
            :can-clear="false"
            :can-deselect="false"
            :options="[
              { value: 'percent', label: '%' },
              { value: 'fixed', label: '£' }
            ]"
            :disabled="readOnly"
          />
        </InputGroup>
        <InputError :has-error="v$.discountValue.$error">{{ v$.discountValue.$errors[0].$message }}</InputError>
      </div>
      <div class="w-16 mt-2 font-medium whitespace-nowrap">{{ numberFormatter(computedTotal, 'normal', 2) }}</div>
      <div class="w-16 border-l border-gray-300 flex justify-center items-start">
        <Button v-tippy="'Remove'" :disabled="readOnly" icon="trash" plain severity="danger" @click="removeItem()" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'

import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, numeric, helpers, minValue, maxValue } from '@vuelidate/validators'

import Swal from 'sweetalert2'

import Input from '@/components/forms/Input.vue'
import InputGroup from '@/components/forms/InputGroup.vue'
import TextArea from '@/components/forms/TextArea.vue'
import Button from '@/components/button/Button.vue'

import InputError from '@/components/forms/InputError.vue'

export default {
  components: {
    Input,
    InputGroup,
    Multiselect,
    TextArea,
    Button,
    InputError
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    hasErrors: {
      type: Boolean,
      default: false
    }
  },
  emits: ['itemRemoved', 'itemUpdated', 'itemValidated'],
  setup(props, { emit }) {
    const state = ref({ ...props.item })

    const vuelidateRules = {
      overrideRate: {
        numeric,
        requiredIf: helpers.withMessage(
          'Required',
          requiredIf(() => state.value.catalogItem.saleRate == 0)
        )
      },
      quantity: {
        numeric,
        minimumValue: helpers.withMessage('Cannot be 0', value => {
          if (state.value?.catalogItem.id == 1) {
            return true
          } else {
            return value > 0 || value == null
          }
        })
      },
      discountValue: { numeric, maxValue: maxValue(100) },
      discountUnit: {}
    }
    const v$ = useVuelidate(vuelidateRules, state, { $stopPropagation: true })

    const computedTotal = computed(() => {
      let rate = state.value.overrideRate ? state.value.overrideRate : state.value.catalogItem.saleRate
      let discountAmount = 0
      let total = 0

      total = rate * state.value.quantity

      if (state.value.discountUnit == 'fixed') {
        discountAmount = state.value.discountValue
      } else if (state.value.discountUnit == 'percent') {
        discountAmount = total * (state.value.discountValue / 100)
      }

      return total - discountAmount
    })

    function removeItem() {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, remove this item',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel'
      }).then(function (response) {
        if (response.isConfirmed) {
          let randId = state.value.randId
          emit('itemRemoved', randId)
        }
      })
    }
    const isFormCorrect = ref(null)

    async function validateItem() {
      isFormCorrect.value = await v$.value.$validate()
    }

    validateItem().then(() => {
      let valid = isFormCorrect.value
      let randId = state.value.randId

      emit('itemValidated', valid, randId)
    })

    // On mounted, run validation straight away
    onMounted(() => {
      v$.value.$touch()
    })

    //Watch for changes to the item and emit to parent
    watch(
      state,
      () => {
        emit('itemUpdated', state.value)
        validateItem().then(() => {
          emit('itemValidated', isFormCorrect.value, state.value.randId)
        })
      },
      { deep: true }
    )

    return {
      state,
      computedTotal,
      removeItem,
      v$
    }
  }
}
// Needs to update data to parent
// need to be able to add a line item // TBD
// see the billing account name, how often the invoice will be generated, and the next invoice date
// need to be able to save the changes
</script>

<style>
.invoice-item {
  border: 1px solid var(--border-secondary);
  border-radius: var(--rounded-lg);
  margin-bottom: var(--s-4);
  padding: var(--s-4);
}
</style>
