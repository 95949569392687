<template>
  <PageHeader :title="$t('ui.entities.appointment-manager')">
    <template #right>
      <div>
        <div v-if="dealershipsAllowed.length > 1" class="w-full flex items-center gap-2">
          <MultiSelect
            v-model="dealershipsSelected"
            :options="dealershipsAllowed"
            display="chip"
            filter
            option-value="id"
            option-label="name"
            placeholder="Select a Dealership"
            class="max-w-[350px]"
          >
          </MultiSelect>
          <Button label="Update" :is-loading="appointmentsIsLoading" @click="onDealershipChange" />
        </div>
        <div v-else class="flex">
          <Icon type="map-marker" class="mr-1" />
          <div>{{ dealershipsAllowed[0].name }}</div>
        </div>
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="appointmentsComputed"
      removable-sort
      sort-field="time"
      :sort-order="1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['customerName', 'dealershipName']"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-appointment-manager"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="appointmentsIsLoading"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
      </template>

      <Column field="date" header="Date" :sortable="true">
        <template #body="{ data }">
          <a class="link" :href="'/agreement/' + data.agreementId + '/appointments/' + data.id">
            <div class="whitespace-nowrap">{{ dayjs(data.date).format('Do MMM YY H:mm') }}</div>
          </a>
        </template>
      </Column>

      <Column field="customerName" header="Customer" :sortable="true">
        <template #body="{ data }">
          {{ data.customerName }}
        </template>
      </Column>

      <Column field="dealershipID" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div>{{ data.dealershipName }}</div>
        </template>
      </Column>

      <Column field="method" header="Method" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Icon
            v-if="data.method"
            v-tippy="$t(getProperties('appointmentMethod', data.method)?.localeKey)"
            :type="$t(getProperties('appointmentMethod', data.method)?.icon)"
          />
        </template>
      </Column>

      <Column field="actionStatus" header="Action" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="data.actionStatus == 'unassigned'">
            <Dropdown
              v-if="$can('assign_appointments')"
              :ref="data.id"
              v-model="data.assignTo"
              mode="single"
              option-label="fullName"
              option-value="id"
              :options="currentAssignableUsers"
              :close-on-select="true"
              :searchable="true"
              :hide-selected="false"
              placeholder="Assign"
              track-by="fullName"
              class="w-full"
              @click="onDropdownClick(data.dealershipId)"
              @change="updateAssignedUser($event, data.id)"
            >
              <template #option="{ option }">
                <div class="flex items-center">
                  <Avatar :url="option.profilePicURL" :text="option.initials" size="sm" class="mr-3" /> {{ option.fullName }}
                </div>
              </template>
            </Dropdown>
          </div>
          <div v-else>
            <router-link v-if="$can('outcome_appointments')" :to="'/agreement/' + data.agreementId + '/appointments/' + data.id">
              <Button label="Set Outcome" outlined />
            </router-link>
          </div>
        </template>

        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="actionStatusOptions"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="view" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Button
            v-tippy="$t('ui.actions.view')"
            icon="eye"
            plain
            severity="secondary"
            @click="$router.push('/agreement/' + data.agreementId + '/appointments/' + data.id)"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Dropdown from 'primevue/dropdown'
import { alertToast } from '@/utilities/notification'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import { getProperties } from '@/utilities/dataMapper'

import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    Card,
    PageHeader,
    DataTable,
    Icon,
    Avatar,
    Dropdown,
    Column,
    MultiSelect,
    Input,
    Button
  },
  setup() {
    const dt = ref()

    const route = useRoute()
    const router = useRouter()

    const appointmentsApiRequest = useApiRequest()
    const userStore = useUserStore()

    const dealershipsSelected = ref([])
    const dealershipsSelectedLS = JSON.parse(localStorage.getItem('appointment-manager-dealerships-selected'))
    if (dealershipsSelectedLS) {
      dealershipsSelected.value = dealershipsSelectedLS
    }
    const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

    const actionStatusOptions = ref([
      {
        label: 'Unassigned',
        value: 'unassigned'
      },
      {
        label: 'No Outcome',
        value: 'no_outcome'
      }
    ])

    const appointments = ref([])
    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        actionStatus: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    initFilters()

    function clearFilters() {
      //remove from session storage
      sessionStorage.removeItem('dt-appointment-manager')
      initFilters()
    }

    function setRouteFilters() {
      console.log('setRouteFilters()')
      if (route.query && Object.keys(route.query).length > 0) {
        clearFilters()
        for (const [key, value] of Object.entries(route.query)) {
          if (key === 'dealerships') {
            localStorage.setItem('appointment-manager-dealerships-selected', JSON.stringify(value.split(',').map(Number)))

            dealershipsSelected.value = value.split(',').map(Number)
            console.log('set dealerships', dealershipsSelected.value)
          }
          if (key === 'actionStatus') {
            filters.value.actionStatus.value = value.split(',')
            console.log('status', filters.value.actionStatus.value)
          }
        }
      }
      // Remove url query params  using router replace
      router.replace({ query: {} })
    }

    setRouteFilters()
    onDealershipChange()

    function fetchAppointments() {
      appointmentsApiRequest
        .send({
          endpoint: '/v1/appointments',
          params: {
            start: '2024-01-01',
            dealerships: dealershipsSelected.value.join(','),
            requireAction: true
          }
        })
        .then(response => {
          if (response.success) {
            appointments.value = response.data
          } else {
            alertToast('Failed to fetch appointments', response.data?.message, 'error')
          }
        })
    }

    function onDealershipChange() {
      if (dealershipsSelected.value?.length) {
        fetchAppointments()
      } else {
        appointments.value = []
      }
      // Store selected dealerships in local storage
      localStorage.setItem('appointment-manager-dealerships-selected', JSON.stringify(dealershipsSelected.value))
    }

    const currentAssignableUsers = ref(null)
    const storedAssignableUsers = ref([])
    const assignableUsersApiRequest = useApiRequest()

    function onDropdownClick(dealershipId) {
      // If assignable users are already stored in the array, set assignableUsers to that list
      if (storedAssignableUsers.value.some(d => d.dealershipId == dealershipId)) {
        currentAssignableUsers.value = storedAssignableUsers.value.find(d => d.dealershipId == dealershipId).assignableUsers
      } else {
        fetchAssignableUsers(dealershipId)
      }
      console.log(storedAssignableUsers.value)
    }

    function fetchAssignableUsers(dealershipId) {
      assignableUsersApiRequest
        .send({
          endpoint: '/v1/users?forAppointmentAssignment=true&userType=externalUsers&dealerships=' + dealershipId,
          method: 'GET'
        })
        .then(response => {
          if (response.data && response.data.length) {
            let users = response.data.filter(user => {
              return (user.active = true)
            })
            currentAssignableUsers.value = users
            storedAssignableUsers.value.push({
              dealershipId: dealershipId,
              assignableUsers: users
            })
          }
        })
    }

    const assignApiRequest = useApiRequest()
    const updateSource = userStore.details.type == 1 ? 'retain' : 'dealer'

    const updateAssignedUser = (event, appointmentId) => {
      let dataToPost = {
        userToAssign: event.value,
        notifyUser: false,
        updatedBy: userStore.details.id,
        updateSource: updateSource
      }

      assignApiRequest
        .send({
          endpoint: '/v1/appointments/' + appointmentId + '/assign',
          method: 'PATCH',
          data: dataToPost
        })
        .then(response => {
          if (response.success) {
            appointments.value.find(a => a.id == appointmentId).assignedTo = event.value
            alertToast('Assigned', null, 'success')
          } else {
            alertToast('Failed to assign', response.data?.message, 'error')
          }
        })
    }

    const appointmentsComputed = computed(() => {
      return appointments.value.map(a => {
        return {
          ...a,
          actionStatus: a.assignedTo ? 'no_outcome' : 'unassigned',
          date: a.time,
          customerName: a.customerName
        }
      })
    })

    return {
      dt,
      appointmentsIsLoading: appointmentsApiRequest.isLoading,
      appointments,
      filters,
      dealershipsSelected,
      actionStatusOptions,
      clearFilters,
      getProperties,
      onDealershipChange,
      currentAssignableUsers,
      fetchAssignableUsers,
      appointmentsComputed,
      updateAssignedUser,
      onDropdownClick,
      dealershipsAllowed
    }
  }
}
</script>

<style></style>
