<template>
  <PageHeader
    :title="$t('ui.common.account')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.common.user', 2), to: '/settings/users' },
      { label: user.basicDetails.id ? user.basicDetails.forename + ' ' + user.basicDetails.surname : 'Unknown User' }
    ]"
  >
    <template #right></template>
  </PageHeader>

  <div class="grid sm:grid-cols-1 md:grid-cols-4 gap-x-8">
    <div>
      <Tabs v-model="currentTab" :tabs="tabs" direction="vertical" type="pills-rail-block" class="mb-8 item" />
    </div>
    <div class="md:col-span-3">
      <div v-if="currentTab == 'basic'">
        <Card>
          <!-- Firstname, last name, email (readonly), pronouns, job title -->
          <CardBody>
            <div class="mb-6 flex">
              <Avatar :url="user.basicDetails.profilePicURL" :text="user.basicDetails.initials" size="xl" />
              <!-- <div>Upload</div> -->
            </div>
            <div class="grid md:grid-cols-2 gap-x-8">
              <FormGroup :label="$t('ui.entities.profile.first-name')" :is-required="v$.basicDetails.forename.required">
                <Input v-model="user.basicDetails.forename" :has-error="v$.basicDetails.forename.$error" />
                <InputError :has-error="v$.basicDetails.forename.$error">{{ v$.basicDetails.forename.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.profile.last-name')" :is-required="v$.basicDetails.surname.required">
                <Input v-model="user.basicDetails.surname" :has-error="v$.basicDetails.surname.$error" />
                <InputError :has-error="v$.basicDetails.surname.$error">{{ v$.basicDetails.surname.$errors[0]?.$message }} </InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.comms.email')" :is-required="v$.basicDetails.email.required">
                <Input v-model="user.basicDetails.email" :has-error="v$.basicDetails.email.$error" :readonly="!$can('update_user_email')" />
                <InputError :has-error="v$.basicDetails.email.$error">{{ v$.basicDetails.email.$errors[0]?.$message }} </InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.common.job-title')" :is-required="v$.basicDetails.jobTitle.required">
                <Input v-model="user.basicDetails.jobTitle" :has-error="v$.basicDetails.jobTitle.$error" />
                <InputError :has-error="v$.basicDetails.jobTitle.$error">{{ v$.basicDetails.jobTitle.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.common.pronouns')" :is-required="v$.basicDetails.pronouns.required">
                <Multiselect
                  v-model="user.basicDetails.pronouns"
                  mode="single"
                  :close-on-select="true"
                  :searchable="false"
                  :hide-selected="false"
                  :options="['he/him', 'she/her', 'they/them']"
                  :can-deselect="false"
                  :class="{ 'has-error': v$.basicDetails.pronouns.$error }"
                >
                </Multiselect>
                <InputError :has-error="v$.basicDetails.pronouns.$error">{{ v$.basicDetails.pronouns.$errors[0]?.$message }}</InputError>
              </FormGroup>
            </div>
            <Button
              label="Save Changes"
              type="primary"
              class="mt-4"
              :is-loading="formState.basicDetailsSubmitting"
              @click="submitBasicForm"
            />
          </CardBody>
        </Card>
      </div>

      <div v-if="currentTab == 'security'">
        <Card>
          <CardBody>
            <CardTitle title="Password Reset" />
            <p>Reset a user's password. They will be sent an email with a link to reset their password.</p>
            <Button
              label="Reset Password"
              type="primary"
              class="mt-6"
              :is-loading="sendResetPasswordIsSending"
              @click="sendResetPasswordEmail"
            />
          </CardBody>
        </Card>
        <!-- <Card>
          <CardBody>
            <CardTitle title="2-Factor Authentication" />
            <p>
              Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log
              in. Learn more.
            </p>
            <Button label="Set Up 2FA" type="primary" class="mt-6" />
          </CardBody>
        </Card> -->
      </div>

      <div v-if="currentTab == 'roles-and-access' && !tabs.find(t => t.id == currentTab)?.isHidden">
        <Card>
          <CardBody>
            <CardTitle title="Level & Roles" />
            <div class="grid md:grid-cols-2 gap-x-8">
              <FormGroup
                v-if="user.basicDetails.type == 2"
                :label="$t('ui.common.level')"
                :is-required="v$.rolesAndAccessDetails.level.required"
              >
                <Multiselect
                  v-model="user.rolesAndAccessDetails.level"
                  mode="single"
                  :close-on-select="true"
                  :searchable="false"
                  :hide-selected="false"
                  :options="levelOptions"
                  :can-deselect="false"
                  :class="{ 'has-error': v$.rolesAndAccessDetails.level.$error }"
                  required
                  @change="user.rolesAndAccessDetails.roles = []"
                >
                </Multiselect>
                <InputError :has-error="v$.rolesAndAccessDetails.level.$error">{{
                  v$.rolesAndAccessDetails.level.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
              <FormGroup
                :label="$t('ui.common.role', 2)"
                info="Roles define what the user is permitted to do<br><br>E.g. The Sales Executive role can't create users, but the Sales Manager role can"
                :is-required="v$.rolesAndAccessDetails.roles.required"
              >
                <Multiselect
                  v-model="user.rolesAndAccessDetails.roles"
                  mode="tags"
                  :close-on-select="true"
                  :searchable="false"
                  :hide-selected="false"
                  track-by="value"
                  label="label"
                  :options="roleOptions"
                  :can-deselect="false"
                  :class="{ 'has-error': v$.rolesAndAccessDetails.roles.$error }"
                >
                </Multiselect>
                <InputError :has-error="v$.rolesAndAccessDetails.roles.$error">{{
                  v$.rolesAndAccessDetails.roles.$errors[0]?.$message
                }}</InputError>
              </FormGroup>
            </div>
            <CardTitle title="Location Access" class="mt-6" />
            <UserAccessSelector v-model="user.rolesAndAccessDetails.access" :is-required="v$.rolesAndAccessDetails.access.required" />
            <Button
              label="Save Changes"
              type="primary"
              class="mt-4"
              :is-loading="formState.rolesAndAccessDetailsSubmitting"
              @click="submitLevelAndRoles"
            />
          </CardBody>
        </Card>
      </div>

      <div v-if="currentTab == 'notifications'">
        <Card>
          <CardBody>
            <CardTitle title="Notifications" />

            <TableBasic bordered class="mt-6">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>
                    <Icon class="text-lg" v-tippy="'App'" type="laptop" />
                  </th>
                  <th>
                    <Icon class="text-lg" v-tippy="'Email'" type="envelope" />
                  </th>
                  <th>
                    <Icon class="text-lg" v-tippy="'SMS'" type="sms" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="cat in user.notifications">
                  <tr>
                    <td colspan="4" class="bg-secondary">
                      <div class="font-bold">{{ cat.category }}</div>
                    </td>
                  </tr>
                  <tr v-for="notification in cat.notifications">
                    <td>
                      <div class="font-medium">{{ notification.name }}</div>
                      <div class="text-sm text-quaternary">{{ notification.description }}</div>
                    </td>
                    <td>
                      <Checkbox
                        v-if="notification.channels.push?.isActive != null"
                        v-model="notification.channels.push.isActive"
                        :readonly="!notification.channels.push.isAllowed"
                      />
                    </td>
                    <td>
                      <Checkbox
                        v-if="notification.channels.email?.isActive != null"
                        v-model="notification.channels.email.isActive"
                        :readonly="!notification.channels.email.isAllowed"
                      />
                    </td>
                    <td>
                      <Checkbox
                        v-if="notification.channels.sms?.isActive != null"
                        v-model="notification.channels.sms.isActive"
                        :readonly="!notification.channels.sms.isAllowed"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </TableBasic>
            <Button
              label="Save Changes"
              type="primary"
              class="mt-6"
              :is-loading="formState.notificationsDetailsSubmitting"
              @click="submitNotificationsForm"
            />
          </CardBody>
        </Card>
      </div>

      <div v-if="currentTab == 'schedule'">
        <Card>
          <CardBody>
            <CardTitle :title="$t('ui.entities.schedule.label')" />
            <Switch v-model="user.schedule.hasShifts" label="Has shifts" />

            <Button
              label="Save Changes"
              type="primary"
              class="mt-6"
              :is-loading="formState.scheduleDetailsSubmitting"
              @click="submitScheduleForm"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, email } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import axios from 'axios'
import { useAbility } from '@casl/vue'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import { alertToast } from '@/utilities/notification'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Button from '@/components/button/Button.vue'
import TableBasic from '@/components/tables/TableBasic.vue'
import Icon from '@/components/icon/Icon.vue'

import UserAccessSelector from '@/components/unique/users/UserAccessSelector.vue'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'

export default {
  components: {
    PageHeader,
    Card,
    CardBody,
    Tabs,
    FormGroup,
    Input,
    Multiselect,
    InputError,
    Avatar,
    Button,
    UserAccessSelector,
    CardTitle,
    TableBasic,
    Icon,
    ToggleSwitch,
    Checkbox,
    ListGroup,
    ListGroupItem
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const { can } = useAbility()

    const userStore = useUserStore()

    const userRequest = useApiRequest()
    const userSubmitFormRequest = useApiRequest()

    const user = ref({
      basicDetails: {
        id: null,
        type: null,
        profilePicURL: null,
        forename: null,
        surname: null,
        initials: null,
        email: null,
        pronouns: null,
        jobTitle: null
      },
      securityDetails: {
        password: null,
        passwordConfirm: null
      },
      notifications: [],
      rolesAndAccessDetails: {
        level: null,
        roles: [],
        access: null
      },
      schedule: {
        hasShifts: null,
        shiftTemplate: null
      }
    })

    userRequest.send({ endpoint: '/v1/users/' + route.params.id, method: 'GET' }).then(response => {
      if (!response.success) {
        console.error(`Endpoint failed, error fetching data: /v1/users/${route.params.id}`)
        return
      }

      user.value.basicDetails = {
        id: response.data[0].id,
        type: response.data[0].type,
        profilePicURL: response.data[0].profilePicURL,
        forename: response.data[0].forename,
        surname: response.data[0].surname,
        initials: response.data[0].initials,
        email: response.data[0].email,
        pronouns: response.data[0].pronouns,
        jobTitle: response.data[0].jobTitle
      }
      user.value.securityDetails = {
        password: null,
        passwordConfirm: null
      }
      user.value.notifications = setNotifications(response.data[0].notifications)
      user.value.rolesAndAccessDetails = {
        level: response.data[0].level,
        roles: response.data[0].roles,
        access: {
          regions: response.data[0].access.regionsAll ? [] : response.data[0].access.regions,
          regionsAll: response.data[0].access.regionsAll,
          manufacturers: response.data[0].access.manufacturersAll ? [] : response.data[0].access.manufacturers,
          manufacturersAll: response.data[0].access.manufacturersAll,
          groups: response.data[0].access.groupsAll ? [] : response.data[0].access.groups,
          groupsAll: response.data[0].access.groupsAll,
          dealerships: response.data[0].access.dealershipsAll ? [] : response.data[0].access.dealerships,
          dealershipsAll: response.data[0].access.dealershipsAll
        }
      }
      user.value.schedule = {
        hasShifts: response.data[0].hasShifts,
        shiftTemplate: response.data[0].shiftTemplate
      }
    })

    const casl = useApiRequest()

    casl.send({ endpoint: '/v1/casl' })

    const currentTab = ref(route.params.tab)
    const tabs = ref([
      {
        id: 'basic',
        label: t('ui.common.basic-info'),
        icon: 'user',
        to: '/settings/users/' + route.params.id
      },
      {
        id: 'security',
        label: t('ui.common.security'),
        icon: 'key',
        to: '/settings/users/' + route.params.id + '/security'
      },
      {
        id: 'notifications',
        label: t('ui.entities.notifications.label', 2),
        icon: 'bell',
        to: '/settings/users/' + route.params.id + '/notifications'
      },
      {
        id: 'roles-and-access',
        label: t('ui.common.roles-and-access'),
        icon: 'shield',
        to: '/settings/users/' + route.params.id + '/roles-and-access',
        isHidden: computed(() => {
          if (can('update_own_access')) {
            return false
          } else {
            return userStore.details.id == user.value.basicDetails.id
          }
        })
      },
      {
        id: 'schedule',
        label: t('ui.entities.schedule.label'),
        icon: 'calendar-days',
        to: '/settings/users/' + route.params.id + '/schedule',
        isHidden: computed(() => {
          if (user.value.basicDetails.type != 1) {
            return true
          } else {
            return false
          }
        })
      }
    ])

    // Set tab to 'Basic' if no tab is set
    if (route.params.tab === '') {
      currentTab.value = 'basic'
    } else {
      currentTab.value = route.params.tab
    }

    // If the user changes the tab, update the route
    watch(
      () => currentTab.value,
      newTab => {
        currentTab.value = newTab
        //fetch tab route
        let tab = tabs.value.find(tab => tab.id === newTab)
        if (tab) {
          // console.log('Tab Change: ', tab)
          router.replace(tab.to)
        }
      }
    )

    const rolesList = computed(() => {
      let rolesArray = casl.data.value?.roles
      let sessionUserLevel = userStore.details.level

      if (sessionUserLevel == null || sessionUserLevel == '') {
        // FIX - In API, make sure to always return null if empty string
        //User is of type: internal, so allow all roles to be in the list
        return rolesArray
      }

      // remove roles that the user doesn't have access to
      // levels: region, manufacturer, group, dealership. allow access to all roles that are equal or lower than the user's level
      rolesArray = rolesArray?.filter(role => role.level <= sessionUserLevel)
      return rolesArray
    })

    const inputRules = {
      basicDetails: {
        forename: {
          required
        },
        surname: {
          required
        },
        email: {
          required,
          email
        },
        pronouns: { required },
        jobTitle: { required }
      },
      securityDetails: {
        password: {
          required
        },
        passwordConfirm: {
          required
        }
      },
      rolesAndAccessDetails: {
        level: { required: requiredIf(() => user.value.basicDetails.type == 2) },
        roles: { required },
        access: { required }
      }
    }

    const v$ = useVuelidate(inputRules, user)

    const roleOptions = computed(() => {
      let options = []
      rolesList.value?.forEach(role => {
        options.push({
          value: role.id,
          label: role.name,
          level: role.level,
          userType: role.userType,
          color: role.color
        })
      })

      if (user.value.basicDetails.type == 1) {
        options = options.filter(role => role.userType == 1)
      } else {
        // Filter out any roles and type that do not equal level && type
        options = options.filter(
          role => role.level == user.value.rolesAndAccessDetails.level && role.userType == user.value.basicDetails.type
        )
      }

      // If the logged in user does not have role 1 (admin), remove it from the list of options
      if (!userStore.roles.includes(1)) {
        options = options.filter(role => role.value != 1)
      }

      return options
    })

    const levelOptions = computed(() => {
      let allOptions = [
        {
          value: 'region',
          label: 'Global',
          weight: 1
        },
        {
          value: 'manufacturer',
          label: 'Manufacturer',
          weight: 2
        },
        {
          value: 'group',
          label: 'Group',
          weight: 3
        },
        {
          value: 'dealership',
          label: 'Dealership',
          weight: 4
        }
      ]

      if (userStore.details.type == 1) {
        return allOptions
      }

      let sessionUserLevelWeight = allOptions.find(option => option.value == userStore.details.level)?.weight

      return allOptions.filter(option => option.weight >= sessionUserLevelWeight)
    })

    // update state with structured notifications
    function setNotifications(notifications) {
      let notificationCategories = [...new Set(notifications.map(notification => notification.category))]

      let computedNotifications = []

      notificationCategories.forEach(category => {
        let categoryNotifications = notifications.filter(notification => notification.category == category)
        computedNotifications.push({
          category: category,
          notifications: categoryNotifications
        })
      })

      return computedNotifications
    }

    const formState = ref({
      basicDetailsSubmitting: false,
      securityDetailsSubmitting: false,
      notificationsDetailsSubmitting: false,
      rolesAndAccessDetailsSubmitting: false,
      scheduleDetailsSubmitting: false
    })

    async function submitBasicForm() {
      const isFormCorrect = await v$.value.basicDetails.$validate()
      // console.log('isFormCorrect: ', isFormCorrect)

      if (isFormCorrect) {
        formState.value.basicDetailsSubmitting = true

        userSubmitFormRequest
          .send({
            endpoint: '/v1/users/' + route.params.id,
            method: 'PATCH',
            data: {
              forename: user.value.basicDetails.forename,
              surname: user.value.basicDetails.surname,
              email: user.value.basicDetails.email,
              pronouns: user.value.basicDetails.pronouns,
              jobTitle: user.value.basicDetails.jobTitle
            }
          })
          .then(response => {
            if (response.success) {
              formState.value.basicDetailsSubmitting = false

              //Notification
              alertToast('Updated', null, 'success')
            }
          })
      }
    }

    async function submitNotificationsForm() {
      formState.value.notificationsDetailsSubmitting = true

      // sort data into correct format for PATCH request
      //   "notifications": [
      //     {
      //         "id": 1,
      //         "channels": {
      //             "email": false
      //         }
      //     }
      // ]
      let notifications = []

      user.value.notifications.forEach(category => {
        category.notifications.forEach(notification => {
          notifications.push({
            id: notification.notificationId,
            channels: {
              push: notification.channels.push?.isActive,
              email: notification.channels.email?.isActive,
              smm: notification.channels.sms?.isActive
            }
          })
        })
      })

      // console.log(notifications)

      userSubmitFormRequest
        .send({
          endpoint: '/v1/users/' + route.params.id + '/notifications/settings',
          method: 'PATCH',
          data: {
            notifications: notifications
          }
        })
        .then(response => {
          if (response.success) {
            formState.value.notificationsDetailsSubmitting = false

            //Notification
            alertToast('Updated', null, 'success')
          }
        })
    }

    async function submitLevelAndRoles() {
      const isFormCorrect = await v$.value.rolesAndAccessDetails.$validate()
      // console.log('isFormCorrect: ', isFormCorrect)

      if (isFormCorrect) {
        formState.value.rolesAndAccessDetailsSubmitting = true

        // Submit level and roles to user PATCH endpoint
        userSubmitFormRequest
          .send({
            endpoint: '/v1/users/' + route.params.id,
            method: 'PATCH',
            data: {
              roleIds: user.value.rolesAndAccessDetails.roles
            }
          })
          .then(response => {
            if (response.success) {
              // If successful, format and submit access to user/access PATCH endpoint
              let userAccess = user.value.rolesAndAccessDetails.access
              let newAccessData = {
                level: user.value.rolesAndAccessDetails.level,
                regions: userAccess.regionsAll ? '*' : userAccess.regions.length === 0 ? null : userAccess.regions,
                manufacturers: userAccess.manufacturersAll ? '*' : userAccess.manufacturers.length === 0 ? null : userAccess.manufacturers,
                groups: userAccess.groupsAll ? '*' : userAccess.groups.length === 0 ? null : userAccess.groups,
                dealerships: userAccess.dealershipsAll ? '*' : userAccess.dealerships.length === 0 ? null : userAccess.dealerships
              }

              // console.log(newAccessData)

              userSubmitFormRequest
                .send({
                  endpoint: '/v1/users/' + route.params.id + '/access',
                  method: 'PUT',
                  data: newAccessData
                })
                .then(response => {
                  if (response.success) {
                    formState.value.rolesAndAccessDetailsSubmitting = false

                    //Notification
                    alertToast('Updated', null, 'success')
                  }
                })
            }
          })
      }
    }

    async function submitScheduleForm() {
      formState.value.scheduleDetailsSubmitting = true

      userSubmitFormRequest
        .send({
          endpoint: '/v1/users/' + route.params.id,
          method: 'PATCH',
          data: {
            hasShifts: user.value.schedule.hasShifts
          }
        })
        .then(response => {
          if (response.success) {
            formState.value.scheduleDetailsSubmitting = false

            alertToast('Updated', null, 'success')
          }
        })
    }

    const sendResetPasswordIsSending = ref(false)

    // REFACTOR: Convert to use UseApiRequest()
    async function sendResetPasswordEmail() {
      sendResetPasswordIsSending.value = true
      await axios
        .post(
          import.meta.env.VITE_API_BASE_URL + '/v1/auth/password/forgot',
          { email: user.value.basicDetails.email },
          {
            HTTP_X_API_ACCESS_TOKEN:
              'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
          }
        )
        .then(response => {
          if (response.data.response.status == 'success') {
            alertToast('Email sent', null, 'success')
          } else {
            alertToast('Failed to reset password', response.message, 'error')
          }
        })
        .catch(err => {
          alertToast('Failed to reset password', err.message, 'error')
        })
        .finally(() => {
          sendResetPasswordIsSending.value = false
        })
    }

    return {
      userStore,
      user,
      casl,
      roleOptions,
      levelOptions,
      tabs,
      currentTab,
      v$,
      submitBasicForm,
      submitNotificationsForm,
      submitLevelAndRoles,
      submitScheduleForm,
      formState,
      sendResetPasswordEmail,
      sendResetPasswordIsSending,
      rolesList
    }
  }
}
</script>

<style></style>
