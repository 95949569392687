<!-- TODO: Move this table to the view, instead of it being its own component  -->
<!-- TODO: convert to: script setup -->
<template>
  <DataTable
    ref="dt"
    v-model:selection="selectedUsers"
    v-model:filters="filters"
    export-filename="Users"
    :value="modelValue"
    :loading="isLoading"
    removable-sort
    sort-field="id"
    :sort-order="-1"
    :rows="20"
    data-key="id"
    column-resize-mode="fit"
    :paginator="true"
    filter-display="menu"
    :rows-per-page-options="[10, 20, 50]"
    state-key="dt-settings-users"
    state-storage="session"
    responsive-layout="scroll"
    scrollable
    current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    :global-filter-fields="['id', 'fullName', 'jobTitle', 'email', 'rolesNamed.name']"
  >
    <template #empty> No users found </template>
    <template #header>
      <div class="flex justify-between">
        <span class="flex justify-between gap-4">
          <span>
            <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
          </span>
          <Button
            :label="$t('ui.actions.clear', 1)"
            severity="secondary"
            type="button"
            outlined
            icon="filter-slash"
            @click="clearFilter()"
          />
        </span>
      </div>

      <div class="flex justify-between mt-4">
        <span>
          <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
        </span>
      </div>
    </template>

    <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->
    <Column field="id" header="Id" :sortable="true">
      <template #body="{ data }">
        <router-link class="link" :to="'/settings/users/' + data.id">{{ data.id }}</router-link>
      </template>
    </Column>
    <Column field="fullName" header="User" :sortable="true">
      <template #body="{ data }">
        <AvatarLabelGroup
          :label="data.fullName"
          :description="data.jobTitle"
          :avatar-url="data.profilePicURL"
          :avatar-text="data.initials"
          :label-link="'/settings/users/' + data.id"
          class="whitespace-nowrap"
        />
      </template>
    </Column>
    <Column field="jobTitle" header="Job Title" :sortable="true" hidden>
      <template #body="{ data }">
        {{ data.jobTitle }}
      </template>
    </Column>
    <Column field="userType" header="Type" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
      <template #body="{ data }">
        <Chip :severity="data.userType === 1 ? 'brand' : 'primary'" :rounded="false" size="sm">{{ data.userTypeNamed }}</Chip>
      </template>
      <template #filter="{ filterModel }">
        <MultiSelect
          v-model="filterModel.value"
          :options="[
            { value: 1, label: 'Internal' },
            { value: 2, label: 'External' }
          ]"
          filter
          display="chip"
          option-value="value"
          option-label="label"
          placeholder="All"
        >
          <template #option="slotProps">
            {{ slotProps.option.label }}
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="rolesNamed" header="Roles" :sortable="false" :show-filter-match-modes="false" :show-filter-operator="false">
      <template #body="{ data }">
        <Chip v-for="role in data.rolesNamed" :key="role.id" :color="role.color" size="sm" class="mr-1 my-[0.1rem]">{{ role.name }}</Chip>
      </template>
      <template #filter="{ filterModel }">
        <MultiSelect
          v-model="filterModel.value"
          :options="rolesList"
          filter
          display="chip"
          option-value="id"
          option-label="name"
          placeholder="All"
        >
          <template #option="slotProps">
            {{ slotProps.option.name }}
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="email" header="Email" :sortable="true">
      <template #body="{ data }">
        <div class="flex gap-2">
          <Icon v-if="data.accountVerified" type="circle-check" class="text-success" />
          <Icon v-else type="circle-xmark" class="text-danger" />
          {{ data.email }}
        </div>
      </template>
    </Column>
    <Column
      field="accountVerified"
      header="Verified"
      :sortable="false"
      hidden
      :show-filter-match-modes="false"
      :show-filter-operator="false"
    >
      <template #body="{ data }">
        <Icon v-if="data.accountVerified" type="circle-check" class="text-success" />
        <Icon v-else type="circle-xmark" class="text-danger" />
        <!-- <Button v-else label="Send Verification" size="sm" outlined /> -->
      </template>
      <template #filter="{ filterModel }">
        <MultiSelect
          v-model="filterModel.value"
          :options="[
            { value: true, label: 'Verified' },
            { value: false, label: 'Unverified' }
          ]"
          filter
          display="chip"
          option-value="value"
          option-label="label"
          placeholder="All"
        >
          <template #option="slotProps">
            {{ slotProps.option.label }}
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="lastActive" header="Last Active" :sortable="true">
      <template #body="{ data }">
        <div v-tippy="$dayjs(data.lastActive).format('LLL')" class="whitespace-nowrap">
          {{ data.lastActive ? $dayjs(data.lastActive).from($dayjs()) : 'Never' }}
        </div>
      </template>
    </Column>
    <Column field="active" header="Status" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
      <template #body="{ data }">
        <Chip v-if="data.active" :rounded="false" outlined size="sm" dot severity="success">Active</Chip>
        <Chip v-else :rounded="false" outlined size="sm" dot severity="danger">Disabled</Chip>
      </template>
      <template #filter="{ filterModel }">
        <MultiSelect
          v-model="filterModel.value"
          :options="[
            { value: true, label: 'Active' },
            { value: false, label: 'Disabled' }
          ]"
          filter
          display="chip"
          option-value="value"
          option-label="label"
          placeholder="All"
        >
          <template #option="slotProps">
            {{ slotProps.option.label }}
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column header="" :sortable="false">
      <template #body="slotProps">
        <div class="flex">
          <!-- <Button v-tippy="'Roles and Permissions'" icon="shield-halved" plain severity="secondary"></Button> -->
          <Button
            v-tippy="$t('ui.common.details')"
            icon="cog"
            plain
            severity="secondary"
            @click="$router.push('/settings/users/' + slotProps.data.id)"
          ></Button>
          <!-- <Button
          v-tippy="'Access'"
          icon="building-shield"
          plain
          severity="secondary"
          @click="openAccessModal(slotProps.data)"
        ></Button> -->
          <Dropdown>
            <template #triggerContent>
              <Button icon="ellipsis-vertical" plain severity="secondary"></Button>
            </template>
            <!-- <DropdownItem @item-clicked="$router.push('/settings/users/' + slotProps.data.id)">
            <Icon type="cog" />
            <span class="w-full">{{ $t('ui.common.details') }}</span>
          </DropdownItem> -->
            <DropdownItem @item-clicked="openAccessModal(slotProps.data)">
              <Icon type="building-shield" />
              <span class="w-full">{{ $t('ui.common.access') }}</span>
            </DropdownItem>
            <!-- <DropdownItem disabled @item-clicked="$router.push('/user/' + slotProps.data.id)">
              <Icon type="user-circle" />
              <span class="w-full">{{ $t('ui.entities.profile.label') }}</span>
            </DropdownItem> -->
            <DropdownSeparator />
            <DropdownItem @item-clicked="resetUserStatus(slotProps.data.id)">
              <Icon type="rotate-right" />
              <span class="w-full">{{ $t('ui.actions.reset', { item: $t('ui.common.status') }) }}</span>
            </DropdownItem>
            <DropdownItem
              v-if="slotProps.data.active"
              class="text-error"
              @item-clicked="toggleActiveState(slotProps.data.id, 'deactivate')"
            >
              <Icon type="ban" />
              <span class="w-full">{{ $t('ui.actions.deactivate') }}</span>
            </DropdownItem>
            <DropdownItem v-else class="text-success" @item-clicked="toggleActiveState(slotProps.data.id, 'reactivate')">
              <Icon type="circle-check" />
              <span class="w-full">{{ $t('ui.actions.reactivate') }}</span>
            </DropdownItem>
          </Dropdown>
        </div>
      </template>
    </Column>
  </DataTable>

  <teleport to="#modals-container">
    <Modal
      :is-open="accessModalIsOpen"
      title="Access"
      description="Update user access permissions"
      icon="building-shield"
      :buttons="['ok', 'close']"
      close-text="Cancel"
      ok-text="Save"
      :show-loader-on-confirm="true"
      :is-confirming="false"
      min-width="600"
      @close-modal="accessModalIsOpen = false"
      @ok-modal="submitAccessModal"
    >
      <UserAccessSelector v-model="userBeingActioned.access" />
    </Modal>
  </teleport>
</template>

<script>
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Chip from '@/components/chip/Chip.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import Modal from '@/components/modal/Modal.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'
import MultiSelect from 'primevue/multiselect'

import UserAccessSelector from '@/components/unique/users/UserAccessSelector.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode, FilterService } from 'primevue/api'

import { ref, onMounted } from 'vue'
import { alertToast } from '@/utilities/notification'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import socket from '@/services/socket.service.js'
import DropdownSeparator from '@/components/dropdown/DropdownSeparator.vue'

export default {
  components: {
    Input,
    Button,
    AvatarLabelGroup,
    Icon,
    DataTable,
    Column,
    Chip,
    Dropdown,
    DropdownItem,
    Modal,
    UserAccessSelector,
    DropdownSeparator,
    MultiSelect
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['user-updated'],
  setup(props, { emit }) {
    const userStore = useUserStore()

    const dt = ref({ processedData: [] })

    const accessModalIsOpen = ref(false)

    const selectedUsers = ref([])
    const onRowSelect = event => {
      console.log('Selected ' + event.data.name)
    }

    const caslApiRequest = useApiRequest()
    const rolesList = ref(null)

    const onRowUnselect = event => {
      console.log('Unselected ' + event.data.name)
    }

    onMounted(() => {
      caslApiRequest.send({ endpoint: '/v1/casl' }).then(response => {
        console.log(response)
        if (response.http.status == 200) {
          rolesList.value = response.data.roles
        }
        console.log(rolesList.value)
      })

      FilterService.register(rolesFilter.value, (value, filter) => {
        if (!filter.length) {
          return true
        }

        return value.some(role => filter.includes(role.id))
      })
    })

    const rolesFilter = ref('Roles Filter')

    const filters = ref(null)

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userTypeNamed: { value: null, matchMode: FilterMatchMode.IN },
        rolesNamed: { value: null, matchMode: rolesFilter.value },
        accountVerified: { value: null, matchMode: FilterMatchMode.IN },
        active: { value: null, matchMode: FilterMatchMode.IN },
        userType: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    initFilters()

    const clearFilter = () => {
      initFilters()
    }

    const userBeingActioned = ref({
      id: null,
      access: {
        regions: [],
        regionsAll: false,
        manufacturers: [],
        manufacturersAll: false,
        groups: [],
        groupsAll: false,
        dealerships: [],
        dealershipsAll: false
      }
    })

    function openAccessModal(userObj) {
      userBeingActioned.value = userObj
      accessModalIsOpen.value = true
    }

    const updateUserAccess = useApiRequest()
    const updateUserActiveState = useApiRequest()

    function submitAccessModal() {
      let userAccess = userBeingActioned.value.access
      let newAccessData = {
        level: userBeingActioned.value.userLevel,
        regions: userAccess.regionsAll ? '*' : userAccess.regions.length === 0 ? null : userAccess.regions,
        manufacturers: userAccess.manufacturersAll ? '*' : userAccess.manufacturers.length === 0 ? null : userAccess.manufacturers,
        groups: userAccess.groupsAll ? '*' : userAccess.groups.length === 0 ? null : userAccess.groups,
        dealerships: userAccess.dealershipsAll ? '*' : userAccess.dealerships.length === 0 ? null : userAccess.dealerships
      }

      updateUserAccess
        .send({
          endpoint: '/v1/users/' + userBeingActioned.value.id + '/access',
          method: 'PUT',
          data: newAccessData
        })
        .then(response => {
          // console.log(response.data.response)
          if (response.success) {
            alertToast('Access Updated', null, 'success')
            userBeingActioned.value = null
            accessModalIsOpen.value = false
          }
        })
    }

    function toggleActiveState(userId, action) {
      updateUserActiveState
        .send({
          endpoint: '/v1/users/' + userId + '/' + action,
          method: 'PATCH'
        })
        .then(response => {
          if (response.success) {
            // Emit event to parent to update user status
            emit('user-updated', userId, { active: response.data.active })

            //Notification
            alertToast('Updated', null, 'success')
          } else {
            alertToast('Failed to update user', response.data.response.message, 'error')
          }
        })
    }

    function resetUserStatus(userId) {
      socket.emit('reset-specific-users-status', userId)
    }

    // BUG: Handle export of roles, type and status to a human readable format
    const exportCSV = () => {
      dt.value.exportCSV()
    }

    return {
      dt,
      filters,
      clearFilter,
      selectedUsers,
      onRowSelect,
      onRowUnselect,
      accessModalIsOpen,
      userBeingActioned,
      openAccessModal,
      submitAccessModal,
      userStore,
      resetUserStatus,
      toggleActiveState,
      rolesList,
      exportCSV
    }
  }
}
</script>
