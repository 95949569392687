<template>
  <PageHeader title="Customers">
    <template #right>
      <Button
        v-if="$can('create_customer')"
        :label="$t('ui.crud.new', { item: $t('ui.entities.customer') })"
        icon="plus"
        @click="newCustomerModalIsOpen = true"
      />
    </template>
  </PageHeader>
  <div class="grid lg:grid-cols-1 gap-8">
    <Card>
      <CardBody>
        <Alert title="List of customers coming soon!" severity="info" :show-close-button="false" v-bind:show-dismiss-button="false"></Alert>
      </CardBody>
    </Card>
  </div>

  <teleport to="#modals-container">
    <Modal
      :is-open="newCustomerModalIsOpen"
      :buttons="[]"
      :has-unsaved-changes="newCustomerHasUnsavedChanges"
      overflow-behaviour="visible"
      @close-modal="(newCustomerModalIsOpen = false), (newCustomerHasUnsavedChanges = false)"
    >
      <NewCustomer @form-updated="newCustomerHasUnsavedChanges = true" />
    </Modal>
  </teleport>
</template>

<script>
import { ref } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import Alert from '@/components/alert/Alert.vue'
import Button from '@/components/button/Button.vue'
import Modal from '@/components/modal/Modal.vue'
import 'flatpickr/dist/flatpickr.css'

import NewCustomer from '@/components/unique/forms/NewCustomer.vue'

export default {
  components: { PageHeader, Card, CardBody, Alert, Button, NewCustomer, Modal },
  setup() {
    const newCustomerModalIsOpen = ref(false)
    const newCustomerHasUnsavedChanges = ref(false)

    return { newCustomerModalIsOpen, newCustomerHasUnsavedChanges }
  }
}
</script>

<style></style>
