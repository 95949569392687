<template>
  <PageHeader
    :title="$t('ui.actions.edit')"
    breadcrumb
    :items="[{ label: customerName, to: '/agreement/' + route.params.id }, { label: $t('ui.actions.edit') }]"
  >
  </PageHeader>

  <div v-if="agreementApiRequest.isLoading.value || state == null">
    <div class="grid sm:grid-cols-1 md:grid-cols-4 gap-x-8">
      <Skeleton width="100%" height="14rem" />
      <div class="md:col-span-3">
        <Skeleton width="100%" height="80vh" />
      </div>
    </div>
  </div>

  <div v-else class="grid sm:grid-cols-1 md:grid-cols-8 gap-x-8">
    <div class="md:col-span-2">
      <Tabs v-model="currentTab" :tabs="tabs" direction="vertical" type="pills-rail-block" class="mb-8 item" />
    </div>
    <div v-if="currentTab == 'basic'" class="md:col-span-6">
      <Card>
        <CardBody class="max-w-[600px]">
          <Form>
            <div class="grid md:grid-cols-3 md:gap-x-4">
              <FormGroup :label="$t('ui.entities.profile.company-name')" class="md:col-span-3">
                <Input
                  v-model="state.basic.companyName"
                  :readonly="!$can('edit_agreement_details')"
                  :has-error="v$.basic.companyName.$error"
                />
                <InputError :has-error="v$.basic.companyName.$error">{{ v$.basic.companyName.$errors[0]?.$message }}</InputError>
              </FormGroup>

              <FormGroup :label="$t('ui.common.title')" class="w-full">
                <Multiselect
                  v-model="state.basic.title"
                  mode="single"
                  :can-clear="true"
                  :options="titleOptions"
                  :searchable="true"
                  :create-option="true"
                  class="w-full"
                  :disabled="!$can('edit_agreement_details')"
                >
                </Multiselect>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.profile.first-name')" class="w-full">
                <Input v-model="state.basic.forename" :readonly="!$can('edit_agreement_details')" :has-error="v$.basic.forename.$error" />
                <InputError :has-error="v$.basic.forename.$error">{{ v$.basic.forename.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.profile.last-name')" class="w-full">
                <Input v-model="state.basic.surname" :readonly="!$can('edit_agreement_details')" :has-error="v$.basic.surname.$error" />
                <InputError :has-error="v$.basic.forename.$error">{{ v$.basic.surname.$errors[0]?.$message }}</InputError>
              </FormGroup>

              <FormGroup :label="$t('ui.entities.profile.date-of-birth')">
                <DatePicker
                  v-model="state.basic.dateOfBirth"
                  :config="datePickerConfig"
                  :readonly="!$can('edit_agreement_details')"
                  :has-error="v$.basic.dateOfBirth.$error"
                />
                <InputError :has-error="v$.basic.dateOfBirth.$error">{{ v$.basic.dateOfBirth.$errors[0]?.$message }}</InputError>
              </FormGroup>
            </div>
            <Button
              :disabled="!$can('edit_agreement_details')"
              label="Save Changes"
              class="mt-4"
              :is-loading="submitApiRequest.isLoading.value"
              @click="submitCustomerDetails('basic')"
            />
          </Form>
        </CardBody>
      </Card>
    </div>

    <div v-if="currentTab == 'address'" class="md:col-span-6">
      <Card>
        <CardBody>
          <Form>
            <div class="max-w-[600px] grid md:grid-cols-2 md:gap-x-4">
              <FormGroup :label="$t('ui.entities.address.line-1')" class="md:col-span-2">
                <Input v-model="state.address.line1" :readonly="!$can('edit_agreement_details')" :has-error="v$.address.line1.$error" />
                <InputError :has-error="v$.address.line1.$error">{{ v$.address.line1.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.address.line-2')" class="md:col-span-2">
                <Input v-model="state.address.line2" :readonly="!$can('edit_agreement_details')" :has-error="v$.address.line2.$error" />
                <InputError :has-error="v$.address.line2.$error">{{ v$.address.line2.$errors[0]?.$message }}</InputError>
              </FormGroup>

              <FormGroup class="w-full" :label="$t('ui.entities.address.city')">
                <Input v-model="state.address.town" :readonly="!$can('edit_agreement_details')" :has-error="v$.address.town.$error" />
                <InputError :has-error="v$.address.town.$error">{{ v$.address.town.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup class="w-full" :label="$t('ui.entities.address.county')">
                <Input v-model="state.address.county" :readonly="!$can('edit_agreement_details')" :has-error="v$.address.county.$error" />
                <InputError :has-error="v$.address.county.$error">{{ v$.address.county.$errors[0]?.$message }}</InputError>
              </FormGroup>

              <FormGroup :label="$t('ui.entities.address.post-code')" class="w-full">
                <Input
                  v-model="state.address.postcode"
                  :readonly="!$can('edit_agreement_details')"
                  :has-error="v$.address.postcode.$error"
                />
                <InputError :has-error="v$.address.postcode.$error">{{ v$.address.postcode.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.address.country')" class="w-full">
                <Multiselect
                  v-model="state.address.country"
                  mode="single"
                  :close-on-select="true"
                  :searchable="true"
                  :hide-selected="false"
                  :options="countriesList"
                  :can-deselect="false"
                  :is-object="true"
                  track-by="name"
                  label="name"
                  value-prop="code"
                  :disabled="!$can('edit_agreement_details')"
                  :class="{ 'has-error': v$.address.country.$error }"
                >
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img :src="value.image" width="25" class="mr-3" />
                      {{ value.name }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    <img :src="option.image" width="25" class="mr-3" />
                    {{ option.name }}</template
                  >
                </Multiselect>
                <InputError :has-error="v$.address.country.$error">{{ v$.address.country.$errors[0]?.$message }}</InputError>
              </FormGroup>
            </div>

            <Button
              :disabled="!$can('edit_agreement_details')"
              label="Save Changes"
              class="mt-4"
              :is-loading="submitApiRequest.isLoading.value"
              @click="submitCustomerDetails('address')"
            />
          </Form>
        </CardBody>
      </Card>
    </div>

    <div v-if="currentTab == 'contact-methods'" class="md:col-span-6">
      <Card>
        <CardBody>
          <Form>
            <div class="grid md:grid-cols-2 gap-4">
              <div class="max-w-[400px]">
                <CardTitle :title="$t('ui.entities.comms.phone-number', 2)" />
                <FormGroup label="Mobile">
                  <InputPhone v-model="state.contactMethods.mobile" :readonly="!$can('edit_customer_contact_methods')" />
                </FormGroup>
                <FormGroup label="Telephone 1">
                  <InputPhone v-model="state.contactMethods.telephone1" :readonly="!$can('edit_customer_contact_methods')" />
                </FormGroup>
                <FormGroup label="Telephone 2">
                  <InputPhone v-model="state.contactMethods.telephone2" :readonly="!$can('edit_customer_contact_methods')" />
                </FormGroup>
              </div>

              <div class="max-w-[400px]">
                <CardTitle :title="$t('ui.entities.comms.email-address')" />
                <FormGroup>
                  <Input
                    v-model="state.contactMethods.email"
                    icon="at"
                    :readonly="!$can('edit_customer_contact_methods')"
                    :has-error="v$.contactMethods.email.$error"
                  />
                  <InputError :has-error="v$.contactMethods.email.$error">{{ v$.contactMethods.email.$errors[0]?.$message }}</InputError>
                </FormGroup>
              </div>
            </div>

            <Button
              :disabled="!$can('edit_customer_contact_methods')"
              label="Save Changes"
              class="mt-4"
              :is-loading="submitApiRequest.isLoading.value"
              @click="submitCustomerDetails('contact-methods')"
            />
          </Form>
        </CardBody>
      </Card>
      <Card class="md:max-w-[350px]">
        <CardBody>
          <CardTitle title="Imported Contact Methods" class="text-secondary" />
          <FormGroup label="Mobile" class="text-secondary">
            <span>{{ state.contactMethods.importedContactMethods?.mobile ?? '-' }}</span>
          </FormGroup>
          <FormGroup label="Tel 1" class="text-secondary">
            <span>{{ state.contactMethods.importedContactMethods?.telephone1 ?? '-' }}</span>
          </FormGroup>
          <FormGroup label="Tel 2" class="text-secondary">
            <span>{{ state.contactMethods.importedContactMethods?.telephone2 ?? '-' }}</span>
          </FormGroup>
          <FormGroup label="Email" class="text-secondary">
            <span>{{ state.contactMethods.importedContactMethods?.email ?? '-' }}</span>
          </FormGroup>
        </CardBody>
      </Card>
    </div>

    <div v-if="currentTab == 'vehicle'" class="col-span-6">
      <Card>
        <CardBody>
          <Form>
            <div class="grid md:grid-cols-3 md:gap-x-4 max-w-[600px]">
              <FormGroup :label="$t('ui.entities.vehicle.vrm')">
                <Input v-model="state.vehicle.vrm" :readonly="!$can('edit_agreement_details')" :has-error="v$.vehicle.vrm.$error" />
                <InputError :has-error="v$.vehicle.vrm.$error">{{ v$.vehicle.vrm.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.vehicle.vin')" class="md:col-span-2">
                <Input v-model="state.vehicle.vin" :readonly="!$can('edit_agreement_details')" :has-error="v$.vehicle.vin.$error" />
                <InputError :has-error="v$.vehicle.vin.$error">{{ v$.vehicle.vin.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.vehicle.make')">
                <Multiselect
                  v-model="state.vehicle.make"
                  mode="single"
                  :close-on-select="true"
                  :searchable="true"
                  :hide-selected="false"
                  :options="manufacturers"
                  :can-deselect="false"
                  :is-object="true"
                  track-by="name"
                  label="name"
                  value-prop="name"
                  :disabled="!$can('edit_agreement_details')"
                  :class="{ 'has-error': v$.vehicle.make.$error }"
                >
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <img :src="value.squareLogoUrl" style="width: 20px; max-height: 20px" class="mr-3" />
                      {{ value.name }}
                    </div>
                  </template>
                  <template #option="{ option }">
                    <img :src="option.squareLogoUrl" style="width: 20px; max-height: 20px" class="mr-3" />
                    {{ option.name }}</template
                  >
                </Multiselect>
                <InputError :has-error="v$.vehicle.make.$error">{{ v$.vehicle.make.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.vehicle.model')">
                <Input v-model="state.vehicle.model" :readonly="!$can('edit_agreement_details')" :has-error="v$.vehicle.model.$error" />
                <InputError :has-error="v$.vehicle.model.$error">{{ v$.vehicle.model.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.vehicle.derivative')">
                <Input
                  v-model="state.vehicle.derivative"
                  :readonly="!$can('edit_agreement_details')"
                  :has-error="v$.vehicle.derivative.$error"
                />
                <InputError :has-error="v$.vehicle.derivative.$error">{{ v$.vehicle.derivative.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.vehicle.new-used')" :disabled="true">
                <RadioTabbed
                  v-model="state.vehicle.newUsed"
                  :options="[
                    { label: 'New', value: 'New' },
                    { label: 'Used', value: 'Used' }
                  ]"
                  :readonly="!$can('edit_agreement_details')"
                  :has-error="v$.vehicle.newUsed.$error"
                />
                <InputError :has-error="v$.vehicle.newUsed.$error">{{ v$.vehicle.newUsed.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.vehicle.reg-date')">
                <DatePicker
                  v-model="state.vehicle.regDate"
                  :readonly="!$can('edit_agreement_details')"
                  :config="datePickerConfig"
                  :has-error="v$.vehicle.regDate.$error"
                />
                <InputError :has-error="v$.vehicle.regDate.$error">{{ v$.vehicle.regDate.$errors[0]?.$message }}</InputError>
              </FormGroup>
            </div>
            <Button
              :disabled="!$can('edit_agreement_details')"
              label="Save Changes"
              class="mt-4"
              :is-loading="submitApiRequest.isLoading.value"
              @click="submitAgreementDetails('vehicle')"
            />
          </Form>
        </CardBody>
      </Card>
    </div>

    <div v-if="currentTab == 'agreement'" class="col-span-6">
      <Card>
        <CardBody>
          <Form>
            <div class="grid md:grid-cols-3 md:gap-x-4 max-w-[600px]">
              <FormGroup :label="$t('ui.entities.finance-agreement.finance-product')">
                <Dropdown
                  v-model="state.agreement.productType"
                  filter
                  :options="['PCP', 'HP', 'CH', 'BCH']"
                  class="w-full"
                  :disabled="!$can('edit_agreement_details')"
                >
                </Dropdown>
              </FormGroup>

              <FormGroup :label="$t('ui.entities.finance-agreement.finance-product-name')" class="md:col-span-2">
                <Input
                  v-model="state.agreement.productName"
                  :readonly="!$can('edit_agreement_details')"
                  :has-error="v$.agreement.productName.$error"
                />
                <InputError :has-error="v$.agreement.productName.$error">{{ v$.agreement.productName.$errors[0]?.$message }}</InputError>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.term')">
                <InputGroup>
                  <Input v-model="state.agreement.term" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">months</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.start-date')">
                <DatePicker v-model="state.agreement.startDate" :readonly="!$can('edit_agreement_details')" :config="datePickerConfig" />
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.end-date')">
                <DatePicker v-model="state.agreement.endDate" :readonly="!$can('edit_agreement_details')" :config="datePickerConfig" />
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.purchase-price')">
                <InputGroup>
                  <Input v-model="state.agreement.purchasePrice" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">{{ state.agreement.currency }}</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.amount-financed')">
                <InputGroup>
                  <Input v-model="state.agreement.amountBorrowed" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">{{ state.agreement.currency }}</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.deposit')">
                <InputGroup>
                  <Input v-model="state.agreement.deposit" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">{{ state.agreement.currency }}</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.equity')">
                <InputGroup>
                  <Input v-model="state.agreement.equity" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">{{ state.agreement.currency }}</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.final-repayment')">
                <InputGroup>
                  <Input v-model="state.agreement.finalRepayment" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">{{ state.agreement.currency }}</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup label="Rate">
                <InputGroup>
                  <Input v-model="state.agreement.rate" class="w-full" icon="percent" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">APR</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.monthly-payment')">
                <InputGroup>
                  <Input v-model="state.agreement.monthlyRepayment" :readonly="!$can('edit_agreement_details')" />
                  <InputGroupAddon :readonly="!$can('edit_agreement_details')">{{ state.agreement.currency }}</InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup :label="$t('ui.entities.finance-agreement.annual-distance-allowance')">
                <InputGroup>
                  <Input
                    v-model="state.agreement.odometerAllowance"
                    class="w-full"
                    icon="gauge"
                    :readonly="!$can('edit_agreement_details')"
                  />
                  <Dropdown
                    v-model="state.agreement.odometerUnit"
                    filter
                    :options="[
                      { label: 'mi', value: 'miles' },
                      { label: 'km', value: 'kilometers' }
                    ]"
                    option-label="label"
                    option-value="value"
                    class="w-auto"
                    :disabled="!$can('edit_agreement_details')"
                  >
                  </Dropdown>
                </InputGroup>
              </FormGroup>
            </div>
            <Button
              :disabled="!$can('edit_agreement_details')"
              label="Save Changes"
              class="mt-4"
              :is-loading="submitApiRequest.isLoading.value"
              @click="submitAgreementDetails('agreement')"
            />
          </Form>
        </CardBody>
      </Card>
    </div>
  </div>
</template>

<script setup>
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Form from '@/components/forms/Form.vue'
import InputGroup from '@/components/forms/InputGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputPhone from '@/components/forms/InputPhone.vue'
import Button from '@/components/button/Button.vue'
import DatePicker from '@/components/forms/DatePicker.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'

import InputError from '@/components/forms/InputError.vue'

import useApiRequest from '@/composables/useApiRequest'

import Skeleton from 'primevue/skeleton'

import { useI18n } from 'vue-i18n'

import axios from 'axios'

import { ref, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import FormGroup from '@/components/forms/FormGroup.vue'
import Multiselect from '@vueform/multiselect'
import Dropdown from 'primevue/dropdown'
import InputGroupAddon from '@/components/forms/InputGroupAddon.vue'

import { alertToast } from '@/utilities/notification'

import useVuelidate from '@vuelidate/core'
import { email, required, requiredIf } from '@vuelidate/validators'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const currentTab = ref(route.params.tab)

const tabs = ref([
  {
    id: 'basic',
    label: t('ui.common.basic-info'),
    icon: 'user',
    to: '/agreement-edit/' + route.params.id
  },
  {
    id: 'address',
    label: t('ui.entities.address.label'),
    icon: 'location-dot',
    to: '/agreement-edit/' + route.params.id + '/address'
  },
  {
    id: 'contact-methods',
    label: t('ui.common.contact-method', 2),
    icon: 'phone',
    to: '/agreement-edit/' + route.params.id + '/contact-methods'
  },
  {
    id: 'vehicle',
    label: t('ui.entities.vehicle.label'),
    icon: 'car',
    to: '/agreement-edit/' + route.params.id + '/vehicle'
  },
  {
    id: 'agreement',
    label: t('ui.entities.agreement.label'),
    icon: 'file',
    to: '/agreement-edit/' + route.params.id + '/agreement'
  }
])

// Set tab to 'Basic' if no tab is set
if (route.params.tab === '') {
  currentTab.value = 'basic'
} else {
  currentTab.value = route.params.tab
}

const titleOptions = ref(['Mr', 'Ms', 'Mrs', 'Miss', 'Dr'])

const datePickerConfig = {
  enableTime: false,
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'd/m/Y'
}

const countriesList = ref(null)

axios.get('https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json', { withCredentials: false }).then(response => {
  let sortedCountries = response.data.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  countriesList.value = sortedCountries
})

const manufacturers = ref(null)
const manufacturersRequest = useApiRequest()

function fetchManufacturers() {
  manufacturersRequest.send({ endpoint: '/v1/manufacturers' }).then(response => {
    if (response.success) {
      manufacturers.value = response.data
    }
  })
}
fetchManufacturers()

const agreementApiRequest = useApiRequest()
const state = ref(null)

function fetchAgreementData() {
  agreementApiRequest.send({ endpoint: '/v1/agreements/' + route.params.id, method: 'GET' }).then(response => {
    if (response.success) {
      state.value = {
        basic: {
          id: response.data.customer.id,
          companyName: response.data.customer.companyName,
          title: response.data.customer.title,
          forename: response.data.customer.forename,
          surname: response.data.customer.surname,
          dateOfBirth: response.data.customer.dateOfBirth
        },
        address: {
          line1: response.data.customer.address.line1,
          line2: response.data.customer.address.line2,
          town: response.data.customer.address.town,
          county: response.data.customer.address.county,
          postcode: response.data.customer.address.postcode,
          country: response.data.customer.address.country
        },
        contactMethods: {
          mobile: response.data.customer.contact.mobile,
          telephone1: response.data.customer.contact.telephone1,
          telephone2: response.data.customer.contact.telephone2,
          email: response.data.customer.contact.email,
          importedContactMethods: response.data.customer.importedContactMethods
        },
        vehicle: {
          vrm: response.data.vehicle.vrm,
          vin: response.data.vehicle.vin,
          make: response.data.vehicle.make,
          model: response.data.vehicle.model,
          derivative: response.data.vehicle.derivative,
          newUsed: response.data.newUsed,
          regDate: response.data.vehicle.regDate
        },
        agreement: {
          productType: response.data.productType,
          productName: response.data.productName,
          term: response.data.term,
          startDate: response.data.startDate,
          endDate: response.data.endDate,
          purchasePrice: response.data.purchasePrice,
          amountBorrowed: response.data.amountBorrowed,
          deposit: response.data.deposit,
          equity: response.data.equity,
          finalRepayment: response.data.finalRepayment,
          rate: response.data.rate,
          monthlyRepayment: response.data.monthlyRepayment,
          odometerAllowance: response.data.odometer.allowanceYearly,
          odometerUnit: response.data.odometer.unit,
          currency: response.data.currency
        }
      }

      // Add current title to titleOptions if not already in the list
      if (state.value.basic.title !== null) {
        if (!titleOptions.value.includes(state.value.basic.title)) {
          titleOptions.value.push(state.value.basic.title)
        }
      }
    }
  })
}
fetchAgreementData()

watch(
  () => currentTab.value,
  newTab => {
    currentTab.value = newTab
    //fetch tab route
    let tab = tabs.value.find(tab => tab.id === newTab)
    if (tab) {
      router.replace(tab.to)
    }
  }
)

const validationRules = {
  basic: {
    companyName: {},
    title: {},
    forename: {
      required: requiredIf(() => {
        return state.value.basic.companyName == '' || state.value.basic.companyName == null
      })
    },
    surname: {
      required: requiredIf(() => {
        return state.value.basic.companyName == '' || state.value.basic.companyName == null
      })
    },
    dateOfBirth: {}
  },
  address: {
    line1: {},
    line2: {},
    town: {},
    county: {},
    postcode: {},
    country: { required }
  },
  contactMethods: {
    mobile: {},
    telephone1: {},
    telephone2: {},
    email: { email }
  },
  vehicle: {
    vrm: {
      required: requiredIf(() => {
        return state.value.vehicle.vin == ''
      })
    },
    vin: {
      required: requiredIf(() => {
        return state.value.vehicle.vrm == ''
      })
    },
    make: { required },
    model: { required },
    derivative: { required },
    newUsed: { required },
    regDate: {}
  },
  agreement: {
    productType: { required },
    productName: { required },
    term: { required },
    startDate: { required },
    endDate: { required },
    purchasePrice: { required },
    amountBorrowed: { required },
    deposit: { required },
    equity: { required },
    finalRepayment: { required },
    rate: { required },
    monthlyRepayment: { required },
    odometerAllowance: {},
    odometerUnit: {}
  }
}

const v$ = useVuelidate(validationRules, state)

const submitApiRequest = useApiRequest()

async function submitAgreementDetails(form) {
  let formData = {}

  if (form == 'vehicle') {
    let isFormCorrect = await v$.value.vehicle.$validate()
    if (!isFormCorrect) {
      return
    }
    formData = {
      vrm: state.value.vehicle.vrm,
      vin: state.value.vehicle.vin,
      make: state.value.vehicle.make,
      model: state.value.vehicle.model,
      derivative: state.value.vehicle.derivative,
      newUsed: state.value.vehicle.newUsed,
      regDate: state.value.vehicle.regDate == '' ? null : state.value.vehicle.regDate
    }
  }

  if (form == 'agreement') {
    let isFormCorrect = await v$.value.agreement.$validate()
    if (!isFormCorrect) {
      return
    }
    formData = {
      productType: state.value.agreement.productType,
      productName: state.value.agreement.productName,
      term: state.value.agreement.term,
      startDate: state.value.agreement.startDate == '' ? null : state.value.agreement.startDate,
      endDate: state.value.agreement.endDate == '' ? null : state.value.agreement.endDate,
      purchasePrice: state.value.agreement.purchasePrice,
      amountBorrowed: state.value.agreement.amountBorrowed,
      deposit: state.value.agreement.deposit,
      equity: state.value.agreement.equity,
      finalRepayment: state.value.agreement.finalRepayment,
      rate: state.value.agreement.rate,
      monthlyRepayment: state.value.agreement.monthlyRepayment,
      odometerAllowance: state.value.agreement.odometerAllowance,
      odometerUnit: state.value.agreement.odometerUnit
    }
  }

  submitApiRequest.send({ endpoint: '/v1/agreements/' + route.params.id, method: 'PATCH', data: formData }).then(response => {
    if (response.success) {
      // fetchAgreementData()
      alertToast('Updated', null, 'success')
    } else {
      alertToast('Failed to update', response.message, 'error')
    }
  })
}

async function submitCustomerDetails(form) {
  let customerFormData = {}

  if (form == 'basic') {
    let isFormCorrect = await v$.value.basic.$validate()
    if (!isFormCorrect) {
      return
    }
    customerFormData = {
      companyName: state.value.basic.companyName,
      title: state.value.basic.title,
      forename: state.value.basic.forename,
      surname: state.value.basic.surname,
      dateOfBirth: state.value.basic.dateOfBirth
    }
  }
  if (form == 'address') {
    let isFormCorrect = await v$.value.address.$validate()
    if (!isFormCorrect) {
      return
    }
    customerFormData = {
      addressLine1: state.value.address.line1,
      addressLine2: state.value.address.line2,
      addressTown: state.value.address.town,
      addressCounty: state.value.address.county,
      addressPostcode: state.value.address.postcode,
      addressCountry: state.value.address.countryCode
    }
  }
  if (form == 'contact-methods') {
    let isFormCorrect = await v$.value.contactMethods.$validate()
    if (!isFormCorrect) {
      return
    }
    console.log(state.value.contactMethods.telephone1)

    customerFormData = {
      contactTelephone1: state.value.contactMethods.telephone1.length > 5 ? state.value.contactMethods.telephone1 : null,
      contactTelephone2: state.value.contactMethods.telephone2.length > 5 ? state.value.contactMethods.telephone2 : null,
      contactMobile: state.value.contactMethods.mobile ? state.value.contactMethods.mobile : null,
      contactEmail: state.value.contactMethods.email ? state.value.contactMethods.email : null
    }
  }

  submitApiRequest.send({ endpoint: '/v1/customers/' + state.value.basic.id, method: 'PATCH', data: customerFormData }).then(response => {
    if (response.success) {
      // fetchAgreementData()
      alertToast('Details Updated', null, 'success')
    } else {
      alertToast('Failed to update', response.message, 'error')
    }
  })
}

const customerName = computed(() => {
  if (state.value == null) return '...'
  // If company name is set, return company name
  if (state?.value?.basic?.companyName) {
    return state.value.basic.companyName
  } else {
    return state?.value?.basic?.forename + ' ' + state?.value?.basic?.surname
  }
})
</script>

<style></style>
