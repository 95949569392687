<template>
  <div class="select-wrapper">
    <div
      ref="triggerRef"
      class="relative form-control select-input !flex items-center justify-between whitespace-nowrap w-full"
      :class="{ expanded: dropdownIsExpanded, 'has-error': hasError }"
      tabindex="0"
      @click="toggleDropdown(true)"
    >
      <div class="relative w-full mr-2">
        <div v-if="modelValue == null && localSearchTerm == ''" class="absolute">
          {{ placeholder }}
        </div>
        <div v-else-if="localSearchTerm == '' && modelValue != null" class="absolute left-0 w-full selected-value">
          <template v-if="$slots.value">
            <!-- Render the named slot if it exists -->
            <slot name="value" :value="options?.find(option => option[valueKey] === modelValue)" />
          </template>
          <template v-else>
            <!-- Find the obj and Render the label if the named slot does not exist -->
            {{ options?.find(option => option[valueKey] === modelValue)?.[labelKey] || '' }}
          </template>
        </div>
        <input ref="searchInput" v-model="localSearchTerm" class="w-full mr-4 h-full bg-transparent cursor-pointer" />
      </div>
      <div class="flex items-center">
        <Icon v-if="clearable && modelValue != null" type="xmark" class="mr-1" @click="clearSelection()" />
        <Icon type="chevron-down" />
      </div>
    </div>
    <teleport to="#dropdown-overlay">
      <div v-if="dropdownIsExpanded" ref="dropdownRef" :style="floatingStyles" class="select-dropdown-wrapper" v-click-away="clickAway">
        <Empty v-if="!filteredOptions || filteredOptions?.length === 0" />
        <div
          v-for="(item, index) in filteredOptions"
          :key="item"
          class="select-dropdown-item"
          :class="{
            'focused-item': index === focusedItemIndex,
            'selected-item': modelValue === item[valueKey]
          }"
          @click="selectItem(item)"
        >
          <template v-if="$slots.option">
            <!-- Render the named slot if it exists -->
            <slot name="option" :option="item" />
          </template>
          <template v-else>
            <!-- Render default content if the named slot does not exist -->
            {{ item[labelKey] }}
          </template>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import { ref, watch, computed, nextTick } from 'vue'
import { onKeyStroke } from '@vueuse/core'

import { useFloating, autoUpdate, offset, flip, size } from '@floating-ui/vue'
import Empty from '@/components/empty/Empty.vue'

export default {
  components: {
    Icon,
    Empty
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    searchable: {
      type: Boolean,
      default: false
    },
    searchTerm: {
      type: String,
      default: ''
    },
    handleSearch: {
      type: Boolean,
      default: true // if false, the search logic is expected to happen on the invoking parent
    },
    onSelectReturnAsObject: {
      type: Boolean,
      default: false
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    }
  },
  emits: ['update:modelValue', 'update:searchTerm', 'select'],
  setup(props, context) {
    const triggerRef = ref(null)
    const dropdownRef = ref(null)

    const { floatingStyles, update } = useFloating(triggerRef, dropdownRef, {
      placement: 'bottom-start',
      middleware: [
        offset(10),
        flip(),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`
            })
          }
        })
      ],
      strategy: 'fixed',
      whileElementsMounted: autoUpdate
    })

    const dropdownIsExpanded = ref(false)
    const localSearchTerm = ref(props.searchTerm)
    const selectedItemObject = ref(null)
    const searchInput = ref(null)

    const focusedItemIndex = ref(-1) // initialize with -1, no item is focused initially

    // Create a computed property which filters results based on the search term
    const filteredOptions = computed(() => {
      if (props.handleSearch) {
        return props.options?.filter(option => {
          return option[props.labelKey].toLowerCase().includes(localSearchTerm.value.toLowerCase())
        })
      } else {
        return props.options
      }
    })

    // Focus the first item when the dropdown is opened
    watch(dropdownIsExpanded, isExpanded => {
      if (isExpanded && filteredOptions.value?.length > 0) {
        focusedItemIndex.value = 0
      }
    })

    function toggleDropdown(shouldBeOpen) {
      dropdownIsExpanded.value = shouldBeOpen

      // if now open, focus the search input
      if (shouldBeOpen && props.searchable) {
        searchInput.value.focus()
      } else {
        searchInput.value.blur()
        localSearchTerm.value = ''
      }
    }

    function clickAway() {
      console.log('Clicked away')

      toggleDropdown(false)
    }

    function selectItem(item) {
      context.emit('update:modelValue', item[props.valueKey])

      if (props.onSelectReturnAsObject) {
        context.emit('select', item)
      } else {
        context.emit('select', item[props.valueKey])
      }

      if (props.searchable) {
        console.log('Clearing search input')

        localSearchTerm.value = ''
        searchInput.value.blur()
      }
      nextTick(() => {
        toggleDropdown(false)
      })
    }

    function clearSelection() {
      context.emit('update:modelValue', null)
    }

    watch(localSearchTerm, newSearchTerm => {
      context.emit('update:searchTerm', newSearchTerm)
    })

    // ArrowDown logic
    onKeyStroke('ArrowDown', e => {
      if (!dropdownIsExpanded.value) {
        return
      }
      e.preventDefault()
      if (focusedItemIndex.value < filteredOptions.value?.length - 1) {
        focusedItemIndex.value++
      }
    })

    // ArrowUp logic
    onKeyStroke('ArrowUp', e => {
      if (!dropdownIsExpanded.value) {
        return
      }
      e.preventDefault()
      if (focusedItemIndex.value > 0) {
        focusedItemIndex.value--
      }
    })

    // Enter logic
    onKeyStroke('Enter', e => {
      if (!dropdownIsExpanded.value) {
        if (document.activeElement === triggerRef.value) {
          toggleDropdown(true)
        }
        return
      }
      e.preventDefault()
      selectItem(filteredOptions.value[focusedItemIndex.value])
      focusedItemIndex.value = -1 // reset focus
    })

    // Escape logic
    onKeyStroke('Escape', e => {
      if (!dropdownIsExpanded.value) {
        return
      }
      e.preventDefault()
      toggleDropdown(false)
      focusedItemIndex.value = -1 // reset focus
    })

    return {
      triggerRef,
      dropdownRef,
      floatingStyles,
      filteredOptions,
      focusedItemIndex,
      searchInput,
      dropdownIsExpanded,
      localSearchTerm,
      toggleDropdown,
      clickAway,
      selectItem,
      clearSelection,
      selectedItemObject
    }
  }
}
</script>

<style>
.select-wrapper {
  cursor: pointer;
  position: relative;
  width: 100%;
}
.select-input {
  height: var(--s-10);
  position: relative;
}

.select-input.expanded {
  border: 1px solid var(--border-brand);
  box-shadow: var(--focus-ring-brand);
}

.select-dropdown-wrapper {
  top: 0;
  left: 0;
  background-color: var(--bg-primary_alt);
  border-radius: var(--rounded-md);
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--border-secondary);
  max-height: 200px;
  overflow-y: auto;
  padding: var(--s-2) 0;
}

.selected-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select-dropdown-item {
  padding: var(--s-2) var(--s-3);
  margin: 0 var(--s-2);
  border-radius: var(--rounded-md);
  padding-right: 30px;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select-dropdown-placeholder {
  padding: var(--s-2) var(--s-3);
  color: var(--text-secondary);
}

.select-dropdown-item:hover {
  background-color: var(--bg-primary_hover);
}

.focused-item {
  background-color: var(--bg-secondary);
}

.selected-item {
  background-color: var(--bg-brand);
}
.selected-item::after {
  content: '\f00c';
  font-family: 'Font Awesome 6 Pro';
  position: absolute;
  right: var(--s-6);
  color: var(--text-brand);
}
</style>
