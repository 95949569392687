<template>
  <Alert
    v-if="newAppVersionBanner.isVisible"
    :severity="newAppVersionBanner.severity"
    icon="sparkles"
    title="Update Available"
    :message="`A new version (${newAppVersionBanner.latestVersion}) of the app is available. Refresh the page or click on update below.`"
    action-button
    action-button-text="Update"
    :show-dismiss-button="false"
    :show-close-button="false"
    class="mb-6 bg-info"
    @action="refreshPage()"
  />
  <div id="navbar" :class="{ 'app-expanded-menu': mainMenuState == 'lockedOpen' }">
    <div id="navbar-content" class="flex flex-row items-center">
      <ul class="flex flex-1 sm:flex-none xl:hidden cursor-pointer">
        <li class="nav-item">
          <Button icon="bars" plain severity="secondary" @click="openMobileMenu()" />
          <!-- <div class="flex" @click="openMobileMenu()">
            <Icon type="bars" />
          </div> -->
        </li>
      </ul>
      <!-- Favourites -->
      <!-- TODO: determine if we want to keep favourites feature? -->
      <!-- <ul class="hidden sm:flex sm:flex-1" id="navbar-favs">
              <li v-for="item in starredItems" :key="item.name" class="nav-item">
                  <router-link :to="item.route">
                      <Icon :type="item.icon" />
                  </router-link>
              </li>

              <li class="nav-item">
                  <Dropdown text="Dropdown">
                      <template v-slot:triggerContent>
                          <Icon type="star" class="text-yellow-500" />
                      </template>
                      <DropdownItem v-for="item in favsToolbar" :key="item.name" :route="item.route">
                          <div class="flex">
                              <Icon :type="item.icon" style="margin-right: 10px" />
                              <span class="flex-1">{{ $t("terms." + item.localeTerm, 2) }}</span>
                              <Icon
                                  type="star"
                                  :class="{ 'text-yellow-500': item.isFav }"
                                  style="margin-left: 10px"
                                  @click.prevent="toggleFav(item)"
                              />
                          </div>
                      </DropdownItem>
                  </Dropdown>
              </li>
          </ul> -->
      <ul class="sm:flex sm:flex-1 ml-2">
        <li class="nav-item">
          <div class="cursor-pointer flex items-center justify-start" @click="toggleSearchPanel('open')">
            <Icon type="magnifying-glass" class="text-lg" />
            <div class="hidden sm:flex items-center">
              <span class="pl-2 text-quaternary text-lg leading-[1]">{{ $t('ui.common.search') }}</span>
              <span class="text-xs py-0.5 px-1.5 text-secondary border border-secondary rounded-md ml-2">
                <span v-if="isMac">⌘</span><span v-else>Ctrl </span>K
              </span>
            </div>
          </div>
        </li>
      </ul>
      <!-- Right -->
      <ul class="flex items-center gap-2">
        <li v-if="$can('make_calls') && userPresence" class="nav-item hidden sm:block">
          <div class="text-[0.9rem] flex justify-between border border-gray-300 dark:border-darkmode-3 rounded-md">
            <Dropdown>
              <template #triggerContent>
                <div class="cursor-pointer py-1 px-2 flex items-center justify-center">
                  <div
                    :class="[userPresence.color]"
                    class="rounded-full text-white mr-2 h-5 w-5 flex justify-center items-center text-[0.6rem]"
                  >
                    <Icon :type="userPresence.icon" fa-style="fas" />
                  </div>
                  <div>
                    {{ userPresence.text }}
                  </div>
                  <Icon type="chevron-down" class="ml-1" />
                </div>
              </template>
              <DropdownItem @item-clicked="changeStatus('onBreak')">
                <div class="flex items-center">
                  <div
                    :class="[userStatuses.onBreak.color]"
                    class="rounded-full text-white mr-2 h-5 w-5 flex justify-center items-center text-[0.6rem]"
                  >
                    <Icon :type="userStatuses.onBreak.icon" fa-style="fas" />
                  </div>
                  {{ userStatuses.onBreak.text }}
                </div>
              </DropdownItem>
              <DropdownItem @item-clicked="changeStatus('busy')">
                <div class="flex items-center">
                  <div
                    :class="[userStatuses.busy.color]"
                    class="rounded-full text-white mr-2 h-5 w-5 flex justify-center items-center text-[0.6rem]"
                  >
                    <Icon :type="userStatuses.busy.icon" fa-style="fas" />
                  </div>
                  {{ userStatuses.busy.text }}
                </div>
              </DropdownItem>
              <DropdownSeparator v-if="$can('reset_own_presence_status')" />
              <DropdownItem v-if="$can('reset_own_presence_status')" @item-clicked="changeStatus(null)">
                <div class="flex items-center">
                  <div class="bg-gray-400 rounded-full text-white mr-2 h-5 w-5 flex justify-center items-center text-[0.6rem]">
                    <Icon type="rotate-right" fa-style="fas" />
                  </div>
                  {{ $t('ui.actions.reset') }}
                </div>
              </DropdownItem>
            </Dropdown>
            <div
              class="py-1 px-3 border-l dark:border-darkmode-3 text-center"
              :class="{ 'w-14': userStatusTimerTime.length === 4, 'w-16': userStatusTimerTime.length === 5 }"
            >
              {{ userStatusTimerTime }}
            </div>
          </div>
        </li>

        <li class="nav-item">
          <Dropdown>
            <template #triggerContent>
              <Button plain severity="secondary" size="lg" icon="language" />
            </template>
            <DropdownItem @item-clicked="changeLocale('en')">English (UK) </DropdownItem>
            <DropdownItem @item-clicked="changeLocale('en-US')">English (US)</DropdownItem>
            <DropdownItem @item-clicked="changeLocale('es')">Spanish</DropdownItem>
            <DropdownItem @item-clicked="changeLocale('fr')">French</DropdownItem>
          </Dropdown>

          <router-link to="/access">
            <Button plain severity="secondary" size="lg" icon="location-dot" />
          </router-link>

          <div class="hidden">
            <Dropdown>
              <template #triggerContent>
                <Button plain severity="secondary" size="lg" icon="bell" />
              </template>
              <div style="min-width: 400px">
                <div class="px-5 pt-3 pb-1">
                  <h4 class="font-medium">Notifications</h4>
                </div>
                <DropdownSeparator />

                <Chip class="ml-5">Coming Soon!</Chip>

                <!-- <NotificationsList /> -->

                <DropdownSeparator />
                <div class="px-5 pt-1 pb-3">
                  <!-- <router-link to="/notifications"> -->
                  <router-link to="/home">
                    <Button label="View All" block plain />
                  </router-link>
                </div>
              </div>
            </Dropdown>
          </div>

          <Dropdown text="Dropdown" :close-on-click-within="false" :padding="false">
            <template #triggerContent>
              <Button plain severity="secondary" size="lg" :icon="selectedThemeObj.icon ?? 'moon'" />
            </template>
            <div class="px-5 py-3">
              <RadioTabbed v-model="selectedTheme" :options="systemThemeOptions" @option-change="darkmodeChange" />
              <!-- <div class="text-sm text-secondary mt-5"><Icon type="info-circle" /> <b>Auto</b> is based on time-of-day</div> -->
            </div>
          </Dropdown>
        </li>
        <li class="nav-item cursor-pointer !text-md flex">
          <Dropdown text="Dropdown">
            <template #triggerContent>
              <Avatar
                size="md"
                :url="userStore.details.profilePicUrl"
                :user-presence-user-id="userStore.details.id"
                :text="userStore.details.initials"
                :status-indicator-icon="userPresence.icon"
                :status-indicator-color-class="userPresence.color"
              />
            </template>
            <DropdownItem>
              <AvatarLabelGroup
                :label="userStore.details.name"
                :description="userStore.details.email"
                :avatar-url="userStore.details.profilePicUrl"
                :avatar-text="userStore.details.initials"
              />
            </DropdownItem>

            <DropdownSeparator />

            <!-- <DropdownItem route="/me/profile">
              <Icon type="user" />
              <span class="w-full">{{ $t('ui.entities.profile.label') }}</span>
            </DropdownItem>
            <DropdownItem route="/me/settings">
              <Icon type="cog" />
              <span class="w-full">{{ $t('ui.entities.setting', 2) }}</span>
            </DropdownItem>

            <DropdownSeparator /> -->

            <DropdownItem v-if="userStore.details.type == 1" link="https://retain-automotive.canny.io/changelog">
              <Icon type="sparkles" />
              <span class="w-full">{{ $t('ui.support.whats-new') }}</span>
            </DropdownItem>
            <!-- <DropdownItem link="https://forms.clickup.com/2572793/f/2egft-4528/UIDVM6B0E91WP5KXVA"> -->
            <DropdownItem v-if="userStore.details.type == 1" link="https://retain-automotive.canny.io/feature-requests">
              <Icon type="lightbulb-on" />
              <span class="w-full">{{ $t('ui.support.feature-requests') }}</span>
            </DropdownItem>
            <!-- <DropdownItem link="https://forms.clickup.com/2572793/f/2egft-4328/YG6YNJD2S13499Q0WP"> -->
            <DropdownItem v-if="userStore.details.type == 1" link="https://retain-automotive.canny.io/bugs-issues">
              <!-- <Icon type="bug" /> -->
              <Icon type="bug" />
              <span class="w-full">{{ $t('ui.support.report-issue') }}</span>
            </DropdownItem>
            <DropdownItem link="mailto:support@retain.group">
              <Icon type="ticket" />
              <span class="w-full">{{ $t('ui.support.label') }}</span>
            </DropdownItem>

            <DropdownSeparator />

            <DropdownItem @item-clicked="logout">
              <Icon type="arrow-right-from-bracket" />
              <span class="w-full">{{ $t('ui.common.sign-out') }}</span>
            </DropdownItem>
          </Dropdown>
        </li>
      </ul>
    </div>
  </div>
  <GlobalSearch :is-open="searchIsOpen" @close-search="closeSearch" />
</template>

<script>
import { computed, ref, onMounted, inject } from 'vue'

import Icon from '@/components/icon/Icon.vue'
import { state, mobileMenuIsOpen } from '@/components/main-menu/state'
import Avatar from '@/components/avatar/Avatar.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import DropdownSeparator from '@/components/dropdown/DropdownSeparator.vue'
import NotificationsList from '@/components/notifications/NotificationsList.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'
import Alert from '@/components/alert/Alert.vue'

import dayjs from 'dayjs'

import GlobalSearch from '@/components/search/GlobalSearch.vue'

import socket from '@/services/socket.service.js'

import { useUserStore } from '@/stores/UserStore'

import userStatuses from '@/utilities/userStatuses.json'
import getUserStatus from '@/utilities/getUserStatus.js'

import { useEventListener } from '@vueuse/core'

import initializeCanny from '@/plugins/canny.js'

import packageInfo from '/package.json'

export default {
  components: {
    Icon,
    Avatar,
    AvatarLabelGroup,
    Button,
    Chip,
    Dropdown,
    DropdownItem,
    DropdownSeparator,
    NotificationsList,
    RadioTabbed,
    GlobalSearch,
    Alert
  },
  props: {
    darkMode: Boolean
  },
  setup() {
    const searchIsOpen = ref(false)
    const localeIso = ref(localStorage.getItem('locale'))
    const mainMenuState = state
    const newAppVersionBanner = ref({
      isVisible: false,
      version: null,
      severity: null
    })

    function refreshPage() {
      location.reload()
    }

    const emitter = inject('emitter')

    onMounted(() => {
      // console.log('Listening for version mismatch event')
      // Listen for the version mismatch event
      emitter.on('version-mismatch', ({ currentVersion, latestVersion }) => {
        // console.log('Show Banner ', currentVersion, latestVersion)
        newAppVersionBanner.value.isVisible = true
        newAppVersionBanner.value.currentVersion = currentVersion
        newAppVersionBanner.value.latestVersion = latestVersion
        newAppVersionBanner.value.severity = 'info' // or 'warning' or 'danger' based on your needs
      })
    })

    const userStore = useUserStore() //PINIA

    onMounted(() => {
      // Call the initializeCanny function with the user data
      initializeCanny(userStore.details)
    })

    const favsToolbarDefaults = [
      {
        name: 'inbox',
        icon: 'inbox',
        route: '/inbox',
        isFav: true,
        localeTerm: 'inbox'
      },
      {
        name: 'flow',
        icon: 'wind',
        route: '/flow',
        isFav: false,
        localeTerm: 'flow'
      },
      {
        name: 'appointments',
        icon: 'calendar-days',
        route: '/appointments/calendar',
        isFav: true,
        localeTerm: 'appointment'
      },
      {
        name: 'availability',
        icon: 'calendar-check',
        route: '/appointments/availability',
        isFav: false,
        localeTerm: 'availability'
      },
      {
        name: 'to-do',
        icon: 'check-double',
        route: '/tasks/to-do',
        isFav: true,
        localeTerm: 'to-do'
      }
    ]

    if (!localStorage.getItem('favsToolbar')) {
      let init = favsToolbarDefaults.filter(item => item.isFav)
      let initDefaults = []

      init.forEach(item => {
        initDefaults.push(item.name)
      })
      localStorage.setItem('favsToolbar', JSON.stringify(initDefaults))
    }

    const favsToolbar = ref(favsToolbarDefaults)
    const favs = ref(JSON.parse(localStorage.getItem('favsToolbar')) || [])

    //Loop through each faved item and update favsToolbar
    favsToolbarDefaults.forEach(item => {
      // console.log(item.name)

      if (favs.value.includes(item.name)) {
        item.isFav = true
      } else {
        item.isFav = false
      }
    })

    const starredItems = computed(() => {
      return favsToolbar.value.filter(item => item.isFav)
    })

    function toggleFav(item) {
      // console.log('fav toggled')

      if (item.isFav) {
        // If already a fav, remove from array
        favs.value = favs.value.filter(e => e !== item.name)
      } else {
        // Else, add to array
        favs.value.push(item.name)
      }

      item.isFav = !item.isFav

      //Update local storage
      localStorage.setItem('favsToolbar', JSON.stringify(favs.value))
    }

    let selectedTheme = computed(() => userStore.userSettings.darkmode)
    const systemThemeOptions = [
      {
        label: 'Light',
        value: 'light',
        icon: 'sun-bright'
      },
      {
        label: 'Dark',
        value: 'dark',
        icon: 'moon'
      },
      {
        label: 'Auto',
        value: 'auto',
        icon: 'eclipse'
      }
    ]

    let selectedThemeObj = computed(() => {
      //Find the object which matched the selected theme value
      //Check if the settings have been set. Else, should show default icon as to not throw undefined error
      let themeInStore

      if (userStore.userSettings.length > 0) {
        themeInStore = systemThemeOptions.find(theme => theme.value == userStore.stateSettings.darkmode)
      } else {
        themeInStore = systemThemeOptions[0]
      }

      return themeInStore
    })

    function darkmodeChange(option) {
      userStore.updateUserSettings([
        {
          name: 'darkmode',
          value: option
        }
      ])
    }

    function openMobileMenu() {
      mobileMenuIsOpen.value = true
    }

    function changeLocale(iso) {
      if (localeIso.value != iso) {
        localeIso.value = iso
        localStorage.setItem('locale', iso)

        location.reload()
      }
    }

    function logout() {
      userStore.signOut()
    }

    //User status widget

    function fancyTimeFormat(duration) {
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600)
      var mins = ~~((duration % 3600) / 60)
      var secs = ~~duration % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    }

    let userStatusStartTime = ref(0)

    const userPresence = computed(() => {
      let user = getUserStatus(userStore.details.id)
      //If there is a change in statusStartTime, reset the timer, else ignore it as the status hasn't changed
      if (userStatusStartTime.value != user.statusStartTime) {
        // console.log("Change in Time!")
        // console.log("Current time: " + userStatusStartTime.value)
        // console.log("New time: " + user.statusStartTime)
        userStatusStartTime.value = user.statusStartTime
      }
      return user
    })

    let userStatusTimerTime = ref('0:00')
    setInterval(() => {
      userStatusTimerTime.value = fancyTimeFormat(dayjs().diff(userStatusStartTime.value, 'second'))

      // console.log(userStatusTimerTime.value)
    }, 1000)

    function changeStatus(status) {
      socket.emit('user-status', status)
    }

    function toggleSearchPanel(action = null) {
      if (action != null) {
        if (action == 'open') {
          searchIsOpen.value = true
        } else {
          searchIsOpen.value = false
        }
      } else {
        searchIsOpen.value = !searchIsOpen.value
      }
    }

    const isMac = /Mac|iPod|iPhone|iPad/.test(window.navigator.userAgent)

    //Listen for Cmd+k for searching
    const onKeydown = event => {
      const modifier = isMac ? 'metaKey' : 'ctrlKey'
      const cmdK = event[modifier] && event.key.toLowerCase() === 'k'

      if (cmdK) {
        event.preventDefault()
        // console.log('CMD+K (or CTRL+K) pressed')
        toggleSearchPanel()
      }
    }
    useEventListener(document, 'keydown', onKeydown)

    function closeSearch() {
      // console.log('receiving')
      searchIsOpen.value = false
    }

    let currentAppVersion = packageInfo.version
    socket.on('new-fe-version-available', ({ version, severity }) => {
      console.log('new-fe-version-available: ' + version)
      if (currentAppVersion !== version) {
        newAppVersionBanner.value.isVisible = true
        newAppVersionBanner.value.version = version
        newAppVersionBanner.value.severity = severity
      }
    })

    return {
      refreshPage,
      searchIsOpen,
      closeSearch,
      toggleSearchPanel,
      mainMenuState,
      userStore,
      Icon,
      systemThemeOptions,
      selectedTheme,
      selectedThemeObj,
      darkmodeChange,
      openMobileMenu,
      mobileMenuIsOpen,
      Dropdown,
      DropdownItem,
      DropdownSeparator,
      localeIso,
      changeLocale,
      favsToolbar,
      toggleFav,
      starredItems,
      logout,
      userPresence,
      userStatusTimerTime,
      userStatuses,
      changeStatus,
      newAppVersionBanner,
      isMac
    }
  }
}
</script>

<style>
#navbar {
  box-sizing: border-box;
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: 1440px;
}

.dark #navbar {
  background: none;
}

#navbar::after {
  position: absolute;
  z-index: -1;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-repeat: repeat;
  block-size: calc(64px + 1.5rem);
  content: '';
  inset-block-start: -1.02rem;
  inset-inline-end: 0;
  inset-inline-start: 0;
  -webkit-mask: linear-gradient(black, black 18%, transparent 100%);
  mask: linear-gradient(black, black 18%, transparent 100%);
}

#navbar-content {
  /* box-shadow: var(--shadowCard); */
  padding: var(--s-2) var(--s-4) var(--s-2) var(--s-3);
  border-radius: var(--rounded-xl);
  border: 1px solid;
  border-color: var(--border-secondary);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  height: 100%;
  background-color: var(--bg-primary_alt);
}

.dark #navbar-content {
  border-color: transparent;
}
</style>
