<template>
  <PageHeader title="ReAuction" breadcrumb :items="[{ label: 'ReAuction', to: '/vehicles/reauction' }]" />
  <div v-if="reAuctionEnabled && data?.length" class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-10">
    <VehicleListingCard v-for="(listing, index) in data" :key="index" :listing-data="listing" />
  </div>

  <Empty
    v-else-if="!reAuctionEnabled"
    icon="gavel"
    title="ReAuction is not enabled"
    icon-size="lg"
    description="ReAuction is not enabled for any of your dealerships"
  />
  <Empty v-else icon="cloud-question" title="No Listings" icon-size="lg" description="There are currently no ReAuction listings" />
</template>

<script setup>
import { ref } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import VehicleListingCard from '@/components/unique/vehicles/VehicleListingCard.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import { alertToast } from '@/utilities/notification'

import dayjs from 'dayjs'
import Empty from '@/components/empty/Empty.vue'

const data = ref(null)
const fetchListingsApiRequest = useApiRequest()
const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

const reAuctionEnabled = ref(false)

// check if any dealerships (dealership.features) within dealershipsAllowed have auction set to true
if (dealershipsAllowed.value.some(dealership => dealership.features.auction)) {
  reAuctionEnabled.value = true
}

fetchListingsApiRequest
  .send({
    endpoint: '/v1/valuations',
    params: { dealershipIds: JSON.stringify(dealershipsAllowed.value.map(d => d.id)), types: 'sell' }
  })
  .then(response => {
    if (!response.success) {
      alertToast('Failed to fetch ReAuctions', response.data?.message, 'error')
      return
    }
    // If external user, filter out items that are not on the auth user's level (dealership, group, etc.)
    if (userStore.details.type == 2) {
      response.data = response.data.filter(item => item.sale.offeringTo === userStore.details.level)
    }
    data.value = response.data.filter(listing => dayjs(listing.sale.currentWindowExpiresAt).diff(dayjs(), 'hour') > -24)
  })
</script>

<style></style>
