import axios from 'axios'
import { ref } from 'vue'
import { alertToast } from '@/utilities/notification'

const getShiftTypes = (params = {}) => {
  let shiftTypes = ref([])
  let errorShiftTypes = ref(null)

  const loadShiftTypes = async params => {
    let url = import.meta.env.VITE_API_BASE_URL + '/v1/users/schedule/shift-types'

    try {
      let data = await axios.get(url, { withCredentials: true })
      // console.log(data.data.data)

      if (data.status != 200) {
        if (data.http.status === 401) {
          error.value = '401'
          throw Error('401 Unauthorized')
        } else {
          error.value = 'Error fetching data'
          throw Error('Error fetching data')
        }
      }
      //If everything is fine
      shiftTypes.value = data.data.data

      // console.log(shiftTypes.value)
    } catch (err) {
      console.log(err.message)
      alertToast('Error', err.message, 'error')
    }
  }

  return { shiftTypes, errorShiftTypes, loadShiftTypes }
}
export default getShiftTypes
