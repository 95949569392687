import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import updateAbility from '@/config/update-ability.js'
import ability from '@/config/ability'

import LogIn from '../views/auth/LogIn.vue'
import Verify from '../views/auth/Verify.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import PasswordReset from '../views/auth/PasswordReset.vue'
import NotFound from '../views/error/NotFound.vue'
import NoAccess from '../views/error/NoAccess.vue'
import Maintenance from '../views/error/MaintenanceMode.vue'
import MainLayout from '../views/MainLayout.vue'

import Home from '../views/Home.vue'
import Templates from '../views/Templates.vue'
import Access from '../views/Access.vue'
import Notifications from '../views/Notifications.vue'

// Dashboards
import DashboardView from '../views/DashboardView.vue'

//Tasks
import Todo from '../views/Todo.vue'

//Calendars
import AppointmentsCalendar from '../views/appointments/AppointmentsCalendar.vue'
import AvailabilityCalendar from '../views/appointments/AvailabilityCalendar.vue'
import AppointmentManager from '../views/appointments/AppointmentManager.vue'

//Flow, Campaigns and Leads
import Flow from '../views/Flow.vue'
import Campaigns from '../views/campaigns/Campaigns.vue'
import Campaign from '../views/campaigns/Campaign.vue'

// Conversations
import MissedConversations from '../views/conversations/MissedConversations.vue'

//Customers and agreements
import Customers from '../views/customers/Customers.vue'
import EditCustomer from '../views/agreement/EditCustomer.vue'
import Agreement from '../views/agreement/Agreement.vue'

//Vehicles
import Valuations from '../views/vehicles/valuations/Valuations.vue'
import Valuation from '../views/vehicles/valuations/Valuation.vue'
import UsedStock from '../views/vehicles/stock/UsedStock.vue'
import ReAuctions from '../views/vehicles/reauction/ReAuctions.vue'
import ReAuction from '../views/vehicles/reauction/ReAuction.vue'

//Finance
import Invoicing from '../views/finance/Invoicing.vue'
import Statements from '../views/finance/Statements.vue'

// Settings
import Settings from '../views/Settings.vue'
import Users from '../views/settings/users/Users.vue'
import UserSettings from '../views/settings/users/User.vue'
import Schedule from '../views/settings/schedule/Schedule.vue'
import ScheduleTemplate from '../views/settings/schedule/ScheduleTemplate.vue'

import Dealerships from '../views/settings/dealerships/Dealerships.vue'
import Dealership from '../views/settings/dealerships/Dealership.vue'
import DealershipTargets from '../views/settings/dealerships/targets/Targets.vue'
import PhoneNumbers from '../views/settings/communications/PhoneNumbers.vue'

import CronJobs from '../views/settings/admin/CronJobs.vue'
import Manufacturers from '../views/settings/dealerships/Manufacturers.vue'
import Groups from '../views/settings/dealerships/Groups.vue'

import RepeatingInvoices from '../views/settings/finance/RepeatingInvoices.vue'
import RepeatingInvoice from '../views/settings/finance/RepeatingInvoice.vue'

// Reports
import Reports from '../views/Reports.vue'

import AgentStatistics from '../views/reports/staff/AgentStatistics.vue'
import AgentKpi from '../views/reports/staff/AgentKpi.vue'
import AllocationsReport from '../views/reports/staff/Allocations.vue'
import StatusTimeline from '../views/reports/staff/StatusTimeline.vue'
import StatusSummary from '../views/reports/staff/StatusSummary.vue'
import Emails from '../views/reports/engagement/Emails.vue'
import Summary from '../views/reports/engagement/Summary.vue'
import Insights from '../views/reports/engagement/Insights.vue'

import Sms from '../views/reports/engagement/Sms.vue'

import Calls from '../views/reports/engagement/Calls.vue'

import ReloopSubmissions from '../views/reports/reloop/Submissions.vue'
import ReloopScorecard from '../views/reports/reloop/Scorecard.vue'
import AppointmentsList from '../views/reports/appointments/AppointmentsList.vue'
import CancelledNoShow from '../views/reports/appointments/CancelledNoShow.vue'
import Conversions from '../views/reports/appointments/Conversions.vue'

import DealershipStatistics from '../views/reports/dealerships/Statistics.vue'
import DealershipData from '../views/reports/dealerships/Data.vue'

import AppointedCustomers from '../views/reports/customers/AppointedCustomers.vue'

import ReAuctionList from '../views/reports/vehicles/ReAuctionList.vue'

// DEV TESTS
import BlankView from '../views/dev-tests/BlankView.vue'
import UI from '../views/dev-tests/UI.vue'
import HealthCheck from '../views/dev-tests/HealthCheck.vue'
import Testing from '../views/dev-tests/Testing.vue'
import Sockets from '../views/dev-tests/Sockets.vue'
import LiveCalls from '../views/dev-tests/LiveCalls.vue'
import CallsByAgent from '../views/dev-tests/CallsByAgent.vue'
import Queues from '../views/dev-tests/Queues.vue'
import User from '../views/dev-tests/User.vue'
import TestsDealerships from '../views/dev-tests/Dealerships.vue'

// Permissions for can gates
// assign_appointments
// assign_tasks
// be_assigned_to_appointments
// book_any_appointment
// create_customer
// create_users
// delete_records
// download_call_recordings
// edit_agreement_details
// edit_customer_contact_methods
// edit_locked_appointments
// listen_to_call_recordings
// make_calls
// manage_agreement
// manage_call_center_team
// manage_campaigns
// manage_dealerships
// manage_dealership_billing
// manage_finances
// manage_roles_and_permissions
// manage_users
// manage_user_schedule
// merge_records
// outcome_appointments
// override_user_permissions
// receive_calls
// reset_own_presence_status
// send_emails
// send_sms
// set_appointment_creator
// submit_reloop
// update_appointment_creator
// update_dealership_locked_data
// update_own_access
// update_user_email
// view_agreement
// view_all_missed_contacts
// view_appointments
// view_availability
// view_campaigns
// view_conversations
// view_customers
// view_customers_list
// view_dataprotect
// view_finances
// view_flow
// view_leads
// view_multiple_dashboards
// view_reports
// view_reports_agents
// view_reports_appointments
// view_reports_campaigns
// view_reports_customers
// view_reports_dealerships
// view_reports_engagement
// view_reports_finance
// view_reports_reloop
// view_settings
// view_switch
// view_tasks
// view_user_type_1
// view_user_type_2
// view_user_type_3
// view_valuations
// view_vehicles

const routes = [
  {
    path: '/login',
    name: 'Log In',
    component: LogIn
  },
  {
    path: '/verify/:token',
    name: 'Verify',
    component: Verify
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword
  },
  {
    path: '/password-reset/:token',
    name: 'Password Reset',
    component: PasswordReset
  },
  {
    path: '/access',
    name: 'Access',
    component: Access
  },
  {
    path: '',
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/templates',
        name: 'Templates',
        component: Templates
      },
      {
        path: '/tests/testing',
        name: 'Testing',
        component: Testing
      },
      {
        path: '/tests/user',
        name: 'User',
        component: User
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/dashboard/:pathMatch(.*)*',
        name: 'Dashboard',
        component: DashboardView
      },
      {
        path: '/tasks/to-do/:tab?',
        name: 'To-Do',
        component: Todo
      },
      {
        path: '/customers',
        name: 'Customers',
        component: Customers,
        meta: { canGates: ['view_customers'] }
      },
      {
        path: '/customers/flow',
        name: 'Flow',
        component: Flow,
        meta: { canGates: ['view_customers', 'view_flow'] }
      },
      {
        path: '/customers/campaigns',
        name: 'Campaigns',
        component: Campaigns,
        meta: { canGates: ['view_customers', 'view_campaigns'] }
      },
      {
        path: '/customers/campaigns/:campid',
        name: 'Campaign',
        component: Campaign,
        meta: { canGates: ['view_customers', 'view_campaigns'] }
      },
      {
        path: '/appointments/manager',
        name: 'Appointment Manager',
        component: AppointmentManager,
        meta: { canGates: ['view_appointments', 'assign_appointments'] }
      },
      {
        path: '/appointments/calendar',
        name: 'Appointments Calendar',
        component: AppointmentsCalendar,
        meta: { canGates: ['view_appointments'] }
      },

      {
        path: '/appointments/availability',
        name: 'Availability Calendar',
        component: AvailabilityCalendar,
        meta: { canGates: ['view_availability'] }
      },
      {
        path: '/conversations/missed',
        name: 'Missed Conversations',
        component: MissedConversations,
        meta: { canGates: ['view_all_missed_contacts'] }
      },
      {
        path: '/agreement/:agid/:tab?/:id?',
        name: 'Agreement',
        component: Agreement,
        meta: { canGates: ['view_agreement'] }
      },
      {
        path: '/agreement-edit/:id/:tab?',
        name: 'Edit Customer',
        component: EditCustomer,
        meta: { canGates: ['view_agreement', 'edit_agreement_details'] }
      },
      {
        path: '/vehicles/valuations',
        name: 'Valuations',
        component: Valuations,
        meta: { canGates: ['view_valuations'] }
      },
      {
        path: '/vehicles/valuations/:id',
        name: 'Valuation',
        component: Valuation,
        meta: { canGates: ['view_valuations'] }
      },
      {
        path: '/vehicles/stock/used',
        name: 'Used Stock',
        component: UsedStock,
        meta: { canGates: ['view_vehicles_used_stock'] }
      },
      {
        path: '/vehicles/reauction',
        name: 'ReAuctions',
        component: ReAuctions,
        meta: { canGates: ['view_valuations'] }
      },
      {
        path: '/vehicles/reauction/:id',
        name: 'ReAuction',
        component: ReAuction,
        meta: { canGates: ['view_valuations'] }
      },
      {
        path: '/finance/invoicing',
        name: 'Invoicing',
        component: Invoicing,
        meta: { canGates: ['view_finances'] }
      },
      {
        path: '/finance/invoicing/statements',
        name: 'Statements',
        component: Statements,
        meta: { canGates: ['view_finances'] }
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta: { canGates: ['view_reports'] }
      },
      {
        path: '/reports/call-agents/statistics',
        name: 'Statistics',
        component: AgentStatistics,
        meta: { canGates: ['view_reports', 'view_reports_agents'] }
      },
      {
        path: '/reports/call-agents/kpis',
        name: 'KPIs',
        component: AgentKpi,
        meta: { canGates: ['view_reports', 'view_reports_agents'] }
      },
      {
        path: '/reports/call-agents/allocations',
        name: 'Allocations & Availability',
        component: AllocationsReport,
        meta: { canGates: ['view_reports', 'view_reports_agents'] }
      },
      {
        path: '/reports/call-agents/status-timeline',
        name: 'Status Timeline',
        component: StatusTimeline,
        meta: { canGates: ['view_reports', 'view_reports_agents'] }
      },
      {
        path: '/reports/call-agents/status-summary',
        name: 'Status Summary',
        component: StatusSummary,
        meta: { canGates: ['view_reports', 'view_reports_agents'] }
      },
      {
        path: '/reports/engagement/calls',
        name: 'Calls',
        component: Calls,
        meta: { canGates: ['view_reports', 'view_reports_engagement'] }
      },
      {
        path: '/reports/engagement/sms',
        name: 'SMS',
        component: Sms,
        meta: { canGates: ['view_reports', 'view_reports_engagement'] }
      },
      {
        path: '/reports/engagement/emails',
        name: 'Emails',
        component: Emails,
        meta: { canGates: ['view_reports', 'view_reports_engagement'] }
      },
      {
        path: '/reports/engagement/summary',
        name: 'Summary',
        component: Summary,
        meta: { canGates: ['view_reports', 'view_reports_engagement'] }
      },
      {
        path: '/reports/engagement/insights',
        name: 'Insights',
        component: Insights,
        meta: { canGates: ['view_reports', 'view_reports_engagement'] }
      },
      {
        path: '/reports/dealerships/statistics',
        name: 'Dealership Statistics',
        component: DealershipStatistics,
        meta: { canGates: ['view_reports', 'view_reports_dealerships'] }
      },
      {
        path: '/reports/dealerships/data',
        name: 'Dealership Data',
        component: DealershipData,
        meta: { canGates: ['view_reports', 'view_reports_dealerships'], userTypesAllowed: [1] }
      },
      {
        path: '/reports/customers/appointed-customers',
        name: 'Appointed Customers',
        component: AppointedCustomers,
        meta: { canGates: ['view_reports', 'view_reports_customers'] }
      },
      {
        path: '/reports/reloop/submissions',
        name: 'Submissions',
        component: ReloopSubmissions,
        meta: { canGates: ['view_reports', 'view_reports_reloop'] }
      },
      {
        path: '/reports/reloop/scorecard',
        name: 'Scorecard',
        component: ReloopScorecard,
        meta: { canGates: ['view_reports', 'view_reports_reloop'] }
      },
      {
        path: '/reports/appointments/list',
        name: 'Appointments List',
        component: AppointmentsList,
        meta: { canGates: ['view_reports', 'view_reports_appointments'] }
      },
      {
        path: '/reports/appointments/cancelled-and-no-show',
        name: 'Cancelled & No-Show',
        component: CancelledNoShow,
        meta: { canGates: ['view_reports', 'view_reports_appointments'] }
      },
      {
        path: '/reports/appointments/conversions',
        name: 'Conversions',
        component: Conversions,
        meta: { canGates: ['view_reports', 'view_reports_appointments'] }
      },
      {
        path: '/reports/vehicles/reauction-list',
        name: 'ReAuction List',
        component: ReAuctionList,
        meta: { canGates: ['view_reports'] }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: { canGates: ['view_settings'] }
      },
      {
        path: '/settings/users',
        name: 'Users',
        component: Users,
        meta: { canGates: ['view_settings', 'manage_users'] }
      },
      {
        path: '/settings/users/:id/:tab?',
        name: 'User Settings',
        component: UserSettings,
        meta: { canGates: ['view_settings', 'manage_users'] }
      },
      {
        path: '/settings/schedule',
        name: 'Schedule',
        component: Schedule,
        meta: { canGates: ['view_settings', 'manage_user_schedule'] }
      },
      {
        path: '/settings/schedule/template',
        name: 'Schedule Template',
        component: ScheduleTemplate,
        meta: { canGates: ['view_settings', 'manage_user_schedule'] }
      },
      {
        path: '/settings/dealerships',
        name: 'Dealerships',
        component: Dealerships,
        meta: { canGates: ['view_settings', 'manage_dealerships'] }
      },
      {
        path: '/settings/dealerships/:id/:tab?',
        name: 'Dealership',
        component: Dealership,
        meta: { canGates: ['view_settings', 'manage_dealerships'] }
      },
      {
        path: '/settings/dealership/targets',
        name: 'Dealership Targets',
        component: DealershipTargets,
        meta: { canGates: ['view_settings', 'manage_dealerships'] }
      },
      {
        path: '/settings/phone-numbers',
        name: 'Phone Numbers',
        component: PhoneNumbers,
        meta: { canGates: ['view_settings', 'manage_dealerships'] }
      },
      {
        path: '/settings/admin/cron-jobs',
        name: 'CronJobs',
        component: CronJobs,
        meta: { canGates: ['administer_system'] }
      },
      {
        path: '/settings/manufacturers',
        name: 'Manufacturers',
        component: Manufacturers,
        meta: { canGates: ['view_settings'] }
        //  TODO add back in
        // 'manage_manufacturers'
      },
      {
        path: '/settings/groups',
        name: 'Groups',
        component: Groups,
        meta: { canGates: ['view_settings'] }
        // TODO Add back in
        // 'manage_groups'
      },
      {
        path: '/settings/finance/repeating-invoices',
        name: 'Invoice Templates',
        component: RepeatingInvoices,
        meta: { canGates: ['view_settings', 'manage_finances'] }
      },
      {
        path: '/settings/finance/repeating-invoice/:val',
        name: 'Invoice',
        component: RepeatingInvoice,
        meta: { canGates: ['view_settings', 'manage_finances'] }
      }
    ]
  },
  {
    path: '/no-access',
    name: 'No Access',
    component: NoAccess
  },
  {
    path: '/maintenance',
    name: 'Maintenance Mode',
    component: Maintenance
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFound
  },
  // DEV TESTS & PAGES
  {
    path: '/tests/blank',
    name: 'BlankView',
    component: BlankView
  },
  {
    path: '/tests/ui',
    name: 'UI',
    component: UI
  },
  {
    path: '/healthcheck',
    name: 'App Health Check',
    component: HealthCheck
  },
  {
    path: '/tests/sockets',
    name: 'Sockets',
    component: Sockets
  },
  {
    path: '/tests/live-calls',
    name: 'LiveCalls',
    component: LiveCalls
  },
  {
    path: '/tests/calls-by-agent',
    name: 'Calls by Agent',
    component: CallsByAgent
  },
  {
    path: '/tests/queues',
    name: 'Queues',
    component: Queues
  },
  {
    path: '/tests/dealerships',
    name: 'TestsDealerships',
    component: TestsDealerships
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = `${to.name}`

  const userStore = useUserStore()
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // Flag to track if verification has already been done in this routing sequence
  let verificationDone = false

  // Verify user on page reload for routes that require authentication
  if (from === START_LOCATION && requiresAuth && !userStore.isLoggedIn) {
    await userStore.verify()
    verificationDone = true
  }

  if (to.path === '/') {
    router.replace('/home')
  }

  if (requiresAuth && !userStore.isLoggedIn) {
    userStore.signOut()
    return next({ path: '/login', query: { ref: to.fullPath } })
  }

  if (requiresAuth && userStore.isLoggedIn) {
    // Only call verify if it hasn't been done already in this routing sequence
    if (!verificationDone) {
      const response = await userStore.verify()
      if (!response.success) {
        userStore.signOut()
        return next({ path: '/login', query: { ref: to.fullPath } })
      }
    }

    try {
      await updateAbility(null, router)

      if (to.meta.canGates) {
        const can = to.meta.canGates.every(permission => ability.can(permission))
        if (!can) {
          return next({ path: '/no-access' })
        }
      }

      if (to.meta.userTypesAllowed) {
        const can = to.meta.userTypesAllowed.includes(userStore.details.type)
        if (!can) {
          return next({ path: '/no-access' })
        }
      }

      return next()
    } catch (error) {
      console.error('Ability update failed:', error)
      return next({ path: '/no-access' })
    }
  }

  return next()
})

export default router
