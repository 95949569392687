<template>
  <div class="file-upload-wrapper">
    <div v-if="headerTitle" class="text-xl">{{ headerTitle }}</div>
    <div
      class="drop-area"
      :class="{ 'drag-over': isDragOver }"
      @click="triggerFileInput"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
    >
      <div>
        <Icon type="cloud-arrow-up" class="text-6xl text-secondary" />
      </div>
      <div class="mt-2"><span class="underline font-medium">Click to upload</span> or drag and drop</div>
      <div class="mt-2 text-sm text-secondary">Maximum file size: {{ readableFileSize(maxFileSize) }}</div>
      <div class="text-sm text-secondary">Accepted types: {{ acceptedFileTypes.join(', ') }}</div>
    </div>
    <input ref="fileInput" type="file" multiple style="display: none" @change="handleFiles" />
    <Alert
      v-for="error in errors"
      :key="error"
      :severity="error.severity"
      :title="error.detail"
      :show-dismiss-button="false"
      :show-close-button="false"
    />
    <ul class="files-list">
      <li v-for="(file, index) in files" :key="index" class="file-container">
        <div class="w-full flex">
          <div class="file-preview">
            <img v-if="showFilePreview && isImageFile(file.file)" :src="file.preview" alt="Preview" class="w-full h-full object-cover" />
            <Icon v-else :type="fileTypeIcon(file.file)" />
          </div>
          <div class="flex-grow">
            <div class="w-full flex justify-between">
              <div class="flex-grow">
                <div class="font-medium">{{ file.file.name }}</div>
                <div class="text-sm">{{ readableFileSize(file.file.size) }}</div>
              </div>
              <Icon
                v-if="file.progress === 0"
                class="text-secondary hover:text-black cursor-pointer text-lg"
                type="times"
                fa-style="far"
                @click="() => removeFile(index)"
              />
              <Icon v-else-if="file.progress > 0 && file.progress < 100" type="spinner" fa-spinner class="text-lg" />
              <Icon v-else-if="file.progress === 100" type="check" fa-style="far" class="text-success text-lg" />
            </div>
            <div class="w-full flex justify-between mt-2 items-center">
              <div class="flex-grow mr-4">
                <ProgressBar :pt="{ value: { style: { backgroundColor: primaryColor } } }" :value="file.progress" style="height: 10px">{{
                  null
                }}</ProgressBar>
              </div>
              <div>{{ file.progress }}%</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="fu-buttons flex gap-2 mt-4" :class="'fu-buttons-' + buttonPosition">
      <Button severity="secondary" outlined label="Cancel" @click="cancelAll" />
      <Button
        :style="{ backgroundColor: primaryColor }"
        label="Upload"
        :disabled="!files.length || allFilesUploaded"
        @click="uploadAllFiles"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  headerTitle: {
    type: String,
    default: 'File Upload'
  },
  primaryColor: {
    type: String,
    default: 'var(--fg-brand)'
  },
  showFilePreview: {
    type: Boolean,
    default: true
  },
  apiEndpoint: {
    type: String,
    required: true
  },
  apiPostData: {
    type: Object,
    default: () => ({})
  },
  apiHeaders: {
    type: Object,
    default: () => ({})
  },
  maxFileSize: {
    type: Number,
    default: 5000000 // 5MB by default
  },
  acceptedFileTypes: {
    type: Array,
    default: () => ['image/jpeg', 'image/png', 'application/pdf']
  },
  buttonPosition: {
    type: String,
    default: 'right'
  }
})

const emit = defineEmits(['cancelAll', 'uploaded'])

import { ref, computed, watch } from 'vue'
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import ProgressBar from 'primevue/progressbar'
import axios from 'axios'
import Alert from '@/components/alert/Alert.vue'

//Validate that apiEndpoint is a valid URL
if (!props.apiEndpoint) {
  throw new Error('Invalid API endpoint URL')
}

const fileTypesMap = {
  'application/pdf': {
    text: 'PDF',
    icon: 'file-pdf'
  },
  'image/png': {
    text: 'PNG',
    icon: 'file-image'
  },
  'image/jpeg': {
    text: 'JPEG',
    icon: 'file-image'
  },
  'text/csv': {
    text: 'CSV',
    icon: 'file-csv'
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    text: 'Excel',
    icon: 'file-excel'
  },
  'application/msword': {
    text: 'Word',
    icon: 'file-word'
  },
  'application/vnd.ms-excel': {
    text: 'Excel',
    icon: 'file-excel'
  },
  'application/vnd.ms-powerpoint': {
    text: 'PowerPoint',
    icon: 'file-powerpoint'
  },
  'application/zip': {
    text: 'ZIP',
    icon: 'file-zip'
  },
  'audio/mpeg': {
    text: 'MP3',
    icon: 'file-audio'
  },
  'video/mp4': {
    text: 'MP4',
    icon: 'file-video'
  },
  default: {
    text: 'File',
    icon: 'file'
  }
}

//create a function that receives the file type and returns the corresponding mapped item
const getMappedFileType = fileType => {
  let fileExtension = fileType.split('/')[1]

  let mappedType = null

  if (fileTypesMap[fileType]) {
    mappedType = fileTypesMap[fileType]
  } else {
    mappedType = fileTypesMap.default
    mappedType.text = fileExtension.toUpperCase()
  }
  return mappedType
}

const fileInput = ref(null)
const files = ref([])
const isDragOver = ref(false)

const dragOver = () => {
  isDragOver.value = true
}

const dragLeave = () => {
  isDragOver.value = false
}

const handleDrop = event => {
  errors.value = []
  const droppedFiles = [...event.dataTransfer.files]
    .map(file => ({
      file,
      progress: 0,
      preview: getObjectURL(file)
    }))
    .filter(fileIsValid)
  files.value.push(...droppedFiles)
  isDragOver.value = false
}

const errors = ref([])

const fileIsValid = file => {
  console.log('File:', file.file.name, file.file.size, file.file.type)

  if (file.file.size > props.maxFileSize) {
    errors.value.push({
      severity: 'danger',
      summary: 'Error',
      detail: `File size of ${file.file.name} exceeds the limit of ${readableFileSize(props.maxFileSize)}`
    })
    return false
  }
  if (!props.acceptedFileTypes.includes(file.file.type)) {
    let typeMatched = getMappedFileType(file.file.type)
    errors.value.push({
      severity: 'danger',
      summary: 'Error',
      detail: `${file.file.name} file type (${typeMatched.text}) is not supported`
    })
    return false
  }
  return true
}

const handleFiles = event => {
  errors.value = []
  const selectedFiles = [...event.target.files]
    .map(file => ({
      file,
      progress: 0,
      preview: getObjectURL(file)
    }))
    .filter(fileIsValid)
  files.value.push(...selectedFiles)
}

const removeFile = index => {
  URL.revokeObjectURL(files.value[index].preview) // Clean up the object URL
  files.value.splice(index, 1)
}

const triggerFileInput = () => {
  fileInput.value.click()
}

const getObjectURL = file => {
  if (file.type.startsWith('image/')) {
    return URL.createObjectURL(file)
  }
  return null
}

const isImageFile = file => {
  return file.type.startsWith('image/')
}

const fileTypeIcon = file => {
  return fileTypesMap[file.type] ? fileTypesMap[file.type].icon : fileTypesMap.default.icon
}

const uploadFile = async fileWrapper => {
  if (fileWrapper.progress < 100) {
    const formData = new FormData()
    formData.append('file', fileWrapper.file)

    // Foreach key in apiPostData, append it to the formData
    for (const key in props.apiPostData) {
      formData.append(key, props.apiPostData[key])
    }

    try {
      const response = await axios.post(props.apiEndpoint, formData, {
        onUploadProgress: progressEvent => {
          fileWrapper.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
        withCredentials: true,
        headers: props.apiHeaders
      })
      fileWrapper.isUploaded = true

      console.log('Upload successful:', response.data)
    } catch (error) {
      console.error('Upload error:', error)
      fileWrapper.progress = 0 // Reset or handle error state
    }
  }
}

const uploadAllFiles = () => {
  files.value.forEach(file => uploadFile(file))
}

const allFilesUploaded = computed(() => {
  // Check that all files have isUploaded set to true
  return files.value.every(file => file.isUploaded)
})

watch(allFilesUploaded, newValue => {
  console.log('All files uploaded:', newValue)
  if (newValue && files.value.length > 0) {
    emit('uploaded')
  }
})

const readableFileSize = size => {
  if (size === 0) return '0 bytes'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  const num = size / Math.pow(1024, i)
  return `${num.toFixed(0)} ${['bytes', 'KB', 'MB', 'GB', 'TB'][i]}`
}

const cancelAll = () => {
  // Remove all files from the list
  files.value.forEach((file, index) => {
    URL.revokeObjectURL(file.preview) // Clean up the object URL
    files.value.splice(index, 1)
  })
  // Emit an event to notify the parent component
  emit('cancelAll')
}
</script>

<style scoped>
.file-upload-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.drop-area {
  border: 3px dashed var(--border-color);
  border-radius: var(--rounded-md);
  padding: 20px;
  margin-bottom: var(--s-4);
  text-align: center;
  cursor: pointer;
}
.drag-over {
  border-color: #000;
}
.files-list {
  overflow-y: auto;
  height: 100%;
}
.file-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--border-color);
  border-radius: var(--rounded-md);
  padding: var(--s-4);
  margin-bottom: var(--s-2);
}
.file-preview {
  margin-right: var(--s-4);
  border: 1px solid var(--border-color);
  border-radius: var(--rounded-md);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: var(--text-secondary);
  overflow: hidden;
}
.fu-buttons {
  justify-content: flex-end;
}
.fu-buttons.fu-buttons-left {
  justify-content: flex-start;
}
.fu-buttons.fu-buttons-full-width {
  justify-content: space-between;
}
.fu-buttons.fu-buttons-full-width > .btn {
  width: 100%;
}
</style>
