<template>
  <div class="w-full flex">
    <Button :label="$t('ui.actions.new')" icon="plus" @click="newValuationModalIsOpen = true" />
  </div>

  <ListGroup v-if="valuations.length" class="mt-4">
    <ListGroupItem
      v-for="valuation in valuations"
      :key="valuation.id"
      :route="valuation.type == 'part-exchange' ? '/vehicles/valuations/' + valuation.id : '/vehicles/reauction/' + valuation.id"
    >
      <div class="flex justify-between items-center">
        <div class="flex gap-4 items-center">
          <Icon type="circle-dashed" class="text-xl text-info" /><span>{{ valuationStatusTitle(valuation).title }}</span>
          <Chip size="sm">{{ valuation.type == 'part-exchange' ? 'Part Exchange' : 'Sell' }}</Chip>
        </div>
        <div v-if="valuation.updatedAt" class="text-sm text-secondary">Updated: {{ dayjs(valuation.updatedAt).fromNow() }}</div>
        <div v-else class="text-sm text-secondary">Created: {{ dayjs(valuation.createdAt).fromNow() }}</div>
      </div>
    </ListGroupItem>
  </ListGroup>

  <teleport to="#modals-container">
    <Modal
      title="New Valuation"
      description="Create a new valuation for this vehicle"
      icon="car"
      :is-open="newValuationModalIsOpen"
      ok-text="Create"
      close-text="Cancel"
      :buttons="newValuation.reason ? ['close', 'ok'] : []"
      :has-unsaved-changes="newValuationHasUnsavedChanges"
      show-loader-on-confirm
      :is-confirming="newValuationApiRequest.isLoading.value"
      min-width="400"
      @close-modal="(newValuationModalIsOpen = false), (newValuationHasUnsavedChanges = false)"
      @ok-modal="submitNewValuation()"
    >
      <FormGroup class="w-[280px]">
        <RadioBoxed v-model="newValuation.reason" :options="reasonOptions" />
      </FormGroup>
      <div v-if="newValuation.reason">
        <div class="grid grid-cols-2 gap-4 mb-4">
          <FormGroup label="Has Service History" no-margin>
            <RadioTabbed v-model="newValuation.hasServiceBook" :options="serviceOptions" full-width />
          </FormGroup>
          <FormGroup label="Full Franchised Service" no-margin>
            <RadioTabbed v-model="newValuation.hasFullFranchisedService" :options="serviceOptions" full-width />
          </FormGroup>
          <FormGroup label="Current Mileage" no-margin>
            <Input v-model="newValuation.latestMileage" icon="gauge" :has-error="v$.latestMileage.$error" />
            <InputError :has-error="v$.latestMileage.$error">{{ v$.latestMileage.$errors[0].$message }}</InputError>
          </FormGroup>
          <FormGroup label="Last Service Mileage" no-margin>
            <Input v-model="newValuation.lastServiceMileage" :has-error="v$.lastServiceMileage.$error" icon="gauge" />
            <InputError :has-error="v$.lastServiceMileage.$error">{{ v$.lastServiceMileage.$errors[0].$message }}</InputError>
          </FormGroup>
          <FormGroup label="Last Service Date" no-margin>
            <DatePicker v-model="newValuation.lastServiceDate" :config="datePickerConfig" />
          </FormGroup>
        </div>

        <div v-if="newValuation.reason === 'sell'">
          <div class="grid grid-cols-2 gap-4">
            <FormGroup label="Listing Coverage">
              <RadioTabbed
                v-model="newValuation.listingReach"
                :options="listingReachOptions"
                :has-error="v$.listingReach.$error"
                full-width
              />
              <InputError :has-error="v$.listingReach.$error">{{ v$.listingReach.$errors[0].$message }}</InputError>
            </FormGroup>
            <FormGroup label="Current Mileage" no-margin>
              <Input v-model="newValuation.latestMileage" icon="gauge" :has-error="v$.latestMileage.$error" />
              <InputError :has-error="v$.latestMileage.$error">{{ v$.latestMileage.$errors[0].$message }}</InputError>
            </FormGroup>
          </div>
          <Checkbox
            v-model="newValuation.settlementConsent"
            label="Consent given by customer for any dealership to request settlement figure"
          />
        </div>

        <FormGroup>
          <Checkbox
            v-model="newValuation.requestImagesFromCustomer"
            :readonly="userStore.roles.includes(2) || !agreementData.customer.contact.email"
            class="mt-4"
            label="Request images from customer"
          />
        </FormGroup>
        <Alert
          v-if="!agreementData.customer.contact.email"
          severity="warning"
          title="No email address - vehicle images can't be requested from customer"
          :show-close-button="false"
          :show-dismiss-button="false"
        />
      </div>
    </Modal>
  </teleport>
</template>

<script>
import { ref } from 'vue'

import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'
import DatePicker from '@/components/forms/DatePicker.vue'
import Alert from '@/components/alert/Alert.vue'
import InputError from '@/components/forms/InputError.vue'
import Modal from '@/components/modal/Modal.vue'
import RadioBoxed from '@/components/forms/RadioBoxed.vue'

import useApiRequest from '@/composables/useApiRequest'
import numberFormatter from '@/scripts/numberFormatter'
import { alertToast } from '@/utilities/notification'
import { useUserStore } from '@/stores/UserStore'

import useVuelidate from '@vuelidate/core'
import { required, numeric, minValue } from '@vuelidate/validators'

export default {
  components: {
    ListGroup,
    ListGroupItem,
    Icon,
    Button,
    Chip,
    Modal,
    FormGroup,
    Input,
    Checkbox,
    RadioTabbed,
    DatePicker,
    Alert,
    RadioBoxed,
    InputError
  },
  props: {
    agreementData: {
      type: Object,
      default: null
    }
  },
  emits: ['onSubmit'],
  setup(props, { emit }) {
    const newValuationModalIsOpen = ref(false)
    const newValuationHasUnsavedChanges = ref(false)

    const valuations = ref([])

    const valuationsApiRequest = useApiRequest()
    const newValuationApiRequest = useApiRequest()
    const updateAgreementApiRequest = useApiRequest()

    const userStore = useUserStore()

    valuationsApiRequest
      .send({
        endpoint: '/v1/valuations?agreementId=' + props.agreementData.id
      })
      .then(response => {
        valuations.value = response.data
      })

    function valuationStatusTitle(valuation) {
      if (valuation.isDeclined) {
        return { title: 'Declined', subTitle: 'by ' + valuation.declinedByName, icon: 'circle-xmark' }
      }
      if (valuation.valuation) {
        return {
          title: 'Valued at ' + numberFormatter(valuation.valuation, 'currency', 0, valuation.currencyCode),
          subTitle: 'by ' + valuation.valuedByName,
          icon: 'circle-check'
        }
      }
      if (valuation.canBeValued) {
        return { title: 'Awaiting Valuation', subTitle: null, icon: 'circle-dashed' }
      } else {
        return { title: 'Awaiting Images from Customer', subTitle: null, icon: 'circle-three-quarters-stroke' }
      }
    }

    const newValuationDefault = {
      agreementId: props.agreementData.id,
      vrm: props.agreementData.vehicle.vrm,
      countryCode: props.agreementData.countryCode,
      currencyCode: props.agreementData.currency,
      reason: null,
      latestMileage: props.agreementData.odometer.current || null,
      hasServiceBook: null,
      hasFullFranchisedService: null,
      lastServiceDate: null,
      lastServiceMileage: null,
      requestImagesFromCustomer: props.agreementData.customer.contact.email ? true : false,
      settlementConsent: false,
      listingReach: 'group'
    }
    const newValuation = ref({ ...newValuationDefault })

    // Check that this dealership has the ReAuction feature enabled
    const dealership = userStore.accessAllowed.dealerships.find(dealership => dealership.id == props.agreementData.dealership.id)

    const reasonOptions = [
      { value: 'part-exchange', label: 'Part Exchange', icon: 'right-left' },
      {
        value: 'sell',
        label: 'Sell',
        icon: 'badge-dollar',
        disabled: !dealership.features.auction,
        disabledTooltip: 'ReAuction not enabled'
      }
    ]

    const serviceOptions = [
      { label: 'Yes', value: true, description: null },
      { label: 'No', value: false, description: null },
      { label: 'Unknown', value: null, description: null }
    ]

    const listingReachOptions = [
      { label: 'Dealership', value: 'dealership', description: null },
      { label: 'Group', value: 'group', description: null },
      { label: 'National', value: 'national', description: null }
    ]

    const datePickerConfig = {
      enableTime: false,
      maxDate: Date.now(),
      dateFormat: 'Y-m-d',
      altInput: true,
      altFormat: 'd-m-Y'
    }

    const vuelidateRules = {
      lastServiceMileage: {
        numeric,
        required,
        minValue: minValue(1)
      },
      latestMileage: {
        numeric,
        required
      },
      listingReach: {
        required
      }
    }

    const v$ = useVuelidate(vuelidateRules, newValuation)
    const isFormCorrect = ref(null)

    async function submitNewValuation() {
      isFormCorrect.value = await v$.value.$validate()
      if (isFormCorrect.value) {
        // Make sure lastServiceDate is null not an empty string
        if (newValuation.value.lastServiceDate == '') {
          newValuation.value.lastServiceDate = null
        }

        newValuationApiRequest
          .send({
            endpoint: '/v1/valuations',
            method: 'POST',
            data: newValuation.value
          })
          .then(response => {
            if (!response.success) {
              alertToast('Failed to Create', response.data?.message, 'error')
              return
            }
            alertToast('Valuation created', null, 'success')
            newValuationModalIsOpen.value = false
            newValuationHasUnsavedChanges.value = false
            newValuation.value = { ...newValuationDefault }
            // re-fetch valuations
            valuationsApiRequest
              .send({
                endpoint: '/v1/valuations?agreementId=' + props.agreementData.id
              })
              .then(response => {
                valuations.value = response.data
              })
          })

        updateAgreementApiRequest.send({
          endpoint: '/v1/agreements/' + props.agreementData.id,
          method: 'PATCH',
          data: { odometerCurrent: newValuation.value.latestMileage }
        })

        emit('onSubmit', newValuation.value)
      }
    }

    return {
      valuations,
      valuationStatusTitle,
      newValuation,
      newValuationModalIsOpen,
      newValuationHasUnsavedChanges,
      reasonOptions,
      serviceOptions,
      listingReachOptions,
      submitNewValuation,
      datePickerConfig,
      newValuationApiRequest,
      v$,
      userStore
    }
  }
}
</script>

<style></style>
