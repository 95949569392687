<template>
  <teleport to="#modals-container">
    <ShiftTemplateHandler
      :key="sidebarKey"
      :is-open="shiftHandlerIsOpen"
      :shift="shiftHandlerData"
      :users="users"
      @close-shift-handler="shiftHandlerToggle()"
      @add-new-shift-submit="newShiftSubmit"
      @update-shift-submit="updateShiftSubmit"
      @delete-shift="deleteShift"
    />
  </teleport>

  <PageHeader
    :title="$t('ui.entities.schedule.template.label')"
    breadcrumb
    :items="[{ label: $t('ui.entities.setting', 2), to: '/settings' }, { label: $t('ui.entities.schedule.template.label') }]"
  ></PageHeader>

  <div>
    <Card>
      <div class="schedule-header grid" :style="'grid-template-columns: repeat(' + (columns.length + 2) + ', minmax(0, 1fr));'">
        <div class="schedule-header-cell col-span-2"></div>
        <div v-for="column in columns" :key="column" class="schedule-header-cell text-center">
          <div>{{ $t('ui.common.date-time.day-of-week.' + column) }}</div>
          <div v-tippy="{ content: $t('ui.entities.schedule.productive-hours') }" class="text-xs">
            {{ getDayProductiveHours(column) }} <span class="text-secondary">{{ $t('ui.common.date-time.hr', 2) }}</span>
          </div>
        </div>
      </div>
      <div
        v-for="user in users"
        :key="user.id"
        class="schedule-row grid"
        :style="'grid-template-columns: repeat(' + (columns.length + 2) + ', minmax(0, 1fr));'"
      >
        <div class="schedule-row-side col-span-2 flex justify-between">
          <div class="flex justify-between items-center">
            <Avatar size="md" :url="user.profilePicURL" :text="user.initials" :user-presence-user-id="user.id" />
            <span class="ml-3">{{ user.fullName }}</span>
          </div>
          <div v-tippy="{ content: $t('ui.entities.schedule.productive-hours') }" class="text-sm">
            {{ getWeekProductiveHours(user.id) }} <span class="text-secondary">{{ $t('ui.common.date-time.hr', 2) }}</span>
          </div>
        </div>
        <div
          v-for="column in columns"
          :key="column"
          class="drop-block"
          @drop="onDrop($event, user.id, column)"
          @dragenter.prevent
          @dragover.prevent
          @dragenter="highlightDrop($event)"
          @dragleave="unhighlightDrop($event)"
          @click.self="addShift($event, user.id, column)"
        >
          <div v-if="!getShiftsRequest.isLoading.value && getShift(user.id, column)?.length > 0">
            <div
              v-for="shift in getShift(user.id, column)"
              :key="shift.id"
              class="shift-block"
              draggable="true"
              @dragstart="startDrag($event, shift)"
              @click="shiftHandlerToggle(shift)"
            >
              <div class="shift-block-delete" @click.stop="deleteShift(shift)">
                <Icon type="trash" />
              </div>
              <div class="font-medium text-[14px] flex justify-center items-center gap-1">
                {{ formatShiftTime(shift.start) }}
                <Icon type="arrow-right" class="text-secondary text-xs" />
                <!-- <span class="text-secondary">-</span> -->
                {{ formatShiftTime(shift.end) }}
              </div>
              <div class="text-[13px] text-slate-400 flex justify-center gap-4">
                <span class="flex items-center">
                  <Icon type="business-time" class="text-[10px] mr-[2px]" />
                  <span>{{ shift.productiveHours }}</span>
                </span>
                <span class="flex items-center">
                  <Icon type="phone" class="text-[10px] mr-[2px]" />
                  <span>{{ shift.callTarget }}</span>
                </span>
                <span class="flex items-center">
                  <Icon type="calendar" class="text-[10px] mr-[2px]" />
                  <span>{{ shift.appointmentTarget }}</span>
                </span>
              </div>
            </div>
          </div>
          <!-- todo add skeleton -->
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import ShiftTemplateHandler from '@/components/unique/ShiftTemplateHandler.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Icon from '../../../components/icon/Icon.vue'
import Avatar from '../../../components/avatar/Avatar.vue'

import { alertToast } from '@/utilities/notification'
import useApiRequest from '@/composables/useApiRequest'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

export default {
  components: {
    PageHeader,
    Card,
    Icon,
    Avatar,
    ShiftTemplateHandler
  },
  setup() {
    const shifts = ref(null)
    const users = ref(null)

    const getShiftsRequest = useApiRequest()
    const getUsersRequest = useApiRequest()
    const deleteShiftRequest = useApiRequest()
    const copyShiftRequest = useApiRequest()
    const moveShiftRequest = useApiRequest()

    const { t } = useI18n()
    const sidebarKey = ref(Math.random())

    //TODO - Use isLoading on all data to show skeleton and avoid errors

    function fetchUsers() {
      try {
        getUsersRequest
          .send({
            method: 'GET',
            endpoint: '/v1/users',
            params: {
              userType: 'retainUsers',
              forSchedule: true
            }
          })
          .then(response => {
            if (response.success) {
              users.value = response.data
              // Order users by name
              users.value?.sort((a, b) => {
                return a.fullName.localeCompare(b.fullName)
              })
            } else {
              alertToast('Failed to fetch users', response.message, 'error')
            }
          })
      } catch (err) {
        alertToast('Failed to fetch users', err.message, 'error')
      }
    }
    fetchUsers()

    function fetchShifts() {
      try {
        getShiftsRequest.send({ method: 'GET', endpoint: '/v1/users/schedule/template' }).then(response => {
          if (response.success) {
            shifts.value = response.data
          } else {
            alertToast('Failed to fetch shifts', response.message, 'error')
          }
        })
      } catch (err) {
        alertToast('Failed to fetch shifts', err.message, 'error')
      }
    }
    fetchShifts()

    const columns = ref(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'])

    const getShift = (userId, column) => {
      let filteredShifts = shifts.value?.filter(shift => shift.userId == userId && shift.dayOfWeek == column)

      // Sort the filtered shifts by start time
      filteredShifts?.sort((a, b) => {
        return a.start.localeCompare(b.start)
      })

      return filteredShifts
    }

    const getWeekProductiveHours = userId => {
      let userShifts = shifts.value?.filter(shift => shift.userId == userId)
      let weekHours = userShifts?.reduce((n, { productiveHours }) => n + productiveHours, 0)

      if (weekHours > 0) {
        return weekHours
      } else {
        return 0
      }
    }

    const getDayProductiveHours = column => {
      let dayShifts = shifts.value?.filter(shift => shift.dayOfWeek == column)
      let dayHours = dayShifts?.reduce((n, { productiveHours }) => n + productiveHours, 0)

      if (dayHours > 0) {
        return dayHours
      } else {
        return 0
      }
    }

    const startDrag = (event, shift) => {
      if (event.ctrlKey) {
        //Copy
        event.dataTransfer.dropEffect = 'copy'
        event.dataTransfer.effectAllowed = 'copy'
      } else {
        //Move
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.effectAllowed = 'move'
      }
      event.dataTransfer.setData('shiftID', shift.id)
    }

    function copyShift(event, shiftData) {
      //Show spinner
      event.target.classList.add('drop-block-spinner')

      try {
        copyShiftRequest.send({ method: 'POST', endpoint: '/v1/users/schedule/template/', data: shiftData }).then(response => {
          if (response.data != '' && response.success) {
            //Add shift to shifts array
            shifts.value.push(response.data)
            alertToast('Copied', null, 'success')

            //Hide spinner
            event.target.classList.remove('drop-block-spinner')
          } else {
            alertToast('Failed to copy the shift', response.message, 'error')
            throw Error('Error posting data')
          }
        })
      } catch (err) {
        alertToast('Failed to copy the shift', err.message, 'error')
      }
    }

    function moveShift(data, userId, column) {
      let updatedData = { ...data }
      updatedData.userId = userId
      updatedData.dayOfWeek = column

      try {
        moveShiftRequest
          .send({ method: 'PATCH', endpoint: '/v1/users/schedule/template/' + updatedData.id, data: updatedData })
          .then(response => {
            if (response.data && response.success) {
              data.dayOfWeek = column
              data.userId = userId

              alertToast('Updated', response.message, 'success')
            } else {
              alertToast('Failed to update', response.message, 'error')
              throw Error('Error patching data')
            }
          })
      } catch (err) {
        alertToast('Failed to update', err.message, 'error')
      }
    }

    const highlightDrop = event => {
      // console.log(event.target)
      event.target.classList.add('dragover')
    }
    const unhighlightDrop = event => {
      event.target.classList.remove('dragover')
    }
    const onDrop = (event, userId, column) => {
      const shiftID = event.dataTransfer.getData('shiftID')
      const shift = shifts.value.find(shift => shift.id == shiftID)
      if (event.shiftKey) {
        //Copy shift
        let newShift = { ...shift }
        newShift.userId = userId
        newShift.dayOfWeek = column

        //Create new shift in DB
        copyShift(event, newShift)
      } else {
        //Move shift
        moveShift(shift, userId, column)
      }
      event.target.classList.remove('dragover')
    }
    const formatShiftTime = time => {
      return dayjs(time, 'hh:mm:ss').format('HH:mm')
    }

    const shiftHandlerIsOpen = ref(false)
    const shiftHandlerData = ref()

    const addShift = (event, userId, dayOfWeek) => {
      // console.log(event.target)
      shiftHandlerData.value = { userId: userId, dayOfWeek: dayOfWeek }
      shiftHandlerToggle()
    }

    function shiftHandlerToggle(shift) {
      sidebarKey.value = Math.random() // Change key so sidebar will re-mount
      shiftHandlerIsOpen.value = !shiftHandlerIsOpen.value

      if (shift) {
        //If update shift
        shiftHandlerData.value = shift
      }
    }

    function newShiftSubmit(data) {
      shifts.value.push(data)
      shiftHandlerToggle()
    }

    function updateShiftSubmit(data) {
      // Update shift with new data
      const shiftIndex = shifts.value.findIndex(x => x.id === data.id)
      shifts.value[shiftIndex] = data

      shiftHandlerToggle()
    }

    async function deleteShift(shift) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this shift!',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fa fa-trash"></i> ' + t('ui.crud.delete'),
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: t('ui.actions.cancel'),
        cancelButtonColor: 'var(--bg-tertiary)'
      }).then(function (response) {
        if (response.isConfirmed) {
          try {
            deleteShiftRequest.send({ method: 'DELETE', endpoint: '/v1/users/schedule/template/' + shift.id }).then(response => {
              if (response.data != '' && response.success) {
                alertToast('Deleted', null, 'success')

                const shiftIndex = shifts.value.findIndex(x => x.id === shift.id)
                shifts.value.splice(shiftIndex, 1)
                shiftHandlerIsOpen.value = false
              } else {
                alertToast('Failed to delete shift', response.message, 'error')
                throw Error('Error deleting data')
              }
            })
          } catch (err) {
            alertToast('Failed to delete shift', err.message, 'error')
          }
        } else {
          shiftHandlerToggle()
        }
      })
    }

    return {
      users,
      columns,
      shifts,
      getShift,
      getWeekProductiveHours,
      getDayProductiveHours,
      addShift,
      formatShiftTime,
      startDrag,
      highlightDrop,
      unhighlightDrop,
      onDrop,
      shiftHandlerIsOpen,
      sidebarKey,
      shiftHandlerToggle,
      shiftHandlerData,
      newShiftSubmit,
      updateShiftSubmit,
      deleteShift,
      getShiftsRequest
    }
  }
}
</script>
