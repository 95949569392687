<template>
  <div class="chip-list">
    <!-- Render the visible chips -->
    <Chip v-for="(item, index) in shownItems" :key="index" v-bind="item" />

    <!-- Render the overflow chip if there are extra items -->
    <template v-if="overflowItems.length > 0">
      <tippy interactive>
        <Chip v-bind="overflowChipProps"> +{{ overflowItems.length }} </Chip>
        <template #content>
          <div class="overflow-chips">
            <Chip v-for="(item, index) in overflowItems" :key="index" v-bind="item" />
          </div>
        </template>
      </tippy>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Chip from '@/components/chip/Chip.vue'
import { Tippy } from 'vue-tippy'

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  maxShown: {
    type: Number,
    default: 3
  },
  showOverflowInTippy: {
    type: Boolean,
    default: true
  },
  overflowChipProps: {
    type: Object,
    default: () => ({
      rounded: true
    })
  }
})

const shownItems = computed(() => {
  return props.items.slice(0, props.maxShown)
})

const overflowItems = computed(() => {
  return props.items.slice(props.maxShown)
})
</script>

<style>
.chip-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--s-1);
}

.overflow-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; /* Adjust as needed */
}
</style>
