<template>
  <div
    v-tippy="tooltip"
    class="chip"
    :class="[
      'chip-' + severity,
      { 'chip-rounded': rounded, 'chip-outlined': outlined, 'chip-no-background': noBackground, 'chip-plain': plain, 'chip-dot': dot },
      'chip-' + size
    ]"
    :style="color ? { color: color, backgroundColor: addOpacityToHexColor(color, 0.1) } : {}"
  >
    <Icon v-if="icon != null && icon != ''" :type="icon" class="mr-1 text-sm" />
    <Icon v-if="dot" type="circle" class="mr-1" :class="['text-[6px]']" fa-style="fas"></Icon>
    <Avatar v-if="avatar || avatarText" :url="avatar" :text="avatarText" style="margin-inline-start: -10px" class="mr-2" size="xs"></Avatar>
    <slot /> {{ label }}
    <Icon
      v-if="canRemove"
      type="xmark"
      fa-style="fad"
      style="margin-inline-end: -5px"
      class="ml-1 cursor-pointer text-sm"
      @click="removeChip"
    />
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import Avatar from '@/components/avatar/Avatar.vue'
export default {
  name: 'Chip',
  components: {
    Icon,
    Avatar
  },
  props: {
    label: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    severity: {
      type: String,
      default: 'default'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    icon: {
      type: String,
      default: null
    },
    avatar: {
      type: String,
      default: null
    },
    avatarText: {
      type: String,
      default: null
    },
    canRemove: {
      type: Boolean,
      default: false
    },
    noBackground: {
      type: Boolean,
      default: false
    },
    dot: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  emits: ['removeChip'],
  setup(props, { emit }) {
    function removeChip(event) {
      if (event) {
        emit('removeChip', event)
      } else {
        emit('removeChip')
      }
    }

    function addOpacityToHexColor(hex, opacity) {
      hex = hex.replace('#', '')
      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)
      return `rgba(${r},${g},${b},${opacity})`
    }

    return { removeChip, addOpacityToHexColor }
  }
}
</script>

<style>
.chip {
  align-items: center;
  display: inline-flex;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  border-style: solid;
  border-width: 1;
  border: 1px solid;
  border-radius: var(--rounded-md);
}

.chip-xs {
  font-size: var(--text-xs);
  padding: 0 var(--s-2);
}
.chip-sm {
  font-size: var(--text-sm);
  padding: 0 var(--s-2);
  line-height: 1.5;
}
.chip-md {
  font-size: var(--text-md);
  padding: 0 var(--s-3);
}
.chip-lg {
  font-size: var(--text-lg);
  padding: 0 var(--s-3);
}

.chip-rounded {
  border-radius: var(--rounded-full);
}

.chip-outlined {
  background: transparent !important;
}
.chip-outlined:not(.chip-label) {
  border-width: 1.5px;
}
.chip-no-background {
  background: transparent !important;
}

/* .dark .chip-outlined {
  border: 1px solid var(--darkmode-3);
} */

.avatar .chip {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 0.7rem;
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon .chip {
  position: absolute;
  top: -8px;
  right: -11px;
  font-size: 0.7rem;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip-primary,
.chip-default {
  background-color: var(--utility-gray-50);
  border-color: var(--utility-gray-200);
  color: var(--text-secondary);
}
.chip-primary.chip-outlined,
.chip-default.chip-outlined {
  color: var(--text-secondary);
  border-color: var(--utility-gray-600);
}
.chip-primary.chip-outlined,
.chip-default.chip-outlined {
  color: var(--text-secondary);
  border-color: var(--border-quaternary);
}

.chip-brand {
  background-color: var(--utility-brand-50);
  border-color: var(--utility-brand-200);
  color: var(--text-brand);
}
.chip-brand.chip-outlined {
  color: var(--text-brand);
  border-color: var(--utility-brand-200);
}

.chip-warning {
  background-color: var(--utility-warning-50);
  border-color: var(--utility-warning-200);
  color: var(--text-warning);
}
.chip-warning.chip-outlined {
  color: var(--text-warning);
  border-color: var(--utility-warning-200);
}

.chip-danger {
  background-color: var(--utility-error-50);
  border-color: var(--utility-error-200);
  color: var(--text-error);
}
.chip-danger.chip-outlined {
  color: var(--text-error);
  border-color: var(--utility-error-200);
}

.chip-success {
  background-color: var(--utility-success-50);
  color: var(--text-success);
}
.chip-success.chip-outlined {
  color: var(--text-success);
}

.chip-info {
  background-color: var(--utility-purple-50);
  border-color: var(--utility-purple-200);
  color: var(--text-info);
}
.chip-info.chip-outlined {
  color: var(--text-info);
  border-color: var(--utility-purple-200);
}

/* Label Outlined - Remove Background and Keep Border */
.chip-rounded.chip-outlined {
  border-color: var(--border-primary);
  color: var(--text-secondary);
}

/* Chip Dot */
.chip-outlined.chip-dot {
  border-color: var(--border-primary);
  color: var(--text-primary);
}

.chip-brand.chip-outlined.chip-dot > .icon {
  color: var(--text-brand);
}
.chip-primary.chip-outlined.chip-dot > .icon {
  color: var(--text-primary);
}
.chip-warning.chip-outlined.chip-dot > .icon {
  color: var(--text-warning);
}
.chip-danger.chip-outlined.chip-dot > .icon {
  color: var(--text-error);
}
.chip-success.chip-outlined.chip-dot > .icon {
  color: var(--text-success);
}
.chip-info.chip-outlined.chip-dot > .icon {
  color: var(--text-info);
}

/* Chip Plain */
.chip-plain {
  background-color: transparent;
  border-color: transparent;
  color: var(--text-secondary);
}
.chip-brand.chip-plain {
  color: var(--text-brand);
}
.chip-primary.chip-plain,
.chip-default.chip-plain {
  color: var(--text-secondary);
}
.chip-warning.chip-plain {
  color: var(--text-warning);
}
.chip-danger.chip-plain {
  color: var(--text-error);
}
.chip-success.chip-plain {
  color: var(--text-success);
}
.chip-info.chip-plain {
  color: var(--text-info);
}
</style>
