<template>
  <PageHeader
    :title="$t('ui.entities.dealership', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.entities.dealership', 2), to: '/settings/dealerships' }
    ]"
  >
    <template #right>
      <Button
        v-if="$can('create_dealerships')"
        :label="$t('ui.crud.new', { item: $t('ui.entities.dealership') })"
        severity="primary"
        icon="plus"
        @click="newDealershipModalIsOpen = true"
      />
    </template>
  </PageHeader>

  <Card>
    <DealershipsTable v-model="dealershipsComputed" :is-loading="dealershipsAreLoading" @dealership-updated="dealershipDataUpdated" />
  </Card>

  <teleport to="#modals-container">
    <Modal
      :is-open="newDealershipModalIsOpen"
      title="New Dealership"
      icon="car-building"
      :buttons="['ok', 'close']"
      :has-unsaved-changes="newDealershipHasUnsavedChanges"
      close-text="Cancel"
      ok-text="Save"
      :show-loader-on-confirm="true"
      :is-confirming="false"
      min-width="650"
      overflow-behaviour="visible"
      @close-modal="closeNewDealershipModal"
      @ok-modal="submitNewDealership"
    >
      <div class="grid grid-cols-2 gap-x-6">
        <FormGroup :label="$t('ui.common.name')" :is-required="v$.name.required?.$invalid">
          <Input v-model="newDealership.name" :has-error="v$.name.$error" />
          <InputError :has-error="v$.name.$error">{{ v$.name.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.common.display-name')" :is-required="v$.displayName.required?.$invalid">
          <Input v-model="newDealership.displayName" :has-error="v$.displayName.$error" />
          <InputError :has-error="v$.displayName.$error">{{ v$.displayName.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.common.location-name')" :is-required="v$.locationName.required?.$invalid">
          <Input v-model="newDealership.locationName" :has-error="v$.locationName.$error" />
          <InputError :has-error="v$.locationName.$error">{{ v$.locationName.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.common.date-time.timezone')" :is-required="v$.timezone.required">
          <Multiselect
            v-model="newDealership.timezone"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="timezoneList"
            :can-deselect="false"
            :class="{ 'has-error': v$.timezone.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.timezone.$error">{{ v$.timezone.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.manufacturer')" :is-required="v$.manufacturerId.required">
          <Multiselect
            v-model="newDealership.manufacturerId"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="manufacturerOptions"
            :can-deselect="false"
            :class="{ 'has-error': v$.manufacturerId.$error }"
            @select="selectManufacturer($event)"
          >
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img :src="value.logo" width="25" class="mr-3" />
                {{ value.label }}
              </div>
            </template>
            <template #option="{ option }">
              <img :src="option.logo" width="20" class="mr-3" />
              {{ option.label }}
            </template>
          </Multiselect>
          <InputError :has-error="v$.manufacturerId.$error">{{ v$.manufacturerId.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.group')" :is-required="v$.groupId.required">
          <Multiselect
            v-model="newDealership.groupId"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="groupOptions"
            :can-deselect="false"
            :class="{ 'has-error': v$.groupId.$error }"
            @select="selectGroup($event)"
          >
          </Multiselect>
          <InputError :has-error="v$.groupId.$error">{{ v$.groupId.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup label="Send Emails From" :is-required="v$.email.required">
          <Multiselect
            v-model="newDealership.email"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="[
              { value: 'contact@ms.retainauto.com', label: 'Main' },
              { value: 'admin@finance.triumph.co.uk', label: 'Triumph UK' },
              { value: 'upgrades@finance.triumphmotorcycles.com', label: 'Triumph US' }
            ]"
            :can-deselect="false"
            :class="{ 'has-error': v$.email.$error }"
            @select="selectGroup($event)"
          >
            <template #option="{ option }">
              <div>
                <div>{{ option.label }}</div>
                <div class="text-sm text-quaternary">{{ option.value }}</div>
              </div>
            </template>
          </Multiselect>
          <InputError :has-error="v$.email.$error">{{ v$.email.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup label="Send Sms From" :is-required="v$.sendSmsFrom.required">
          <Multiselect
            v-model="newDealership.sendSmsFrom"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="[
              { value: '441183246086', label: 'UK' },
              { value: '18336604554', label: 'US' }
            ]"
            :can-deselect="false"
            :class="{ 'has-error': v$.sendSmsFrom.$error }"
          >
            <template #option="{ option }">
              <div>
                <div>{{ option.label }}</div>
                <div class="text-sm text-quaternary">{{ option.value }}</div>
              </div>
            </template>
          </Multiselect>
          <InputError :has-error="v$.sendSmsFrom.$error">{{ v$.sendSmsFrom.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </Modal>
  </teleport>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Modal from '@/components/modal/Modal.vue'
import DealershipsTable from '@/components/unique/tables/DealershipsTable.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'

import { alertToast } from '@/utilities/notification'

import { useRouter } from 'vue-router'
import useApiRequest from '@/composables/useApiRequest'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const router = useRouter()
const userStore = useUserStore()

const dealershipsApiRequest = useApiRequest()
const newDealershipApiRequest = useApiRequest()
const newDealershipHasUnsavedChanges = ref(false)

const dealershipQueryparams = ref({
  showAll: true,
  showDisabled: true
})

if (userStore.details.type == 2) {
  const dealershipIDs = userStore.accessAllowed.dealerships.map(dealership => dealership.id)
  const dealershipIDsString = dealershipIDs.join(',')

  dealershipQueryparams.value.ids = dealershipIDsString
}

dealershipsApiRequest
  .send({
    endpoint: '/v1/dealerships',
    params: dealershipQueryparams.value
  })
  .then(() => {})

const dealershipsComputed = computed(() => {
  let dealershipsArray = dealershipsApiRequest.data.value
  if (!dealershipsArray) {
    return []
  }

  dealershipsArray.forEach(d => {
    if (d.assignedPhoneNumber != null) {
      d.assignedPhoneNumber.numberFull = '+' + d.assignedPhoneNumber.countryCode + ' ' + d.assignedPhoneNumber.number
    }
  })

  return dealershipsArray
})

function dealershipDataUpdated(dealershipId, newData) {
  console.log('Dealership data updated')
  console.log(dealershipId, newData)

  let dealership = dealershipsApiRequest.data.value.find(d => d.id == dealershipId)
  // Merge updated data with existing data
  Object.assign(dealership, newData)
}

const newDealershipModalIsOpen = ref(false)

const newDealershipInitState = ref({
  name: null,
  displayName: null,
  locationName: null,
  manufacturerId: null,
  manufacturerName: null,
  groupId: null,
  groupName: null,
  timezone: null,
  email: null,
  sendSmsFrom: null,
  createdBy: userStore.details.id
})

const newDealership = ref({ ...newDealershipInitState.value })

const inputRules = {
  name: { required },
  displayName: { required },
  locationName: { required },
  manufacturerId: { required },
  groupId: { required },
  email: { required },
  sendSmsFrom: { required },
  timezone: { required }
}

const manufacturerOptions = ref([])
const groupOptions = ref([])

try {
  useApiRequest()
    .send({ endpoint: '/v1/manufacturers' })
    .then(response => {
      if (response.http.status == 200) {
        manufacturerOptions.value = response.data.map(manufacturer => {
          return {
            value: manufacturer.id,
            label: manufacturer.name,
            logo: manufacturer.logoUrl
          }
        })
      } else {
        alertToast('Failed to fetch manufacturers', response.message, 'error')
      }
    })
} catch (err) {
  alertToast('Failed to fetch manufacturers', err.message, 'error')
}

try {
  useApiRequest()
    .send({ endpoint: '/v1/groups' })
    .then(response => {
      if (response.http.status == 200) {
        console.log(response.data)
        groupOptions.value = response.data.map(group => {
          return {
            value: group.id,
            label: group.name,
            logo: group.logoUrl
          }
        })
      } else {
        alertToast('Failed to fetch groups', response.message, 'error')
      }
    })
} catch (err) {
  alertToast('Failed to fetch groups', err.message, 'error')
}

const timezoneList = ref([])

try {
  axios.get('https://api.timezonedb.com/v2.1/list-time-zone?key=PJ3WFAM872HM&format=json', { withCredentials: false }).then(response => {
    if (response.data.status == 'OK') {
      timezoneList.value = response.data.zones.map(timezone => {
        return {
          value: timezone.zoneName,
          label: timezone.zoneName
        }
      })
    } else {
      alertToast('Failed to fetch timezones', response.message, 'error')
    }
  })
} catch (err) {
  alertToast('Failed to fetch timezones', err.message, 'error')
}

function selectManufacturer(id) {
  newDealership.value.manufacturerName = manufacturerOptions.value.find(option => option.value == id).label
}
function selectGroup(id) {
  newDealership.value.groupName = groupOptions.value.find(option => option.value == id).label
}

const v$ = useVuelidate(inputRules, newDealership)
const isFormCorrect = ref(null)

async function submitNewDealership() {
  isFormCorrect.value = await v$.value.$validate()
  if (isFormCorrect.value) {
    try {
      newDealershipApiRequest
        .send({
          endpoint: '/v1/dealerships',
          method: 'POST',
          data: JSON.stringify(newDealership.value)
        })
        .then(response => {
          if (response.success) {
            router.push('/settings/dealerships/' + response.data.id)
          } else {
            alertToast('Failed to add', response.data?.message, 'error')
          }
        })
    } catch (err) {
      alertToast('Failed to add', err.message, 'error')
    }
  }
}

function closeNewDealershipModal() {
  newDealershipModalIsOpen.value = false
  newDealership.value = { ...newDealershipInitState.value }
  newDealershipHasUnsavedChanges.value = false
}

watch(
  () => newDealership.value,
  () => {
    newDealershipHasUnsavedChanges.value = true
  },
  { deep: true }
)
</script>

<style></style>
