<template>
  <div v-if="steps.length > 0">
    <div class="text-secondary text-sm mb-2">{{ currentStep + 1 }} / {{ steps.length }}</div>
    <!-- Below is for Tabbed style -->
    <!-- <div v-for="(step, index) in steps" :key="step.label">
      <h1 @click="setCurrentStep(index)">{{ step.title }}</h1>
    </div> -->
    <h1 class="mb-8">{{ steps[currentStep].label }}</h1>

    <slot :name="`step-${currentStep + 1}`"></slot>

    <div class="grid grid-cols-2 gap-8 mt-8">
      <div>
        <Button
          v-if="currentStep > 0"
          :label="$t('ui.actions.previous')"
          severity="secondary"
          outlined
          icon="arrow-left"
          @click="move(-1)"
        />
      </div>
      <div class="flex justify-end">
        <Button
          v-if="currentStep < steps.length - 1"
          :label="$t('ui.actions.next')"
          icon="arrow-right"
          icon-pos="right"
          @click="beforeMove(1)"
        />

        <div v-if="slots.completeAction && currentStep === steps.length - 1">
          <slot name="completeAction"></slot>
        </div>
        <Button
          v-else-if="currentStep === steps.length - 1"
          :label="$t('ui.actions.complete')"
          icon="arrow-right"
          icon-pos="right"
          :is-loading="isSubmitting"
          @click="emitComplete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useSlots } from 'vue'
import Button from '@/components/button/Button.vue'

export default {
  components: {
    Button
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    steps: {
      type: Array,
      default: () => []
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    variation: {
      type: String,
      default: 'indexTitle'
    },
    startStep: {
      type: Number,
      default: null
    }
  },
  emits: ['before-move', 'on-complete'],
  setup(props, { emit }) {
    // console.log(props.startStep)
    const currentStep = ref(props.startStep ?? 0)
    const slots = useSlots()

    const beforeMove = step => {
      emit('before-move', step)
    }
    const move = step => {
      currentStep.value += step
    }

    const setCurrentStep = index => {
      currentStep.value = index
    }

    const emitComplete = () => {
      emit('on-complete')
    }

    return {
      currentStep,
      beforeMove,
      move,
      setCurrentStep,
      emitComplete,
      slots
    }
  }
}
</script>

<style></style>
