<template>
  <div class="bg-container">
    <img class="blob blob-1" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-1.svg'" />
    <img class="blob blob-2" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-2.svg'" />
    <img class="blob blob-3" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-3.svg'" />
  </div>
  <div class="auth-wrapper">
    <div class="auth-card">
      <form v-if="tokenIsValid" @submit.prevent>
        <h4 class="mb-5 font-medium text-gray-600">Set a Password 🔒</h4>

        <FormGroup label="New Password">
          <Input v-model="newPasswordObject.password" required type="password" />
          <InputError :has-error="v$.password.$error">{{ v$.password.$errors[0]?.$message }}</InputError>
          <PasswordMeter :password="newPasswordObject.password" @score="s => (newPasswordObject.quality = s)" />
        </FormGroup>

        <FormGroup label="Confirm Password">
          <Input v-model="newPasswordObject.passwordConfirm" required type="password" />
          <InputError :has-error="v$.passwordConfirm.$error">{{ v$.passwordConfirm.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <Button label="Set New Password" type="submit" block :is-loading="isSubmitting" class="mt-5" @click="submit" />
      </form>

      <div v-else>
        <div class="flex items-center text-xl justify-center">
          <Icon type="circle-exclamation" class="text-red-500 mr-2" /> Verification Token is invalid.
        </div>
        <div class="mt-4 text-center">
          <a href="mailto:support@retain.group" target="_blank" class="text-primary">Contact Support</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// REFACTOR: Convert to use UseApiRequest()
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'

import PasswordMeter from 'vue-simple-password-meter'

import useVuelidate from '@vuelidate/core'
import { required, sameAs, helpers } from '@vuelidate/validators'
import { alertToast } from '@/utilities/notification'

export default {
  name: 'VerifyAccount',
  components: {
    FormGroup,
    Input,
    InputError,
    Button,
    Icon,
    PasswordMeter
  },
  setup() {
    const router = useRouter()
    const verificationToken = router.currentRoute.value.params.token

    const tokenIsValid = ref(false)

    const isSubmitting = ref(false)

    async function validateVerificationToken() {
      const response = await axios.post(
        import.meta.env.VITE_API_BASE_URL + '/v1/auth/validate/verification-token',
        { token: verificationToken },
        {
          HTTP_X_API_ACCESS_TOKEN:
            'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
        }
      )

      if (response.data.response.status == 'error') {
        return
      }

      tokenIsValid.value = response.data.data.valid
    }
    validateVerificationToken()

    const newPasswordObject = ref({
      password: null,
      passwordConfirm: null,
      quality: null
    })

    const newPasswordRules = {
      password: {
        required,
        maxLength: 64,
        isStrong: helpers.withMessage('Password not strong enough. Score: ', () => {
          return newPasswordObject.value.quality?.score == 4
        })
      },
      passwordConfirm: { required, sameAsPassword: sameAs(computed(() => newPasswordObject.value.password)) }
    }

    const v$ = useVuelidate(newPasswordRules, newPasswordObject)

    async function submit() {
      const isFormCorrect = await v$.value.$validate()

      // console.log('FormCorrect:' + isFormCorrect, v$.value)
      if (isFormCorrect) {
        // Axios POST to API /v1/auth/validate/set-password
        isSubmitting.value = true

        const response = await axios.post(
          import.meta.env.VITE_API_BASE_URL + '/v1/auth/validate/set-password',
          { token: verificationToken, password: newPasswordObject.value.password },
          {
            HTTP_X_API_ACCESS_TOKEN:
              'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
          }
        )

        if (response.data.response.status == 'error') {
          alertToast('Failed to set password', response.data.response.message, 'error')
        } else {
          router.push('/login')
        }
      }
    }

    return { newPasswordObject, v$, tokenIsValid, submit, isSubmitting }
  }
}
</script>

<style scoped>
body,
html {
  height: 100%;
  font-size: 14px;
}

#app {
  height: 100%;
}

.auth-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ecf8fc;
  height: 100vh;
}

.auth-card {
  background: #fff;
  min-width: 400px;
  max-width: 500px;
  margin: 1rem 0rem;
  padding: 3rem;
  box-shadow: var(--shadow-md);
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.04);
}

.bg-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.blob {
  position: absolute;
}

.blob-1 {
  top: -20%;
  right: 0;
  width: 40%;
  animation: moveBlobs1 8s infinite;
}

.blob-2 {
  top: -20%;
  left: 0;
  width: 20%;
  animation: moveBlobs2 7s infinite;
}

.blob-3 {
  bottom: -50%;
  left: 0;
  width: 80%;
  animation: moveBlobs3 6s infinite;
}

@keyframes moveBlobs1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 10px) scale(1.01);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveBlobs2 {
  0% {
    transform: translate(0, 0) scale(1.01);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  100% {
    transform: translate(0, 0) scale(1.01);
  }
}

@keyframes moveBlobs3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, -10px) scale(1.01);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
