<template>
  <PageHeader :title="$t('ui.entities.used-stock')">
    <template #right>
      <div class="flex items-center gap-2">
        <div v-if="dealershipsAllowed.length > 1">
          <MultiSelect
            v-model="dealershipsSelected"
            :options="dealershipsAllowed"
            display="chip"
            filter
            option-value="id"
            option-label="name"
            placeholder="Select a Dealership"
            class="max-w-[350px]"
          >
          </MultiSelect>
        </div>
        <div v-else class="flex">
          <Icon type="map-marker" class="mr-1" />
          <div>{{ dealershipsAllowed[0].name }}</div>
        </div>
        <Button class="flex-shrink-0" label="Update" :is-loading="vehiclesRequest.isLoading.value" @click="onDealershipChange" />
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Used Stock"
      :value="vehicles"
      :loading="false"
      removable-sort
      sort-field="id"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-vehicles-stock-used"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'vehicle', 'fuelType', 'transmission', 'location', 'price']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilter()"
            />
          </span>
        </div>
      </template>

      <Column field="id" header="#" :sortable="true" hidden>
        <template #body="{ data }">
          <div class="link" @click="$router.push('/vehicles/stock/used/' + data.id)">{{ data.id }}</div>
        </template>
      </Column>

      <Column field="model" header="Vehicle" :sortable="true">
        <template #body="{ data }">
          <div class="flex gap-4 items-center">
            <div class="max-h-32 max-w-40 rounded-lg overflow-hidden" style="height: 128px; width: 160px">
              <img :src="data.imageUrl" alt="Vehicle" class="w-full h-full object-cover" />
            </div>
            <div class="flex flex-col gap-3 items-start">
              <div class="flex gap-2">
                <Avatar :url="`${$cdnBaseUrl}/img/logos/manufacturers/${_toLower(data.manufacturer)}.svg`" is-logo />
                <div>
                  <div class="font-medium">{{ data.model }}</div>
                  <div class="text-quaternary max-w-[30vw]">{{ data.variant }} {{ data.derivative }}</div>
                </div>
              </div>
              <div class="flex gap-1.5">
                <Chip :rounded="false" size="sm">{{ numberFormatter(data.odometer) }} {{ data.odometerUnit }}</Chip>
                <Chip :rounded="false" size="sm">{{ data.doors }}dr</Chip>
                <Chip :rounded="false" size="sm">{{ data.transmission }}</Chip>
              </div>
            </div>
          </div>
        </template>
      </Column>

      <Column field="fuelType" header="Fuel Type" :sortable="true">
        <template #body="{ data }">
          <Chip v-if="data.fuelType == 'Electric'" icon="battery-bolt" :rounded="false" size="sm">Electric</Chip>
          <Chip v-else-if="data.fuelType == 'Hybrid' || data.fuelType == 'MHEV'" icon="leaf" :rounded="false" size="sm">Hybrid</Chip>
          <Chip v-else-if="data.fuelType == 'PHEV'" icon="plug" :rounded="false" size="sm">Plug-In Hybrid</Chip>
          <Chip v-else icon="gas-pump" :rounded="false" size="sm">{{ data.fuelType }}</Chip>
        </template>
      </Column>

      <Column field="location" header="Location" :sortable="true">
        <template #body="{ data }">
          <Chip icon="map-marker" :rounded="false" size="sm">{{ data.location }}</Chip>
        </template>
      </Column>

      <Column field="price" header="Price" :sortable="true">
        <template #body="{ data }">
          {{ numberFormatter(data.price, 'currency', 0, 'GBP') }}
        </template>
      </Column>

      <Column field="createdAt" header="Added" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="{ content: toReadableDateTime(data.createdAt, 'date') }">
            {{ dayjs(data.createdAt).fromNow() }}
          </div>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
import { ref } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'
import Icon from '@/components/icon/Icon.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Chip from '@/components/chip/Chip.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

import { toReadableDateTime } from '@/utilities/timeFormatterUtil'

import _toLower from 'lodash/toLower'

export default {
  components: {
    PageHeader,
    Card,
    Avatar,
    Chip,
    Button,
    DataTable,
    Column,
    Input,
    Icon,
    MultiSelect
  },
  setup() {
    const vehicles = ref()
    const vehiclesRequest = useApiRequest()

    const userStore = useUserStore()

    const dealershipsSelected = ref([])
    const dealershipsSelectedLS = JSON.parse(localStorage.getItem('vehicles-used-dealerships-selected'))
    if (dealershipsSelectedLS) {
      dealershipsSelected.value = dealershipsSelectedLS
    }
    const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

    // If dealershipsSelected has values which aren't in dealershipsAllowed, remove them
    dealershipsSelected.value = dealershipsSelected.value.filter(d => dealershipsAllowed.value.map(da => da.id).includes(d))
    localStorage.setItem('vehicles-used-dealerships-selected', JSON.stringify(dealershipsSelected.value))

    function fetchVehicles() {
      vehiclesRequest
        .send({
          endpoint: '/v1/vehicles/used',
          params: { dealerships: JSON.stringify(dealershipsSelected.value) }
        })
        .then(() => {
          vehicles.value = vehiclesRequest.data.value
        })
    }
    if (dealershipsSelected.value.length) {
      fetchVehicles()
    }

    function onDealershipChange() {
      localStorage.setItem('vehicles-used-dealerships-selected', JSON.stringify(dealershipsSelected.value))

      fetchVehicles()
    }

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const clearFilter = () => {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    }

    return {
      _toLower,
      dealershipsAllowed,
      dealershipsSelected,
      onDealershipChange,
      vehiclesRequest,
      vehicles,
      filters,
      clearFilter,
      toReadableDateTime
    }
  }
}
</script>

<style lang="scss" scoped></style>
