import axios from 'axios'
import { ref } from 'vue'
import { alertToast } from '@/utilities/notification'

const downloadInvoice = () => {
  let downloadCount = ref(null)
  let downloadProgress = ref(null)

  function downloadPDF(invoices) {
    // Invoices passes an array of objects. {id: 0, reference: BA10-S271-20220102}
    // invoices = [
    //   {
    //     id: 1119,
    //     fileName: 'My PDF'
    //   }
    // ]

    downloadProgress.value = 0
    downloadCount.value = invoices.length

    invoices.forEach(invoice => {
      // console.log('Downloading: ' + invoice.id)

      axiosDownloadInvoice(invoice)
    })
  }

  async function axiosDownloadInvoice(invoice) {
    try {
      const url = import.meta.env.VITE_API_BASE_URL + '/file-download/invoice.php?invoiceID=' + invoice.id

      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      let data = await axios(url, { withCredentials: false, headers, responseType: 'blob' })

      if (data.status != 200) {
        if (data.http.status === 401) {
          throw Error('401 Unauthorized')
        } else {
          throw Error('Error fetching data')
        }
      }

      //If everything is fine
      var fileURL = window.URL.createObjectURL(new Blob([data.data]))
      var fURL = document.createElement('a')

      fURL.href = fileURL
      fURL.setAttribute('download', invoice.fileName + '.pdf')
      // fURL.setAttribute('target', '_blank');
      document.body.appendChild(fURL)

      fURL.click()

      downloadProgress.value++

      // console.log(downloadProgress.value + '/' + downloadCount.value)
      if (downloadCount.value === downloadProgress.value) {
        downloadCount.value = null
        downloadProgress.value = null
        // console.log('Download Completed')
      }
    } catch (err) {
      console.log(err.message)
      alertToast('Error', err.message, 'error')
    }
  }

  return { downloadCount, downloadProgress, downloadPDF }
}

export default downloadInvoice
