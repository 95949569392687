<template>
  <!-- Landline: {{ landlineValidator }}<br />Mobile: {{ mobileValidator }} -->
  <Steps
    ref="formWizard"
    :steps="formSteps"
    :is-submitting="postNewCustomer.isLoading.value"
    @before-move="validateStep"
    @on-complete="submitNewCustomer"
  >
    <template #step-1>
      <FormGroup :label="$t('ui.entities.profile.company-name')">
        <Input v-model="newCustomer.companyName" icon="building" />
      </FormGroup>
      <div class="grid sm:grid-cols-3 sm:gap-8">
        <FormGroup :label="$t('ui.common.title')" :is-required="v$.title.required">
          <Multiselect
            v-model="newCustomer.title"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            :options="['Mr', 'Ms', 'Mrs', 'Miss', 'Dr']"
            :can-deselect="true"
            :class="{ 'has-error': v$.title.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.title.$error">{{ v$.title.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.profile.first-name')" :is-required="v$.forename.required">
          <Input v-model="newCustomer.forename" :has-error="v$.forename.$error" />
          <InputError :has-error="v$.forename.$error">{{ v$.forename.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.profile.last-name')" :is-required="v$.surname.required">
          <Input v-model="newCustomer.surname" :has-error="v$.surname.$error" />
          <InputError :has-error="v$.surname.$error">{{ v$.surname.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <div class="grid sm:grid-cols-3 sm:gap-8">
        <FormGroup :label="$t('ui.entities.profile.date-of-birth')">
          <DatePicker v-model="newCustomer.dateOfBirth" :config="datePickrConfigs.dateOfBirth" :has-error="v$.dateOfBirth.$error" />
          <InputError :has-error="v$.dateOfBirth.$error">{{ v$.dateOfBirth.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </template>

    <template #step-2>
      <FormGroup :label="$t('ui.entities.address.line-1')" :is-required="v$.address.line1.required?.$invalid">
        <Input v-model="newCustomer.address.line1" :has-error="v$.address.line1.$error" />
        <InputError :has-error="v$.address.line1.$error">{{ v$.address.line1.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup :label="$t('ui.entities.address.line-2')" :is-required="v$.address.line2.required?.$invalid">
        <Input v-model="newCustomer.address.line2" :has-error="v$.address.line2.$error" />
        <InputError :has-error="v$.address.line2.$error">{{ v$.address.line2.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <div class="grid sm:grid-cols-2 sm:gap-8">
        <FormGroup :label="$t('ui.entities.address.city')" :is-required="v$.address.city.required?.$invalid">
          <Input v-model="newCustomer.address.city" :has-error="v$.address.city.$error" />
          <InputError :has-error="v$.address.city.$error">{{ v$.address.city.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.address.county')" :is-required="v$.address.county.required?.$invalid">
          <Input v-model="newCustomer.address.county" :has-error="v$.address.county.$error" />
          <InputError :has-error="v$.address.county.$error">{{ v$.address.county.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <div class="grid sm:grid-cols-2 sm:gap-8">
        <FormGroup :label="$t('ui.entities.address.post-code')" :is-required="v$.address.postCode.required?.$invalid">
          <Input v-model="newCustomer.address.postCode" :has-error="v$.address.postCode.$error" />
          <InputError :has-error="v$.address.postCode.$error">{{ v$.address.postCode.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.address.country')" :is-required="v$.address.country.required">
          <Multiselect
            v-model="newCustomer.address.country"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="countriesList"
            :can-deselect="false"
            :is-object="true"
            :class="{ 'has-error': v$.address.country.$error }"
            track-by="name"
            label="name"
            value-prop="code"
          >
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img :src="value.image" width="25" class="mr-3" />
                {{ value.name }}
              </div>
            </template>
            <template #option="{ option }">
              <img :src="option.image" width="25" class="mr-3" />
              {{ option.name }}</template
            >
          </Multiselect>
          <InputError :has-error="v$.address.country.$error">{{ v$.address.country.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </template>

    <template #step-3>
      <div class="grid sm:grid-cols-2 sm:gap-4">
        <FormGroup :label="$t('ui.entities.comms.landline')">
          <InputPhone v-model="newCustomer.landlineString" @update:number-details="newCustomer.landlineObj = $event" />
          <InputError :has-error="v$.landlineString.$error">{{ v$.landlineString.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.comms.mobile')">
          <InputPhone v-model="newCustomer.mobileString" @update:number-details="newCustomer.mobileObj = $event" />
          <InputError :has-error="v$.mobileString.$error">{{ v$.mobileString.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <FormGroup :label="$t('ui.entities.comms.email')">
        <Input v-model="newCustomer.email" icon="at" />
        <InputError :has-error="v$.email.$error">{{ v$.email.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <div>
        <h2 class="mb-2">{{ $t('ui.entities.comms.permissions.label') }}</h2>
        <Checkbox v-model="newCustomer.contactPermissions.canPhone" :label="$t('ui.entities.comms.permissions.call')"></Checkbox>
        <Checkbox v-model="newCustomer.contactPermissions.canEmail" :label="$t('ui.entities.comms.permissions.email')"></Checkbox>
        <Checkbox v-model="newCustomer.contactPermissions.canSMS" :label="$t('ui.entities.comms.permissions.sms')"></Checkbox>
        <Checkbox v-model="newCustomer.contactPermissions.canMail" :label="$t('ui.entities.comms.permissions.mail')"></Checkbox>
      </div>
    </template>

    <template #step-4>
      <div class="grid sm:grid-cols-3 sm:gap-4">
        <FormGroup :label="$t('ui.entities.vehicle.make')" :is-required="v$.vehicle.make.required">
          <Multiselect
            v-model="newCustomer.vehicle.make"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="manufacturers.data.value"
            :can-deselect="false"
            :is-object="true"
            :class="{ 'has-error': v$.vehicle.make.$error }"
            track-by="name"
            label="name"
            value-prop="name"
          >
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img :src="value.squareLogoUrl" style="width: 20px; max-height: 20px" class="mr-3" />
                {{ value.name }}
              </div>
            </template>
            <template #option="{ option }">
              <img :src="option.squareLogoUrl" style="width: 20px; max-height: 20px" class="mr-3" />
              {{ option.name }}</template
            >
          </Multiselect>
          <InputError :has-error="v$.vehicle.make.$error">{{ v$.vehicle.make.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.vehicle.model')" :is-required="v$.vehicle.model.required">
          <Input v-model="newCustomer.vehicle.model" :has-error="v$.vehicle.model.$error" />
          <InputError :has-error="v$.vehicle.model.$error">{{ v$.vehicle.model.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.vehicle.derivative')" :is-required="v$.vehicle.derivative.required">
          <Input v-model="newCustomer.vehicle.derivative" :has-error="v$.vehicle.derivative.$error" />
          <InputError :has-error="v$.vehicle.derivative.$error">{{ v$.vehicle.derivative.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <div class="grid sm:grid-cols-3 sm:gap-4">
        <FormGroup :label="$t('ui.entities.vehicle.vrm')" :is-required="v$.vehicle.vrm.required">
          <Input v-model="newCustomer.vehicle.vrm" :has-error="v$.vehicle.vrm.$error" />
          <InputError :has-error="v$.vehicle.vrm.$error">{{ v$.vehicle.vrm.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.vehicle.vin')" class="col-span-2" :is-required="v$.vehicle.vin.required">
          <Input v-model="newCustomer.vehicle.vin" :has-error="v$.vehicle.vin.$error" />
          <InputError :has-error="v$.vehicle.vin.$error">{{ v$.vehicle.vin.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <div class="grid sm:grid-cols-2 sm:gap-4">
        <FormGroup :label="$t('ui.entities.vehicle.new-used')" :is-required="v$.vehicle.newUsed.required">
          <RadioTabbed
            v-model="newCustomer.vehicle.newUsed"
            :options="[
              { label: 'New', value: 'new' },
              { label: 'Used', value: 'used' }
            ]"
            :selected-option="newCustomer.vehicle.newUsed"
          />
          <InputError :has-error="v$.vehicle.newUsed.$error">{{ v$.vehicle.newUsed.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <!-- <FormGroup :label="$t('ui.entities.vehicle.new-used')" :is-required="v$.vehicle.newUsed.required">
          <Multiselect
            v-model="newCustomer.vehicle.newUsed"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            :options="['New', 'Used']"
            :can-deselect="false"
            :class="{ 'has-error': v$.vehicle.newUsed.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.vehicle.newUsed.$error">{{ v$.vehicle.newUsed.$errors[0]?.$message }}</InputError>
        </FormGroup> -->

        <FormGroup :label="$t('ui.entities.vehicle.reg-date')">
          <DatePicker v-model="newCustomer.vehicle.regDate" :config="datePickrConfigs.regDate" :has-error="v$.vehicle.regDate.$error" />
          <InputError :has-error="v$.vehicle.regDate.$error">{{ v$.vehicle.regDate.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </template>

    <template #step-5>
      <div class="grid sm:grid-cols-2 sm:gap-4">
        <FormGroup
          :label="$t('ui.entities.import.import-source')"
          info="The source of the data you are adding"
          :is-required="v$.importInfo.source.required"
        >
          <Multiselect
            v-model="newCustomer.importInfo.source"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            :options="[
              { label: 'Key2Key', value: 'k2k' },
              { label: 'P360', value: 'p360' },
              { label: 'Blackhorse', value: 'blackhorse' },
              { label: 'Leap (Ford)', value: 'leap' },
              { label: 'eMaster', value: 'emaster' }
            ]"
            :can-deselect="false"
            :class="{ 'has-error': v$.importInfo.source.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.importInfo.source.$error">{{ v$.importInfo.source.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <div class="grid sm:grid-cols-2 sm:gap-4">
        <FormGroup :label="$t('ui.entities.dealership')" :is-required="v$.importInfo.dealershipID.required">
          <Multiselect
            v-model="newCustomer.importInfo.dealershipID"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="dealerships.data.value"
            :can-deselect="false"
            :class="{ 'has-error': v$.importInfo.dealershipID.$error }"
            track-by="name"
            label="name"
            value-prop="id"
          >
          </Multiselect>
          <InputError :has-error="v$.importInfo.dealershipID.$error">{{ v$.importInfo.dealershipID.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup
          :label="$t('ui.entities.finance-agreement.finance-plan-number')"
          info="Found on 3rd party finance system"
          :is-required="v$.importInfo.financePlanNumber.required"
        >
          <Input v-model="newCustomer.importInfo.financePlanNumber" :has-error="v$.importInfo.financePlanNumber.$error" />
          <InputError :has-error="v$.importInfo.financePlanNumber.$error">{{
            v$.importInfo.financePlanNumber.$errors[0]?.$message
          }}</InputError>
        </FormGroup>
      </div>
    </template>

    <template #step-6>
      <div class="grid sm:grid-cols-3 sm:gap-4">
        <FormGroup :label="$t('ui.entities.finance-agreement.finance-product')" :is-required="v$.agreement.productType.required">
          <RadioTabbed
            v-model="newCustomer.agreement.productType"
            :options="[
              { label: 'PCP', value: 'PCP' },
              { label: 'HP', value: 'HP' },
              { label: 'CH', value: 'CH' }
            ]"
            :has-error="v$.agreement.productType.$error"
            :selected-option="newCustomer.agreement.productType"
          />
          <InputError :has-error="v$.agreement.productType.$error">{{ v$.agreement.productType.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup
          :label="$t('ui.entities.finance-agreement.finance-product-name')"
          info="Name of the product (ie. Selekt)"
          :is-required="v$.agreement.productName.required"
        >
          <Input v-model="newCustomer.agreement.productName" :has-error="v$.agreement.productName.$error" />
          <InputError :has-error="v$.agreement.productName.$error">{{ v$.agreement.productName.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.common.currency')" :is-required="v$.agreement.currency.required">
          <Multiselect
            v-model="newCustomer.agreement.currency"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            :options="['GBP', 'USD', 'EUR']"
            :can-deselect="false"
            :can-clear="false"
            :class="{ 'has-error': v$.agreement.currency.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.agreement.currency.$error">{{ v$.agreement.currency.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.rate')" :is-required="v$.agreement.rate.required">
          <Input v-model="newCustomer.agreement.rate" icon="percent" :has-error="v$.agreement.rate.$error" />
          <InputError :has-error="v$.agreement.rate.$error">{{ v$.agreement.rate.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.term')" :is-required="v$.agreement.term.required">
          <Input v-model="newCustomer.agreement.term" :has-error="v$.agreement.term.$error" />
          <InputError :has-error="v$.agreement.term.$error">{{ v$.agreement.term.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.deposit')" :is-required="v$.agreement.deposit.required">
          <Input v-model="newCustomer.agreement.deposit" icon="gbp" :has-error="v$.agreement.deposit.$error" />
          <InputError :has-error="v$.agreement.deposit.$error">{{ v$.agreement.deposit.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.start-date')" :is-required="v$.agreement.startDate.required">
          <DatePicker
            v-model="newCustomer.agreement.startDate"
            :config="datePickrConfigs.startDate"
            :has-error="v$.agreement.startDate.$error"
          />
          <InputError :has-error="v$.agreement.startDate.$error">{{ v$.agreement.startDate.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.end-date')" :is-required="v$.agreement.endDate.required">
          <DatePicker v-model="newCustomer.agreement.endDate" :config="datePickrConfigs.endDate" :has-error="v$.agreement.endDate.$error" />
          <InputError :has-error="v$.agreement.endDate.$error">{{ v$.agreement.endDate.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.monthly-payment')" :is-required="v$.agreement.monthlyPayment.required">
          <Input v-model="newCustomer.agreement.monthlyPayment" icon="gbp" :has-error="v$.agreement.monthlyPayment.$error" />
          <InputError :has-error="v$.agreement.monthlyPayment.$error">{{ v$.agreement.monthlyPayment.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.purchase-price')" :is-required="v$.agreement.purchasePrice.required">
          <Input v-model="newCustomer.agreement.purchasePrice" icon="gbp" :has-error="v$.agreement.purchasePrice.$error" />
          <InputError :has-error="v$.agreement.purchasePrice.$error">{{ v$.agreement.purchasePrice.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.amount-financed')" :is-required="v$.agreement.amountBorrowed.required">
          <Input v-model="newCustomer.agreement.amountBorrowed" icon="gbp" :has-error="v$.agreement.amountBorrowed.$error" />
          <InputError :has-error="v$.agreement.amountBorrowed.$error">{{ v$.agreement.amountBorrowed.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup
          v-if="newCustomer.agreement.productType == 'PCP'"
          :label="$t('ui.entities.finance-agreement.final-repayment')"
          :is-required="v$.agreement.finalRepayment.required"
        >
          <Input v-model="newCustomer.agreement.finalRepayment" icon="gbp" :has-error="v$.agreement.finalRepayment.$error" />
          <InputError :has-error="v$.agreement.finalRepayment.$error">{{ v$.agreement.finalRepayment.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup
          :label="$t('ui.entities.finance-agreement.annual-distance-allowance')"
          :is-required="v$.agreement.odometer.annualAllowance.required"
        >
          <Input
            v-model="newCustomer.agreement.odometer.annualAllowance"
            icon="gauge"
            :has-error="v$.agreement.odometer.annualAllowance.$error"
          />
          <InputError :has-error="v$.agreement.odometer.annualAllowance.$error">{{
            v$.agreement.odometer.annualAllowance.$errors[0]?.$message
          }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.finance-agreement.distance-unit')" :is-required="v$.agreement.odometer.distanceUnit.required">
          <RadioTabbed
            v-model="newCustomer.agreement.odometer.distanceUnit"
            :options="[
              { label: 'mi', value: 'miles' },
              { label: 'km', value: 'kilometers' }
            ]"
            :has-error="v$.agreement.odometer.distanceUnit.$error"
            :selected-option="newCustomer.agreement.odometer.distanceUnit"
          />
          <InputError :has-error="v$.agreement.odometer.distanceUnit.$error">{{
            v$.agreement.odometer.distanceUnit.$errors[0]?.$message
          }}</InputError>
        </FormGroup>
      </div>
    </template>
  </Steps>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import Steps from '@/components/steps/Steps.vue'

import dayjs from 'dayjs'

import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputPhone from '@/components/forms/InputPhone.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'
import InputError from '@/components/forms/InputError.vue'

import Multiselect from '@vueform/multiselect'
import DatePicker from '@/components/forms/DatePicker.vue'

import useVuelidate from '@vuelidate/core'
import { required, requiredIf, email, integer, decimal, helpers } from '@vuelidate/validators'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

import axios from 'axios'
import { alertToast } from '@/utilities/notification'

export default {
  components: { Steps, FormGroup, Input, InputPhone, Checkbox, RadioTabbed, InputError, Multiselect, DatePicker },
  emits: ['form-updated'],
  setup(props, { emit }) {
    const router = useRouter()
    const userStore = useUserStore()

    const manufacturers = useApiRequest()
    const dealerships = useApiRequest()
    const postNewCustomer = useApiRequest()

    manufacturers.send({ endpoint: '/v1/manufacturers' })
    let dealershipsAllowed = userStore.accessAllowed.dealerships.map(dealership => dealership.id)

    console.log(dealershipsAllowed.join(','))

    dealerships.send({ endpoint: '/v1/dealerships', params: { ids: dealershipsAllowed.join(',') } })

    const formWizard = ref(null) // Reference to the FormWizard component

    const formSteps = [
      {
        label: 'Basic Details',
        title: 'Their basic details',
        icon: null
      },
      {
        label: 'Address',
        title: 'What is their address?',
        icon: null
      },
      {
        label: 'Contact Details',
        title: 'How can we contact them?',
        icon: null
      },
      {
        label: 'Vehicle',
        title: 'What car do they have?',
        icon: null
      },
      {
        label: 'Import Details',
        title: 'Source and ID of the info',
        icon: null
      },
      {
        label: 'Finance Agreement',
        title: 'More about their agreement',
        icon: null
      }
    ]

    const datePickrConfigs = {
      dateOfBirth: {
        enableTime: false,
        maxDate: Date.now(),
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd-m-Y'
      },
      regDate: {
        enableTime: false,
        maxDate: Date.now(),
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd-m-Y'
      },
      startDate: {
        enableTime: false,
        maxDate: Date.now(),
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd-m-Y'
      },
      endDate: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd-m-Y'
      }
    }

    const newCustomer = ref({
      companyName: null,
      title: null,
      forename: null,
      surname: null,
      dateOfBirth: null,
      address: {
        line1: null,
        line2: null,
        city: null,
        county: null,
        postCode: null,
        country: null
      },
      landlineString: '',
      landlineObj: null,
      mobileString: '',
      mobileObj: null,
      email: '',
      contactPermissions: {
        canPhone: false,
        canEmail: false,
        canSMS: false,
        canMail: false
      },
      vehicle: {
        make: null,
        model: null,
        derivative: null,
        vin: null,
        vrm: null,
        newUsed: null,
        regDate: null
      },
      importInfo: {
        dealershipID: null,
        source: null,
        financePlanNumber: null
      },
      agreement: {
        financePlanNumber: null,
        currency: null,
        productType: null,
        productName: null,
        startDate: null,
        endDate: null,
        term: null,
        rate: null,
        rateType: 'APR',
        deposit: null,
        purchasePrice: null,
        odometer: {
          annualAllowance: null,
          distanceUnit: null
        },
        monthlyPayment: null,
        equity: null,
        amountBorrowed: null,
        finalRepayment: null
      }
    })

    // newCustomer.value = {
    //   companyName: 'Test Company',
    //   title: 'Mr',
    //   forename: 'Max',
    //   surname: 'Resnikoff',
    //   dateOfBirth: '1996-09-06',
    //   address: {
    //     line1: '57 Farm Road',
    //     line2: 'Pinkneys Green',
    //     city: 'Maidenhead',
    //     county: 'Berkshire',
    //     postCode: 'SL65JB',
    //     country: 'GB'
    //   },
    //   landline: null,
    //   mobile: null,
    //   email: 'testemail@email.com',
    //   contactPermissions: {
    //     canPhone: false,
    //     canEmail: true,
    //     canSMS: true,
    //     canMail: false
    //   },
    //   vehicle: {
    //     make: 'Tesla',
    //     model: 'Model 3',
    //     derivative: 'Long Range',
    //     vin: 'WVWZZZAUZFP534526',
    //     vrm: 'LB71HLU',
    //     newUsed: 'new',
    //     regDate: '2022-11-13'
    //   },
    //   importInfo: {
    //     dealershipID: null,
    //     source: 'blackhorse',
    //     financePlanNumber: 123456
    //   },
    //   agreement: {
    //     productType: null,
    //     productName: 'PCP Selekt',
    //     startDate: '2020-01-01',
    //     endDate: '2023-01-01',
    //     term: 36,
    //     rate: 4.5,
    //     rateType: null,
    //     deposit: null,
    //     purchasePrice: null,
    //     odometer: {
    //       annualAllowance: null,
    //       distanceUnit: 'miles'
    //     },
    //     monthlyPayment: 550,
    //     equity: 9468,
    //     amountBorrowed: null,
    //     finalRepayment: 23405
    //   }
    // }

    const inputRules = {
      formStep1: {
        companyName: {},
        title: {},
        forename: {
          required: helpers.withMessage(
            'Required if no company name',
            requiredIf(function () {
              return !newCustomer.value.companyName
            })
          )
        },
        surname: {
          required: helpers.withMessage(
            'Required if no company name',
            requiredIf(function () {
              return !newCustomer.value.companyName
            })
          )
        },
        dateOfBirth: {
          required: false,
          dateIsValid: helpers.withMessage('Not a valid date', value => {
            return value != null ? dayjs(value).isValid() : true
          }),
          dateInFuture: helpers.withMessage('Must be in the past', value => {
            return value != null ? dayjs(value).isBefore(dayjs()) : true
          })
        }
      },
      formStep2: {
        address: {
          line1: {
            required: requiredIf(function () {
              return newCustomer.value.address.postCode
            })
          },
          line2: {},
          city: {
            required: requiredIf(function () {
              return newCustomer.value.address.postCode
            })
          },
          county: {
            required: requiredIf(function () {
              return newCustomer.value.address.postCode
            })
          },
          postCode: {
            required: requiredIf(function () {
              return newCustomer.value.address.line1
            })
          },
          country: { required }
        }
      },
      formStep3: {
        landlineString: {
          validNumber: helpers.withMessage('Invalid Number', () => {
            // Otherwise, return true (meaning no error for this rule)
            return true
          }),
          isLandline: helpers.withMessage('Not a landline number', () => {
            return true
          })
        },
        mobileString: {
          validNumber: helpers.withMessage('Invalid Number', () => {
            return true
          }),
          isMobile: helpers.withMessage('Not a mobile number', () => {
            return true
          })
        },
        email: { email },
        contactPermissions: {
          canPhone: {
            required
          },
          canEmail: {
            required
          },
          canSMS: {
            required
          },
          canMail: {
            required
          }
        }
      },
      formStep4: {
        vehicle: {
          make: { required },
          model: { required },
          derivative: { required },
          vin: {
            required
            // valid: helpers.withMessage('Invalid VIN', helpers.regex(/\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/))
          },
          vrm: { required },
          newUsed: { required },
          regDate: {}
        }
      },
      formStep5: {
        importInfo: {
          dealershipID: { required },
          source: { required },
          financePlanNumber: {
            required,
            validFormat: helpers.withMessage('Invalid Format', value => {
              let regex
              switch (newCustomer.value.importInfo.source) {
                case 'k2k':
                  regex = /^000044\d{10}$/
                  break
                case 'p360':
                  regex = /^\d{9}$/
                  break
                default:
                  regex = /^.{5,}$/
                  break
              }

              return regex.test(value)
            })
          }
        }
      },
      formStep6: {
        agreement: {
          currency: { required },
          productType: { required },
          productName: {},
          startDate: { required },
          endDate: { required },
          term: { required, integer },
          rate: { decimal },
          rateType: {},
          deposit: { decimal },
          purchasePrice: { required, decimal },
          odometer: {
            annualAllowance: { required, integer },
            distanceUnit: { required }
          },
          monthlyPayment: { required, decimal },
          amountBorrowed: { required, decimal },
          finalRepayment: {
            required: helpers.withMessage(
              'Required if product type is PCP',
              requiredIf(function () {
                return newCustomer.value.agreement.productType == 'PCP'
              })
            ),
            decimal
          }
        }
      }
    }

    watch(
      () => newCustomer.value,
      () => {
        emit('form-updated')
      },
      { deep: true }
    )

    const stepValidationRules = computed(() => {
      // console.log('stepValidationRules ' + formWizard.value?.currentStep)
      if (formWizard.value?.currentStep) {
        // console.log(`formStep${formWizard.value?.currentStep}`)
        return inputRules[`formStep${formWizard.value?.currentStep + 1}`]
      }
      return inputRules.formStep1
    })

    const v$ = useVuelidate(stepValidationRules, newCustomer)

    async function validateStep() {
      // console.log('Step: ' + step)
      const isStepCorrect = await v$.value.$validate()
      // console.log('Is Valid: ' + isStepCorrect)
      if (isStepCorrect) {
        formWizard.value.move(1) // Move to the next step
      }
    }

    async function submitNewCustomer() {
      const isFormCorrect = await v$.value.$validate()
      // console.log('FormCorrect:' + isFormCorrect, v$.value)

      // Timezone should be same as dealership's timezone
      let timezone = dealerships.data.value.find(dealership => dealership.id == newCustomer.value.importInfo.dealershipID)?.timeZone

      let dataToPost = {
        createdBy: userStore.details.id,
        timeZone: timezone,
        companyName: newCustomer.value.companyName || null,
        title: newCustomer.value.title || null,
        forename: newCustomer.value.forename || null,
        surname: newCustomer.value.surname || null,
        dateOfBirth: newCustomer.value.dateOfBirth || null,
        address: {
          line1: newCustomer.value.address.line1 || null,
          line2: newCustomer.value.address.line2 || null,
          city: newCustomer.value.address.city || null,
          county: newCustomer.value.address.county || null,
          postCode: newCustomer.value.address.postCode || null,
          country: newCustomer.value.address.country || null
        },
        landline: {
          countryCode: newCustomer.value.landlineObj.number ? newCustomer.value.landlineObj.countryCode || null : null,
          number: newCustomer.value.landlineObj.number || null
        },
        mobile: {
          countryCode: newCustomer.value.mobileObj.number ? newCustomer.value.mobileObj.countryCode || null : null,
          number: newCustomer.value.mobileObj.number || null
        },
        email: newCustomer.value.email || null,
        contactPermissions: {
          canPhone: newCustomer.value.contactPermissions.canPhone,
          canEmail: newCustomer.value.contactPermissions.canEmail,
          canSMS: newCustomer.value.contactPermissions.canSMS,
          canMail: newCustomer.value.contactPermissions.canMail
        },
        vehicle: {
          make: newCustomer.value.vehicle.make || null,
          model: newCustomer.value.vehicle.model || null,
          derivative: newCustomer.value.vehicle.derivative || null,
          vin: newCustomer.value.vehicle.vin || null,
          vrm: newCustomer.value.vehicle.vrm || null,
          newUsed: newCustomer.value.vehicle.newUsed || null,
          regDate: newCustomer.value.vehicle.regDate || null
        },
        importInfo: {
          dealershipID: newCustomer.value.importInfo.dealershipID || null,
          source: newCustomer.value.importInfo.source || null,
          financePlanNumber: newCustomer.value.importInfo.financePlanNumber || null
        },
        agreement: {
          financePlanNumber: newCustomer.value.agreement.financePlanNumber || null,
          currency: newCustomer.value.agreement.currency || null,
          productType: newCustomer.value.agreement.productType || null,
          productName: newCustomer.value.agreement.productName || null,
          startDate: newCustomer.value.agreement.startDate || null,
          endDate: newCustomer.value.agreement.endDate || null,
          term: newCustomer.value.agreement.term || null,
          rate: newCustomer.value.agreement.rate || null,
          rateType: newCustomer.value.agreement.rateType || null,
          deposit: newCustomer.value.agreement.deposit || null,
          purchasePrice: newCustomer.value.agreement.purchasePrice || null,
          odometer: {
            annualAllowance: newCustomer.value.agreement.odometer.annualAllowance || null,
            distanceUnit: newCustomer.value.agreement.odometer.distanceUnit || null
          },
          monthlyPayment: newCustomer.value.agreement.monthlyPayment || null,
          equity: newCustomer.value.agreement.equity || null,
          amountBorrowed: newCustomer.value.agreement.amountBorrowed || null,
          finalRepayment: newCustomer.value.agreement.finalRepayment || null
        }
      }

      if (isFormCorrect) {
        postNewCustomer.send({ method: 'POST', endpoint: '/v1/customers', data: JSON.stringify(dataToPost) }).then(response => {
          if (response.success) {
            //Emit completion to parent view
            router.push('/agreement/' + response.data.agreementID)
          } else {
            alertToast('Failed to add customer', response.message, 'error')
          }
        })
      }
    }

    const countriesList = ref([])

    axios.get('https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json', { withCredentials: false }).then(response => {
      // Assuming each item in the response has a "name" property that holds the country name
      let sortedCountries = response.data.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })

      countriesList.value = sortedCountries
    })

    return {
      newCustomer,
      v$,
      formWizard,
      datePickrConfigs,
      countriesList,
      validateStep,
      submitNewCustomer,
      formSteps,
      manufacturers,
      dealerships,
      postNewCustomer
    }
  }
}
</script>

<style></style>
