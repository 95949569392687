<template>
  <div v-if="dowBeingEdited == null">
    <CardTitle title="Appointment Availability" />
    <ListGroup v-if="modelValue" :bordered="false">
      <ListGroupItem v-for="(dow, index) in 7" :key="index" title="dow" :is-open="true">
        <div class="w-full flex gap-8">
          <div class="flex items-center min-w-24 font-medium">
            {{ dayjs().day(dow).format('dddd') }}
          </div>

          <div class="w-full flex items-center">
            <div v-if="!modelValue[index].isAcceptingAppointments" class="whitespace-nowrap text-sm">No appointments</div>

            <div v-else-if="modelValue[index]" class="flex items-center gap-2">
              <Chip v-if="getSlotTypes(index, 'automatic')" icon="circle-check" severity="brand" size="sm">Automatic</Chip>
              <Chip v-else icon="circle-xmark" size="sm">Automatic</Chip>

              <Chip v-if="getSlotTypes(index, 'timed')" icon="circle-check" severity="brand" size="sm">Timed</Chip>
              <Chip v-else icon="circle-xmark" size="sm">Timed</Chip>
            </div>
          </div>

          <div class="flex items-center gap-4">
            <div v-if="modelValue[index]?.maxAppointments" class="whitespace-nowrap">
              <Chip class="flex gap-1" size="sm">
                <Icon type="calendar" class="text-quaternary" />
                Max {{ modelValue[index]?.maxAppointments }}
              </Chip>
            </div>

            <Button
              v-if="$can('manage_appointment_availability')"
              v-tippy="$t('ui.actions.edit')"
              icon="pencil"
              plain
              severity="secondary"
              @click="editAvailability(index)"
            />
          </div>
        </div>
      </ListGroupItem>
    </ListGroup>
  </div>
  <div v-else>
    <Button plain text label="Back" severity="secondary" icon="chevron-left" class="mb-4" @click="cancelEdit()" />
    <div class="flex gap-2 items-center mb-6 mt-2">
      <ToggleSwitch v-model="dowState.isAcceptingAppointments" class="mr-2" @change="toggleAcceptingAppointments()" />
      <div class="text-lg font-semibold">
        {{
          dayjs()
            .day(dowBeingEdited + 1)
            .format('dddd')
        }}
      </div>
    </div>

    <div>
      <div>
        <div class="grid grid-cols-2">
          <div>
            <FormGroup label="Maximum per day" :is-required="v$.maxAppointments.required">
              <Input
                v-model="dowState.maxAppointments"
                icon="calendar-pen"
                class="w-24"
                :readonly="!dowState.isAcceptingAppointments"
                :has-error="v$.maxAppointments.$error"
              />
              <InputError :has-error="v$.maxAppointments.$error">{{ v$.maxAppointments.$errors[0]?.$message }}</InputError>
            </FormGroup>
            <FormGroup label="Maximum per method">
              <div class="flex gap-2 max-w-72">
                <FormGroup>
                  <Input
                    v-model="dowState.methodLimits.physical"
                    icon="location-dot"
                    :readonly="!dowState.isAcceptingAppointments"
                    :has-error="v$.methodLimits.physical.$error"
                  />
                  <InputError :has-error="v$.methodLimits.physical.$error">{{ v$.methodLimits.physical.$errors[0]?.$message }}</InputError>
                </FormGroup>
                <FormGroup>
                  <Input
                    v-model="dowState.methodLimits.phone"
                    icon="phone"
                    :readonly="!dowState.isAcceptingAppointments"
                    :has-error="v$.methodLimits.phone.$error"
                  />
                  <InputError :has-error="v$.methodLimits.phone.$error">{{ v$.methodLimits.phone.$errors[0]?.$message }}</InputError>
                </FormGroup>
                <FormGroup>
                  <Input
                    v-model="dowState.methodLimits.video"
                    icon="video"
                    :readonly="!dowState.isAcceptingAppointments"
                    :has-error="v$.methodLimits.video.$error"
                  />
                  <InputError :has-error="v$.methodLimits.video.$error">{{ v$.methodLimits.video.$errors[0]?.$message }}</InputError>
                </FormGroup>
              </div>
            </FormGroup>
          </div>
          <div>
            <div>
              <FormGroup label="Internal note">
                <TextArea
                  v-model="dowState.internalNote"
                  :readonly="!dowState.isAcceptingAppointments"
                  placeholder="Type a new note..."
                  rows="2"
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup label="Customer note">
                <TextArea
                  v-model="dowState.customerNote"
                  :readonly="!dowState.isAcceptingAppointments"
                  placeholder="Type a new note..."
                  rows="2"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <Separator class="my-8" />

      <div>
        <div class="font-medium mb-4">Automatically Generated</div>
        <div class="max-w-[600px] w-full">
          <div v-for="(slot, index) in dowState?.automaticSlots" :key="index" class="grid grid-cols-5 gap-2">
            <FormGroup>
              <div class="flex gap-4 items-start">
                <div class="flex gap-2">
                  <FormGroup :label="index == 0 ? 'Start time' : ''" no-margin>
                    <Input
                      v-model="slot.timeStart"
                      :id="slot.randId + '_timeStart'"
                      icon="clock"
                      class="w-28"
                      :readonly="!dowState.isAcceptingAppointments"
                      :has-error="vSlots$[slot.randId]?.timeStart.$error"
                    />
                    <InputError :has-error="vSlots$[slot.randId]?.timeStart.$error">{{
                      vSlots$[slot.randId]?.timeStart.$errors[0]?.$message
                    }}</InputError>
                  </FormGroup>

                  <Icon type="arrow-right" :class="index == 0 ? 'mt-[34px]' : 'mt-[8px]'" />

                  <FormGroup :label="index == 0 ? 'End time' : ''" no-margin>
                    <Input
                      v-model="slot.timeEnd"
                      icon="clock"
                      class="w-28"
                      :readonly="!dowState.isAcceptingAppointments"
                      :has-error="vSlots$[slot.randId]?.timeEnd.$error"
                    />
                    <InputError :has-error="vSlots$[slot.randId]?.timeEnd.$error">{{
                      vSlots$[slot.randId]?.timeEnd.$errors[0]?.$message
                    }}</InputError>
                  </FormGroup>
                </div>

                <FormGroup :label="index == 0 ? 'Length' : ''" no-margin>
                  <InputGroup>
                    <Input
                      v-model="slot.duration"
                      class="w-16"
                      :readonly="!dowState.isAcceptingAppointments"
                      :has-error="vSlots$[slot.randId]?.duration.$error"
                    />
                    <InputGroupAddon :readonly="!dowState.isAcceptingAppointments">min</InputGroupAddon>
                  </InputGroup>
                  <InputError :has-error="vSlots$[slot.randId]?.duration.$error">{{
                    vSlots$[slot.randId]?.duration.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>

                <FormGroup :label="index == 0 ? 'Interval' : ''" no-margin>
                  <InputGroup>
                    <Input
                      v-model="slot.interval"
                      class="w-16"
                      :readonly="!dowState.isAcceptingAppointments"
                      :has-error="vSlots$[slot.randId]?.interval.$error"
                    />
                    <InputGroupAddon :readonly="!dowState.isAcceptingAppointments">min</InputGroupAddon>
                  </InputGroup>
                  <InputError :has-error="vSlots$[slot.randId]?.interval.$error">{{
                    vSlots$[slot.randId]?.interval.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>

                <FormGroup :label="index == 0 ? 'Methods' : ''" no-margin>
                  <RadioTabbed
                    v-model="slot.methodsAllowed"
                    selection-mode="multiple"
                    :options="[
                      { value: 'physical', icon: 'location-dot' },
                      { value: 'phone', icon: 'phone' },
                      { value: 'video', icon: 'video' }
                    ]"
                    :readonly="!dowState.isAcceptingAppointments"
                    :has-error="vSlots$[slot.randId]?.methodsAllowed.$error"
                  />
                  <InputError :has-error="vSlots$[slot.randId]?.methodsAllowed.$error">{{
                    vSlots$[slot.randId]?.methodsAllowed.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>
                <!-- The max appointments here are per slot, not per timeframe -->
                <!-- <FormGroup :label="index == 0 ? 'Max Appts' : ''" no-margin>
                  <Input
                    v-model="slot.maxAppointments"
                    icon="calendar-pen"
                    class="w-28"
                    :readonly="!dowState.isAcceptingAppointments"
                    :has-error="vSlots$[slot.randId]?.maxAppointments.$error"
                  />

                  <InputError :has-error="vSlots$[slot.randId]?.maxAppointments.$error">{{
                    vSlots$[slot.randId]?.maxAppointments.$errors[0]?.$message
                  }}</InputError>
                </FormGroup> -->

                <Button
                  icon="trash"
                  plain
                  severity="danger"
                  :class="index == 0 ? 'mt-[26px]' : ''"
                  :disabled="!dowState.isAcceptingAppointments"
                  @click="removeSlot(index, 'automatic')"
                />
              </div>
            </FormGroup>
          </div>
          <Button icon="plus" outlined severity="secondary" :disabled="!dowState.isAcceptingAppointments" @click="addSlot('automatic')" />
        </div>
      </div>

      <Separator class="my-8" />

      <div>
        <div class="font-medium mb-4">Specific Times</div>
        <div class="max-w-[600px] w-full">
          <div v-for="(slot, index) in dowState?.timedSlots" :key="index" class="grid grid-cols-5 gap-2">
            <FormGroup>
              <div class="flex items-start gap-4">
                <FormGroup :label="index == 0 ? 'Time' : ''" no-margin>
                  <Input
                    v-model="slot.timeStart"
                    icon="clock"
                    class="w-28"
                    :readonly="!dowState.isAcceptingAppointments"
                    :has-error="vSlots$[slot.randId]?.timeStart.$error"
                  />
                  <InputError :has-error="vSlots$[slot.randId]?.timeStart.$error">{{
                    vSlots$[slot.randId]?.timeStart.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>
                <FormGroup :label="index == 0 ? 'Length' : ''" no-margin>
                  <InputGroup>
                    <Input
                      v-model="slot.duration"
                      class="w-16"
                      :readonly="!dowState.isAcceptingAppointments"
                      :has-error="vSlots$[slot.randId]?.duration.$error"
                    />
                    <InputGroupAddon :readonly="!dowState.isAcceptingAppointments">min</InputGroupAddon>
                  </InputGroup>
                  <InputError :has-error="vSlots$[slot.randId]?.duration.$error">{{
                    vSlots$[slot.randId]?.duration.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>
                <FormGroup :label="index == 0 ? 'Methods' : ''" no-margin>
                  <RadioTabbed
                    v-model="slot.methodsAllowed"
                    selection-mode="multiple"
                    :options="[
                      { value: 'physical', icon: 'location-dot' },
                      { value: 'phone', icon: 'phone' },
                      { value: 'video', icon: 'video' }
                    ]"
                    :readonly="!dowState.isAcceptingAppointments"
                    :has-error="vSlots$[slot.randId]?.methodsAllowed.$error"
                  />
                  <InputError :has-error="vSlots$[slot.randId]?.methodsAllowed.$error">{{
                    vSlots$[slot.randId]?.methodsAllowed.$errors[0]?.$message
                  }}</InputError>
                </FormGroup>
                <Button
                  icon="trash"
                  plain
                  severity="danger"
                  :class="index == 0 ? 'mt-[26px]' : ''"
                  :disabled="!dowState.isAcceptingAppointments"
                  @click="removeSlot(index, 'timed')"
                />
              </div>
            </FormGroup>
          </div>
          <Button icon="plus" outlined severity="secondary" :disabled="!dowState.isAcceptingAppointments" @click="addSlot('timed')" />
        </div>
      </div>
    </div>
    <div class="flex gap-4 justify-end mt-6">
      <Button label="Cancel" severity="secondary" plain @click="cancelEdit()" />
      <Button label="Save" :is-loading="updateAvailability.isLoading.value" @click="saveDowSettings()" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import Swal from 'sweetalert2'

import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'
import useVuelidate from '@vuelidate/core'
import { required, numeric, helpers } from '@vuelidate/validators'
import dayjs from 'dayjs'

import CardTitle from '@/components/card/CardTitle.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputGroup from '@/components/forms/InputGroup.vue'
import InputGroupAddon from '@/components/forms/InputGroupAddon.vue'
import Icon from '@/components/icon/Icon.vue'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import InputError from '@/components/forms/InputError.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import RadioTabbed from '@/components/forms/RadioTabbed.vue'
import TextArea from '@/components/forms/TextArea.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
import Separator from '../separator/Separator.vue'

import _cloneDeep from 'lodash/cloneDeep'

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  }
})
const emit = defineEmits(['submit', 'changes-made'])

const updateAvailability = useApiRequest()

const dowVuelidateRules = {
  maxAppointments: {
    numeric
  },
  methodLimits: {
    physical: {
      numeric,
      exceedsTotalMax: helpers.withMessage('Exceeds max per day', value => {
        return value <= dowState.value.maxAppointments
      })
    },
    phone: {
      numeric,
      exceedsTotalMax: helpers.withMessage('Exceeds max per day', value => {
        return value <= dowState.value.maxAppointments
      })
    },
    video: {
      numeric,
      exceedsTotalMax: helpers.withMessage('Exceeds max per day', value => {
        return value <= dowState.value.maxAppointments
      })
    }
  }
}

const dowSlotVuelidateRules = ref({})

const dowStateDefault = {
  id: null,
  dealershipId: null,
  dayOfWeek: null,
  dayName: null,
  isAcceptingAppointments: null,
  methodLimits: {
    physical: null,
    phone: null,
    video: null
  },
  maxAppointments: null,
  internalNote: null,
  customerNote: null,
  slots: [],
  automaticSlots: [],
  timedSlots: []
}

const dowBeingEdited = ref(null)
const dowHasUnsavedChanges = ref(false)

const dowState = ref()
const dowStateInit = ref()

function editAvailability(dow) {
  dowBeingEdited.value = dow

  // Deep copy of the day's data, including slots
  let modelValueClone = _cloneDeep(props.modelValue[dow])

  let slotsSorted = modelValueClone.slots

  slotsSorted.forEach(s => {
    s.randId = Math.random().toString(36).substring(7)

    addSlotValidationRules(s.randId, s.type)

    let availableMethods = []
    if (s.methodsAllowed.physical) {
      availableMethods.push('physical')
    }
    if (s.methodsAllowed.phone) {
      availableMethods.push('phone')
    }
    if (s.methodsAllowed.video) {
      availableMethods.push('video')
    }
    s.methodsAllowed = availableMethods
  })

  let day = {
    ...dowStateDefault,
    ...modelValueClone,
    automaticSlots: slotsSorted.filter(s => s.type == 'automatic'),
    timedSlots: slotsSorted.filter(s => s.type == 'timed')
  }

  dowStateInit.value = _cloneDeep(day)
  dowState.value = _cloneDeep(day)
}

function toggleAcceptingAppointments() {
  if (!dowState.value.isAcceptingAppointments) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Changes will be discarded if you continue',
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Continue',
      confirmButtonColor: 'var(--bg-error-solid)',
      cancelButtonText: 'Cancel'
    }).then(function (response) {
      if (response.isConfirmed) {
        dowState.value = JSON.parse(JSON.stringify(dowStateInit.value))
        dowState.value.isAcceptingAppointments = false
      } else {
        dowState.value.isAcceptingAppointments = true
      }
    })
  }
}

const getSlotTypes = (index, type) => {
  let hasSlotType = false
  props.modelValue[index].slots.forEach(s => {
    s.type == type ? (hasSlotType = true) : ''
  })
  return hasSlotType
}

function addSlotValidationRules(randId, type) {
  if (!randId) {
    throw new Error('randId is required')
  }
  // Add the vuelidate rules with the randId as the key
  // If is automatic, add the automatic slot rules
  if (type == 'automatic') {
    dowSlotVuelidateRules.value[randId] = {
      type: { required },
      timeStart: {
        required,
        isValidTime: helpers.withMessage('Not a valid time', value => {
          const timeRegex = /^([01]\d|2[0-3]):[0-5]\d$/
          return timeRegex.test(value)
        }),
        overlap: helpers.withMessage('Overlapping with another slot', value => {
          let doesNotOverlap = true

          if (dowState.value.automaticSlots?.length > 1) {
            dowState.value.automaticSlots.forEach(s => {
              if (!s.timeStart || !s.timeEnd || s.randId === randId) {
                return
              }
              const startTime = dayjs('2001-01-01 ' + s.timeStart)
              const endTime = dayjs('2001-01-01 ' + s.timeEnd)
              const currentTime = dayjs('2001-01-01 ' + value)

              if (currentTime.isBetween(startTime, endTime, null, '[)')) {
                doesNotOverlap = false
              }
            })
          }
          return doesNotOverlap
        })
      },
      timeEnd: {
        required,
        isValidTime: helpers.withMessage('Not a valid time', value => {
          const timeRegex = /^([01]\d|2[0-3]):[0-5]\d$/
          return timeRegex.test(value)
        })
      },
      duration: { numeric, required },
      interval: { numeric, required },
      maxAppointments: {
        // numeric,
        // exceedsTotalMax: helpers.withMessage('Exceeds max per day', value => {
        //   return value <= dowState.value.maxAppointments
        // })
      },
      methodsAllowed: {
        required,
        isEmpty: helpers.withMessage('Value is required', value => {
          return value != []
        })
      }
    }
  } else {
    dowSlotVuelidateRules.value[randId] = {
      type: { required },
      timeStart: {
        required,
        isValidTime: helpers.withMessage('Not a valid time', value => {
          const timeRegex = /^([01]\d|2[0-3]):[0-5]\d$/
          return timeRegex.test(value)
        })
      },
      timeEnd: {},
      duration: { numeric, required },
      interval: {},
      maxAppointments: {},
      methodsAllowed: {
        required,
        isEmpty: helpers.withMessage('Value is required', value => {
          return value != []
        })
      }
    }
  }
}

function addSlot(type) {
  let randId = Math.random().toString(36).substring(7)

  if (type == 'automatic') {
    dowState.value.automaticSlots.push({
      randId: randId,
      type: 'automatic',
      timeStart: null,
      timeEnd: null,
      duration: 45,
      interval: 30,
      maxAppointments: null,
      methodsAllowed: ['physical', 'phone', 'video']
    })
  } else if (type == 'timed') {
    dowState.value.timedSlots.push({
      randId: randId,
      type: 'timed',
      timeStart: null,
      timeEnd: null,
      duration: 45,
      interval: null,
      maxAppointments: null,
      methodsAllowed: ['physical', 'phone', 'video']
    })
  }

  addSlotValidationRules(randId, type)
}

function removeSlot(index, type) {
  if (type == 'automatic') {
    // remove the slot from the vuelidate rules
    delete dowSlotVuelidateRules.value[dowState.value.automaticSlots[index].randId]
    dowState.value.automaticSlots.splice(index, 1)
  } else if (type == 'timed') {
    // remove the slot from the vuelidate rules
    delete dowSlotVuelidateRules.value[dowState.value.timedSlots[index].randId]
    dowState.value.timedSlots.splice(index, 1)
  }
}

function cancelEdit() {
  if (!dowHasUnsavedChanges.value) {
    resetDowEditing()
  } else {
    Swal.fire({
      title: 'Unsaved Changes',
      text: 'Changes made will not be saved if you continue',
      icon: 'warning',
      reverseButtons: true,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Discard',
      confirmButtonColor: 'var(--bg-error-solid)',
      cancelButtonText: 'Cancel'
    }).then(function (response) {
      if (response.isConfirmed) {
        resetDowEditing()
      }
    })
  }
}

const mergedSlots = computed(() => {
  // loop through each automatic slot, and set the key to randId
  let automaticSlots = {}
  dowState.value.automaticSlots.forEach(slot => {
    automaticSlots[slot.randId] = slot
  })

  // loop through each timed slot, and set the key to randId
  let timedSlots = {}
  dowState.value.timedSlots.forEach(slot => {
    timedSlots[slot.randId] = slot
  })

  return { ...automaticSlots, ...timedSlots }
})

const v$ = useVuelidate(dowVuelidateRules, dowState)
const vSlots$ = useVuelidate(dowSlotVuelidateRules.value, mergedSlots)

function resetDowEditing() {
  dowState.value = _cloneDeep(dowStateDefault)
  dowStateInit.value = _cloneDeep(dowStateDefault)

  dowBeingEdited.value = null

  // $reset vuelidate
  vSlots$.value.$reset()

  // loop through each dowSlotVuelidateRules and remove them
  for (const key in dowSlotVuelidateRules.value) {
    delete dowSlotVuelidateRules.value[key]
  }
}

resetDowEditing()

async function saveDowSettings() {
  const isSettingsCorrect = await v$.value.$validate()
  const isSlotsCorrect = await vSlots$.value.$validate()

  if (!isSlotsCorrect || !isSettingsCorrect) {
    return
  }
  let dowDataSanitized = JSON.parse(JSON.stringify(dowState.value))
  dowDataSanitized.slots = [...dowDataSanitized.automaticSlots, ...dowDataSanitized.timedSlots]

  dowDataSanitized.maxAppointments = dowDataSanitized.maxAppointments || null
  dowDataSanitized.methodLimits.physical = dowDataSanitized.methodLimits.physical || null
  dowDataSanitized.methodLimits.phone = dowDataSanitized.methodLimits.phone || null
  dowDataSanitized.methodLimits.video = dowDataSanitized.methodLimits.video || null

  dowDataSanitized.slots.forEach(s => {
    let methodsSorted = {
      physical: s.methodsAllowed.includes('physical') ? true : false,
      phone: s.methodsAllowed.includes('phone') ? true : false,
      video: s.methodsAllowed.includes('video') ? true : false
    }

    s.methodsAllowed = methodsSorted

    // set max to null if empty
    s.maxAppointments = s.maxAppointments || null
  })

  // remove automaticSlots and timedSlots from the object
  delete dowDataSanitized.automaticSlots
  delete dowDataSanitized.timedSlots

  let dataToPost = {
    dealershipId: dowDataSanitized.dealershipId,
    daysOfWeek: [dowDataSanitized]
  }

  try {
    updateAvailability.send({ method: 'PATCH', endpoint: '/v1/availability/settings', data: JSON.stringify(dataToPost) }).then(response => {
      if (response.success) {
        alertToast('Updated', null, 'success')
        resetDowEditing()

        emit('changes-made', props.modelValue)
      } else {
        alertToast('Failed to update', null, 'error')
      }
    })
  } catch (err) {
    alertToast('Failed to update', null, 'error')
  }
}

watch(
  () => dowState.value,
  () => {
    JSON.stringify(dowStateInit.value) === JSON.stringify(dowState.value)
      ? (dowHasUnsavedChanges.value = false)
      : (dowHasUnsavedChanges.value = true)
  },
  { deep: true }
)
</script>
