<template>
  <div>asds</div>
  <button v-for="toast in alertToasts" :key="toast.type" @click="alertToast(toast.title, toast.text, toast.severity)">
    {{ toast.title }}
  </button>
  <br />
  <button
    v-for="notification in notifications"
    :key="notification.title"
    @click="notificationToast({}, notification.notificationData, notification.onActionClick)"
  >
    {{ notification.title }}
  </button>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { notificationToast, alertToast } from '@/utilities/notification'

const router = useRouter()

const alertToasts = [
  {
    title: 'Info',
    text: 'This is an info message',
    severity: 'info'
  },
  {
    title: 'Success',
    text: 'This is a success message',
    severity: 'success'
  },
  {
    title: 'Warning',
    text: 'This is a warning message',
    severity: 'warning'
  },
  {
    title: 'Error',
    text: 'This is an error message',
    severity: 'error'
  }
]

const notifications = [
  {
    title: 'Notification 1',
    notificationData: {
      notificationId: 1,
      data: {
        customerName: 'John Anderson',
        agreementId: 2,
        appointmentId: 52679
      }
    },
    onActionClick: url => {
      console.log('Action clicked', url)
      // route to url
      router.push(url)
    }
  }
]
</script>

<style></style>
