<template>
  <div class="accordion-item">
    <div
      v-if="!slots.header"
      class="accordion-title cursor-pointer py-3 flex items-center justify-between hover:text-primary"
      @click="toggleItem"
    >
      <div class="text-2xl">{{ title }}</div>
      <Icon class="text-xl" :type="isOpen ? 'chevron-up' : 'chevron-down'"></Icon>
    </div>
    <div v-else @click="toggleItem"><slot name="header" /></div>
    <div v-show="isOpen || isOpen" class="accordion-item-content pb-5">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import { useSlots } from 'vue'

export default {
  name: 'AccordionItem',
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: 'Accordion Title'
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    function toggleItem() {
      console.log('toggleItem')

      // update isOpen
      emit('update:isOpen', !props.isOpen)
    }

    const slots = useSlots()

    return { toggleItem, slots }
  }
}
</script>

<style>
.accordion-item {
  border-bottom: 1px solid var(--border-secondary);
}
.accordion > .accordion-item:last-of-type {
  border-bottom: none;
}
</style>
