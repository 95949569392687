<template>
  <Sidebar :is-open="isOpen" @on-close="closeSidebar">
    <SidebarHeader :title="isNewShift ? $t('ui.actions.new') : $t('ui.actions.update') + ' Shift'"></SidebarHeader>
    <SidebarContent>
      <form ref="form" @submit.prevent="shiftHandlerSubmit">
        <FormGroup :label="$t('ui.common.user')" label-for="user">
          <Multiselect
            v-model="state.userId"
            mode="single"
            label="fullName"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="users"
            track-by="fullName"
            value-prop="id"
            placeholder="Select a User"
          >
            <template #placeholder>
              <div class="multiselect-placeholder"><Icon type="user" size="sm" class="mr-3" /> Select a user</div>
            </template>
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <Avatar :url="value.profilePicURL" :text="value.initials" size="sm" class="mr-3" />
                {{ value.fullName }}
              </div>
            </template>

            <template #option="{ option }">
              <Avatar :url="option.profilePicURL" :text="option.initials" size="sm" class="mr-3" />
              {{ option.fullName }}
            </template>
          </Multiselect>
          <InputError :has-error="v$.userId.$error">{{ v$.userId.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.common.type')" label-for="type">
          <Multiselect
            v-model="state.type"
            mode="single"
            label="type"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="shiftTypes"
            track-by="type"
            value-prop="id"
          >
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <Icon type="circle" :style="{ color: value.color, '--fa-secondary-opacity': 1 }" class="mr-2" />
                {{ value.name }}
              </div>
            </template>

            <template #option="{ option }">
              <Icon type="circle" :style="{ color: option.color, '--fa-secondary-opacity': 1 }" class="mr-2" />
              {{ option.name }}
            </template>
          </Multiselect>
          <InputError :has-error="v$.type.$error">{{ v$.type.$errors[0].$message }}</InputError>
        </FormGroup>

        <div>
          <div class="flex">
            <FormGroup :label="$t('ui.common.start')" label-for="start" :class="{ 'has-error': v$.start.$error }">
              <DatePicker v-model="state.start" :config="datePickerConfig" :has-error="v$.start.$error" />
              <InputError :has-error="v$.start.$error">{{ v$.start.$errors[0].$message }}</InputError>
            </FormGroup>
            <Icon type="arrow-right" class="mx-5" style="margin-top: 30px" />
            <FormGroup :label="$t('ui.common.end')" label-for="end" :class="{ 'has-error': v$.end.$error }">
              <DatePicker v-model="state.end" :config="datePickerConfig" :has-error="v$.end.$error" />
              <InputError :has-error="v$.end.$error">{{ v$.end.$errors[0].$message }}</InputError>
            </FormGroup>
          </div>
        </div>

        <div>
          <div class="flex">
            <FormGroup :label="$t('ui.entities.schedule.break') + ' (min)'" label-for="break" class="mr-3">
              <Input
                id="break"
                v-model.number="state.break"
                type="text"
                icon="coffee-pot"
                :value="state.break"
                :has-error="v$.break.$error"
              />
              <InputError :has-error="v$.break.$error">{{ v$.break.$errors[0].$message }}</InputError>
              <!-- <InputError :has-error="true">{{ v$.break }}</InputError> -->
            </FormGroup>

            <FormGroup
              v-tippy="{ content: 'Calculated' }"
              :label="$t('ui.entities.schedule.total-hours')"
              label-for="totalShiftHours"
              class="mr-3"
            >
              <Input
                id="totalShiftHours"
                v-model.number="state.totalShiftHours"
                type="text"
                icon="hourglass-clock"
                readonly
                :value="state.totalShiftHours"
              />
            </FormGroup>
            <FormGroup :label="$t('ui.entities.schedule.productive')" label-for="productiveHours" class="mr-3">
              <Input
                id="productiveHours"
                v-model.number="state.productiveHours"
                type="text"
                icon="business-time"
                :value="state.productiveHours"
                :has-error="v$.productiveHours.$error"
              />
              <InputError :has-error="v$.productiveHours.$error">{{ v$.productiveHours.$errors[0].$message }} </InputError>
            </FormGroup>
          </div>
        </div>

        <div>
          <div class="flex">
            <FormGroup
              :label="$t('ui.entities.report.target') + ' (' + $t('ui.entities.comms.call', 2) + ')'"
              label-for="callTarget"
              class="mr-3"
            >
              <Input
                id="callTarget"
                v-model.number="state.callTarget"
                type="text"
                icon="phone"
                :value="state.callTarget"
                :has-error="v$.callTarget.$error"
              />
              <InputError :has-error="v$.callTarget.$error">{{ v$.callTarget.$errors[0].$message }}</InputError>
            </FormGroup>
            <FormGroup
              :label="$t('ui.entities.report.target') + ' (' + $t('ui.entities.appointment.label', 2) + ')'"
              label-for="appointmentTarget"
            >
              <Input
                id="appointmentTarget"
                v-model.number="state.appointmentTarget"
                type="text"
                icon="calendar"
                :value="state.appointmentTarget"
                :has-error="v$.appointmentTarget.$error"
              />
              <InputError :has-error="v$.appointmentTarget.$error">{{ v$.appointmentTarget.$errors[0].$message }} </InputError>
            </FormGroup>
          </div>
        </div>
      </form>
    </SidebarContent>
    <SidebarFooter>
      <div class="flex justify-between">
        <Button label="Delete" size="sm" severity="danger" plain @click="deleteShift" />
        <div class="flex justify-end gap-2">
          <Button :label="$t('ui.actions.close')" severity="secondary" outlined class="ml-3" type="button" @click="closeSidebar" />
          <Button :label="isNewShift ? $t('ui.actions.add') : $t('ui.actions.save')" type="submit" @click="shiftHandlerSubmit" />
        </div>
      </div>
    </SidebarFooter>
  </Sidebar>
</template>

<script>
import { ref, watch, computed } from 'vue'

import Sidebar from '@/components/sidebar/Sidebar.vue'
import SidebarHeader from '@/components/sidebar/SidebarHeader.vue'
import SidebarContent from '@/components/sidebar/SidebarContent.vue'
import SidebarFooter from '@/components/sidebar/SidebarFooter.vue'
import Icon from '@/components/icon/Icon.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import Button from '@/components/button/Button.vue'
import DatePicker from '@/components/forms/DatePicker.vue'
import Multiselect from '@vueform/multiselect'

import { useUserStore } from '@/stores/UserStore'
import useApiRequest from '@/composables/useApiRequest'
import getShiftTypes from '@/composables/getShiftTypes'
import { alertToast } from '@/utilities/notification'
import useVuelidate from '@vuelidate/core'
import { required, numeric, integer } from '@vuelidate/validators'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

export default {
  components: {
    Sidebar,
    SidebarHeader,
    SidebarContent,
    SidebarFooter,
    Icon,
    FormGroup,
    Input,
    InputError,
    Button,
    Multiselect,
    Avatar,
    DatePicker
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    shift: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  emits: ['deleteShift', 'updateShiftSubmit', 'addNewShiftSubmit', 'closeShiftHandler'],
  setup(props, { emit }) {
    const newShiftRequest = useApiRequest()
    const updateShiftRequest = useApiRequest()

    const userStore = useUserStore()
    const sessionUserID = userStore.details.id

    const hasChanges = ref(false)
    const isNewShift = ref(true)

    const datePickerConfig = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i:ss',
      allowInput: true,
      altInput: true,
      altFormat: 'H:i'
    }

    //Instantiate state
    const state = ref({
      id: null,
      userId: null,
      dayOfWeek: null,
      start: null,
      end: null,
      break: 0,
      totalShiftHours: 0,
      productiveHours: 0,
      callTarget: 0,
      appointmentTarget: 0,
      timestamp: null
    })

    const inputRules = {
      userId: { required },
      type: { required },
      start: { required },
      end: { required },
      break: { required, numeric, integer },
      productiveHours: { required, numeric },
      callTarget: { required, numeric },
      appointmentTarget: { required, numeric }
    }
    const v$ = useVuelidate(inputRules, state)

    const { shiftTypes, loadShiftTypes } = getShiftTypes()
    loadShiftTypes()

    if (props.shift?.id) {
      //Shift passed, so we should update.
      isNewShift.value = false

      state.value.id = props.shift.id
      state.value.userId = props.shift.userId
      state.value.dayOfWeek = props.shift.dayOfWeek
      state.value.type = props.shift.type
      state.value.start = props.shift.start
      state.value.end = props.shift.end
      state.value.break = props.shift.break
      state.value.totalShiftHours = props.shift.totalShiftHours
      state.value.productiveHours = props.shift.productiveHours
      state.value.callTarget = props.shift.callTarget
      state.value.appointmentTarget = props.shift.appointmentTarget
      state.value.timestamp = props.shift.timestamp
    } else {
      //New Shift
      state.value.userId = props.shift?.userId
      state.value.dayOfWeek = props.shift?.dayOfWeek
    }

    hasChanges.value = false
    const stateUnchanged = ref({ ...state.value })

    function closeSidebar() {
      if (hasChanges.value) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'Changes made will not be saved if you continue',
          icon: 'warning',
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Discard',
          confirmButtonColor: 'var(--bg-error-solid)',
          cancelButtonText: 'Cancel'
        }).then(function (response) {
          if (response.isConfirmed) {
            emit('closeShiftHandler')
          }
        })
      } else {
        emit('closeShiftHandler')
      }
    }

    function clearForm() {
      state.value.type = 1
      state.value.start = null
      state.value.end = null
      state.value.break = null
      state.value.totalShiftHours = null
      state.value.productiveHours = null
      state.value.callTarget = null
      state.value.appointmentTarget = null
    }

    async function shiftHandlerSubmit() {
      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        return
      }
      const dataToPost = JSON.stringify({
        id: state.value.id,
        dayOfWeek: state.value.dayOfWeek,
        userId: state.value.userId,
        type: state.value.type,
        start: state.value.start,
        end: state.value.end,
        break: state.value.break,
        totalShiftHours: state.value.totalShiftHours,
        productiveHours: state.value.productiveHours,
        callTarget: state.value.callTarget,
        appointmentTarget: state.value.appointmentTarget
      })

      if (isNewShift.value) {
        // Add new shift
        try {
          newShiftRequest.send({ method: 'POST', endpoint: '/v1/users/schedule/template', data: dataToPost }).then(response => {
            if (response.success) {
              // Emit event to shift template view with json, which will then update the task in the DOM
              emit('addNewShiftSubmit', response.data)

              alertToast('Added', response.message, 'success')
            } else {
              alertToast('Error adding shift', response.message, 'danger')
              throw Error('Error posting data')
            }
          })
        } catch (err) {
          alertToast('Error adding shift', err.message, 'danger')
        }
      } else {
        // Update existing shift
        try {
          updateShiftRequest
            .send({ method: 'PATCH', endpoint: '/v1/users/schedule/template/' + state.value.id, data: dataToPost })
            .then(response => {
              if (response.data && response.success) {
                // Emit event to shift template view with json, which will then update the task in the DOM
                emit('updateShiftSubmit', state.value)
                alertToast('Updated', response.message, 'success')
              } else {
                alertToast('Failed to update', response.message, 'error')
                throw Error('Error patching data')
              }
            })
        } catch (err) {
          alertToast('Failed to update', err.message, 'error')
        }
      }
    }

    const deleteShift = () => {
      emit('deleteShift', state.value)
    }

    const totalShiftHoursCalculated = computed(() => {
      let diff = 0
      if (state.value.start && state.value.end) {
        let start = dayjs(state.value.start, 'hh:m:s')
        let end = dayjs(state.value.end, 'hh:m:s').subtract(state.value.break, 'minute')
        diff = end.diff(start, 'hour', true)
      } else {
        diff = 0
      }
      return diff
    })

    watch(totalShiftHoursCalculated, () => {
      state.value.totalShiftHours = totalShiftHoursCalculated.value
    })

    watch(state.value, () => {
      if (state.value != stateUnchanged.value) {
        hasChanges.value = true
      }
    })

    return {
      sessionUserID,
      closeSidebar,
      isNewShift,
      shiftHandlerSubmit,
      state,
      v$,
      datePickerConfig,
      totalShiftHoursCalculated,
      clearForm,
      deleteShift,
      hasChanges,
      shiftTypes
    }
  }
}
</script>

<style></style>
