<template>
  <PageHeader
    title="Repeating Invoices"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.entities.finance.label') },
      { label: 'Repeating Invoices', to: '/settings/finance/repeating-invoices' }
    ]"
  >
    <template #right>
      <div class="flex gap-4">
        <RouterLink to="/settings/finance/repeating-invoice/new">
          <Button label="New" severity="primary" icon="plus" />
        </RouterLink>
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="invoicesList"
      removable-sort
      sort-field="id"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-repeating-invoices"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'billingAccount.name', 'dealerships']"
      :loading="invoicesRequest.isLoading.value"
    >
      <template #loading>Loading repeating invoices...</template>
      <template #empty>No repeating invoices found</template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilter()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="id" header="ID" :sortable="true" class="whitespace-nowrap">
        <template #body="{ data }">
          <router-link :to="'/settings/finance/repeating-invoice/' + data.id" class="link">{{ data.id }}</router-link>
        </template>
      </Column>
      <Column
        field="billingAccount.name"
        filter-field="billingAccountName"
        header="Billing Account"
        :sortable="true"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
      >
        <template #body="{ data }">
          <span class="whitespace-wrap md:whitespace-nowrap">{{ data.billingAccount.name }}</span>
        </template>
        <!-- <template #filter="{ filterModel }">
          <div class="mb-3 font-bold">Account Picker</div>
          <MultiSelect v-model="filterModel.value" :options="billingAccounts" placeholder="Any" class="p-column-filter">
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option }}</span>
              </div>
            </template>
          </MultiSelect>
        </template> -->
      </Column>
      <Column field="isFixedFee" header="Type" :sortable="true">
        <template #body="{ data }">
          <Chip :severity="data.isFixedFee ? 'primary' : 'info'">
            {{ data.isFixedFee ? 'Fixed' : 'PAYG' }}
          </Chip>
        </template>
      </Column>
      <Column field="startDate" header="Start Date" :sortable="true" class="whitespace-nowrap">
        <template #body="{ data }">
          {{ dayjs(data.startDate).format('DD-MM-YYYY') }}
        </template>
      </Column>
      <Column field="repetitionPeriod" header="Repetition Period" :sortable="true">
        <template #body="{ data }">
          <div v-if="data.repetitionPeriod == 'month'">Monthly</div>
          <div v-else-if="data.repetitionPeriod == 'quarter'">Quarterly</div>
          <div v-else>{{ data.repetitionPeriod }}</div>
        </template>
      </Column>

      <Column field="dueNet" header="Due Net" :sortable="true" class="whitespace-nowrap">
        <template #body="{ data }"> {{ data.dueNet }} days </template>
      </Column>

      <Column
        field="dealerships"
        header="Dealerships"
        :sortable="true"
        class="whitespace-nowrap"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
      >
        <template #body="{ data }">
          <ChipList :items="data.dealershipChipList" :max-shown="3" :overflowChipProps="{ size: 'sm' }" />
        </template>
        <!-- <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template> -->
      </Column>

      <Column field="isPaused" header="Status" :sortable="true" class="whitespace-nowrap">
        <template #body="{ data }">
          <ToggleSwitch v-model="data.isActive" @change="toggleActiveStatus(data.id)" />
        </template>
      </Column>

      <!-- <Column>
        <template #body="{ data }">
          <Button icon="pencil" severity="secondary" plain @click="editTemplate(data.id)"></Button>
        </template>
      </Column> -->
    </DataTable>
  </Card>
</template>

<script setup>
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import Chip from '@/components/chip/Chip.vue'
import { onMounted, ref } from 'vue'
import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'
import ChipList from '@/components/chip/ChipList.vue'

const dt = ref()

const invoicesRequest = useApiRequest()
const invoicesList = ref([])

const dealershipsList = ref([])
const dealershipsApiRequest = useApiRequest()

const dealershipQueryParams = ref({
  showAll: true,
  showDisabled: true
})

dealershipsApiRequest.send({ method: 'GET', endpoint: '/v1/dealerships', params: dealershipQueryParams.value }).then(response => {
  if (response.success) {
    dealershipsList.value = response.data.map(dealership => {
      return { value: dealership.id, label: dealership.displayName, isActive: dealership.isActive }
    })
  } else {
    alertToast('Failed to fetch dealerships', response.message, 'error')
  }
})

function fetchInvoiceTemplates() {
  try {
    invoicesRequest.send({ method: 'GET', endpoint: '/v1/finance/invoice-templates' }).then(response => {
      if (response.success) {
        let invoices = []
        response.data.forEach(invoice =>
          invoices.push({
            ...invoice,
            isActive: !invoice.isPaused
          })
        )

        // For each invoice, create a dealershipChipList. If the dealership is inactive, add a warning icon
        invoices.forEach(invoice => {
          invoice.dealershipChipList = invoice.dealerships.map(dealershipId => {
            let dealership = dealershipsList.value.find(d => d.value == dealershipId)
            return {
              label: dealership.label,
              severity: dealership.isActive ? 'default' : 'warning',
              icon: dealership.isActive ? null : 'warning',
              size: 'sm',
              rounded: true,
              tooltip: dealership.isActive ? null : 'Dealership is inactive'
            }
          })
        })

        invoicesList.value = invoices
      } else {
        alertToast('Failed to fetch invoices', response.message, 'error')
      }
    })
  } catch (err) {
    alertToast('Failed to fetch invoices', err.message, 'error')
  }
}

onMounted(() => {
  fetchInvoiceTemplates()
})

const filters = ref(null)

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealerships: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

const clearFilter = () => {
  initFilters()
}

const exportCSV = () => {
  dt.value.exportCSV()
}

function toggleActiveStatus(id) {
  let invoiceTemplate = invoicesList.value.find(i => i.id == id)

  try {
    useApiRequest()
      .send({ method: 'PATCH', endpoint: `/v1/finance/invoice-templates/${id}`, data: { isPaused: !invoiceTemplate.isActive } })
      .then(response => {
        if (response.success) {
          alertToast('Updated', null, 'success')
          invoicesList.value.find(i => i.id == id).isActive = invoiceTemplate.isActive
        } else {
          alertToast('Failed to update invoice', response.message, 'error')
        }
      })
  } catch (err) {
    alertToast('Failed to update invoice', err.message, 'error')
  }
}
</script>

<style></style>
