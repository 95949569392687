<template>
  <PageHeader
    title="ReAuction List"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: 'ReAuction List', to: '/reports/vehicles/reauction-list' }
    ]"
  >
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="ReAuction List"
      :value="reauctions"
      removable-sort
      sort-field="date"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['vehicle.make', 'vehicle.model', 'vehicle.derivative', 'dealershipName', 'customerName', 'dealershipId']"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-reauction-list"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="isLoading"
      :export-function="formatExportData"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="vehicle.model" header="Vehicle" :sortable="true">
        <template #body="{ data }">
          <AvatarLabelGroup
            :label="data.vehicle.model"
            :description="data.vehicle.derivative"
            :avatar-url="data.vehicle.manufacturerLogoUrl"
            :label-link="'/vehicles/reauction/' + data.id"
            avatar-is-logo
          />
        </template>
      </Column>

      <Column field="vehicle.derivative" header="Derivative" hidden />
      <Column field="agreementId" header="Agreement Id" hidden />

      <Column field="customerName" header="Customer" :sortable="true">
        <template #body="{ data }">
          <router-link :to="'/agreement/' + data.agreementId">
            <div class="link-hover">{{ data.customerName }}</div>
          </router-link>
        </template>
      </Column>

      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          {{ data.dealershipName }}
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="dealershipName" header="Dealership Name" hidden />

      <Column field="createdAt" header="Date Created" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="dayjs(data.createdAt).format('ll')" class="inline-block">{{ dayjs().to(dayjs(data.createdAt)) }}</div>
        </template>
      </Column>

      <Column field="sale.status" header="Status" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Chip
            :rounded="false"
            outlined
            size="sm"
            dot
            :severity="statusOptions.find(option => option.value == data.sale.status)?.severity"
          >
            {{ statusOptions.find(option => option.value == data.sale.status)?.label }}
          </Chip>
        </template>

        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="statusOptions"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="sale.stage" header="Stage" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Chip v-if="data.sale.stage" :severity="stageOptions.find(option => option.value == data.sale.stage)?.severity">
            {{ stageOptions.find(option => option.value == data.sale.stage)?.label }}
          </Chip>
        </template>

        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="stageOptions"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'
import MultiSelect from 'primevue/multiselect'
import Chip from '@/components/chip/Chip.vue'

import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import dayjs from 'dayjs'
import { useUserStore } from '@/stores/UserStore'

const dt = ref()
const userStore = useUserStore()

const reauctionsApiRequest = useApiRequest()

const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const reauctions = ref([])
const filters = ref([])
const isLoading = reauctionsApiRequest.isLoading

const statusOptions = [
  { value: 'active', label: 'Active', severity: 'success' },
  { value: 'paused', label: 'Paused', severity: 'warning' },
  { value: 'expired', label: 'Expired', severity: 'danger' }
]

const stageOptions = [
  { value: 'awaiting_images', label: 'Awaiting images', severity: 'error' },
  { value: 'offering_dealership', label: 'Offering to dealership', severity: 'info' },
  { value: 'offering_group', label: 'Offering to group', severity: 'info' },
  { value: 'offering_national', label: 'Offering nationally', severity: 'info' },
  { value: 'awaiting_offer_acceptance', label: 'Awaiting offer acceptance', severity: 'warning' }
]

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN },
    dealershipName: { value: null, matchMode: FilterMatchMode.IN },
    customerName: { value: null, matchMode: FilterMatchMode.IN },
    'vehicle.make': { value: null, matchMode: FilterMatchMode.IN },
    'vehicle.model': { value: null, matchMode: FilterMatchMode.IN },
    'vehicle.derivative': { value: null, matchMode: FilterMatchMode.IN },
    'sale.status': { value: null, matchMode: FilterMatchMode.IN },
    'sale.stage': { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  sessionStorage.removeItem('dt-report-appointment-list')
  initFilters()
}

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

reauctionsApiRequest
  .send({
    endpoint: '/v1/valuations',
    params: { dealershipIds: JSON.stringify(dealershipsAllowed.value.map(d => d.id)), types: 'sell' }
  })
  .then(response => {
    console.log(response)
    if (!response.success) {
      alertToast('Failed to fetch ReAuctions', response.data?.message, 'error')
      return
    }

    // If external user, filter out items that are not on the auth user's level (dealership, group, etc.)
    if (userStore.details.type == 2) {
      response.data = response.data.filter(item => item.sale.offeringTo === userStore.details.level)
    }
    reauctions.value = response.data
  })

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
