<template>
  <div class="wrapper text-sm">
    <div>
      <div class="flex items-center justify-between mb-10">
        <h1 class="font-bold capitalize">{{ $t('ui.entities.location-selection.label') }}</h1>
        <Icon v-if="accessSelected.dealerships.length > 0" type="times" class="text-3xl cursor-pointer" @click="closeView"> </Icon>
      </div>

      <Accordion>
        <AccordionItem :title="$t('ui.entities.location-selection.region', 2)" v-model:is-open="accordionState.regions">
          <div v-if="accessSelector.regions.length > 1" class="mb-2 text-secondary">
            <Checkbox
              :is-checked="areAllSelected('regions')"
              :label="areAllSelected('regions') ? $t('ui.actions.deselect-all') : $t('ui.actions.select-all')"
              checkbox-color="utility-gray-500"
              @change="selectAll('regions')"
            />
          </div>
          <div v-for="item in accessSelector.regions" :key="item.id" class="mb-1">
            <Checkbox :is-checked="accessSelected.regions.includes(item.id)" :label="item.name" @change="toggleItem('regions', item.id)" />
          </div>
          <div v-if="accessSelector.regions.length === 0">{{ $t('ui.support.assistance') }}</div>
        </AccordionItem>

        <AccordionItem :title="$t('ui.entities.manufacturer', 2)" v-model:is-open="accordionState.manufacturers">
          <div v-if="accessSelector.manufacturers.length > 1" class="mb-2 text-secondary">
            <Checkbox
              :is-checked="areAllSelected('manufacturers')"
              :label="areAllSelected('manufacturers') ? $t('ui.actions.deselect-all') : $t('ui.actions.select-all')"
              checkbox-color="utility-gray-500"
              @change="selectAll('manufacturers')"
            />
          </div>
          <div v-for="item in accessSelector.manufacturers" :key="'manufacturer-' + item.id" class="mb-1">
            <Checkbox
              :is-checked="accessSelected.manufacturers.includes(item.id)"
              :label="item.name"
              @change="toggleItem('manufacturers', item.id)"
            />
          </div>
          <div v-if="accessSelector.manufacturers.length === 0" class="text-secondary">
            {{ $t('ui.entities.location-selection.sentences.select-regions', { item: $t('ui.entities.location-selection.region', 2) }, 2) }}
          </div>
        </AccordionItem>

        <AccordionItem :title="$t('ui.entities.group', 2)" v-model:is-open="accordionState.groups">
          <div v-if="accessSelector.groups.length > 1" class="mb-2 text-secondary">
            <Checkbox
              :is-checked="areAllSelected('groups')"
              :label="areAllSelected('groups') ? $t('ui.actions.deselect-all') : $t('ui.actions.select-all')"
              checkbox-color="utility-gray-500"
              @change="selectAll('groups')"
            />
          </div>
          <div v-for="item in accessSelector.groups" :key="'group-' + item.id" class="mb-1">
            <Checkbox :is-checked="accessSelected.groups.includes(item.id)" :label="item.name" @change="toggleItem('groups', item.id)" />
          </div>
          <div v-if="accessSelector.groups.length === 0" class="text-secondary">
            {{ $t('ui.entities.location-selection.sentences.select-manufacturers', { item: $t('ui.entities.manufacturer', 2) }, 2) }}
          </div>
        </AccordionItem>

        <AccordionItem :title="$t('ui.entities.dealership', 2)" v-model:is-open="accordionState.dealerships">
          <div v-if="accessSelector.dealerships.length > 1" class="mb-2 text-secondary">
            <Checkbox
              :is-checked="areAllSelected('dealerships')"
              :label="areAllSelected('dealerships') ? $t('ui.actions.deselect-all') : $t('ui.actions.select-all')"
              checkbox-color="utility-gray-500"
              @change="selectAll('dealerships')"
            />
          </div>
          <div v-for="item in accessSelector.dealerships" :key="'dealership-' + item.id" class="mb-1">
            <Checkbox
              :is-checked="accessSelected.dealerships.includes(item.id)"
              :label="item.name"
              @change="toggleItem('dealerships', item.id)"
            />
          </div>
          <div v-if="accessSelector.dealerships.length === 0" class="text-secondary">
            {{ $t('ui.entities.location-selection.sentences.select-groups', { item: $t('ui.entities.group', 2) }, 2) }}
          </div>
        </AccordionItem>
      </Accordion>
      <Button
        :label="$t('ui.actions.save')"
        :disabled="accessSelected.dealerships.length > 0 ? false : true"
        class="mt-10"
        @click="saveChanges"
      />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref, computed, onMounted, watch } from 'vue'

import { useUserStore } from '@/stores/UserStore'
import updateAbility from '@/config/update-ability.js'

import Icon from '@/components/icon/Icon.vue'
import Accordion from '@/components/accordion/Accordion.vue'
import AccordionItem from '@/components/accordion/AccordionItem.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import Button from '@/components/button/Button.vue'

export default {
  components: {
    Icon,
    Accordion,
    AccordionItem,
    Checkbox,
    Button
  },
  setup() {
    const router = useRouter()
    const userStore = useUserStore()

    onMounted(async () => {
      const response = await userStore.verify()
      if (response.success) {
        updateAbility(null, router)
      } else {
        userStore.signOut()
      }
    })

    const accordionState = ref({
      regions: true,
      manufacturers: true,
      groups: true,
      dealerships: true
    })

    const accessAllowed = ref(userStore.accessAllowed)
    const accessSelected = ref(JSON.parse(localStorage.getItem('accessSelected')))

    // If no access has ever been selected previously
    if (accessSelected.value === null) {
      accessSelected.value = {
        regions: [],
        manufacturers: [],
        groups: [],
        dealerships: []
      }
    }

    // Watch for changes in the store and update the local state accordingly
    watch(
      () => userStore.accessAllowed,
      newVal => {
        accessAllowed.value = newVal
      }
    )

    // Computed should check if the value is in accessSelected
    const accessSelector = computed(() => {
      // Loop through all items in accessAllowed, and check if the value is present in accessSelected.
      let newArray = {}
      newArray.regions = accessAllowed.value.regions.map(obj => {
        return {
          ...obj,
          isSelected: accessSelected.value.regions.includes(obj.id)
        }
      })
      newArray.manufacturers = accessAllowed.value.manufacturers.map(obj => {
        return {
          ...obj,
          isSelected: accessSelected.value.manufacturers.includes(obj.id)
        }
      })
      newArray.groups = accessAllowed.value.groups.map(obj => {
        return {
          ...obj,
          isSelected: accessSelected.value.groups.includes(obj.id)
        }
      })
      newArray.dealerships = accessAllowed.value.dealerships.map(obj => {
        return {
          ...obj,
          isSelected: accessSelected.value.dealerships.includes(obj.id)
        }
      })

      accessAllowed.value.manufacturers.forEach(man => {
        //Check if the manufacturer should be visible in the DOM or not
        man.isVisible = man.regions.some(manRegion => accessSelected.value.regions.includes(manRegion))
        let isSelected = accessSelected.value.manufacturers.includes(man.id)

        // If is Selected, and should not be visible, remove is selected
        if (isSelected && !man.isVisible) {
          // Remove from accessSelected
          toggleItem('manufacturers', man.id, false)
        }
      })
      //Filter out any that isVisible=false
      newArray.manufacturers = accessAllowed.value.manufacturers.filter(man => man.isVisible)

      newArray.groups = accessAllowed.value.groups.map(obj => {
        return {
          ...obj,
          isSelected: accessSelected.value.groups.includes(obj.id)
        }
      })

      accessAllowed.value.groups.forEach(group => {
        //Determine if region match
        let regionMatch = group.regions.some(region => accessSelected.value.regions.includes(region))

        //Determine if manufacturer match
        let manufacturerMatch = group.manufacturers.some(man => accessSelected.value.manufacturers.includes(man))

        //Check both matches
        if (regionMatch && manufacturerMatch) {
          group.isVisible = true
        } else {
          group.isVisible = false
        }

        let isSelected = accessSelected.value.groups.includes(group.id)

        // If is Selected, and should not be visible, remove is selected
        if (isSelected && !group.isVisible) {
          toggleItem('groups', group.id, false)
        }
      })

      //Filter out any that isVisible=false
      newArray.groups = accessAllowed.value.groups.filter(group => group.isVisible)

      newArray.dealerships = accessAllowed.value.dealerships.map(obj => {
        return {
          ...obj,
          isSelected: accessSelected.value.dealerships.includes(obj.id)
        }
      })

      accessAllowed.value.dealerships.forEach(dealership => {
        let dealershipGroup = dealership.groupId

        //Determine if manufacturer match
        let manufacturerMatch = dealership.manufacturers.some(man => accessSelected.value.manufacturers.includes(man))

        //Determine if group match
        let groupMatch = accessSelected.value.groups.includes(dealershipGroup)
        // console.log(dealership.name, manufacturerMatch, groupMatch);

        //Check both matches
        if (groupMatch && manufacturerMatch) {
          dealership.isVisible = true
        } else {
          dealership.isVisible = false
        }
        let isSelected = accessSelected.value.dealerships.includes(dealership.id)

        // If is Selected, and should not be visible, remove is selected
        if (isSelected && !dealership.isVisible) {
          toggleItem('dealerships', dealership.id, false)
          dealership.isSelected = false
        }
      })

      //Filter out any that isVisible=false
      newArray.dealerships = accessAllowed.value.dealerships.filter(dealership => dealership.isVisible)

      // set if accordion should be open or closed
      newArray.regions.length > 1 ? (accordionState.value.regions = true) : (accordionState.value.regions = false)
      newArray.manufacturers.length > 1 ? (accordionState.value.manufacturers = true) : (accordionState.value.manufacturers = false)
      newArray.groups.length > 1 ? (accordionState.value.groups = true) : (accordionState.value.groups = false)
      newArray.dealerships.length > 1 ? (accordionState.value.dealerships = true) : (accordionState.value.dealerships = false)

      // console.log('New: ', newArray)

      return newArray
    })

    function toggleItem(category, itemId, value) {
      let newValue
      if (value != null) {
        newValue = value
      } else {
        newValue = !accessSelected.value[category].includes(itemId)
      }

      // console.log(newValue)

      if (newValue) {
        // Add item to array
        accessSelected.value[category].push(itemId)
      } else {
        // Remove item from array
        const index = accessSelected.value[category].indexOf(itemId)
        if (index > -1) {
          accessSelected.value[category].splice(index, 1)
        }
      }
    }

    function closeView() {
      if (userStore.details.type !== 0) {
        router.push('/home')
      } else {
        router.back()
      }
    }

    function saveChanges() {
      // userStore.accessAllowed = accessAllowed.value
      localStorage.setItem('accessSelected', JSON.stringify(accessSelected.value))
      closeView()
    }

    function areAllSelected(category) {
      if (accessSelected.value[category].length > 0) {
        return accessSelector.value[category].every(item => accessSelected.value[category].includes(item.id))
      } else {
        return false
      }
    }

    function selectAll(category) {
      // console.log('Selecting All ' + category)

      // If all items are already selected, unselect them
      if (areAllSelected(category)) {
        accessSelector.value[category].forEach(item => {
          toggleItem(category, item.id, false)
        })
      } else {
        // Otherwise, select all items
        accessSelector.value[category].forEach(item => {
          if (!accessSelected.value[category].includes(item.id)) {
            // accessSelected.value[category].push(item.id)
            toggleItem(category, item.id, true)
          }
        })
      }
    }

    return {
      accordionState,
      userStore,
      accessAllowed,
      accessSelected,
      accessSelector,
      toggleItem,
      areAllSelected,
      selectAll,
      closeView,
      saveChanges
    }
  }
}
</script>

<style scoped>
body {
  min-height: 100%;
}

.wrapper {
  padding: 3rem;

  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: 600px;
}
</style>
