// TODO: Merge this component with InvoiceItem.vue, as 95% of the code is the same
<template>
  <div class="invoice-item invoice-item-new grid grid-cols-12 gap-4">
    <div class="col-span-6">
      <Select v-model="itemSelected" :options="itemsComputed" searchable @select="onItemSelected">
        <template #option="{ option }">
          <div class="w-full flex gap-4 justify-between">
            <div>
              <div>{{ option.label }}</div>
              <div class="text-tertiary text-sm leading-tight max-w-xs whitespace-normal">{{ option.description }}</div>
            </div>
            <div>£{{ option.rate }}</div>
          </div>
        </template>
      </Select>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import useApiRequest from '@/composables/useApiRequest'

import Dropdown from 'primevue/dropdown'
import Select from '@/components/forms/Select.vue'

const props = defineProps({
  invoiceTemplateId: {
    type: Number,
    required: true
  },
  dealershipId: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['item-added'])

const itemsRequest = useApiRequest()
const itemSelected = ref(null)
const items = ref(null)

itemsRequest
  .send({
    endpoint: '/v1/finance/invoice-items-catalog'
  })
  .then(response => {
    items.value = response.data
  })

// convert items to a format that the multiselect component can understand (label, value)
const itemsComputed = computed(() => {
  if (!items.value) return []
  return items.value.map(item => {
    return {
      label: item.name,
      value: item.id,
      description: item.description,
      rate: item.saleRate
    }
  })
})

function onItemSelected() {
  let item = {
    id: null,
    randId: Math.random(),
    invoiceTemplateId: props.invoiceTemplateId,
    invoiceTemplateDealershipId: props.dealershipId,
    quantity: 1,
    discountUnit: 'percent',
    discountValue: null,
    catalogItemId: itemSelected.value,
    overrideRate: null,
    catalogItem: items.value.find(item => item.id == itemSelected.value)
  }

  // console.log('item', item)
  let dealershipId = props.dealershipId
  emit('item-added', dealershipId, item)

  // Reset the selected item
  itemSelected.value = null
}
</script>

<style>
.invoice-item-new {
  background-color: var(--bg-secondary);
}
</style>
