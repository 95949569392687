<template>
  <Sidebar :is-open="isOpen" :has-changes="hasUnsavedChanges" @on-close="closeSidebar">
    <SidebarContent class="pt-6">
      <form ref="form" @submit.prevent="taskHandlerSubmit">
        <FormGroup>
          <div class="flex items-center">
            <Checkbox v-model="state.isCompleted" size="lg" />
            <input
              ref="inputTitle"
              v-model="state.title"
              type="text"
              :has-error="v$.title.$error"
              class="text-xl w-full py-2 my-2 bg-transparent pr-5"
              :class="{ 'border border-error rounded-md px-[0.5rem] py-[0.75rem]': v$.title.$error }"
              placeholder="Task name"
            />
            <Icon
              type="star"
              :fa-style="state.isStarred ? 'fas' : 'fad'"
              class="mx-2 cursor-pointer text-lg"
              :class="[state.isStarred ? 'text-yellow-500' : 'text-quaternary']"
              @click="state.isStarred = !state.isStarred"
            />
          </div>
          <InputError :has-error="v$.title.$error" class="ml-10">{{ v$.title.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup>
          <TextArea
            id="description"
            v-model="state.description"
            :value="state.description"
            :has-error="false"
            placeholder="Description"
          ></TextArea>
          <InputError :has-error="v$.description.$error">{{ v$.description.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup v-if="state.linkType == 'agreement'" :label="$t('ui.entities.agreement.label')">
          <router-link v-if="state.linkType == 'agreement'" :to="'/agreement/' + state.linkID">
            <Chip icon="link">{{ state.linkTitle }}</Chip>
          </router-link>
        </FormGroup>

        <FormGroup v-if="state.createdBy != sessionUserID" :label="$t('ui.entities.task.assigned-by')" label-for="assignedBy">
          <Chip :avatar="createdByData[0].profilePicURL" :avatar-text="createdByData[0].initials">{{
            createdByData[0].fullName || '?'
          }}</Chip>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.task.assigned-to')" label-for="assignee" :class="{ hidden: !$can('assign_tasks') }">
          <Multiselect
            v-model="state.assignee"
            mode="single"
            label="fullName"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="users.data.value"
            track-by="fullName"
            value-prop="id"
            placeholder="Select a User"
          >
            <template #placeholder="{}">
              <div class="multiselect-placeholder"><Icon type="user" size="sm" class="mr-2" /> Select a user</div>
            </template>
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <Avatar :url="value.profilePicURL" :text="value.initials" size="xs" class="mr-2" /> {{ value.fullName }}
              </div>
            </template>

            <template #option="{ option }">
              <Avatar :url="option.profilePicURL" :text="option.initials" size="sm" class="mr-2" /> {{ option.fullName }}
            </template>
          </Multiselect>
          <InputError :has-error="v$.assignee.$error">{{ v$.assignee.$errors[0].$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.common.due-date')" label-for="dueDate">
          <DatePicker v-model="state.dueDate" :config="{ enableTime: true }" :has-error="v$.dueDate.$error" />
          <InputError :has-error="v$.dueDate.$error">{{ v$.dueDate.$errors[0].$message }}</InputError>
        </FormGroup>
      </form>
    </SidebarContent>
    <SidebarFooter>
      <div class="flex justify-end">
        <div v-if="!isNewTask" class="w-full">
          <Button v-if="!state.isDeleted" severity="danger" label="Delete Task" plain @click="deleteTask(true)" />
          <Button v-else severity="secondary" label="Restore Task" plain @click="deleteTask(false)" />
        </div>
        <div class="flex justify-end gap-2">
          <Button
            :label="$t('ui.actions.cancel')"
            severity="secondary"
            outlined
            type="button"
            :disabled="submitIsLoading"
            @click="closeSidebar"
          />
          <Button
            :label="isNewTask ? $t('ui.actions.add') : $t('ui.actions.save')"
            :is-loading="submitIsLoading"
            @click="taskHandlerSubmit"
          />
        </div>
      </div>
    </SidebarFooter>
  </Sidebar>
</template>

<script>
import { ref, watch, onMounted, nextTick } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import DatePicker from '@/components/forms/DatePicker.vue'

import useApiRequest from '@/composables/useApiRequest'

import Icon from '@/components/icon/Icon.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import SidebarContent from '@/components/sidebar/SidebarContent.vue'
import SidebarFooter from '@/components/sidebar/SidebarFooter.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import TextArea from '@/components/forms/TextArea.vue'
import InputError from '@/components/forms/InputError.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'

import Multiselect from '@vueform/multiselect'

import { alertToast } from '@/utilities/notification'

import { useUserStore } from '@/stores/UserStore'

export default {
  components: {
    Icon,
    Avatar,
    Sidebar,
    SidebarContent,
    SidebarFooter,
    FormGroup,
    Checkbox,
    TextArea,
    InputError,
    Button,
    Chip,
    DatePicker,
    Multiselect
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    task: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['updateTaskSubmit', 'closeTaskHandler', 'newTaskSubmit'],
  setup(props, { emit }) {
    const userStore = useUserStore()
    const sessionUserID = ref(userStore.details.id)

    // Instantiate state
    const isNewTask = ref(true)

    const defaultState = {
      id: null,
      title: '',
      assignee: sessionUserID.value,
      dueDate: '',
      tags: [],
      description: null,
      linkType: null,
      linkTitle: null,
      linkID: null,
      isStarred: false,
      isCompleted: false,
      isReminder: false,
      isDeleted: false,
      createdBy: sessionUserID.value
    }

    const stateInit = props.task?.id ? { ...props.task } : { ...defaultState }

    const state = ref({ ...stateInit })

    const hasUnsavedChanges = ref(false)

    onMounted(() => {})

    watch(
      state,
      newValue => {
        // Compare the current state with the initial state
        hasUnsavedChanges.value = JSON.stringify(newValue) !== JSON.stringify(stateInit)
      },
      { deep: true } // Deep watch to handle nested properties inside the state
    )

    const inputTitle = ref(null)

    // Watch for change in isOpen
    watch(
      () => props.isOpen,
      isOpen => {
        if (isOpen) {
          nextTick(() => {
            if (isNewTask.value) {
              inputTitle.value.focus()
            }
          })
        }
      },
      { immediate: true }
    )

    if (props.task) {
      if (props.task.id) {
        isNewTask.value = false
      }

      // Iterate over the properties of props.task and set them to the state reference
      Object.keys(props.task).forEach(key => {
        if (props.task[key] !== undefined) {
          state.value[key] = ref(props.task[key])
        }
      })
    }

    const createdByData = ref([
      {
        fullName: null,
        profilePicURL: null,
        initials: null
      }
    ])

    // Users List
    const users = useApiRequest()
    users.send({ endpoint: '/v1/users?userType=retainUsers' }).then(response => {
      if (response.success) {
        createdByData.value = users.data.value.filter(user => parseInt(user.id) === parseInt(state.value.createdBy))
      }
    })

    function clearForm() {
      // console.log('Clearing Form')

      state.value.title = ''
      state.value.assignee = sessionUserID
      state.value.dueDate = ''
      state.value.tags = []
      state.value.description = ''
    }

    const vuelidateRules = {
      title: { required },
      assignee: { required },
      dueDate: {},
      tags: '',
      description: ''
    }
    const v$ = useVuelidate(vuelidateRules, state, { $stopPropagation: true })

    function closeSidebar() {
      emit('closeTaskHandler')
    }

    const taskApiRequest = useApiRequest()

    async function taskHandlerSubmit() {
      // console.log('Is New Task? : ' + isNewTask.value)

      const isFormCorrect = await v$.value.$validate()
      // console.log('form is correct: ' + isFormCorrect)

      if (!isFormCorrect) {
        // If errors in form
        // console.log('Title Errors: ' + JSON.stringify(v$.value.title.$errors))
        // console.log('Title Errors: ' + v$.value.title.$errors.$message)
      } else {
        const jsonData = JSON.stringify({
          assignedTo: state.value.assignee,
          title: state.value.title,
          description: state.value.description,
          tags: state.value.tags,
          dueDate: state.value.dueDate,
          linkType: state.value.linkType,
          linkTitle: state.value.linkTitle,
          linkId: state.value.linkID,
          isReminder: state.value.isReminder,
          isStarred: state.value.isStarred,
          isCompleted: state.value.isCompleted,
          isDeleted: state.value.isDeleted,
          createdBy: state.value.createdBy
        })

        // console.log('DATA: ' + jsonData)

        if (isNewTask.value) {
          taskApiRequest.send({ endpoint: '/v1/todos', method: 'POST', data: jsonData }).then(response => {
            if (response.success) {
              alertToast('Added', null, 'success')
              // Emit event to todo page with json, which will then add the task to the DOM
              emit('newTaskSubmit', response.data)
            }
          })
        } else {
          // Update existing task
          taskApiRequest
            .send({ endpoint: '/v1/todos/' + state.value.id, method: 'PATCH', data: jsonData })
            .then(response => {
              // console.log('Response: ' + JSON.stringify(response))

              // Emit event to todo page with json, which will then update the task in the DOM
              if (response.success) {
                alertToast('Updated', null, 'success')
                emit('updateTaskSubmit', state.value)
              }
            })
            .catch(err => {
              console.error('Error in sendRequest:', err)
              alertToast('Error Updating Task', null, 'error')
            })
        }
      }
    }

    function deleteTask(shouldDelete) {
      // console.log('Deleting Task: ' + shouldDelete)
      state.value.isDeleted = shouldDelete

      taskHandlerSubmit()
    }

    return {
      sessionUserID,
      closeSidebar,
      isNewTask,
      inputTitle,
      taskHandlerSubmit,
      submitIsLoading: taskApiRequest.isLoading,
      v$,
      state,
      users,
      clearForm,
      deleteTask,
      createdByData,
      hasUnsavedChanges
    }
  }
}
</script>

<style></style>
