<template>
  <div class="flex" :class="'switch--' + size">
    <div
      :class="[
        'toggle-switch',
        'toggle-switch--' + size,
        {
          'toggle-switch--on': modelValue,
          'toggle-switch--disabled': disabled,
          'toggle-switch--readonly': readonly,
          'toggle-switch--text': textOptions
        },
        'w-[' + fixedWidth + ']'
      ]"
      :style="{ backgroundColor: modelValue == true ? styling.truthy.backgroundColor : styling.falsy.backgroundColor }"
      @click="toggle"
    >
      <div class="min-w-[14px] w-full h-full flex justify-center items-center">
        <div
          v-if="styling.truthy.label"
          :style="modelValue == true ? { color: styling.truthy.color } : { color: styling.falsy.color }"
          class="mx-1 whitespace-nowrap"
        >
          {{ modelValue == true ? styling.truthy.label : styling.falsy.label }}
        </div>
        <Icon
          v-else-if="styling.truthy.icon"
          :style="modelValue == true ? { color: styling.truthy.color } : { color: styling.falsy.color }"
          :type="modelValue == true ? styling.truthy.icon : styling.falsy.icon"
        />
      </div>

      <div :class="['toggle-switch__slider', { 'toggle-switch__slider--on': modelValue }]"></div>
    </div>
    <div v-if="label || description">
      <div v-if="label" class="flex items-center switch-label-spacing">
        <div class="font-medium leading-7">{{ label }}</div>
        <Chip v-if="chipLabel" :size="size == 'sm' ? 'xs' : 'sm'" :severity="chipSeverity">{{ chipLabel }}</Chip>
      </div>
      <slot v-else name="label"></slot>

      <div v-if="description" class="text-tertiary max-w-[500px]">{{ description }}</div>
      <slot v-else name="description"></slot>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'

import Chip from '@/components/chip/Chip.vue'
import Icon from '@/components/icon/Icon.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: null
  },
  chipLabel: {
    type: String,
    default: null
  },
  chipSeverity: {
    type: String,
    default: 'secondary'
  },
  description: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'md'
  },
  fixedWidth: {
    type: String,
    default: null
  },
  styling: {
    type: Object,
    default: () => {
      return {
        truthy: {
          backgroundColor: 'var(--bg-brand-solid)',
          color: 'var(--white)',
          label: null,
          icon: null
        },
        falsy: {
          backgroundColor: 'var(--bg-tertiary)',
          color: 'var(--fg-primary)',
          label: null,
          icon: null
        }
      }
    }
  }
})

const emit = defineEmits(['update:modelValue', 'change'])

const toggle = () => {
  if (!props.disabled && !props.readonly) {
    emit('update:modelValue', !props.modelValue)
    emit('change', !props.modelValue)
  }
}

watch(
  () => props.modelValue,
  newValue => {
    emit('update:modelValue', newValue)
  }
)
</script>

<style>
.switch--md {
  gap: var(--s-4);
}
.switch--sm {
  gap: var(--s-3);
  font-size: var(--text-sm);
}
.switch--md .switch-label-spacing {
  gap: var(--s-4);
}
.switch--sm .switch-label-spacing {
  gap: var(--s-2);
}
.toggle-switch {
  position: relative;
  height: 24px;
  padding: 0px 6px 0px 24px;
  background-color: var(--bg-tertiary);
  border-radius: var(--rounded-full);
  cursor: pointer;
  transition: color 0.3s;
  transition: padding 0.3s;
}
.toggle-switch--sm {
  height: 20px;
  padding: 0px 4px 0px 20px;
}

.toggle-switch--disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.toggle-switch--readonly {
  cursor: not-allowed;
  opacity: 0.6;
}

.toggle-switch__slider {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--fg-white);
  border-radius: var(--rounded-full);
  top: 2px;
  left: 2px;
  transition: left 0.3s;
  box-shadow: var(--shadow-sm);
}
.toggle-switch--sm .toggle-switch__slider {
  width: 16px;
  height: 16px;
}

.toggle-switch--on {
  padding: 0px 24px 0px 6px;
}

.toggle-switch--sm.toggle-switch--on {
  padding: 0px 20px 0px 4px;
}

.toggle-switch__slider--on {
  left: calc(100% - 22px);
}
.toggle-switch--sm .toggle-switch__slider--on {
  left: calc(100% - 18px);
}
</style>
