<template>
  <FormGroup class="mt-5" label="Regions">
    <div class="grid grid-cols-4">
      <div class="col-span-3">
        <Multiselect
          v-model="state.regions"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :hide-selected="true"
          value-prop="id"
          track-by="name"
          label="name"
          :placeholder="state.regionsAll ? 'All' : 'None selected'"
          :options="userStore.accessAllowed.regions"
          :can-clear="true"
          :can-deselect="true"
          :disabled="state.regionsAll"
          @change="onSelectionChange"
        >
          <template #tag="{ option, handleTagRemove }">
            <Chip size="sm" :rounded="false" class="mr-1 -mt-[0.25rem]" can-remove @remove-chip="$event => handleTagRemove(option, $event)">
              {{ option.name }}
            </Chip>
          </template>
        </Multiselect>
      </div>
      <div class="flex justify-center">
        <ToggleSwitch v-if="userStore.details.type == 1" v-model="state.regionsAll" label="All" @change="onSelectionChange" />
        <!-- TODO: change v-if based on if access allowed = * or not -->
      </div>
    </div>
  </FormGroup>

  <FormGroup class="mt-5" label="Manufacturers">
    <div class="grid grid-cols-4">
      <div class="col-span-3">
        <Multiselect
          v-model="state.manufacturers"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :hide-selected="true"
          value-prop="id"
          track-by="name"
          label="name"
          :placeholder="state.manufacturersAll ? 'All' : 'None selected'"
          :options="dropdownAccessOptions.manufacturers"
          :can-clear="true"
          :can-deselect="true"
          :disabled="state.manufacturersAll"
          @change="onSelectionChange"
        >
          <template #tag="{ option, handleTagRemove }">
            <Chip size="sm" :rounded="false" class="mr-1 -mt-[0.25rem]" can-remove @remove-chip="$event => handleTagRemove(option, $event)">
              {{ option.name }}
            </Chip>
          </template>
        </Multiselect>
      </div>
      <div class="flex justify-center">
        <ToggleSwitch v-if="userStore.details.type == 1" v-model="state.manufacturersAll" label="All" @change="onSelectionChange" />
      </div>
    </div>
  </FormGroup>

  <FormGroup class="mt-5" label="Groups">
    <div class="grid grid-cols-4">
      <div class="col-span-3">
        <Multiselect
          v-model="state.groups"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :hide-selected="true"
          value-prop="id"
          track-by="name"
          label="name"
          :placeholder="state.groupsAll ? 'All' : 'None selected'"
          :options="dropdownAccessOptions.groups"
          :can-clear="true"
          :can-deselect="true"
          :disabled="state.groupsAll"
          @change="onSelectionChange"
        >
          <template #tag="{ option, handleTagRemove }">
            <Chip size="sm" :rounded="false" class="mr-1 -mt-[0.25rem]" can-remove @remove-chip="$event => handleTagRemove(option, $event)">
              {{ option.name }}
            </Chip>
          </template>
        </Multiselect>
      </div>
      <div class="flex justify-center">
        <ToggleSwitch v-if="userStore.details.type == 1" v-model="state.groupsAll" label="All" @change="onSelectionChange" />
      </div>
    </div>
  </FormGroup>

  <FormGroup class="mt-5" label="Dealerships">
    <div class="grid grid-cols-4">
      <div class="col-span-3">
        <Multiselect
          v-model="state.dealerships"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :hide-selected="true"
          value-prop="id"
          track-by="name"
          label="name"
          :placeholder="state.dealershipsAll ? 'All' : 'None selected'"
          :options="dropdownAccessOptions.dealerships"
          :can-clear="true"
          :can-deselect="true"
          :disabled="state.dealershipsAll"
          @change="onSelectionChange"
        >
          <template #tag="{ option, handleTagRemove }">
            <Chip size="sm" :rounded="false" class="mr-1 -mt-[0.25rem]" can-remove @remove-chip="$event => handleTagRemove(option, $event)">
              {{ option.name }}
            </Chip>
          </template>
        </Multiselect>
      </div>

      <div class="flex justify-center">
        <ToggleSwitch v-if="userStore.details.type == 1" v-model="state.dealershipsAll" label="All" @change="onSelectionChange" />
      </div>
    </div>
  </FormGroup>

  <Alert
    v-if="emptyAccessWarning"
    severity="warning"
    icon="circle-exclamation"
    title="Leaving an access category empty will leave the user with no access."
    message="Please ensure at least one item is selected in each category."
    :show-dismiss-button="false"
    :show-close-button="false"
  />
</template>

<script>
import { watch, ref, nextTick } from 'vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Label from '@/components/forms/Label.vue'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import Multiselect from '@vueform/multiselect'

import Alert from '@/components/alert/Alert.vue'

import { useUserStore } from '@/stores/UserStore'
import Chip from '@/components/chip/Chip.vue'

export default {
  components: {
    FormGroup,
    Label,
    ToggleSwitch,
    Multiselect,
    Chip
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const userStore = useUserStore()

    const emptyAccessWarning = ref(null)

    const defaultState = {
      regions: [],
      regionsAll: false,
      manufacturers: [],
      manufacturersAll: false,
      groups: [],
      groupsAll: false,
      dealerships: [],
      dealershipsAll: false
    }
    const state = ref(defaultState)

    // Watch for changes in the modelValue and update the internal state
    watch(
      () => props.modelValue,
      newValue => {
        console.log('modelValue changed', newValue)
        if (newValue) {
          state.value = newValue
        }
      },
      { immediate: true }
    )

    const dropdownAccessOptions = ref({})

    function updateOptions() {
      const newOptions = {}

      newOptions.manufacturers = userStore.accessAllowed.manufacturers.filter(manufacturer => {
        return state.value.regionsAll || manufacturer.regions.some(region => state.value.regions.includes(region))
      })

      newOptions.groups = userStore.accessAllowed.groups.filter(group => {
        return (
          (state.value.regionsAll || group.regions.some(region => state.value.regions.includes(region))) &&
          (state.value.manufacturersAll || group.manufacturers.some(manufacturer => state.value.manufacturers.includes(manufacturer)))
        )
      })

      newOptions.dealerships = userStore.accessAllowed.dealerships.filter(dealership => {
        return (
          (state.value.manufacturersAll ||
            dealership.manufacturers.some(manufacturer => state.value.manufacturers.includes(manufacturer))) &&
          (state.value.groupsAll || state.value.groups.some(group => group === dealership.groupId))
        )
      })

      // Remove selected items from dropdowns if no longer in newOptions

      // Check if selected dealerships are still available in the new options
      state.value.dealerships = state.value.dealerships.filter(dealership => {
        return newOptions.dealerships.some(newDealership => newDealership.id === dealership)
      })
      // Check if selected groups are still available in the new options
      state.value.groups = state.value.groups.filter(group => {
        return newOptions.groups.some(newGroup => newGroup.id === group)
      })
      // Check if selected manufacturers are still available in the new options
      state.value.manufacturers = state.value.manufacturers.filter(manufacturer => {
        return newOptions.manufacturers.some(newManufacturer => newManufacturer.id === manufacturer)
      })

      dropdownAccessOptions.value = newOptions
    }
    updateOptions()

    function onSelectionChange() {
      nextTick(() => {
        // Set to an empty array if 'all' is false and the array is empty
        if (!state.value.regionsAll && state.value.regions.length === 0) {
          state.value.manufacturers = []
          state.value.groups = []
          state.value.dealerships = []
        }
        if (!state.value.manufacturersAll && state.value.manufacturers.length === 0) {
          state.value.groups = []
          state.value.dealerships = []
        }
        if (!state.value.groupsAll && state.value.groups.length === 0) {
          state.value.dealerships = []
        }
        if (state.value.groups.length > 0) {
          state.value.dealerships
        }

        if (state.value.regionsAll) {
          state.value.regions = []
        }
        if (state.value.manufacturersAll) {
          state.value.manufacturers = []
        }
        if (state.value.groupsAll) {
          state.value.groups = []
        }
        if (state.value.dealershipsAll) {
          state.value.dealerships = []
        }
        updateOptions()
      })
    }

    // Watch for changes in the internal state and emit an update
    watch(
      state,
      newValue => {
        console.log('state changed', newValue)
        emit('update:modelValue', newValue)

        // Check if each has atleast all or 1 item selected. If not, show warning message in UI
        if (
          (state.value.regionsAll == false && state.value.regions.length == 0) ||
          (state.value.manufacturersAll == false && state.value.manufacturers.length == 0) ||
          (state.value.groupsAll == false && state.value.groups.length == 0) ||
          (state.value.dealershipsAll == false && state.value.dealerships.length == 0)
        ) {
          emptyAccessWarning.value = true
        } else {
          emptyAccessWarning.value = false
        }
      },
      { deep: true }
    )

    return { userStore, state, dropdownAccessOptions, emptyAccessWarning, onSelectionChange }
  }
}
</script>

<style></style>
